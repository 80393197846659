import { last } from 'lodash/array';

import { updateAppByAction } from 'resources/utils';
import {
  FETCH_INVOICES,
  FETCH_INVOICE,
  FETCH_INVOICE_EMAIL,
  FETCH_INVOICES_PRODUCT_HISTORY,
  FETCH_INVOICES_OF_CLIENT,
  FETCH_INVOICES_OF_VEHICLE,
  FETCH_SALES_REPORT,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  CANCEL_INVOICE,
  REMOVE_INVOICES,
  CHANGE_INVOICES_FILTER,
  RESET_INVOICES_STATE,
  UPDATE_INVOICE_SOCKET,
  UPDATE_SDI_STATUS_SOCKET,
  EDIT_INVOICE,
  CHANGE_CURRENT_INTEGRATION,
  RESTORE_INVOICE,
  FETCH_INVOICE_COUNT_BY_STATUS,
} from './invoice.actions';

const initialInvoiceCount = {
  draft: null,
  preview: null,
  open: null,
  overdue: null,
  paid: null,
  paidCredit: null,
  costListing: null,
  all: null,
};

const initialState = {
  list: [],
  sales: [],
  currentInvoice: {},
  currentInvoiceEmail: {},
  totalAmount: 0,
  invoiceCounts: { ...initialInvoiceCount },
  currentIntegration: null,
};
const actionTypesToUpdateApp = [CREATE_INVOICE, UPDATE_INVOICE, CANCEL_INVOICE, REMOVE_INVOICES];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case FETCH_INVOICES:
    case FETCH_INVOICES_OF_CLIENT:
    case FETCH_INVOICES_OF_VEHICLE:
    case FETCH_INVOICES_PRODUCT_HISTORY:
    {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }
    case FETCH_INVOICE_COUNT_BY_STATUS:
      return {
        ...state,
        invoiceCounts: action.payload,
      };
    case CHANGE_INVOICES_FILTER:
      return {
        ...state,
        list: [...action.payload.results],
        totalAmount: action.payload.count,
      };
    case RESET_INVOICES_STATE:
      return {
        ...state,
        list: [],
        invoiceCounts: { ...initialInvoiceCount },
      };
    case REMOVE_INVOICES:
      return {
        ...state,
        list: state.list.filter((i) => !action.invoiceIds.includes(i._id)),
        totalAmount: state.totalAmount - action.invoiceIds.length,
        currentInvoice:
          action.invoiceIds.includes(state?.currentInvoice?._id) ? {} : state?.currentInvoice,
      };
    case FETCH_INVOICE:
      return {
        ...state,
        currentInvoice: {
          ...action.payload,
        },
      };
    case FETCH_INVOICE_EMAIL:
      return {
        ...state,
        currentInvoiceEmail: action.payload || {},
      };
    case CREATE_INVOICE:
      if (action.keepCurrentInvoice) return state;
      return {
        ...state,
        currentInvoice: action.payload,
      };
    case UPDATE_INVOICE_SOCKET:
      return {
        ...state,
        currentInvoice:
          state.currentInvoice._id === action.payload._id
            ? {
              ...state.currentInvoice,
              pdfs: action.payload.pdfs,
              invoiceEvents: action.payload.invoiceEvents,
            }
            : state.currentInvoice,
      };
    case UPDATE_SDI_STATUS_SOCKET:
      return {
        ...state,
        currentInvoice:
          state.currentInvoice._id === action.payload.invoiceId && action.payload.sdiStatus
            ? {
              ...state.currentInvoice,
              sdi: {
                ...state.currentInvoice.sdi,
                status: action.payload.sdiStatus,
              },
            }
            : state.currentInvoice,
      };
    case UPDATE_INVOICE:
      if (action.keepCurrentInvoice) return state;
      return {
        ...state,
        currentInvoice:
          state.currentInvoice._id === action.payload._id
            ? {
              ...state.currentInvoice,
              ...action.payload,
            }
            : state.currentInvoice,
      };
    case CANCEL_INVOICE:
      return {
        ...state,
        currentInvoice: action.payload,
      };
    case FETCH_SALES_REPORT:
      return {
        ...state,
        sales: action.payload,
      };
    case EDIT_INVOICE:
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
        },
      };
    case CHANGE_CURRENT_INTEGRATION:
      return {
        ...state,
        currentIntegration: action.payload,
      };
    case RESTORE_INVOICE:
      return {
        ...state,
        list: state.list.filter((invoice) => action.payload.invoice._id !== invoice._id),
        invoiceCounts: {
          ...state.invoiceCounts,
          all: state.invoiceCounts.all - 1,
          [action.payload.invoice.status]: state.invoiceCounts[action.payload.invoice.status] - 1,
        },
      };
    default:
      return state;
  }
};
