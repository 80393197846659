import React, { PureComponent } from 'react';
import cx from 'classnames';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faChevronDown } from 'fontawesome/pro-solid-svg-icons';

import adminStyles from './accordion.admin.styles.pcss';
import webStyles from './accordion.web.styles.pcss';
import noStyle from './accordion.noStyle.styles.pcss';

class Accordion extends PureComponent {
  static styles = {
    admin: adminStyles,
    web: webStyles,
    noStyle,
  };

  static propTypes = {
    id: PropTypes.string,
    expanded: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.element,
    onHeaderClicked: PropTypes.func,
    styleType: PropTypes.string,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    title: '',
    styleType: 'noStyle',
    expanded: null,
    children: null,
    onHeaderClicked: () => null,
    className: '',
    headerClassName: '',
  };

  constructor(props) {
    super(props);

    this.bodyRef = React.createRef();
    this.selectedStyle = Accordion.styles[props.styleType];

    this.state = {
      isExpanded: props.expanded,
      headerHeight: 36,
      bodyHeight: null,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    this.resizeObserver = new ResizeObserver((entry) => {
      this.setState({ bodyHeight: this.bodyRef.current.offsetHeight });
    });

    this.resizeObserver.observe(this.bodyRef.current);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isExpanded: this.props.expanded });
    }
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.bodyRef.current);
  }

  toggleExpanded = function toggleExpanded() {
    const { expanded, onHeaderClicked, id } = this.props;
    const { isExpanded } = this.state;
    onHeaderClicked(id, !isExpanded);
    if (expanded !== null) {
      return;
    }
    this.setState({ isExpanded: !isExpanded });
  };

  render() {
    const {
      children, title, id, className, headerClassName,
    } = this.props;
    const { isExpanded, bodyHeight } = this.state;

    if (bodyHeight === null && this.bodyRef.current) {
      this.setState({ bodyHeight: this.bodyRef.current.offsetHeight });
    }

    const bodyStyle = {
      height: isExpanded ? bodyHeight || 'auto' : '0px',
    };

    return (
      <div
        key={id}
        className={cx(
          this.selectedStyle.accordion,
          isExpanded && this.selectedStyle.accordionActive,
          className,
        )}
      >
        <div
            className={cx(
              this.selectedStyle.accordionHeader,
              isExpanded && this.selectedStyle.accordionHeaderActive,
              headerClassName,
            )}
            onClick={() => this.toggleExpanded()}
            role="button"
            tabIndex={0}
            onKeyDown={({ key }) => { if (key === 'Enter') this.toggleExpanded(); }}
          >
          <div className={this.selectedStyle.accordionTitle}>
            {title}
          </div>
          <FontAwesomeIcon
              icon={faChevronDown}
              className={cx(
                this.selectedStyle.accordionIcon,
                isExpanded && this.selectedStyle.accordionIconActive,
              )}
            />
        </div>
        <div
            className={cx(this.selectedStyle.accordionBody)}
            style={bodyStyle}
          >
          <div
              className={cx(this.selectedStyle.accordionBodyContent)}
              ref={this.bodyRef}
            >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default Accordion;
