import React, { PureComponent } from 'react';
import cx from 'classnames';

import PropTypes from 'prop-types';

import styles from './dropZone.styles.pcss';

class DropZone extends PureComponent {
  static STATUSES = {
    IDLE: 'idle',
    ACTIVE: 'active',
    DRAGGING: 'dragging',
    ERROR: 'error',
    HOVER: 'hover',
  };

  static propTypes = {
    removeDefaultStyle: PropTypes.bool,
    capture: PropTypes.string,
    fileExplorerEnabled: PropTypes.bool,
    disableMultiple: PropTypes.bool,
    showFileNames: PropTypes.bool,
    onStatusChanged: PropTypes.func,
    onFilesChanged: PropTypes.func,
    acceptedTypes: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.shape({}),
    className: PropTypes.shape({}),
    style: PropTypes.shape({}),
    files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    returnAs: PropTypes.string,
    accept: PropTypes.string,
  };

  static defaultProps = {
    removeDefaultStyle: false,
    fileExplorerEnabled: false,
    showFileNames: false,
    capture: null,
    disableMultiple: false,
    acceptedTypes: ['.gif', '.png', '.jpeg', '.jpg', '.pdf'],
    onStatusChanged: () => null,
    onFilesChanged: () => null,
    children: null,
    className: null,
    style: null,
    returnAs: 'fileString',
    accept: null,
  };

  state = {
    // eslint-disable-next-line no-use-before-define
    status: DropZone.STATUSES.IDLE,
    fileDragged: false,
  };

  componentDidMount() {
    const { onStatusChanged } = this.props;
    const { status } = this.state;
    window.addEventListener('dragenter', this.onDocumentEnter);
    window.addEventListener('dragleave', this.onDocumentLeave);

    onStatusChanged(status);
  }

  componentWillUnmount() {
    window.removeEventListener('dragenter', this.onDocumentEnter);
    window.removeEventListener('dragleave', this.onDocumentLeave);
  }

  lastEntered = 'palceholder';

  isValidFile = (e) => {
    return (e.dataTransfer.items[0] && e.dataTransfer.items[0].kind === 'file')
    || e.dataTransfer.types[0] === 'Files';
  };

  onDocumentLeave = (e) => {
    setTimeout(() => {
      const { status } = this.state;
      if (status === DropZone.STATUSES.DRAGGING
        && !this.dragTimeout
        && this.lastEntered === e.target
      ) {
        this.dragTimeout = setTimeout(() => {
          this.changeStatus(DropZone.STATUSES.IDLE);
        }, 1000);
      }
    }, 1);
  };

  onDocumentEnter = (e) => {
    if (this.isValidFile(e)) {
      const { status } = this.state;
      if (this.dragTimeout) {
        clearTimeout(this.dragTimeout);
        this.dragTimeout = null;
      }
      if (status === DropZone.STATUSES.IDLE || status === DropZone.STATUSES.ERROR) {
        this.changeStatus(DropZone.STATUSES.DRAGGING);
      }
      this.lastEntered = e.target;
    }
  };

  // So the file does't get opened in a new tab
  onDragOver = (e) => {
    if (this.isValidFile(e)) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  onDragEnter = (e) => {
    if (this.isValidFile(e)) {
      e.stopPropagation();
      e.preventDefault();
      this.changeStatus(DropZone.STATUSES.ACTIVE);
    }
  };

  onDragLeave = (e) => {
    if (this.isValidFile(e)) {
      e.stopPropagation();
      e.preventDefault();
      this.changeStatus(DropZone.STATUSES.DRAGGING);
    }
  };

  onFileDrop = (e) => {
    if (this.isValidFile(e)) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.changeStatus(DropZone.STATUSES.IDLE);
    const files = [];
    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        if (e.dataTransfer.items[i].kind === 'file') {
          files.push(e.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        files.push(e.dataTransfer.files[i]);
      }
    }

    if (!files.length) {
      this.changeStatus(DropZone.STATUSES.ERROR);
      return;
    }
    this.setState({ fileDragged: false });
    this.loadFiles(files);
  };

  onDropZoneClick = () => {
    const { fileExplorerEnabled } = this.props;
    if (fileExplorerEnabled) {
      this.onMouseLeave();
      this.filePickerRef.current.click();
    }
  };

  onMouseLeave = () => {
    const { status } = this.state;
    if (status !== DropZone.STATUSES.ERROR) {
      this.changeStatus(DropZone.STATUSES.IDLE);
    }
  };

  getCurrentClass = () => {
    const { removeDefaultStyle } = this.props;
    const { status } = this.state;

    if (removeDefaultStyle) return null;

    let currentClass = null;
    switch (status) {
      case DropZone.STATUSES.IDLE:
        currentClass = styles.dropZoneContainerIdle;
        break;
      case DropZone.STATUSES.ERROR:
        currentClass = styles.dropZoneContainerError;
        break;
      case DropZone.STATUSES.DRAGGING:
      case DropZone.STATUSES.ACTIVE:
      case DropZone.STATUSES.HOVER:
        currentClass = styles.dropZoneContainerActive;
        break;
      default:
        break;
    }
    return currentClass;
  };

  getDragAndDropClass = () => {
    const { status } = this.state;
    if (status === DropZone.STATUSES.ACTIVE || status === DropZone.STATUSES.DRAGGING) {
      return styles.dropZoneContainerEventsEnabled;
    }
    return styles.dropZoneContainerEventsDisabled;
  };

  filePickerRef = React.createRef();

  changeStatus = (newStatus) => {
    const { onStatusChanged } = this.props;
    if (newStatus === 'dragging') {
      this.setState({ status: newStatus, fileDragged: true });
    } else {
      this.setState({ status: newStatus });
    }
    onStatusChanged(newStatus);
  };

  isAcceptedType = (mimeType, filename) => {
    const { acceptedTypes } = this.props;
    // Check if mimeType or file extension are valid
    if (acceptedTypes.includes('*')) return true;
    return acceptedTypes.includes(`.${mimeType.split('/')[1]}`) || acceptedTypes.includes(filename.substr(filename.lastIndexOf('.')));
  };

  loadFiles = (filesToLoad) => {
    const {
      onFilesChanged,
      files,
      disableMultiple,
      returnAs,
    } = this.props;

    const newFilesArray = disableMultiple ? [] : [...files];

    (disableMultiple ? [filesToLoad[0]] : [...filesToLoad]).forEach((file) => {
      const reader = new FileReader();
      reader.onerror = () => {
        reader.abort();
      };

      reader.onload = (fileLoadedEvent) => {
        const {
          name, size, type, lastModified,
        } = file;
        if (this.isAcceptedType(type, name)) {
          newFilesArray.push({
            name, size, type, lastModified, data: fileLoadedEvent.target.result,
          });
          onFilesChanged([...newFilesArray]);
        } else {
          this.changeStatus(DropZone.STATUSES.ERROR);
        }
      };

      if (returnAs === 'base64') {
        reader.readAsDataURL(file);
      } else if (returnAs === 'file') {
        if (file && this.isAcceptedType(file.type, file.name)) {
          newFilesArray.push(file);
        } else {
          this.changeStatus(DropZone.STATUSES.ERROR);
        }
      } else {
        reader.readAsArrayBuffer(file);
      }
    });

    onFilesChanged([...newFilesArray]);
  };

  render() {
    const {
      children,
      className,
      style,
      acceptedTypes,
      fileExplorerEnabled,
      files,
      showFileNames,
      capture,
      accept,
      disableMultiple,
    } = this.props;
    const { fileDragged } = this.state;
    if (fileDragged || fileExplorerEnabled) {
      return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div
          className={cx(
            styles.dropZoneContainer,
            this.getCurrentClass(),
            fileExplorerEnabled ? styles.dropZoneContainerClickable : this.getDragAndDropClass(),
            className,
          )}
          style={style}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onDrop={(event) => this.onFileDrop(event)}
          onMouseOver={() => this.changeStatus(DropZone.STATUSES.HOVER)}
          onMouseLeave={this.onMouseLeave}
          onClick={this.onDropZoneClick}
          onKeyPress={({ key }) => {
            if (key === 'Enter') this.onDropZoneClick();
          }}
          role="button"
          tabIndex={fileExplorerEnabled ? 0 : -1}
        >
          <div
            style={{
              pointerEvents: 'none',
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            {children}
          </div>
          {showFileNames && files.map((file) =>
            <div style={{ pointerEvents: 'none' }} key={file.name}>{file.name}</div>)}
          <input
            className={styles.dropZoneFilePicker}
            multiple={!disableMultiple}
            type="file"
            id="fileInput"
            accept={accept ?? acceptedTypes}
            capture={capture}
            onChange={({ target }) => this.loadFiles(target.files)}
            onClick={() => null}
            ref={this.filePickerRef}
          />
        </div>
      );
    }
    return null;
  }
}

export default DropZone;
