import moment from 'moment';

import { downloadFile } from 'helpers/file';
import { addPageSize } from 'resources/utils';
import { DATE_FORMAT_MOMENT } from 'constants.js';
import * as fromUser from 'resources/user/user.selectors';
import * as api from './tires.api';

export const FETCH_TIRES = 'fetchTires';
export const RESET_TIRES_STATE = 'resetTiresState';
export const REMOVE_TIRES = 'removeTires';
export const UPDATE_TIRE = 'updateTire';
export const CREATE_TIRE = 'createTire';
export const CHANGE_TIRES_FILTER = 'changeTiresFilter';

export const fetchTires = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);

  return api
    .fetchTires(currentGarageId, data)
    .then((payload) => dispatch({ type: FETCH_TIRES, data, payload }));
};

const naturalSort = ({ aa, bb, sortDirection }) => {
  let a; let b; let a1; let b1;
  const as = aa.storageLocation;
  const bs = bb.storageLocation;
  const rx = /(\d+)|(\D+)/g; const rd = /\d/; const
    rz = /^0/;
  if (typeof as === 'number' || typeof bs === 'number') {
    if (Number.isNaN(as)) return 1 * sortDirection;
    if (Number.isNaN(bs)) return -1 * sortDirection;
    return (as - bs) * sortDirection;
  }
  a = String(as).toLowerCase();
  b = String(bs).toLowerCase();
  if (a === b) return 0;
  if (!(rd.test(a) && rd.test(b))) return (a > b ? 1 : -1) * sortDirection;
  a = a.match(rx);
  b = b.match(rx);
  while (a.length && b.length) {
    a1 = a.shift();
    b1 = b.shift();
    if (a1 !== b1) {
      if (rd.test(a1) && rd.test(b1)) {
        return (a1.replace(rz, '.0') - b1.replace(rz, '.0')) * sortDirection;
      }
      return (a1 > b1 ? 1 : -1) * sortDirection;
    }
  }
  return (a.length - b.length) * sortDirection;
};

export const changeTiresFilter = (options = {}) => async (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);

  return api
    .fetchTires(currentGarageId, data)
    .then((res) => {
      if (data.sortBy[0] === 'storageLocation') {
        res.results = res.results.sort((aa, bb) => naturalSort(
          { aa, bb, sortDirection: data.sortDirection },
        ));
      }
      return res;
    })
    .then((payload) => dispatch({ type: CHANGE_TIRES_FILTER, data, payload }));
};

export const resetTiresState = () => ({ type: RESET_TIRES_STATE });

export const createTire = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const validData = {
    ...data,
    clientId: data.client.clientId,
    vehicleId: data.vehicle.vehicleId,
    storedOn: data.storedOn ? moment.utc(data.storedOn, DATE_FORMAT_MOMENT) : null,
    removedOn: data.removedOn ? moment.utc(data.removedOn, DATE_FORMAT_MOMENT) : null,
  };

  return api
    .createTire(currentGarageId, validData)
    .then((payload) => dispatch({ type: CREATE_TIRE, payload }));
};

export const removeTires = (tireIds) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api
    .removeTires(currentGarageId, tireIds)
    .then(() => dispatch({ type: REMOVE_TIRES, tireIds }));
};

export const updateTire = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const validData = {
    ...data,
    clientId: data.client.clientId,
    vehicleId: data.vehicle.vehicleId,
    storedOn: data.storedOn ? moment.utc(data.storedOn, DATE_FORMAT_MOMENT) : null,
    removedOn: data.removedOn ? moment.utc(data.removedOn, DATE_FORMAT_MOMENT) : null,
  };

  return api
    .updateTire(currentGarageId, validData)
    .then((payload) => dispatch({ type: UPDATE_TIRE, data: validData, payload }));
};

export const getTiresCSV = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.getTiresCSV(currentGarageId, data).then((payload) => downloadFile(payload));
};

export const validateTireStorage = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.validateTireStorage(currentGarageId, data);
};

export const printStorageConfirmation = (tireId) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.printStorageConfirmation(garageId, tireId);
};

export const getConfirmationPdfUrl = (tireId) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.getConfirmationPdfUrl(garageId, tireId);
};

export const fetchPreviousTires = (options = {}) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  const data = addPageSize(options);
  return api.fetchTires(garageId, {
    ...data,
    sortBy: ['storedOn'],
    storedOnLowerBound: moment.utc().subtract(18, 'months').format('X'),
  });
};
