let nextId = 0;
const updateCallbacks = [];

// downloadStruct
// {id, progress, maxProgress, displayText}
let downloads = [];

function getId() {
  nextId += 1;
  return nextId;
}

const registerUpdateCallback = (callback) => {
  updateCallbacks.push(callback);
};

function nofityUpdate() {
  updateCallbacks.forEach((callback) => {
    callback([...downloads]);
  });
}

const getDownloadById = (id) => {
  if (!downloads) {
    return {};
  }
  return [...downloads].find((download) => download.id === id);
};

const removeDownloadById = (id) => {
  if (!downloads) {
    return {};
  }
  downloads = downloads.filter((download) => download.id !== id);
  nofityUpdate();
  return id;
};

const addDownload = (progress, maxProgress, displayText = '') => {
  const newDownload = {
    id: getId(), progress, maxProgress, displayText,
  };
  downloads.push(newDownload);
  nofityUpdate();
  return newDownload.id;
};

const setProgress = (id, progress) => {
  const downloadToChange = downloads.find((download) => { return id === download.id; });
  if (!downloadToChange) return {};
  downloadToChange.progress = progress;
  nofityUpdate();
  return { ...downloadToChange };
};

const setDisplayText = (id, displayText) => {
  const downloadToChange = downloads.find((download) => { return id === download.id; });
  if (!downloadToChange) return {};
  downloadToChange.displayText = displayText;
  nofityUpdate();
  return { ...downloadToChange };
};

const incrementProgress = (id, progress) => {
  const downloadToChange = downloads.find((download) => id === download.id);
  if (!downloadToChange) return {};
  downloadToChange.progress += progress;
  nofityUpdate();
  return { ...downloadToChange };
};

const getProgressById = (id) => {
  const requestedDownload = downloads.find((download) => id === download.id);
  if (!requestedDownload) return {};
  return requestedDownload.progress;
};

const getProgressByIdInPercent = (id) => {
  const requestedDownload = downloads.find((download) => id === download.id);
  if (!requestedDownload) return {};
  return Math.round((requestedDownload.progress / requestedDownload.maxProgress) * 100);
};

const DownloadManager = {
  getDownloads: () => downloads,
  getDownloadById,
  addDownload,
  setProgress,
  registerUpdateCallback,
  incrementProgress,
  setDisplayText,
  removeDownloadById,
  getProgressById,
  getProgressByIdInPercent,
};

// Disallow new properties on our object
Object.freeze(DownloadManager);

export default DownloadManager;
