import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './tabNavigationModern.styles.pcss';

export default function TabNavigationModern({
  tabs,
  selectedTab,
  onChangeTab,
  className,
  dataTest,
  showTitleOnSelect,
  hideTitle,
}) {
  const tabElements = tabs.map((tab) => (
    <button
      type="button"
      key={tab.key}
      className={cx(
        styles.tabHeaderTab,
        tab.className,
        {
          [styles.tabHeaderActive]: selectedTab === tab.key,
          [styles.tabHeaderWithoutTitle]:
            (showTitleOnSelect && selectedTab !== tab.key) || hideTitle,
        },
      )}
      onClick={() => onChangeTab(tab.key)}
      data-test={`${dataTest}${tab.key.charAt(0).toUpperCase() + tab.key.slice(1)}`}
    >
      <div>
        <div className={cx(
          styles.tabHeaderIcon,
          {
            [styles.tabHeaderIconWithoutTitle]:
            (showTitleOnSelect && selectedTab !== tab.key) || hideTitle,
          },
        )}>
          {tab.icon}
        </div>
        {((!showTitleOnSelect || selectedTab === tab.key) && !hideTitle) && tab.title}
      </div>
      {tab.count !== null
      && tab.count !== undefined
      && tab.count !== 0 && (
        <div className={styles.searchResult}>
          {tab.count}
        </div>
      )}
      {tab.count === 0 && (
        <div className={styles.searchResultNone}>
          0
        </div>
      )}
    </button>
  ));

  return (
    <div className={cx(className, styles.container)}>
      {tabElements}
    </div>
  );
}

TabNavigationModern.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    count: PropTypes.number,
  })).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  showTitleOnSelect: PropTypes.bool,
  hideTitle: PropTypes.bool,
};

TabNavigationModern.defaultProps = {
  className: '',
  onChangeTab: () => {},
  dataTest: '',
  showTitleOnSelect: false,
  hideTitle: false,
};
