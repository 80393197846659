import { last } from 'lodash/array';

import { updateAppByAction, getCustomer } from 'resources/utils';
import { UPDATE_CLIENT } from 'resources/clients/clients.actions';
import {
  FETCH_VEHICLES,
  FETCH_VEHICLES_BY_CLIENT_ID,
  FETCH_VEHICLES_INSPECTIONS,
  LAZY_FETCH_VEHICLES,
  LAZY_FETCH_VEHICLES_BY_CLIENT_ID,
  LAZY_FETCH_VEHICLES_INSPECTIONS,
  FETCH_VEHICLE,
  RESET_VEHICLES_STATE,
  RESET_VEHICLES_STATE_INSPECTION,
  RESET_VEHICLES_STATE_BY_CLIENT_ID,
  RESET_CURRENT_VEHICLE,
  CREATE_VEHICLE,
  REMOVE_VEHICLE,
  UPDATE_VEHICLE,
  CHANGE_VEHICLES_FILTER,
  CHANGE_VEHICLES_FILTER_BY_CLIENT_ID,
  CHANGE_VEHICLES_FILTER_INSPECTIONS,
  UPDATE_CURRENT_VEHICLE,
  UPDATE_MILEAGE,
  UPDATE_INSPECTION,
  RESTORE_VEHICLE,
} from './vehicles.actions';

const initialState = { list: [], currentVehicle: {}, totalAmount: 0 };
const actionTypesToUpdateApp = [
  CREATE_VEHICLE,
  REMOVE_VEHICLE,
  UPDATE_VEHICLE,
  UPDATE_CURRENT_VEHICLE,
];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case FETCH_VEHICLES:
    case FETCH_VEHICLES_BY_CLIENT_ID:
    case FETCH_VEHICLES_INSPECTIONS: {
      return {
        ...state,
        list: [...action.payload.results],
        totalAmount: action.payload.count,
      };
    }
    case LAZY_FETCH_VEHICLES:
    case LAZY_FETCH_VEHICLES_BY_CLIENT_ID:
    case LAZY_FETCH_VEHICLES_INSPECTIONS: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }

    case UPDATE_CLIENT: {
      const { payload } = action;
      const { currentVehicle } = state;

      if (currentVehicle._id) {
        return {
          ...state,
          currentVehicle: {
            ...currentVehicle,
            client: getCustomer(payload),
          },
        };
      }

      return state;
    }

    case RESET_VEHICLES_STATE:
    case RESET_VEHICLES_STATE_BY_CLIENT_ID:
    case RESET_VEHICLES_STATE_INSPECTION:
      return {
        ...state,
        list: [],
      };

    case RESET_CURRENT_VEHICLE:
      return { ...state, currentVehicle: {} };

    case UPDATE_MILEAGE:
    case UPDATE_INSPECTION:
    case UPDATE_VEHICLE:
      return {
        ...state,
        currentVehicle: action.payload,
        list: state.list.map((vehicle) => (vehicle._id === action.payload._id ? {
          ...vehicle,
          ...action.payload,
        } : vehicle)),
      };

    case REMOVE_VEHICLE:
      return {
        ...state,
        list: state.list.filter((vehicle) => !action.vehicleIds.includes(vehicle._id)),
        totalAmount: state.totalAmount - action.vehicleIds.length,
      };

    case CHANGE_VEHICLES_FILTER:
    case CHANGE_VEHICLES_FILTER_BY_CLIENT_ID:
    case CHANGE_VEHICLES_FILTER_INSPECTIONS:
      return {
        ...state,
        list: [...action.payload.results],
        totalAmount: action.payload.count,
      };

    case FETCH_VEHICLE:
      return {
        ...state,
        currentVehicle: action.payload,
      };

    case UPDATE_CURRENT_VEHICLE:
      return {
        ...state,
        currentVehicle: action.vehicle,
      };

    case CREATE_VEHICLE:
      return {
        ...state,
        currentVehicle: action.payload,
      };

    case RESTORE_VEHICLE:
      return {
        ...state,
        list: state.list.filter((vehicle) => action.payload?.vehicles?.[0]?._id !== vehicle._id
          && !action.payload.vehicles.find((vehicleToFind) => vehicleToFind._id === vehicle._id)),
        totalAmount: state.totalAmount - (action.payload?.vehicles?.length || 0),
      };
    default:
      return state;
  }
};
