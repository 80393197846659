import React from 'react';
import PropTypes from 'prop-types';

const Carta = ({ className, width, height }) => (
  <svg className={className} id="svgIcon" width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-0.000488281 0.65567H4.02382L4.15229 1.33359L4.23562 1.76815H19.9995L17.2217 10.668H13.255C13.193 10.0828 13.0475 9.52254 12.8308 8.99926H15.996L17.7322 3.43687H4.5516L4.98149 5.69481C4.42671 5.91765 3.91257 6.22028 3.45328 6.58849L2.64187 2.32439H-0.000488281V0.65567Z"
      fill="var(--primary-color)"
    />
    <path
      d="M12.6588 14.0054H17.7773V12.3367H13.2028C13.1024 12.9264 12.9167 13.487 12.6588 14.0054Z"
      fill="var(--primary-color)"
    />
    <path
      d="M17.2891 15.6067C17.6017 15.9196 17.7773 16.344 17.7773 16.7866C17.7773 17.2292 17.6017 17.6536 17.2891 17.9666C16.9766 18.2795 16.5527 18.4553 16.1106 18.4553C15.6686 18.4553 15.2447 18.2795 14.9321 17.9666C14.6196 17.6536 14.444 17.2292 14.444 16.7866C14.444 16.344 14.6196 15.9196 14.9321 15.6067C15.2447 15.2937 15.6686 15.1179 16.1106 15.1179C16.5527 15.1179 16.9766 15.2937 17.2891 15.6067Z"
      fill="var(--primary-color)"
    />
    <path
      d="M3.66104 19.6464L6.50882 16.8107C8.34029 17.0518 9.74309 16.5848 11.0852 15.2428C12.0617 14.2662 12.5801 12.8007 12.6275 11.4372C12.6536 10.6848 12.5406 9.92273 12.2612 9.22182C12.1873 9.03665 12.102 8.85607 12.0051 8.68185L11.8666 8.43276L8.7576 11.5417L7.30386 11.2459L7.00796 9.79212L10.1169 6.68317L9.86785 6.54465C9.03201 6.07974 8.06304 5.88976 7.11207 5.92304C5.74948 5.9707 4.28277 6.48856 3.30678 7.46453C1.96844 8.8029 1.50282 10.193 1.73613 12.0195L-2.2373 15.981V18.1974L-1.15728 17.1172L2.45807 13.5146L3.44112 12.5316L3.40747 12.4106C3.02727 11.0432 3.40857 9.57956 4.41523 8.57292C5.14831 7.83989 6.13896 7.4304 7.1627 7.42052L5.30388 9.2793L5.97469 12.575L9.27038 13.2458L11.1292 11.387C11.1193 12.4107 10.7099 13.4013 9.97678 14.1344C8.96561 15.1456 7.49343 15.5254 6.12161 15.1372L6.00016 15.1028L5.01871 16.0843L1.38414 19.7056L1.3846 19.7062L0.594894 20.4959H2.81154L3.66104 19.6464Z"
      fill="var(--secondary-color)"
    />
    <path
      d="M0.531914 19.0003L3.31311 16.2289L2.20409 15.1199L-0.576342 17.892L0.531914 19.0003Z"
      fill="var(--secondary-color)"
    />
  </svg>
);

Carta.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

Carta.defaultProps = {
  width: '20',
  height: '19',
  className: '',
};

export default Carta;
