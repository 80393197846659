import { PRODUCT_TYPES } from 'shared-library/src/definitions/productTypes';
import { last } from 'lodash/array';

import { updateAppByAction } from 'resources/utils';
import {
  CREATE_PRODUCT,
  FETCH_PRODUCTS,
  FETCH_REPORT,
  CHANGE_REPORT_FILTER,
  RESET_PRODUCTS_STATE,
  CHANGE_PRODUCTS_FILTER,
  REMOVE_PRODUCTS,
  UPDATE_PRODUCT,
  REMOVE_NONE_PRODUCTS,
  REMOVE_NONE_OTHER_PRODUCTS,
  REMOVE_ISPART_PRODUCTS,
  RESTORE_PRODUCT,
} from './product.actions';

const initialProductsCount = {
  product: null,
  work: null,
  other: null,
  positionGroups: null,
  suppliers: null,
  all: null,
};

const initialState = {
  list: [],
  report: [],
  totalAmount: 0,
  productsCounts: { ...initialProductsCount },
};
const actionTypesToUpdateApp = [CREATE_PRODUCT, REMOVE_PRODUCTS, UPDATE_PRODUCT];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case CREATE_PRODUCT:
      return {
        ...state,
        list: [...state.list, action.payload],
        totalAmount: state.totalAmount + 1,
      };

    case FETCH_PRODUCTS: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
        productsCounts: action.data.productsCounts || { ...initialProductsCount },
      };
    }

    case RESET_PRODUCTS_STATE:
      return {
        ...initialState,
      };

    case CHANGE_PRODUCTS_FILTER:
      return {
        ...state,
        list: action.payload.results || [],
        totalAmount: action.payload.count,
        productsCounts: action.data.productsCounts || { ...initialProductsCount },
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        list: state.list.map((p) => (
          p._id === action.productId ? action.payload : p
        )),
      };

    case REMOVE_PRODUCTS:
      return {
        ...state,
        list: state.list.filter((p) => !action.productIds.includes(p._id)),
        totalAmount: state.totalAmount - action.productIds.length,
      };

    case REMOVE_NONE_PRODUCTS: {
      const newList = state.list
        .filter((p) => (p.type === PRODUCT_TYPES.PRODUCT));
      return {
        ...state,
        list: newList,
        totalAmount: newList.length,
      };
    }

    case REMOVE_NONE_OTHER_PRODUCTS: {
      const newList = state.list
        .filter((p) => (p.type === PRODUCT_TYPES.OTHER));
      return {
        ...state,
        list: newList,
        totalAmount: newList.length,
      };
    }

    case REMOVE_ISPART_PRODUCTS: {
      const newList = state.list.filter((p) => p.type === PRODUCT_TYPES.WORK);
      return {
        ...state,
        list: newList,
        totalAmount: newList.length,
      };
    }

    case FETCH_REPORT: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.report);
      const lastFromResponse = last(action.payload);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        report: [...state.report, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }

    case CHANGE_REPORT_FILTER:
      return {
        ...state,
        report: action.payload.results || [],
        totalAmount: action.payload.count,
      };

    case RESTORE_PRODUCT:
      return {
        ...state,
        list: state.list.filter((product) => action.payload.product._id !== product._id),
        totalAmount: state.totalAmount - 1,
      };
    default:
      return state;
  }
};
