import { getLocale } from 'services/localization';
import qs from 'querystring';

const { apiUrl, token } = window.config;

let controller = new AbortController();
let { signal } = controller;

const buildUrl = (path, queryStringObject = {}) =>
  `${apiUrl}/${path}?${qs.stringify(queryStringObject)}`;
const responseHandler = (response, responseOptions = {}) => {
  // custom header with server version. needed to update up in case API version !== client version
  window.config.serverVersion = response.headers.get('Version');

  if (response.status >= 500) {
    return response.text()
      .then((text) => { throw new Error(text); });
  }

  const isJSON = (response.headers.get('Content-Type') || []).includes('application/json');
  const shouldBeBlob = !isJSON && response.status !== 204 && !(response.headers.get('Content-Type') || []).includes('text/plain');

  if (response.status >= 400) {
    if (isJSON) {
      return response.json()
        .then((obj) => { throw new Error(JSON.stringify({ ...obj, status: response.status })); });
    }

    return response.text()
      .then((text) => {
        const err = new Error(text);
        err.responseStatus = response.status;
        throw err;
      });
  }

  if (shouldBeBlob) {
    return response.blob();
  }

  if (responseOptions.returnArrayBuffer) {
    return response.arrayBuffer();
  }
  return isJSON ? response.json() : response.text();
};

const errorHandler = (err) => {
  if (err.name !== 'AbortError') {
    return Promise.reject(err);
  }

  return new Promise(() => {});
};

const localeHeaders = {
  'X-Locale-Easywk': getLocale(),
};

const authHeaders = {
  Authorization: `Bearer ${token}`,
};

const baseHeaders = {
  ...localeHeaders,
  ...authHeaders,
};

const getJsonHeaders = {
  Accept: 'application/json',
  ...baseHeaders,
};

const postJsonHeaders = {
  ...getJsonHeaders,
  'Content-Type': 'application/json',
};

export const get
  = (path, queryStringObject, options = {}, headers = {}, responseOptions = {}) =>
    fetch(buildUrl(path, queryStringObject), {
      headers: { ...getJsonHeaders, ...headers },
      signal,
      ...options,
    })
      .then((res) => responseHandler(res, responseOptions))
      .catch(errorHandler);

export const post
  = (path, queryStringObject, body, options = {}, headers = {}, responseOptions = {}) =>
    fetch(buildUrl(path, queryStringObject), {
      method: 'POST',
      headers: { ...postJsonHeaders, ...headers },
      body: JSON.stringify(body),
      signal,
      ...options,
    })
      .then((res) => responseHandler(res, responseOptions))
      .catch(errorHandler);

export const postRaw
  = (path, queryStringObject, body, options = {}, headers = {}, responseOptions = {}) =>
    fetch(buildUrl(path, queryStringObject), {
      method: 'POST',
      headers: { ...authHeaders, ...headers },
      body,
      signal,
      ...options,
    })
      .then((res) => responseHandler(res, responseOptions))
      .catch(errorHandler);

export const put = (path, queryStringObject, body) => fetch(buildUrl(path, queryStringObject), {
  method: 'PUT',
  headers: postJsonHeaders,
  body: JSON.stringify(body),
  signal,
})
  .then(responseHandler)
  .catch(errorHandler);

export const del = (path, queryStringObject, body) => fetch(buildUrl(path, queryStringObject), {
  method: 'DELETE',
  headers: postJsonHeaders,
  body: JSON.stringify(body),
  signal,
})
  .then(responseHandler)
  .catch(errorHandler);

export const cancelActiveRequest = () => {
  controller.abort();

  controller = new AbortController();
  signal = controller.signal;
};
