const fetcher = fetch;

class Dymo {
  constructor(options) {
    // eslint-disable-next-line no-param-reassign
    options = options || {};

    this.hostname = options.hostname || '127.0.0.1';
    this.port = options.port || 41951;
    this.printerName = options.printerName;
  }

  static WEBSERVICE_VERSIONS = {
    LABEL: 'dymoLabel',
    CONNECT: 'dymoConnect',
  };

  static currentWebserviceVersion = Dymo.WEBSERVICE_VERSIONS.LABEL;

  get apiUrl() {
    return `https://${this.hostname}:${this.port}/DYMO/DLS/Printing`;
  }

  print(printerName, labelXml, labelSetXml = '') {
    const label = `printerName=${encodeURIComponent(printerName)}&printParamsXml=&labelXml=${encodeURIComponent(labelXml)}&labelSetXml=${encodeURIComponent(labelSetXml)}`;

    return fetcher(`${this.apiUrl}/PrintLabel2`, {
      method: 'POST',
      body: label,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => response.text());
  }

  renderLabel(labelXml) {
    const label = `printerName=&renderParamsXml=&labelXml=${encodeURIComponent(labelXml)}&labelSetXml=`;

    return fetcher(
      `${this.apiUrl}/RenderLabel`,
      {
        method: 'POST',
        body: label,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    ).then((response) => response.text());
  }

  getStatus() {
    return fetcher(`${this.apiUrl}/StatusConnected`)
      .then((response) => response.text());
  }

  getPrinters() {
    return fetcher(`${this.apiUrl}/GetPrinters`)
      .then((response) => {
        if (navigator.platform.toUpperCase().indexOf('MAC') === -1) {
          Dymo.currentWebserviceVersion = Dymo.WEBSERVICE_VERSIONS.CONNECT;
        } else {
          Dymo.currentWebserviceVersion = Dymo.WEBSERVICE_VERSIONS.LABEL;
        }
        return response.text();
      });
    // .then(res => res.replace(/<\\\//g, '</'));
  }
}

export default Dymo;
