import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './customLink.styles.pcss';

class CustomLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    value: null,
    children: null,
    type: '',
    onClick: () => {},
  };

  getType = () => {
    const { type } = this.props;
    switch (type) {
      case 'mail':
        return 'mailto:';
      case 'phone':
        return 'tel:';
      default:
        return '';
    }
  };

  render() {
    const {
      children,
      value,
      className,
      onClick,
    } = this.props;

    return (
      <div className={className}>
        <a className={styles.customLink} href={value ? `${this.getType()}${value}` : undefined} onClick={onClick}>
          {children ?? value}
        </a>
      </div>
    );
  }
}

export default CustomLink;
