import React from 'react';
import PropTypes from 'prop-types';

const CameraViewfinder = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 0H1C0.447715 0 0 0.447715 0 1V5.5C0 6.05228 0.447715 6.5 1 6.5C1.55228 6.5 2 6.05228 2 5.5V3C2 2.44772 2.44772 2 3 2H5.5C6.05228 2 6.5 1.55228 6.5 1C6.5 0.447715 6.05228 0 5.5 0Z" fill="#454953" />
      <path d="M20 5.5V1C20 0.447715 19.5523 0 19 0L14.5 0C13.9477 0 13.5 0.447715 13.5 1C13.5 1.55228 13.9477 2 14.5 2H17C17.5523 2 18 2.44772 18 3V5.5C18 6.05228 18.4477 6.5 19 6.5C19.5523 6.5 20 6.05228 20 5.5Z" fill="#454953" />
      <path d="M13.5 20H19C19.5523 20 20 19.5523 20 19V13.5C20 12.9477 19.5523 12.5 19 12.5C18.4477 12.5 18 12.9477 18 13.5V17C18 17.5523 17.5523 18 17 18H13.5C12.9477 18 12.5 18.4477 12.5 19C12.5 19.5523 12.9477 20 13.5 20Z" fill="#454953" />
      <path d="M0 13.5L0 19C0 19.5523 0.447715 20 1 20H6.5C7.05228 20 7.5 19.5523 7.5 19C7.5 18.4477 7.05228 18 6.5 18H3C2.44772 18 2 17.5523 2 17L2 13.5C2 12.9477 1.55228 12.5 1 12.5C0.447715 12.5 0 12.9477 0 13.5Z" fill="#454953" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 13.375V6.625C16 6.00391 15.4961 5.5 14.875 5.5H13C12.652 5.5 12.5219 4.72891 12.5219 4.72891C12.3578 4.29063 11.9383 4 11.4695 4H8.52813C8.05938 4 7.63984 4.29063 7.47578 4.72891C7.47578 4.72891 7.32149 5.5 7 5.5H5.125C4.50391 5.5 4 6.00391 4 6.625V13.375C4 13.9961 4.50391 14.5 5.125 14.5H14.875C15.4961 14.5 16 13.9961 16 13.375ZM10 12.5C11.5188 12.5 12.75 11.2688 12.75 9.75C12.75 8.23122 11.5188 7 10 7C8.48122 7 7.25 8.23122 7.25 9.75C7.25 11.2688 8.48122 12.5 10 12.5Z" fill="#454953" />
    </svg>
  );
};

CameraViewfinder.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

CameraViewfinder.defaultProps = {
  width: '20',
  height: '20',
};

export default CameraViewfinder;
