import React from 'react';
import { toast } from 'react-toastify';
import deviceService from 'services/device.service';

const options = {
  autoClose: 6000,
  closeOnClick: false,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_CENTER,
  pauseOnHover: true,
};

function show({ level, message, component = <></> }) {
  toast.dismiss();
  options.position = deviceService.isMobileSize
    ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_CENTER;
  options.type = level;
  toast(
    <div data-test={`${level}Toast`}>
      {message}
      <div style={{ textAlign: 'center' }}>
        {component}
      </div>
    </div>,
    options,
  );
  options.type = null;
}

export default {
  showSuccess(text, component = <></>) {
    show({ level: toast.TYPE.SUCCESS, message: text, component });
  },

  showWarning(text, component = <></>) {
    show({ level: toast.TYPE.WARNING, message: text, component });
  },

  showError(text, component = <></>) {
    show({ level: toast.TYPE.ERROR, message: text, component });
  },

  showInfo(text, component = <></>) {
    show({ level: toast.TYPE.INFO, message: text, component });
  },

  dismiss() {
    toast.dismiss();
  },
};
