import React from 'react';
import PropTypes from 'prop-types';

const ManualIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 147 161" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <path xmlns="http://www.w3.org/2000/svg" d="M11 9.99526C11 4.47503 15.4804 0 20.999 0H110.598L147 36.3488V151.001C147 156.523 142.514 161 137 161H21.0004C15.4773 161 11 156.526 11 151.005V9.99526Z" fill="#F2F6FB" />
    <path xmlns="http://www.w3.org/2000/svg" d="M110.598 0L147 36.3488L112.847 42.5011C107.409 43.4808 103.756 39.8679 104.691 34.4215L110.598 0Z" fill="#D8DEED" />
    <path xmlns="http://www.w3.org/2000/svg" d="M42 110.505V68.0502C42 67.1879 42.3711 66.3673 43.0185 65.7978C48.1215 61.3085 54.3528 59.4263 61.7125 60.1512C69.5692 60.925 74.7777 64.9116 77.3381 72.1109V118.519C77.3381 119.071 76.8904 119.519 76.3381 119.519C76.026 119.519 75.7319 119.373 75.5427 119.125C72.0116 114.492 68.0103 111.594 63.5386 110.431C59.1199 109.282 53.3312 110.227 46.1725 113.266C44.6474 113.914 42.8862 113.203 42.2386 111.678C42.0812 111.307 42 110.908 42 110.505ZM116.428 110.505C116.428 110.908 116.347 111.307 116.189 111.678C115.542 113.203 113.78 113.914 112.255 113.266C105.097 110.227 99.308 109.282 94.8893 110.431C90.4176 111.594 86.4162 114.492 82.8851 119.125C82.696 119.373 82.4018 119.519 82.0898 119.519C81.5375 119.519 81.0897 119.071 81.0898 118.519V72.1109C83.6501 64.9116 88.8586 60.925 96.7154 60.1512C104.075 59.4263 110.306 61.3085 115.409 65.7978C116.057 66.3673 116.428 67.1879 116.428 68.0502V110.505Z" fill="#AAD9D0" />
    <path xmlns="http://www.w3.org/2000/svg" d="M28 44.5994C27.443 44.5994 26.9089 44.8206 26.5151 45.2144C26.1212 45.6082 25.9 46.1423 25.9 46.6993V64.1984C25.9 64.7553 26.1212 65.2894 26.5151 65.6832C26.9089 66.077 27.443 66.2983 28 66.2983C28.5569 66.2983 29.0911 66.077 29.4849 65.6832C29.8787 65.2894 30.1 64.7553 30.1 64.1984V46.6993C30.1 46.1423 29.8787 45.6082 29.4849 45.2144C29.0911 44.8206 28.5569 44.5994 28 44.5994ZM28 75.3922C27.2574 75.3922 26.5452 75.0972 26.0201 74.5722C25.495 74.0471 25.2 73.3349 25.2 72.5924C25.2 71.8498 25.495 71.1376 26.0201 70.6126C26.5452 70.0875 27.2574 69.7925 28 69.7925C28.7426 69.7925 29.4548 70.0875 29.9799 70.6126C30.505 71.1376 30.8 71.8498 30.8 72.5924C30.8 73.3349 30.505 74.0471 29.9799 74.5722C29.4548 75.0972 28.7426 75.3922 28 75.3922V75.3922ZM28 32C12.5356 32 -1.33514e-06 44.535 -1.33514e-06 59.9986C-1.33514e-06 75.4622 12.5356 87.9972 28 87.9972C32.4688 88.0033 36.8737 86.9356 40.8436 84.8837L51.5564 87.8712C52.1541 88.038 52.7853 88.0429 53.3856 87.8854C53.9858 87.7279 54.5333 87.4137 54.9721 86.9749C55.4108 86.5362 55.7251 85.9887 55.8826 85.3885C56.0401 84.7883 56.0352 84.1571 55.8684 83.5594L52.878 72.8555C54.935 68.8824 56.0058 64.4726 56 59.9986C56 44.535 43.4644 32 28 32ZM28 36.1998C34.3121 36.1998 40.3658 38.7072 44.8291 43.1703C49.2925 47.6334 51.8 53.6868 51.8 59.9986C51.8 64.1144 50.7556 68.0706 48.7956 71.5816L48.3756 72.3376L51.4892 83.4922L40.3256 80.3788L39.5696 80.7987C36.3933 82.5646 32.8548 83.5796 29.2254 83.7661C25.596 83.9525 21.9721 83.3053 18.6316 81.8742C15.2911 80.443 12.3228 78.2659 9.95415 75.5098C7.58552 72.7537 5.87954 69.4918 4.96703 65.9743C4.05452 62.4567 3.95972 58.7769 4.68989 55.217C5.42007 51.6571 6.95583 48.3117 9.1794 45.4373C11.403 42.5629 14.2553 40.2358 17.5177 38.6346C20.7801 37.0334 24.3658 36.2005 28 36.1998V36.1998Z" fill="#429488" />
  </svg>
);

ManualIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ManualIcon.defaultProps = {
  width: '147',
  height: '161',
};

export default ManualIcon;
