import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import pageStyles from 'styles/page.pcss';

import styles from './noEntitiesModern.styles.pcss';

const NoEntitiesModern = ({
  children, withoutBorder, className,
}) => (
  <div className={cx({ [pageStyles.pageContent]: !withoutBorder })}>
    <div className={cx(styles.noEntitiesContent, className)}>
      <div className={styles.noEntitiesCreate}>
        {children}
      </div>
    </div>
  </div>
);

NoEntitiesModern.propTypes = {
  children: PropTypes.node,
  withoutBorder: PropTypes.bool,
  className: PropTypes.string,
};

NoEntitiesModern.defaultProps = {
  children: null,
  withoutBorder: false,
  className: '',
};

export default NoEntitiesModern;
