import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DATE_FORMAT_MOMENT } from 'constants';
import toastService from 'helpers/toastService';
import polyglot from 'services/localization';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faPlus } from 'fontawesome/pro-solid-svg-icons';
import AddonManager from 'components/common/addonManager';
import ButtonModern from 'components/common/buttonModern';

const VecosIntegration = ({
  vehicle,
  className,
}) => {
  const createVecosReport = async () => {
    const url = 'https://portal-test.vecos.at';

    const { client } = vehicle;

    if (
      !vehicle?.dateOfFirstRegistration ||
      (!vehicle?.license && !vehicle?.identificationNumber)
    ) {
      toastService.showError(polyglot.t('vecosIntegration.error.missingData'));
      return;
    }

    const body = {
      LicenseNumber: vehicle?.license || undefined,
      RegistrationDate: vehicle?.dateOfFirstRegistration
        ? moment(vehicle?.dateOfFirstRegistration).format(DATE_FORMAT_MOMENT) : undefined,
      VehicleIdentificationNumber: vehicle?.identificationNumber || undefined,
      Name1: client?.lastName || undefined,
      Name2: client?.firstName || undefined,
      Street: client?.address || undefined,
      ZIP: client?.zipCode || undefined,
      City: client?.city || undefined,
      IsCompany: (client.formOfAddress === 'company') ? 1 : 0,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    try {
      const res = await fetch(`${url}/InspectionReport/PopulateExt/`, requestOptions);
      const data = await res.json();
      window.open(`${url}/InspectionReport/CreateExt?key=${data.key}`, '_blank', 'noopener');
    } catch (x) {
      //
    }
  };

  return (
    <AddonManager
      features={['supplierProducts']}
        actionOnPermission={createVecosReport}
      >
      {(action) => (
        <ButtonModern className={className} styleType={'select'} size="small" title="Vecos" onClick={action}>
          <FontAwesomeIcon icon={faPlus} />
          Vecos
        </ButtonModern>
      )}
    </AddonManager>
  );
};

VecosIntegration.propTypes = {
  vehicle: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default VecosIntegration;
