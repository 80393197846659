import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import inputStyles from 'styles/input.pcss';
import IbanInput from 'components/common/ibanInput';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import {
  faMobileAlt, faPhone, faSearch, faXmark,
} from 'fontawesome/pro-solid-svg-icons';
import {
  MdEmail as EmailIcon,
  MdEdit as EditIcon,
} from 'react-icons/md';
import FormValidator from 'helpers/formValidation/formValidator';
import ValidatableComponent from 'helpers/formValidation/validatableComponent';
import styles from './inputModern.styles.pcss';

export default class InputModern extends ValidatableComponent {
  static TYPES = {
    EDIT: 'edit',
    PHONE: 'phone',
    MOBILEPHONE: 'mobilphone',
    EMAIL: 'email',
    IBAN: 'iban',
    DATE: 'date',
    TIME: 'time',
    HASNOICON: 'hasNoIcon',
    SEARCH: 'searchInput',
  };

  componentDidMount() {
    const { autoFocus, selectOnFocus } = this.props;

    if (autoFocus && this.inputRef && this.inputRef.current) {
      setTimeout(() => {
        if (this.inputRef && this.inputRef.current) {
          this.inputRef.current.focus();
          if (selectOnFocus) this.inputRef.current.select();
        }
      }, 1);
    }
  }

  componentDidUpdate(prevProps) {
    const { autoFocus } = this.props;
    if (autoFocus !== prevProps.autoFocus) {
      if (autoFocus && this.inputRef && this.inputRef.current) {
        setTimeout(() => {
          if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus();
            this.inputRef.current.select();
          }
        }, 1);
      }
    }
  }

  inputRef = React.createRef();

  onChangeHandler = (event) => {
    const { formValidator, parse, onChange } = this.props;
    let parsedEvent;
    let value;
    if (typeof event !== 'string') {
      parsedEvent = event;
      value = event.target.value;
    } else {
      value = event;
    }

    if (parse) {
      value = parse(value);
    }

    if (formValidator) {
      formValidator.set(value);
      onChange(value);
    } else {
      onChange(parsedEvent || value);
    }
  };

  getInputModeForType = (type) => {
    switch (type) {
      case InputModern.TYPES.MOBILEPHONE:
      case InputModern.TYPES.PHONE:
        return 'tel';
      case InputModern.TYPES.DATE:
      case InputModern.TYPES.TIME:
        return 'decimal';
      case InputModern.TYPES.SEARCH:
        return 'search';
      case InputModern.TYPES.EMAIL:
        return 'email';
      default:
        return '';
    }
  };

  render() {
    const {
      name,
      label,
      className,
      inputClassName,
      labelClassName,
      upperRightLabelClassName,
      value,
      icon,
      isUppercase,
      type,
      tabIndex,
      isRow,
      inputClasses,
      onIconClick,
      formValidator,
      suffix,
      suffixClassName,
      upperRightLabel,
      showCancel,
      onClearClicked,
      labelInBorder,
      dataTest,
      ...props
    } = this.props;

    const { isFocused, isHovered } = this.state;
    const hasIcon = type && type !== InputModern.TYPES.DATE && type !== InputModern.TYPES.TIME
      && type !== InputModern.TYPES.IBAN && type !== InputModern.TYPES.HASNOICON;
    const hasIconLeft = hasIcon
      && (type === InputModern.TYPES.EMAIL || type === InputModern.TYPES.PHONE
        || type === InputModern.TYPES.MOBILEPHONE || type === InputModern.TYPES.SEARCH);

    const iconProps = {
      className: cx(
        styles.inputFieldIcon,
        {
          [styles.inputFieldIconNotClickable]: !onIconClick,
          [styles.inputFieldIconLeft]: hasIconLeft,
        },
      ),
      onClick: onIconClick,
    };

    const hasError = this.hasError();

    return (
      <div
        className={cx(styles.input, className)}
      >
        <label
          htmlFor={name}
          className={cx(styles.inputLabel, {
            [styles.inputLabelColumn]: !isRow,
            [styles.inputLabelInBorder]: labelInBorder,
          })}
        >
          {label ? (
            <span className={cx(styles.inputLabelText, labelClassName)}>
              {`${label} `}
              {super.isRequired() ? <span className={cx(styles.isRequired)}>&nbsp; *</span> : null}
            </span>
          ) : null}
          {upperRightLabel && (
            <div className={cx(styles.inputUpperRightLabel, upperRightLabelClassName)}>
              {upperRightLabel}
            </div>
          )}
          <div
            className={
            cx(
              styles.inputContainer,
              styles.inputContainerBorder,
              {
                [styles.inputContainerBorderFocused]: isFocused,
                [styles.inputContainerBorderSearchFocused]:
                type === InputModern.TYPES.SEARCH && (isFocused || value),
                [styles.inputContainerBorderInvalid]: hasError,
                [styles.inputContainerBorderError]: hasError,
              },
              inputClassName,
            )}
            onMouseEnter={(e) => super.onMouseEnter(e)}
            onMouseLeave={(e) => super.onMouseLeave(e)}
          >
            {icon}
            {type === 'iban' ? (
              <IbanInput
                className={cx(
                  styles.inputField,
                  styles.inputFieldBorder,
                  styles.inputFieldDefault,
                  {
                    [inputStyles.inputIsUppercase]: isUppercase,
                  },
                  inputClasses,
                )}
                name={name}
                initialValue={formValidator ? formValidator.value : value}
                type="text"
                data-test={dataTest}
                {...props}
                tabIndex={tabIndex}
                ref={this.inputRef}
                onChange={this.onChangeHandler}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
              />
            ) : (
              <input
                className={cx(
                  styles.inputField,
                  styles.inputFieldBorder,
                  {
                    [inputStyles.inputIsUppercase]: isUppercase,
                    [styles.inputFieldDate]: type === 'date',
                    [styles.inputFieldDefault]: type !== 'date',
                    [styles.inputFieldWithIcon]: hasIcon,
                    [styles.inputFieldWithIconLeft]: hasIconLeft,
                  },
                  inputClasses,
                )}
                name={name}
                id={name}
                value={formValidator || value}
                inputMode={this.getInputModeForType(type)}
                type={type !== 'email' && type /* So default UA validation wont be used */}
                {...props}
                tabIndex={tabIndex}
                ref={this.inputRef}
                onChange={this.onChangeHandler}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                data-test={dataTest}
              />
            )}
            {suffix && (
              <div className={cx(styles.inputFieldSuffix, suffixClassName)}>
                {suffix}
              </div>
            )}
          </div>

          {super.renderTooltip()}
        </label>

        {type === 'phone' && (
          <FontAwesomeIcon
            icon={faPhone}
            {...iconProps}
          />
        )}

        {type === InputModern.TYPES.MOBILEPHONE.toString() && (
          <FontAwesomeIcon
            icon={faMobileAlt}
            {...iconProps}
          />
        ) }

        {type === 'email' && (
          <EmailIcon
            {...iconProps}
          />
        )}

        {type === 'edit' && (
          <EditIcon
            {...iconProps}
          />
        )}

        {type === InputModern.TYPES.SEARCH && (
          <FontAwesomeIcon
            icon={faSearch}
            {...iconProps}
          />
        )}

        {showCancel && value && (isFocused || isHovered) && (
        <FontAwesomeIcon
          icon={faXmark}
          className={styles.inputFieldIcon}
          onClick={onClearClicked}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
          data-test="searchInputClear"
        />
        )}
      </div>
    );
  }
}

InputModern.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  isValid: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  icon: PropTypes.element,
  isUppercase: PropTypes.bool,
  isRequired: PropTypes.bool,
  isRow: PropTypes.bool,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  inputClasses: PropTypes.string,
  onIconClick: PropTypes.func,
  parse: PropTypes.func,
  formValidator: FormValidator.getShape(),
  labelClassName: PropTypes.string,
  upperRightLabelClassName: PropTypes.string,
  autoFocus: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffixClassName: PropTypes.string,
  upperRightLabel: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  labelInBorder: PropTypes.bool,
  showCancel: PropTypes.bool,
  onClearClicked: PropTypes.func,
  dataTest: PropTypes.string,
};

InputModern.defaultProps = {
  label: '',
  errorMessage: '',
  isValid: true,
  className: '',
  inputClassName: '',
  value: '',
  icon: null,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  isUppercase: false,
  isRequired: false,
  tabIndex: 0,
  type: '',
  isRow: false,
  inputClasses: '',
  onIconClick: null,
  parse: null,
  formValidator: null,
  labelClassName: '',
  upperRightLabelClassName: '',
  autoFocus: false,
  selectOnFocus: false,
  suffix: null,
  suffixClassName: '',
  upperRightLabel: null,
  labelInBorder: false,
  showCancel: false,
  name: '',
  dataTest: '',
};
