import * as apiClient from 'resources/api.client';

export function createPositionGroup(data, garageId) {
  return apiClient.post('positionGroups', { garageId }, data);
}

export function getPositionGroups(data) {
  return apiClient.get('positionGroups', data);
}

export function getPositionGroupsByName(data) {
  return apiClient.get('positionGroups/name', data);
}

export function findPositionGroupBySKU(data) {
  return apiClient.get('positionGroups/sku', data);
}

export function removePositionGroup(groupId, garageId) {
  return apiClient.post('positionGroups/remove', { garageId, groupId });
}

export function isSkuAlreadyUsed(data) {
  return apiClient.get('positionGroups/is-sku-used', data);
}

export function updatePositionGroup(groupId, positionGroup, garageId) {
  return apiClient.put(`positionGroups/${groupId}`, { garageId }, positionGroup);
}

export function getPositionGroupsCount(garageId, data) {
  return apiClient.get('positionGroups/count', { garageId, ...data });
}
