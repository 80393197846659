import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import polyglot from 'services/localization';
import * as fromUser from 'resources/user/user.selectors';
import {
  fetchInvoicesProductHistory,
  resetInvoicesState,
} from 'resources/invoice/invoice.actions';
import {
  fetchOldInvoiceItems,
} from 'resources/oldInvoiceItems/oldInvoiceItems.api';
import { toUTCDay } from 'helpers/date';
import NoEntities from 'components/common/noEntities';
import SearchInput from 'components/common/searchInput';
import SortableTableTitle from 'components/common/sortableTableTitle';
import TableLoading from 'components/common/loading/components/tableLoading/tableLoading';
import ColoredPrice from 'components/common/coloredPrice';
import StatusLabel from 'components/invoices/statusLabel';
import Retry from 'components/common/retry';
import toastService from 'helpers/toastService';
import { getMatchingMileageString } from 'shared-library/src/services/vehicleService';

import pageStyles from 'styles/page.pcss';
import tableStyles from 'styles/table.pcss';
import { flushSync } from 'react-dom';
import styles from './productHistory.styles.pcss';

class ProductHistory extends PureComponent {
  static propTypes = {
    garageId: PropTypes.string.isRequired,
    fetchInvoicesProductHistoryAction: PropTypes.func.isRequired,
    resetInvoicesStateAction: PropTypes.func.isRequired,
    type: PropTypes.string,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
    }).isRequired,
  };

  static sortableTableTitles = [{
    title: polyglot.t('invoices.fields.status'),
    keys: ['originalInvoice', 'status'],
  }, {
    title: polyglot.t('productHistory.clientName'),
    keys: ['originalInvoice', 'client', 'lastName'],
  }, {
    title: polyglot.t('productHistory.sku'),
    keys: ['sku'],
    styles: { paddingLeft: 8 },
  }, {
    title: polyglot.t('productHistory.name'),
    keys: ['name'],
    styles: { paddingLeft: 5 },
  }, {
    title: polyglot.t('invoices.fields.invoiceDate'),
    keys: ['originalInvoice', 'invoiceDate'],
    styles: { paddingLeft: 3 },
  }, {
    title: polyglot.t('invoices.fields.vehicle'),
    keys: ['originalInvoice', 'vehicle', 'manufacturer'],
    styles: { paddingLeft: 0 },
  }, {
    title: polyglot.t('vehicles.mileage'),
    keys: ['mileage'],
    styles: { paddingLeft: 0 },
  }, {
    title: polyglot.t('productHistory.unitPrice'),
    keys: ['price'],
    styles: { textAlign: 'right', paddingRight: 22 },
    align: 'right',
  }, {
    title: polyglot.t('invoices.fields.gross'),
    keys: ['positionPrice'],
    styles: { textAlign: 'right', paddingRight: 22 },
    align: 'right',
  }];

  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      sortBy: ['priority', 'invoiceDate', 'numericNumber', 'createdOn'],
      sortDirection: -1,
      isNoInvoices: false,
      isFetchLoading: false,
      isFetchError: false,
      items: [],
      filteredItems: [],
    };
  }

  componentDidMount = () => {
    this.fetch();
  };

  componentWillUnmount = () => {
    const { resetInvoicesStateAction } = this.props;
    resetInvoicesStateAction();
  };

  onChangeSort = ({ sortBy, sortDirection }) => {
    if (this.state.items.length > 750) {
      toastService.showError(polyglot.t('productHistory.sortLimitError'));
      return;
    }
    this.setState({ sortBy, sortDirection });

    const filteredItemsTmp = this.getFilteredItems(this.state.searchText);
    filteredItemsTmp.sort((a, b) => {
      const aEx = this.getSortValueFromItem(a, sortBy);
      const bEx = this.getSortValueFromItem(b, sortBy);
      if ((aEx < bEx) || (!aEx && bEx)) {
        return -1 * sortDirection;
      }
      if ((aEx > bEx) || (aEx && !bEx)) {
        return 1 * sortDirection;
      }
      return 0;
    });
    this.setState({ filteredItems: filteredItemsTmp });
  };

  getSortValueFromItem = (item, sortBy) => {
    let usedValue = JSON.parse(JSON.stringify(item));
    for (let i = 0; i < sortBy.length; i++) {
      if (usedValue) {
        usedValue = usedValue[sortBy[i]];
      } else {
        return null;
      }
    }
    return usedValue;
  };

  onSearchChange = (searchText) => {
    this.setState({ searchText, filteredItems: this.getFilteredItems(searchText) });
  };

  getFilteredItems = (searchText) => {
    const filtered = [];
    for (const item of this.state.items) {
      if (item.type === 'group') {
        let sourceTextGroup = [
          item.name,
          item.sku,
          toUTCDay(item.originalInvoice.invoiceDate),
          item.originalInvoice.vehicle.manufacturer,
          item.originalInvoice.vehicle.license,
          item.positionPrice,
          item.price,
          item.description,
          item.originalInvoice.client.firstName,
          item.originalInvoice.client.lastName,
        ].join(' ');
        sourceTextGroup = sourceTextGroup.toLowerCase();
        const searchPartsGroup = searchText.toLowerCase().split(' ');
        let skipGroup = false;
        for (const s of searchPartsGroup) {
          if (s && !sourceTextGroup.includes(s)) {
            skipGroup = true;
            break;
          }
        }
        if (!skipGroup) {
          filtered.push(item);
        }
        for (const groupItem of item.items) {
          let sourceText = [
            groupItem.name,
            groupItem.sku,
            toUTCDay(item.originalInvoice.invoiceDate),
            item.originalInvoice.vehicle.manufacturer,
            item.originalInvoice.vehicle.license,
            groupItem.positionPrice,
            groupItem.price,
            groupItem.description,
            item.originalInvoice.client.firstName,
            item.originalInvoice.client.lastName,
          ].join(' ');
          sourceText = sourceText.toLowerCase();
          const searchParts = searchText.toLowerCase().split(' ');
          let skip = false;
          for (const s of searchParts) {
            if (s && !sourceText.includes(s)) {
              skip = true;
              break;
            }
          }
          const newGroupItem = { ...item, ...groupItem, isGroupItem: true };
          if (!skip) {
            filtered.push(newGroupItem);
          }
        }
      } else {
        let sourceText = [
          item.name,
          item.sku,
          toUTCDay(item.originalInvoice.invoiceDate),
          item.originalInvoice.vehicle.manufacturer,
          item.originalInvoice.vehicle.license,
          item.positionPrice,
          item.price,
          item.description,
          item.originalInvoice.client.firstName,
          item.originalInvoice.client.lastName,
        ].join(' ');
        sourceText = sourceText.toLowerCase();
        const searchParts = searchText.toLowerCase().split(' ');
        let skip = false;
        for (const s of searchParts) {
          if (s && !sourceText.includes(s)) {
            skip = true;
            break;
          }
        }
        if (!skip) {
          filtered.push(item);
        }
      }
    }
    return filtered;
  };

  onOpenInvoice = (invoiceName, invoiceId, originalInvoice) => {
    const { history } = this.props;

    if (originalInvoice.status === 'imported') {
      toastService.showError(polyglot.t('productHistory.importedOpenNote'));
      return;
    }

    history.push(
      `/invoices/${invoiceName}/${invoiceId || 'new'}`,
    );
  };

  getRange() {
    const { location } = this.props.history;

    let fromUrl;
    let toUrl;
    const searchParamsAreExisting = !!location.search;
    if (searchParamsAreExisting) {
      fromUrl = moment.utc(location.search.split('from=')[1].split('&')[0], 'DD-MM-YYYY-HH-mm');
      toUrl = moment.utc(location.search.split('&to=')[1], 'DD-MM-YYYY-HH-mm');
    }

    const from = fromUrl;
    const to = toUrl;

    return [from, to];
  }

  fetch = () => {
    const {
      match,
      fetchInvoicesProductHistoryAction,
      type,
      garageId,
    } = this.props;
    const { sortBy, sortDirection, searchText } = this.state;
    const [from, to] = this.getRange();
    const typeFilter = {};
    if (type === 'client') {
      typeFilter.clientId = match.params.id;
    }
    if (type === 'vehicle') {
      typeFilter.vehicleId = match.params.id;
    }
    if (type === 'product') {
      typeFilter.includesProduct = match.params.id;
      typeFilter.productId = match.params.id;
    }

    this.setState({ isFetchLoading: true });
    fetchInvoicesProductHistoryAction({
      ...typeFilter,
      sortBy,
      sortDirection,
      searchText,
      pageSize: 4000,
      from: from?.toISOString(),
      to: to?.toISOString(),
    }, true)
      .then(({ payload }) => {
        this.setState({
          isNoInvoices: !payload.isExists,
          isFetchError: false,
          isFetchLoading: !typeFilter.includesProduct,
        });
        if (payload.results) {
          this.parseItemsFromInvoice(payload.results, !typeFilter.includesProduct);
        }
        if (!typeFilter.includesProduct) {
          fetchOldInvoiceItems(garageId, typeFilter).then((results) => {
            this.setState({ isFetchError: false, isFetchLoading: false });
            this.parseItemsFromOldInvoiceItems(results);
          })
            .catch(() => this.setState({ isFetchError: true, isFetchLoading: false }));
        }
      })
      .catch(() => this.setState({ isFetchError: true, isFetchLoading: false }));
  };

  parseItemsFromInvoice = (invoices, skipOnChange = false) => {
    const { type, match } = this.props;
    const parsedItems = [];
    let i = 0;
    for (const invoice of invoices) {
      for (const item of invoice.items) {
        if (type !== 'product' || item.productId === match.params.id) {
          parsedItems.push({ ...item, originalInvoice: invoice, originalIndex: i });
          i++;
        } else if (item.type === 'group' && item.items.find((groupItem) => groupItem.productId === match.params.id)) {
          const foundGroupItems = item.items
            .filter((groupItem) => groupItem.productId === match.params.id);
          foundGroupItems.forEach((groupItem) => {
            parsedItems.push({ ...groupItem, originalInvoice: invoice, originalIndex: i });
            i++;
          });
        }
      }
    }
    flushSync(() => {
      this.setState({ items: parsedItems });
    });
    if (!skipOnChange) {
      this.onSearchChange('');
    }
  };

  parseItemsFromOldInvoiceItems = (oldInvoiceItems) => {
    const parsedItems = [];
    let i = this.state.items.length;
    for (const item of oldInvoiceItems) {
      const parsedItem = {
        originalInvoice: {
          status: 'imported',
          number: item.externalInvoiceNumber ? item.externalInvoiceNumber : null,
          invoiceDate: item.invoiceDate ? item.invoiceDate : null,
          client: {
            firstName: item.firstName ? item.firstName : null,
            lastName: item.lastName ? item.lastName : null,
          },
          vehicle: {
            manufacturer: item.vehicleManufacturer ? item.vehicleManufacturer : null,
            license: item.vehicleLicense ? item.vehicleLicense : null,
          },
        },
        name: item.name ? item.name : null,
        sku: item.sku ? item.sku : null,
        positionPrice: item.price && item.quantity ? item.price * item.quantity : 0.0,
        price: item.price,
        quantity: item.quantity,
        originalIndex: i,
        isOldInvoiceItem: true,
      };
      parsedItems.push(parsedItem);
      i++;
    }
    const combined = [...this.state.items, ...parsedItems];
    flushSync(() => {
      this.setState({ items: combined });
    });
    this.onSearchChange('');
  };

  renderTableBody = () => {
    const { filteredItems: items } = this.state;
    if (!items.length) {
      return <NoEntities withoutBorder noEntitiesText={polyglot.t(`productHistory.${this.props.type}.notFound`)} />;
    }
    return (
      <tbody className={cx(tableStyles.tableBody)} style={{ overflowY: 'scroll' }}>
        {items.map(({
          originalInvoice, name, sku, positionPrice, price, quantity, index, type, isGroupItem,
        }) => (
          <tr
            key={`${originalInvoice._id}${index}`}
            className={cx(
              tableStyles.tableRow,
              { [styles.group]: type === 'group' },
            )}
            onClick={() => this.onOpenInvoice(
              originalInvoice.status,
              originalInvoice._id,
              originalInvoice,
            )}
          >
            <td className={tableStyles.tableElement}>
              <div style={isGroupItem ? { float: 'right' } : { display: 'inline-block' }}>
                <StatusLabel
                  status={originalInvoice.status}
                  gross={originalInvoice.gross}
                  dueDate={originalInvoice.dueDate}
                />
              </div>
              {' '}
              {isGroupItem ? '' : originalInvoice.number || '-'}
            </td>
            <td className={tableStyles.tableElement}>
              {!originalInvoice.client.firstName && !originalInvoice.client.lastName ? '-' : (
                <>
                  {originalInvoice.client.lastName || '-'}
                  {' '}
                  {originalInvoice.client.firstName || '-'}
                </>
              )}
            </td>
            <td className={tableStyles.tableElement}>
              {sku || '---'}
            </td>
            <td className={tableStyles.tableElement}>
              {name || '---'}
            </td>
            <td className={tableStyles.tableElement}>
              {originalInvoice.invoiceDate ? toUTCDay(originalInvoice.invoiceDate) : '---'}
            </td>
            <td className={tableStyles.tableElement}>
              {originalInvoice.vehicle.manufacturer || '-'}
              {' '}
              /
              {' '}
              {originalInvoice.vehicle.license || '-'}
            </td>
            <td className={tableStyles.tableElement}>
              {getMatchingMileageString(originalInvoice.vehicle.mileage, originalInvoice.invoiceDate) || '-'}
            </td>
            <td className={cx(tableStyles.tableElement, tableStyles.tableRight)}>
              {quantity}
              {' '}
              *
              {' '}
              <ColoredPrice priceValue={price || 0} />
            </td>
            <td className={cx(tableStyles.tableElement, tableStyles.tableRight)}>
              <ColoredPrice priceValue={positionPrice || 0} />
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  render() {
    const {
      searchText,
      isFetchLoading,
      isNoInvoices,
      isFetchError,
      sortDirection,
      sortBy,
    } = this.state;

    const { type } = this.props;

    if (isFetchLoading) {
      return (<TableLoading />);
    }

    if (isFetchError) {
      return <Retry className={cx(pageStyles.page, pageStyles.pageContent)} onRetry={this.fetch} />;
    }

    if (isNoInvoices && !this.state.items.length) {
      return (
        <NoEntities noEntitiesText={polyglot.t(`productHistory.${type}.notExist`)} />
      );
    }

    const sortableTableTitles = ProductHistory.sortableTableTitles.map(({
      title, keys, align, styles: titleStyles,
    }) => (
      <SortableTableTitle
        key={title}
        onChangeSort={this.onChangeSort}
        title={title}
        sortKeys={keys}
        sortBy={sortBy}
        sortDirection={sortDirection}
        align={align}
        styles={titleStyles}
      />
    ));

    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.pageContent}>
          <div className={pageStyles.pageActions}>
            <div className={pageStyles.pageActionsSearch}>
              <SearchInput
                onChange={this.onSearchChange}
                onDebounce={() => {}}
                placeholder={polyglot.t('actions.searchPlaceholder')}
                value={searchText}
              />
            </div>
            <div className={pageStyles.pageActionsButtons} />
          </div>

          <table className={tableStyles.table}>
            <thead className={tableStyles.tableHeader}>
              <tr className={tableStyles.tableLightRow}>
                {sortableTableTitles}
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  garageId: fromUser.getCurrentGarageId(state),
}), {
  fetchInvoicesProductHistoryAction: fetchInvoicesProductHistory,
  resetInvoicesStateAction: resetInvoicesState,
})(ProductHistory);
