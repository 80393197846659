const moment = require('moment');
const { getAustrianNumber } = require('../numberHelperService');

// Sorts the mileages/operating hours entries according to their date
const getSortedMileages = (mileageArray = []) =>
  (mileageArray || []).sort(
    (mileageEntryOne, mileageEntryTwo) =>
      moment(mileageEntryTwo.date).diff(moment(mileageEntryOne.date)) ||
      mileageEntryTwo.value - mileageEntryOne.value ||
      mileageEntryTwo.operatingHours - mileageEntryOne.operatingHours,
  );

function getMileageString(mileage) {
  return mileage?.operatingHours || mileage?.operatingHours === 0
    ? `${getAustrianNumber(mileage.operatingHours)} h`
    : `${getAustrianNumber(mileage?.value)} km`;
}

function getMatchingMileages(mileages, date = new Date(), maxDifferenceInDays = 30) {
  if (!mileages || !Array.isArray(mileages) || !date) return undefined;
  return mileages.filter(
    (mileage) => Math.abs(moment(mileage.date).diff(moment(date), 'days')) <= maxDifferenceInDays,
  );
}

function getMatchingMileageString(mileages, date = new Date(), maxDifferenceInDays = 30) {
  const matchingMileages = getMatchingMileages(mileages, date, maxDifferenceInDays);
  if (!matchingMileages || !matchingMileages.length) return undefined;

  const [matchingMileage] = getSortedMileages(matchingMileages);
  return getMileageString(matchingMileage);
}

module.exports = {
  getSortedMileages,
  getMatchingMileageString,
  getMatchingMileages,
  getMileageString,
};
