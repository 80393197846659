import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import ButtonModern from 'components/common/buttonModern';
import * as fromGarage from 'resources/garage/garage.selectors';
import * as fromUser from 'resources/user/user.selectors';
import moment from 'moment';
import { hasRole } from 'helpers/permissions';
import { replace } from 'helpers/locale';

import polyglot from 'services/localization';

import styles from './banner.styles.pcss';

class Banner extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({}).isRequired,
    garage: PropTypes.shape({
      bannerItems: PropTypes.array,
      lockDate: PropTypes.string,
    }).isRequired,
    subscriptionData: PropTypes.shape({
      trialEnd: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { garage: { bannerItems }, user } = this.props;
    this.state = {
      isActive: bannerItems && bannerItems.length > 0 && hasRole(user, 'admin'),
    };
  }

  componentDidUpdate(prevProps) {
    const { garage: { bannerItems }, user } = this.props;
    this.setState({ isActive: bannerItems && bannerItems.length > 0 && hasRole(user, 'admin') });
  }

  calculateRemainingDays = () => {
    const { subscriptionData } = this.props;

    const trialMoment = moment.utc(subscriptionData.trialEnd);
    const currentMoment = moment.utc();
    const diffInMinutes = trialMoment.diff(currentMoment, 'minutes');
    const remainingHours = Math.round(diffInMinutes / 60);
    const remainingDays = Math.floor(remainingHours / 24);
    const remainingTime = `${
      remainingDays ? `${remainingDays} ${remainingDays === 1 ? polyglot.t('header.day') : polyglot.t('header.days')}` : ''
    }`.trim();

    return { remainingTime, remainingDays };
  };

  onOpenSubscription = () => this.props.history.push('/subscription');

  renderChoosePlanBanner = () => {
    const { remainingTime, remainingDays } = this.calculateRemainingDays();
    const title = !remainingDays
      ? polyglot.t('header.lessThanADay')
      : replace(polyglot.t('header.trialEndsIn'), ['remainingTime'], [remainingTime]);
    return (
      <>
        { title }
        <ButtonModern className={styles.choosePlanBannerButton} size={'small'} onClick={this.onOpenSubscription}>
          {polyglot.t('header.orderNow')}
        </ButtonModern>
      </>
    );
  };

  getBannerContentForItem = (bannerItem) => {
    const { garage } = this.props;
    if (!bannerItem) return null;

    switch (bannerItem.type) {
      case 'sepaDispute':
        return this.prepareSepaDisputeBannerText(garage, bannerItem);
      case 'paymentActionRequired':
        return (
          <Link to="/subscription/subscription" style={{ color: 'white' }}>
            {polyglot.t('banner.paymentActionRequired')}
          </Link>
        );
      case 'paymentMethodActionRequired':
        return (
          <Link to="/subscription/paymentMethod" style={{ color: 'white' }}>
            {bannerItem.text || polyglot.t(`banner.paymentMethodActionRequired.${bannerItem.options.action}`)}
          </Link>
        );
      case 'unpaidSendInvoice':
        return (
          <Link to="/subscription/invoices" style={{ color: 'white' }}>
            {polyglot.t('banner.unpaidSendInvoice')}
          </Link>
        );
      case 'choosePlan':
        return this.renderChoosePlanBanner();
      default:
        return bannerItem.text;
    }
  };

  prepareSepaDisputeBannerText = (garage, bannerItem) => {
    if (!garage.lockDate) {
      return (
        <Link to="/subscription/invoices" style={{ color: 'white' }}>
          {bannerItem.text || polyglot.t('banner.sepaDisputeWarningWithoutDays')}
        </Link>
      );
    }

    const today = moment();
    const deadline = moment(garage.lockDate, 'DD.MM.YYYY');

    if (today.isAfter(deadline, 'second')) {
      return (
        <a href={bannerItem.options.hostedUrl} rel="noopener noreferrer" target="_blank"
           style={{ color: 'white' }}>
          {bannerItem.text || polyglot.t('banner.sepaDisputeLocked')}
        </a>
      );
    }

    let displayText;
    if (today.isSame(deadline, 'day')) {
      displayText = polyglot.t('banner.sepaDisputeWarning').replace(
        '{{{DAYS}}}',
        `${polyglot.t('banner.today')}`,
      );
    } else {
      displayText = polyglot.t('banner.sepaDisputeWarning').replace(
        '{{{DAYS}}}',
        `${polyglot.t('banner.in')} ${deadline.diff(moment(), 'days')} ${polyglot.t(
          'banner.days',
        )}`,
      );
    }
    return (
      <Link to="/subscription/invoices" style={{ color: 'white' }}>
        {bannerItem.text || displayText}
      </Link>
    );
  };

  render() {
    const { garage: { bannerItems } } = this.props;
    const { isActive } = this.state;

    const containerStyle = {};
    const bannerStyle = {};

    if (isActive) {
      containerStyle.height = '46px';
      bannerStyle.top = '0px';
    }
    const isChoosePlanBanner = bannerItems?.[0]?.type === 'choosePlan';
    return (
      <div className={styles.bannerContainer} style={containerStyle}>
        <div className={cx(
          styles.banner,
          { [styles.choosePlanBanner]: isChoosePlanBanner },
          { [styles.bannerColor]: !isChoosePlanBanner },
        )} style={bannerStyle}>
          {isActive && bannerItems && bannerItems.length > 0 && (
            this.getBannerContentForItem(bannerItems[0])
          )}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  subscriptionData: fromGarage.getSubscriptionData(state),
  garage: fromGarage.getGarage(state),
  user: fromUser.getCurrentUser(state),
}), {})(Banner);
