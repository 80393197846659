import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import polyglot from 'services/localization';
import * as fromInvoices from 'resources/invoice/invoice.selectors';
import * as fromVehicles from 'resources/vehicles/vehicles.selectors';
import { fetchVehicle } from 'resources/vehicles/vehicles.actions';
import {
  fetchInvoicesOfVehicle,
  changeInvoicesFilterOfVehicle,
  resetInvoicesState,
} from 'resources/invoice/invoice.actions';
import { toUTCDay } from 'helpers/date';
import NoEntities from 'components/common/noEntities';
import SearchInput from 'components/common/searchInput';
import SortableTableTitle from 'components/common/sortableTableTitle';
import Button from 'components/common/button';
import InfiniteTableBody from 'components/common/infiniteTableBody';
import TableLoading from 'components/common/loading/components/tableLoading/tableLoading';
import ColoredPrice from 'components/common/coloredPrice';
import StatusLabel from 'components/invoices/statusLabel';
import Retry from 'components/common/retry';
import * as fromUser from 'resources/user/user.selectors';
import pageStyles from 'styles/page.pcss';
import tableStyles from 'styles/table.pcss';
import RoleManager from 'components/common/roleManager';

class VehicleInvoices extends PureComponent {
  static propTypes = {
    currentVehicleId: PropTypes.string,
    fetchVehicleAction: PropTypes.func.isRequired,
    invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchInvoicesAction: PropTypes.func.isRequired,
    resetInvoicesStateAction: PropTypes.func.isRequired,
    totalAmount: PropTypes.number.isRequired,
    changeInvoicesFilterAction: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    getUserRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    currentVehicleId: '',
  };

  state = {
    searchText: '',
    sortBy: ['priority', 'invoiceDate', 'numericNumber', 'createdOn'],
    sortDirection: -1,
    isNoInvoices: false,
    isFetchLoading: false,
    isFetchError: false,
    isChangeFilterLoading: false,
    isChangeFilterError: false,
  };

  componentDidMount() {
    const { match, currentVehicleId, fetchVehicleAction } = this.props;

    this.fetch();

    if (!currentVehicleId || currentVehicleId !== match.params.id) {
      fetchVehicleAction(match.params.id);
    }
  }

  componentWillUnmount = () => {
    const { resetInvoicesStateAction } = this.props;

    resetInvoicesStateAction();
  };

  static sortableTableTitles = [{
    title: polyglot.t('invoices.fields.status'),
    keys: ['status'],
  }, {
    title: polyglot.t('invoices.fields.number'),
    keys: ['numericNumber'],
  }, {
    title: polyglot.t('invoices.fields.invoiceDate'),
    keys: ['invoiceDate'],
  }, {
    title: polyglot.t('invoices.fields.customer'),
    keys: ['client.lastName', 'client.firstName'],
  }, {
    title: polyglot.t('invoices.fields.gross'),
    keys: ['gross'],
    align: 'right',
  }];

  onChangeSort = ({ sortBy, sortDirection }) => {
    const { searchText } = this.state;
    this.setState({ sortBy, sortDirection, isChangeFilterLoading: true });
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  onSearchChange = (searchText) => {
    this.setState({ searchText, isChangeFilterLoading: true });
  };

  onSearchDebounce = () => {
    const { sortBy, sortDirection, searchText } = this.state;
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  onScroll = () => {
    const {
      invoices,
      totalAmount,
      match,
      fetchInvoicesAction,
    } = this.props;
    const { sortBy, sortDirection, searchText } = this.state;

    if (invoices.length >= totalAmount) {
      return Promise.resolve();
    }

    return fetchInvoicesAction({
      skip: invoices.length,
      sortBy,
      sortDirection,
      searchText,
      vehicleId: match.params.id,
    });
  };

  onOpenInvoice = (invoiceName, invoiceId) => {
    const { history, match } = this.props;

    if (invoiceName === 'offer') {
      return history.push(
        '/offers/new',
        { vehicleId: match.params.id },
      );
    }

    return history.push(
      `/invoices/${invoiceName}/${invoiceId || 'new'}`,
      { vehicleId: match.params.id },
    );
  };

  onRetryChangeFilter = () => {
    this.setState({ isChangeFilterLoading: true });
    const { sortBy, sortDirection, searchText } = this.state;
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  fetch = () => {
    const { match, fetchInvoicesAction, getUserRoles } = this.props;
    const { sortBy, sortDirection, searchText } = this.state;
    const status = getUserRoles.includes('worker') ? 'draft' : null;
    this.setState({ isFetchLoading: true });
    fetchInvoicesAction({
      sortBy,
      sortDirection,
      searchText: searchText.trim(),
      vehicleId: match.params.id,
      status,
    })
      .then(({ payload }) => this.setState({
        isNoInvoices: !payload.results.length,
        isFetchLoading: false,
        isFetchError: false,
      }))
      .catch(() => this.setState({ isFetchError: true, isFetchLoading: false }));
  };

  changeFilter = ({ sortBy, sortDirection, searchText }) => {
    const { match, changeInvoicesFilterAction } = this.props;
    changeInvoicesFilterAction({
      sortBy, sortDirection, searchText, vehicleId: match.params.id,
    })
      .then(() => this.setState({ isChangeFilterLoading: false, isChangeFilterError: false }))
      .catch(() => this.setState({ isChangeFilterLoading: false, isChangeFilterError: true }));
  };

  renderCreateButtons = () => (
    <div className={pageStyles.pageActionsButtons}>
      {
        ['offer', 'draft', 'preview'].map((invoiceName) => (
          <Button
            key={invoiceName}
            styleType="add"
            onClick={() => this.onOpenInvoice(invoiceName)}
          >
            {polyglot.t(`vehicleInvoices.create.${invoiceName}`)}
          </Button>
        ))
      }
    </div>
  );

  renderTableBody = () => {
    const { invoices } = this.props;
    const { isChangeFilterLoading, isChangeFilterError } = this.state;

    if (!isChangeFilterLoading && isChangeFilterError) {
      return <Retry onRetry={this.onRetryChangeFilter} />;
    }

    if (!invoices.length) {
      return <NoEntities withoutBorder noEntitiesText={polyglot.t('vehicleInvoices.notFound')} />;
    }

    return (
      <InfiniteTableBody isDataLoading={isChangeFilterLoading} onScroll={this.onScroll}>
        {invoices.map(({
          _id: invoiceId, status, dueDate, number, invoiceDate, client, gross,
        }) => (
          <tr
            key={invoiceId}
            className={tableStyles.tableRow}
            onClick={() => this.onOpenInvoice(status, invoiceId)}
          >
            <td className={tableStyles.tableElement}>
              <StatusLabel
                status={status}
                gross={gross}
                dueDate={dueDate}
              />
            </td>
            <td className={tableStyles.tableElement}>
              {number || '---'}
            </td>
            <td className={tableStyles.tableElement}>
              {invoiceDate ? toUTCDay(invoiceDate) : '---'}
            </td>
            <td className={tableStyles.tableElement}>
              {client && client.lastName ? `${client.lastName} ${client.firstName}` : '---'}
            </td>
            <RoleManager forbiddenRoles={['worker']}>
              <td className={cx(tableStyles.tableElement, tableStyles.tableRight)}>
                <ColoredPrice priceValue={gross || 0} />
              </td>
            </RoleManager>
          </tr>
        ))}
      </InfiniteTableBody>
    );
  };

  render() {
    const {
      searchText,
      isFetchLoading,
      isNoInvoices,
      isFetchError,
      sortBy,
      sortDirection,
    } = this.state;
    const { totalAmount, getUserRoles } = this.props;

    if (isFetchLoading) {
      return (
        <div className={pageStyles.page}>
          <TableLoading>
            {this.renderCreateButtons()}
          </TableLoading>
        </div>
      );
    }

    if (isFetchError) {
      return <Retry className={pageStyles.pageContent} onRetry={this.fetch} />;
    }

    if (isNoInvoices) {
      return (
        <div className={pageStyles.page}>
          <NoEntities noEntitiesText={polyglot.t('vehicleInvoices.thereAreNoVehicleInvoices')}>
            {this.renderCreateButtons()}
          </NoEntities>
        </div>
      );
    }

    const sortableTableTitles = VehicleInvoices.sortableTableTitles
      .map(({ title, keys, align }) => {
        if (keys[0] === 'gross' && getUserRoles.includes('worker')) {
          return null;
        }
        return (
          <SortableTableTitle
            key={title}
            onChangeSort={this.onChangeSort}
            title={title}
            sortKeys={keys}
            sortBy={sortBy}
            sortDirection={sortDirection}
            align={align}
          />
        );
      });

    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.pageContent}>
          <div className={pageStyles.pageActions}>
            <div className={pageStyles.pageActionsSearch}>
              <SearchInput
                onChange={this.onSearchChange}
                onDebounce={this.onSearchDebounce}
                placeholder={polyglot.t('actions.searchPlaceholder')}
                value={searchText}
                totalAmount={totalAmount}
              />
            </div>
            {this.renderCreateButtons()}
          </div>

          <table className={tableStyles.table}>
            <thead className={tableStyles.tableHeader}>
              <tr className={tableStyles.tableLightRow}>
                {sortableTableTitles}
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  invoices: fromInvoices.getInvoices(state),
  totalAmount: fromInvoices.getTotalAmount(state),
  currentVehicleId: fromVehicles.getCurrentVehicleId(state),
  getUserRoles: fromUser.getUserRoles(state),
}), {
  fetchInvoicesAction: fetchInvoicesOfVehicle,
  changeInvoicesFilterAction: changeInvoicesFilterOfVehicle,
  resetInvoicesStateAction: resetInvoicesState,
  fetchVehicleAction: fetchVehicle,
})(VehicleInvoices);
