export function isNumber(string) {
  return /^[0-9]+$/.test(string);
}

export function isDate(string) {
  return /^[0-9]{2}.[0-9]{2}.[0-9]{4}$/.test(string);
}

export function isISODate(string) {
  return /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/.test(string);
}

export function isEmail(string) {
  // eslint-disable-next-line
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-ZöäüÜÖÄß\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string);
}

export function onlyContainsBasicChars(string) {
  // eslint-disable-next-line
  return /^[a-zA-ZöäüÜÖÄß\-\.0-9]+$/.test(string);
}

export function getFirstPartOfPath(string) {
  return (/\/([a-z0-9])+([_-])*([a-z0-9])*/.exec(string) || [])[0];
}

export function isBic(string) {
  return /^[A-Z]{6}[A-Z\d]{2}([A-Z\d]{3})?$/.test(string);
}

export function xmlEscapeValue(string) {
  return string.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
}

export function isTaxNumber(string) {
  return /^\d{5}\/?\d{4}$/.test(string);
}

export function isURL(string) {
  // eslint-disable-next-line
  return /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/.test(string);
}

export function isZipCode(string) {
  return /^\d{4,6}$/.test(string);
}

export function isPassword(string) {
  // eslint-disable-next-line
  return /^(?=.*[a-zöäüA-ZÖÄÜß])(?=.*\d)[\w!@#$%^&*?±§~`'"\/\\\[\]{}\-,.();:><+=|öäüÖÄÜß €μ]{8,}$/.test(string);
}

export function isZipCodeUK(string) {
  return /^([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/g.test(
    string,
  );
}

export function getValidUIDNumber(string) {
  return string.replace(/([^\w\s]+|_+)/g, '');
}

export function getFormattedAustrianNumber(string) {
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function getFormattedSwissNumber(string) {
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

export function getUserAgent(string) {
  return string.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i);
}

export function hasEightPasswordChars(string) {
  return /^[\w!@#$%^&*?±§~`'"/\\[\]{}\-,.();:><+=|öäüÖÄÜß €μ]{8,}$/.test(string);
}

export function hasOnePasswordLetter(string) {
  return /^(?=.*[a-zöäüA-ZÖÄÜß])/.test(string);
}

export function hasOnePasswordDigit(string) {
  return /^(?=.*\d)/.test(string);
}

export function startsOrEndsWithPoint(string) {
  return /^\..*|.*\.$/.test(string);
}
