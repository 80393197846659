import { last } from 'lodash/array';

import {
  FETCH_EMAILS,
  CHANGE_EMAILS_FILTER,
  RESET_EMAILS_STATE,
} from './email.actions';

const initialState = { list: [], totalAmount: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAILS: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }
    case CHANGE_EMAILS_FILTER:
      return {
        ...state,
        list: [...action.payload.results],
        totalAmount: action.payload.count,
      };
    case RESET_EMAILS_STATE:
      return initialState;
    default:
      return state;
  }
};
