import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import polyglot from 'services/localization';

import styles from './logo.styles.pcss';

class Logo extends Component {
  static propTypes = {
    className: PropTypes.string,
    hideText: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    hideText: false,
  };

  state = {};

  render() {
    const { className, hideText } = this.props;

    return (
      <div className={cx(styles.logo, className)}>
        <svg className={styles.logoSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 122" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M46.2516 101.557L31.1918 116.553L26.6995 121.045H14.9773L19.1535 116.869L19.1511 116.866L38.3715 97.7155L43.5617 92.5253L44.2039 92.7069C51.4584 94.7602 59.2437 92.7514 64.591 87.4039C68.4677 83.5272 70.633 78.2884 70.6854 72.8746L60.8554 82.7045L43.427 79.157L39.8796 61.7285L49.7095 51.8986C44.2957 51.9508 39.0569 54.1164 35.1802 57.9928C29.8567 63.3163 27.8403 71.0567 29.8509 78.2881L30.0289 78.9276L24.8303 84.1263L5.71141 103.178L0 108.89V97.1695L21.0125 76.2197C19.7787 66.5603 22.241 59.2091 29.3185 52.1313C34.4797 46.9701 42.236 44.2314 49.4417 43.9794C54.4707 43.8034 59.5948 44.8081 64.015 47.2667L65.3321 47.9992L48.8912 64.4404L50.456 72.1283L58.1437 73.6928L74.5848 57.2517L75.3173 58.5689C75.8298 59.4903 76.281 60.4452 76.6715 61.4245C78.1491 65.1311 78.7467 69.1612 78.6084 73.1399C78.3578 80.351 75.6167 88.101 70.4525 93.2654C63.3552 100.363 55.9369 102.832 46.2516 101.557Z" fill="var(--secondary-color)"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M8.46729 42.5002V80.8705L0 89.3378V38.4863L38.4863 0H95.3652V121.045H34.5301L42.9975 112.578H86.8979V8.46739H42.0697V42.5002H8.46729ZM33.488 16.9729L16.5425 33.9186H33.488V16.9729Z" fill="var(--primary-color)"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M30.7436 97.0899L16.036 111.746L10.1752 105.885L24.8788 91.2255L30.7436 97.0899Z" fill="var(--secondary-color)"/>
        </svg>
        {!hideText && (
          <div className={styles.logoText}>
            <span className={styles.logoTextMain}>
              easy
            </span>
            <span className={styles.logoTextSecondary}>
              {polyglot.t('common.productNameSecondary')}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default Logo;
