import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import DeviceService from 'services/device.service';

const withWindowSize = (WrappedComponent) => {
  class WrapperComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        width: DeviceService.width,
        height: DeviceService.height,
        isMobileSize: DeviceService.isMobileSize,
        subscription: null,
      };
    }

    componentDidMount() {
      const subscription = DeviceService.addResizeListener(({ width, height }) => {
        this.setState({ width, height, isMobileSize: width < DeviceService.mobileSize });
      });
      this.setState({ subscription });
    }

    componentWillUnmount() {
      const { subscription } = this.state;
      if (subscription && subscription.unsubscribe) {
        subscription.unsubscribe();
      }
    }

    render() {
      const { width, height, isMobileSize } = this.state;
      return (
        <WrappedComponent
          width={width}
          height={height}
          isMobileSize={isMobileSize}
          {...this.props}
        />
      );
    }
  }
  return hoistNonReactStatics(WrapperComponent, WrappedComponent);
};

export default withWindowSize;
