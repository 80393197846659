import * as fromUser from 'resources/user/user.selectors';
import { addPageSize } from 'resources/utils';
import * as api from './invite.api';

export const FETCH_INVITES = 'fetchInvites';
export const SEND_INVITE = 'sendInvite';
export const RESEND_INVITE = 'resendInvite';
export const CANCEL_INVITES = 'cancelInvites';
export const RESET_INVITES_STATE = 'resetInvitesState';
export const CHANGE_INVITES_FILTER = 'changeInvitesFilter';

export const fetchInvites = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.fetchInvites(data)
    .then((payload) => dispatch({ type: FETCH_INVITES, data, payload }));
};

export const changeInvitesFilter = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.fetchInvites(data)
    .then((payload) => dispatch({ type: CHANGE_INVITES_FILTER, data, payload }));
};

export const resetInvitesState = () => ({ type: RESET_INVITES_STATE });

export const sendInvite = (data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.sendInvite(data, garageId)
    .then((payload) => dispatch({ type: SEND_INVITE, payload }));
};

export const resendInvite = (inviteId) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.resendInvite(inviteId, garageId)
    .then(() => dispatch({ type: RESEND_INVITE, inviteId }));
};

export const cancelInvites = (inviteIds) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.cancelInvites(inviteIds, garageId)
    .then(() => dispatch({ type: CANCEL_INVITES, inviteIds }));
};
