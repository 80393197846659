import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import tableStyles from 'styles/table.pcss';
import styles from './listItem.styles.pcss';

const ListItem = ({
  entity,
  label,
  selected,
  onSelect,
  onDoubleClick,
  dataTest,
}) => (
  <li
    className={cx(
      styles.listItem,
      tableStyles.tableRow,
    )}
  >
    <button
      className={cx(
        styles.listItemButton,
        { [styles.listItemSelected]: selected },
      )}
      onDoubleClick={() => onDoubleClick(entity)}
      onClick={() => onSelect(entity)}
      type="button"
      data-test={dataTest}
    >
      {label}
    </button>
  </li>
);

ListItem.propTypes = {
  entity: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  dataTest: PropTypes.string,
};

export default ListItem;
