import { getValidUIDNumber } from 'helpers/regexp';

export const capitalizeWord = ([head = '', ...tail] = '') => {
  return `${head.toUpperCase()}${tail.join('')}`;
};

export const capitalizeOnFirstInput = ([head = '', ...tail] = '') => {
  return !(tail?.length) ? `${head.toUpperCase()}${tail.join('')}` : `${head}${tail.join('')}`;
};

export const toUpperCase = (value) => value && value.toUpperCase();
export const onlyLettersAndNumber = (value) => getValidUIDNumber(value);
export const composeParsers = (...parsers) => (value) => parsers
  .reduce((result, parser) => parser(result), value);
