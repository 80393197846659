import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import NumberString from 'components/common/numberString';

const MileageCell = ({ value, operatingHours }) => (value !== null || operatingHours !== null) && (
<Fragment>
  <NumberString>
    {value !== null ? value : operatingHours}
  </NumberString>
  {value !== null ? ' km' : ' h'}
</Fragment>
);

MileageCell.propTypes = {
  value: PropTypes.number,
  operatingHours: PropTypes.number,
};

MileageCell.defaultProps = {
  value: null,
  operatingHours: null,
};

export default MileageCell;
