import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  MdPerson as ClientIcon,
  MdLocationOn as LocationIcon,
  MdPhone as PhoneIcon,
  MdEmail as EmailIcon,
  MdCake as BirthdayIcon,
  MdInfo as InfoIcon,
} from 'react-icons/md';
import polyglot from 'services/localization';
import countries from 'helpers/countries';
import * as date from 'helpers/date';
import { faMobileAlt } from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import styles from './clientInfo.styles.pcss';

class ClientInfo extends Component {
  static propTypes = {
    client: PropTypes.shape({
      _id: PropTypes.string,
      externalId: PropTypes.string,
      UIDNumber: PropTypes.string,
      phone: PropTypes.string,
      mobilePhone: PropTypes.string,
      email: PropTypes.string,
      formOfAddress: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      dateOfBirthday: PropTypes.string,
      address: PropTypes.string,
      toAttention: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      title: PropTypes.string,
      additionalInfo: PropTypes.string,
    }).isRequired,
    isDetailView: PropTypes.bool,
  };

  static defaultProps = {
    isDetailView: true,
  };

  render() {
    const { client, isDetailView } = this.props;

    return (
      <>
        <div className={styles.name}>
          <ClientIcon className={styles.nameIcon} />
          <div className={styles.nameText}>
            <div className={styles.nameTextId}>
              {client.externalId && `#${client.externalId}`}
            </div>
            <div className={styles.nameTextAddress}>
              {client.formOfAddress
              && `${polyglot.t(`editClient.formOfAddressOptions.${client.formOfAddress}`)} `}
              {client.title}
            </div>
            <div className={styles.nameTextFullName} data-test="clientDetailName">
              {`${client.lastName} `}
              {client.firstName}
            </div>
          </div>
        </div>

        <div className={styles.contentBlocks}>
          <div className={isDetailView
            ? styles.contentBlockInDetailView : styles.contentBlockInTableModalView}
          >
            <div className={styles.data}>
              <div className={styles.dataIcon}>
                <LocationIcon />
              </div>
              <div className={styles.dataText}>
                <div className={styles.dataTextOneLine}>
                  {client.toAttention}
                </div>
                <div className={styles.dataTextTwoLine}>
                  {client.address}
                </div>
                <div className={styles.dataTextOneLine}>
                  {client.zipCode ? `${client.zipCode} ${client.city}` : client.city}
                </div>
                <div className={styles.dataTextOneLine}>
                  {(countries.find(({ key }) => key === client.country) || {}).label}
                </div>
              </div>
            </div>
            {client.UIDNumber && (
              <div className={styles.data}>
                <div className={styles.dataIcon} />
                <div className={styles.dataText}>
                  <div className={styles.dataTextOneLine}>
                    {client.UIDNumber}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={isDetailView
            ? styles.contentBlockInDetailView : styles.contentBlockInTableModalView}
          >
            <div className={styles.data}>
              <div className={styles.dataText}>
                {client.mobilePhone && (
                  <div className={styles.data}>
                    <div className={styles.dataIcon}>
                      <FontAwesomeIcon fixedWidth={5} icon={faMobileAlt} className={styles.icon} />
                    </div>
                    <div className={styles.dataTextThreeLine}>
                      {client.mobilePhone}
                    </div>
                  </div>
                )}
                {client.phone && (
                  <div className={styles.data}>
                    <div className={styles.dataIcon}>
                      <PhoneIcon />
                    </div>
                    <div className={styles.dataTextThreeLine}>
                      {client.phone}
                    </div>
                  </div>
                )}
                {client.email && (
                  <div className={styles.data}>
                    <div className={styles.dataIcon}>
                      <EmailIcon />
                    </div>
                    <div className={styles.dataTextOneLine}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`mailto:${client.email}`}
                      >
                        {client.email}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {client.dateOfBirthday && (
              <div className={styles.data}>
                <div className={styles.dataIcon}>
                  <BirthdayIcon />
                </div>
                <div className={styles.dataText}>
                  <div className={styles.dataTextOneLine}>
                    {date.toUTCDay(client.dateOfBirthday)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={isDetailView
          ? styles.contentBlockInDetailView : styles.contentBlockInTableModalView}
        >
          {client.additionalInfo && (
            <div className={styles.data}>
              <div className={styles.dataIcon}>
                <InfoIcon />
              </div>
              <div className={styles.dataText}>
                <div className={styles.dataTextThreeLine}>
                  {client.additionalInfo}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default ClientInfo;
