import * as apiClient from 'resources/api.client';

export function getNotifications(garageId) {
  return apiClient.get('notifications', { garageId });
}

export function haveEverHadNotifications(garageId) {
  return apiClient.get('notifications/have-ever-had-notifications', { garageId });
}

export function getProcessedNotifications(garageId, options) {
  return apiClient.get('notifications/processed', { garageId, ...options });
}

export function markAsProcessed(garageId, notificationId) {
  return apiClient.post('notifications/mark-as-processed', { garageId }, { notificationId });
}
