import { NOTIFICATION_TYPES } from 'shared-library/src/definitions/notificationTypes';
import { SmsPickupNotificationError } from './smsPickupNotificationError';
import { SdiRejectedNotification } from './sdiRejectedNotification';
import { MailErrorNotification } from './mailErrorNotification';

function getTypeImpl(notification, services) {
  switch (notification.type) {
    case NOTIFICATION_TYPES.SDI_REJECTED:
      return new SdiRejectedNotification(notification, services);
    case NOTIFICATION_TYPES.SMS_PICKUP_NOTIFICATION_ERROR:
      return new SmsPickupNotificationError(notification, services);
    case NOTIFICATION_TYPES.MAIL_ERROR:
      return new MailErrorNotification(notification, services);
    default:
      throw new Error('Notification type not implemented');
  }
}

export { getTypeImpl };
