/* eslint-disable class-methods-use-this */
const googleLibPhonenumber = require('google-libphonenumber');

const { PhoneNumberFormat: PNF, PhoneNumberType: PNT } = googleLibPhonenumber;
const phoneUtil = googleLibPhonenumber.PhoneNumberUtil.getInstance();

class PhoneNumberService {
  static getInstance() {
    if (!this.instance) {
      this.instance = new PhoneNumberService();
    }
    return this.instance;
  }

  isValideMobileNumber(phoneNumber) {
    try {
      const number = phoneUtil.parse(phoneNumber);
      return phoneUtil.getNumberType(number) === PNT.MOBILE;
    } catch (error) {
      return false;
    }
  }

  isNullOrValideMobileNumber(phoneNumber) {
    return phoneNumber ? this.isValideMobileNumber(phoneNumber) : true;
  }

  parseToValideMobileNumberOrNull(phoneNumber, clientCountry, garageCountry) {
    const parsedNumberWithoutLeadingZeros = this.replace00ByPlus(phoneNumber);
    let parsedNumber = parsedNumberWithoutLeadingZeros;
    let isMobileNumber = this.isValideMobileNumber(parsedNumber);

    if (!isMobileNumber) {
      if (clientCountry) {
        parsedNumber = this.formatNumberUsingCountryCode(
          parsedNumberWithoutLeadingZeros,
          clientCountry,
        );
        isMobileNumber = this.isValideMobileNumber(parsedNumber);
      }
      if (!isMobileNumber && garageCountry) {
        parsedNumber = this.formatNumberUsingCountryCode(
          parsedNumberWithoutLeadingZeros,
          garageCountry,
        );
        isMobileNumber = this.isValideMobileNumber(parsedNumber);
      }
    }

    if (isMobileNumber) {
      parsedNumber = this.formatValideMobileNumber(parsedNumber);
      return parsedNumber;
    }
    return null;
  }

  replace00ByPlus(phoneNumber) {
    return phoneNumber && phoneNumber.startsWith('00')
      ? phoneNumber.replace('00', '+')
      : phoneNumber;
  }

  formatNumberUsingCountryCode(phoneNumber, country) {
    try {
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, country);
      return phoneUtil.format(number, PNF.INTERNATIONAL);
    } catch (error) {
      return null;
    }
  }

  formatValideMobileNumber(phoneNumber) {
    const number = phoneUtil.parse(phoneNumber);
    return phoneUtil.format(number, PNF.INTERNATIONAL);
  }
}

module.exports = PhoneNumberService;
