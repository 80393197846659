import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import polyglot from 'services/localization';
import { MdPrint as PrintIcon } from 'react-icons/md';
import tableStyles from 'styles/table.pcss';
import toastService from 'helpers/toastService';

import styles from './printButton.styles.pcss';

const PrintButton = ({
  onPrint,
  onGetPdfUrl,
  getCanPrintnodeBeUsed,
  canPrintnodeBeUsed,
  className,
}) => {
  const handleClick = async () => {
    if (canPrintnodeBeUsed) {
      await onPrint();
      toastService.showSuccess(polyglot.t('invoices.actions.printed'));
      return;
    }

    const newTab = window.open('', '_blank');
    newTab.document.write(polyglot.t('actions.pdfLoading'));
    const url = await onGetPdfUrl();

    newTab.location = url;
  };

  return (
    <button
      type="button"
      className={cx(styles.printButton, className)}
      title={polyglot.t('actions.print')}
      onClick={handleClick}
    >
      <PrintIcon
        className={cx(tableStyles.tableIcon, tableStyles.tableIconAction)}
      />
    </button>
  );
};

PrintButton.propTypes = {
  onPrint: PropTypes.func.isRequired,
  onGetPdfUrl: PropTypes.func.isRequired,
  getCanPrintnodeBeUsed: PropTypes.func.isRequired,
  canPrintnodeBeUsed: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

PrintButton.defaultProps = {
  className: '',
};

export default PrintButton;
