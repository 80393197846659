import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import NumberFormat from 'react-number-format';
import * as fromGarage from 'resources/garage/garage.selectors';
import { GARAGE_COUNTRIES } from 'shared-library/src/definitions/garageCountries';
import { isNumeric } from 'helpers/number';
import { getWidthOfText } from 'helpers/domHelpers';
import { roundDigits } from 'shared-library/src/services/invoiceCalculationService';
import { faCaretDown, faCaretUp } from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';

import styles from './numberInput.styles.pcss';

class NumberInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    inputClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    controlUsingString: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    allowNegative: PropTypes.bool,
    scaleType: PropTypes.oneOf(['integer', 'price', '']),
    errorText: PropTypes.string,
    isValid: PropTypes.bool,
    refFunc: PropTypes.func,
    title: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyDown: PropTypes.func,
    tabIndex: PropTypes.number,
    country: PropTypes.oneOf(Object.values(GARAGE_COUNTRIES)).isRequired,
    isRow: PropTypes.bool,
    autoFocus: PropTypes.bool,
    isShowArrows: PropTypes.bool,
    unit: PropTypes.string,
    maxValue: PropTypes.number,
    dataTest: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    label: '',
    isRequired: false,
    inputClassName: '',
    labelClassName: '',
    value: '',
    controlUsingString: false,
    disabled: false,
    allowNegative: true,
    scaleType: '',
    errorText: '',
    isValid: true,
    refFunc: () => {},
    title: '',
    onFocus: () => {},
    onBlur: () => {},
    onKeyPress: () => {},
    onKeyDown: () => {},
    tabIndex: 0,
    isRow: false,
    autoFocus: false,
    isShowArrows: true,
    unit: '',
    maxValue: null,
    dataTest: '',
  };

  constructor(props) {
    super(props);

    const { value, controlUsingString } = this.props;

    let stringValue;
    if (controlUsingString) {
      stringValue = value;
    } else {
      stringValue = isNumeric(parseFloat(value)) ? parseFloat(value) : '';
    }

    this.state = {
      stringValue,
      isFocused: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, controlUsingString } = this.props;
    if ((!this.state.isFocused || controlUsingString) && value !== nextProps.value) {
      let stringValue;
      if (controlUsingString) {
        stringValue = nextProps.value;
      } else {
        stringValue = isNumeric(parseFloat(nextProps.value)) ? parseFloat(nextProps.value) : '';
      }

      this.setState({
        stringValue,
      });
    }
  }

  onToggleFocus = (callback) =>
    this.setState((prevState) => ({ isFocused: !prevState.isFocused }), callback);

  getDecimalScale = () => {
    if (this.state.stringValue === '-') {
      return undefined;
    }

    const { scaleType } = this.props;

    if (scaleType === 'integer') {
      return 0;
    }

    if (scaleType === 'price') {
      return 2;
    }

    return undefined;
  };

  changeWithArrow = ({ isIncrement = true } = {}) => {
    const { stringValue } = this.state;
    const { allowNegative } = this.props;

    const parsedValue = parseFloat(stringValue);
    const numericValue = isNumeric(parsedValue) ? parseFloat(parsedValue) : 0;
    const newNumericValue = isIncrement ? numericValue + 1 : numericValue - 1;

    if (!isIncrement && !allowNegative && newNumericValue < 0) {
      return;
    }

    const round = 10 ** 12;
    const newStringValue = (Math.round(newNumericValue * round) / round).toString();
    this.setState({ stringValue: newStringValue });

    if (this.props.controlUsingString) {
      this.props.onChange(newStringValue);
      return;
    }

    this.props.onChange(isNumeric(newNumericValue) ? newNumericValue : null);
  };

  render() {
    const {
      className,
      name,
      label,
      isRequired,
      inputClassName,
      labelClassName,
      controlUsingString,
      onChange,
      disabled,
      allowNegative,
      errorText,
      isValid,
      refFunc,
      title,
      onKeyPress,
      onKeyDown,
      tabIndex,
      country,
      isRow,
      scaleType,
      autoFocus,
      isShowArrows,
      unit,
      dataTest,
      maxValue,
    } = this.props;
    const { stringValue, isFocused } = this.state;

    const formattedString
      = stringValue === '' ? '' : parseFloat(Math.round(stringValue * 100) / 100).toFixed(2);
    const widthOfUnitText = parseFloat(getWidthOfText(unit, '13px').replace('px', ''));

    return (
      <div className={cx(className, styles.numberInput)}>
        <label
          htmlFor={name}
          className={cx(styles.numberInputLabel, {
            [styles.numberInputLabelColumn]: !isRow,
          })}
        >
          {label && (
            <div className={cx(styles.numberInputLabelText, labelClassName)}>
              <div>
                {label}
              </div>
              {!disabled && isRequired && <div className={styles.numberInputRequiredMark}>
                *
              </div>}
            </div>
          )}
          <div className={styles.numberInputField}>
            <NumberFormat
              tabIndex={tabIndex}
              id={name}
              className={cx(inputClassName || styles.numberInputFieldInput, {
                [styles.numberInputFieldError]: errorText || !isValid,
                [styles.numberInputFieldDisabled]: disabled,
                [styles.numberInputFieldInputWithArrows]: !disabled && isShowArrows,
                [styles.numberInputFieldInputWithoutArrows]: !isShowArrows,
                [styles.numberInputFieldWithUnit]: !!unit,
              })}
              style={!!unit && !isShowArrows ? { paddingRight: `${21 + widthOfUnitText}px` } : {}}
              name={name}
              value={!isFocused && scaleType === 'price' ? formattedString : stringValue}
              isNumericString
              onValueChange={({ value, floatValue }) => {
                let newFloatValue = floatValue;
                let newStringValue = value;
                if (maxValue !== null && floatValue > maxValue) {
                  newFloatValue = maxValue;
                  newStringValue = parseFloat(roundDigits(maxValue, 2)).toFixed(2);
                }

                this.setState({ stringValue: newStringValue });

                if (!isFocused) {
                  return;
                }

                if (controlUsingString) {
                  onChange(value);
                  return;
                }

                onChange(isNumeric(newFloatValue) ? newFloatValue : null);
              }}
              disabled={disabled}
              allowNegative={allowNegative}
              decimalSeparator={['CH', 'LI'].includes(country) ? '.' : ','}
              thousandSeparator=" "
              decimalScale={this.getDecimalScale()}
              onFocus={() => this.onToggleFocus(this.props.onFocus)}
              onBlur={() => this.onToggleFocus(this.props.onBlur)}
              getInputRef={(input) => refFunc(input)}
              title={title}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              autoFocus={autoFocus}
              data-test={dataTest}
            />
            {!disabled && isShowArrows && (
              <div className={styles.numberInputArrows}>
                <button
                  type="button"
                  className={styles.numberInputArrowButton}
                  onClick={() => this.changeWithArrow()}
                  tabIndex="-1"
                >
                  <div className={styles.numberInputUpArrow} />
                </button>
                <button
                  type="button"
                  className={styles.numberInputArrowButton}
                  onClick={() => this.changeWithArrow({ isIncrement: false })}
                  tabIndex="-1"
                >
                  <div className={styles.numberInputDownArrow} />
                </button>
              </div>
            )}
            { !!unit && (
              <div className={styles.numberInputUnit} style={isShowArrows ? { right: '25px' } : { right: '19px' }}>
                {unit}
              </div>
            )}
            {!isShowArrows && (
              <div className={styles.numberInputIncr} style={{}}>
                <div className={styles.numberInputPlus}>
                  <FontAwesomeIcon icon={faCaretUp} onClick={() => this.changeWithArrow()} />
                </div>
                <div className={styles.numberInputMinus}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    onClick={() => this.changeWithArrow({ isIncrement: false })}
                  />
                </div>
              </div>
            )}
          </div>
        </label>
        {!disabled && errorText && <div className={styles.numberInputError}>
          {errorText}
        </div>}
      </div>
    );
  }
}

export default connect((state) => ({
  country: fromGarage.getCountry(state),
}))(NumberInput);
