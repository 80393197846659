import React, { Component } from 'react';
import '!style-loader!css-loader!./statusBarStyles.pcss';

class SorryBanner extends Component {
  static propTypes = {
  };

  static defaultProps = {
  };

  componentDidMount() {
    this.changeButtonText();
  }

  waitForElm = (selector) => {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        return;
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  changeButtonText = async () => {
    const button = await this.waitForElm('.sorry-status-notice-link');
    button.innerHTML = 'Mehr Informationen';
  };

  render() {
    // This is not working right on the development environment.
    // There the content of the div is not rendered the right way.
    // To display the sorry banner also on development we add the `data-for="0b840cfa"` direct when
    // injecting the library in the index-dev.html. With this workaround the banner will be
    // displayed at the top of the site not the tht top of the content.
    return (
      <div className="sorry-status-bar" data-status-bar-for="0b840cfa" />
    );
  }
}

export default SorryBanner;
