const ENTITIES = Object.freeze({
  CLIENT: 'client',
  OWNER: 'owner',
  VEHICLE: 'vehicle',
  INVOICE: 'invoice',
  OFFER: 'offer',
  PRODUCT: 'product',
  TIRE: 'tire',
});

module.exports = {
  ENTITIES,
};
