import React from 'react';
import PropTypes from 'prop-types';

const SmileyHappy = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15" fill="none"></circle>
    <g clipPath="url(#clip0_35_272)">
      <path d="M0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C11.7565 32 7.68687 30.3143 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16ZM25.4625 20.7188C25.775 19.9813 25.025 19.3125 24.2563 19.55C21.775 20.3125 18.975 20.7375 16.0187 20.7375C13.0625 20.7375 10.2625 20.3125 7.78125 19.55C7.0125 19.3125 6.2625 19.9813 6.575 20.7188C8.1375 24.4125 11.775 27 16.0187 27C20.2625 27 23.9062 24.4125 25.4625 20.7188ZM10 7.5C9.80625 7.5 9.63125 7.6125 9.55 7.7875L8.5125 9.95625L6.13125 10.2688C5.9375 10.2937 5.78125 10.425 5.71875 10.6125C5.65625 10.8 5.7125 11 5.85 11.1313L7.59375 12.7875L7.15625 15.15C7.11875 15.3375 7.2 15.5312 7.35625 15.6438C7.5125 15.7563 7.71875 15.7687 7.8875 15.6812L10 14.5312L12.1125 15.675C12.2812 15.7688 12.4875 15.7562 12.6438 15.6375C12.8 15.5188 12.875 15.3313 12.8438 15.1438L12.4062 12.7812L14.15 11.125C14.2875 10.9937 14.3438 10.7937 14.2812 10.6062C14.2188 10.4187 14.0625 10.2875 13.8687 10.2625L11.4875 9.95L10.45 7.78125C10.3687 7.60625 10.1938 7.49375 10 7.49375V7.5ZM22 7.5C21.8062 7.5 21.6312 7.6125 21.55 7.7875L20.5125 9.95625L18.1313 10.2688C17.9375 10.2937 17.7812 10.425 17.7188 10.6125C17.6562 10.8 17.7125 11 17.85 11.1313L19.5938 12.7875L19.1562 15.15C19.1187 15.3375 19.2 15.5312 19.3563 15.6438C19.5125 15.7563 19.7188 15.7687 19.8875 15.6812L22 14.5312L24.1125 15.675C24.2812 15.7688 24.4875 15.7562 24.6437 15.6375C24.8 15.5188 24.875 15.3313 24.8438 15.1438L24.4062 12.7812L26.15 11.125C26.2875 10.9937 26.3438 10.7937 26.2812 10.6062C26.2188 10.4187 26.0625 10.2875 25.8687 10.2625L23.4875 9.95L22.45 7.78125C22.3688 7.60625 22.1938 7.49375 22 7.49375V7.5Z" fill="#E3E6E9"/>
    </g>
    <defs>
      <clipPath id="clip0_35_272">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

SmileyHappy.propTypes = {
  fill: PropTypes.string,
};

SmileyHappy.defaultProps = {
  fill: '#fff',
};

export default SmileyHappy;
