import { downloadFile } from 'helpers/file';
import { addPageSize } from 'resources/utils';
import * as fromUser from 'resources/user/user.selectors';
import * as api from './offer.api';

export const FETCH_OFFERS = 'fetchOffers';
export const FETCH_OFFER = 'fetchOffer';
export const FETCH_OFFER_EMAIL = 'fetchOfferEmail';
export const UPDATE_OFFER = 'updateOffer';
export const UPDATE_OFFER_SOCKET = 'updateOfferSocket';
export const REMOVE_OFFERS = 'removeOffers';
export const CREATE_OFFER = 'createOffer';
export const CHANGE_OFFERS_FILTER = 'changeOffersFilter';
export const RESET_OFFERS_STATE = 'resetOffersState';
export const PRINT_OFFER = 'printOffer';
export const RESTORE_OFFER = 'restoreOffer';

const getValidOfferData = (data) => {
  const offer = {
    ...data,
    taxSums: data.taxSums.map((sum) => ({ ...sum, rate: +sum.rate })),
  };

  return offer;
};

export const fetchOffers = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchOffers(garageId, data)
    .then((payload) => dispatch({ type: FETCH_OFFERS, data, payload }));
};

export const fetchOffer = (offerId, data = {}) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchOffer(garageId, offerId, data)
    .then((payload) => dispatch({ type: FETCH_OFFER, payload }));
};

export const selectOffer = (offer) => ({ type: FETCH_OFFER, payload: offer });

export const getOffersCSV = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getOffersCSV(garageId, data)
    .then((payload) => downloadFile(payload, 'offers.csv'));
};

export const createOffer = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  const offer = getValidOfferData({
    ...data,
    garageId,
  });

  return api.createOffer(garageId, offer)
    .then((payload) => dispatch({ type: CREATE_OFFER, payload }));
};

export const updateOffer = (offerId, data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  const validData = getValidOfferData(data);

  return api.updateOffer(garageId, offerId, validData)
    .then((payload) => dispatch({ type: UPDATE_OFFER, payload }));
};

export const changeStatus = (offerId, status) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.changeStatus(garageId, offerId, status)
    .then((payload) => dispatch({ type: UPDATE_OFFER, payload }));
};

export const removeOffers = (offerIds) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.removeOffers(garageId, offerIds)
    .then((payload) => dispatch({ type: REMOVE_OFFERS, offerIds }));
};

export const sendOfferViaEmail = (offerId, data) => (dispatch, getState) => {
  return api.sendOfferViaEmail(offerId, fromUser.getCurrentGarageId(getState()), data)
    .then((payload) => dispatch({ type: FETCH_OFFER_EMAIL, payload }));
};

export const changeOffersFilter = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchOffers(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_OFFERS_FILTER, data, payload }));
};

export const resetOffersState = () => ({ type: RESET_OFFERS_STATE });

export const printOffer = (id) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.printOffer(garageId, id)
    .then((payload) => dispatch({ type: PRINT_OFFER, id, payload }));
};

export const generateOfferPdf = (id) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.generateOfferPdf(garageId, id);
};

export const getOfferPdfUrl = (id) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getOfferPdfUrl(id, garageId);
};

export const copyIntoOffer = (offerId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.copyIntoOffer(offerId, garageId)
    .then((payload) => dispatch({ type: UPDATE_OFFER, payload }));
};

export const restoreOffer = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  return api.restoreOffer(currentGarageId, data)
    .then((payload) => dispatch({ type: RESTORE_OFFER, data, payload }));
};
