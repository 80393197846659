import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from 'components/common/confirmModal';

export default class ActionWithConfirmModal extends PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  state = {
    isConfirmModalOpen: false,
    confirmModalTitle: '',
    confirmModalText: '',
    proceedFunction: () => {},
    isDelete: false,
    isMoveToRecycleBin: false,
  };

  onOpenConfirmModal = (
    confirmModalTitle,
    confirmModalText,
    proceedFunction,
    isDelete = false,
    isMoveToRecycleBin = false,
  ) => {
    this.setState({
      isConfirmModalOpen: true,
      confirmModalTitle,
      confirmModalText,
      proceedFunction,
      isDelete,
      isMoveToRecycleBin,
    });
  };

  onCloseConfirmModal = () => this.setState({
    isConfirmModalOpen: false,
    confirmModalTitle: '',
    confirmModalText: '',
    proceedFunction: () => {},
    isDelete: false,
    isMoveToRecycleBin: false,
  });

  render() {
    const { children } = this.props;
    const {
      isConfirmModalOpen,
      confirmModalTitle,
      confirmModalText,
      proceedFunction,
      isDelete,
      isMoveToRecycleBin,
    } = this.state;
    return (
      <Fragment>
        {children(this.onOpenConfirmModal)}
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={confirmModalTitle}
          text={confirmModalText}
          onProceed={proceedFunction}
          onClose={this.onCloseConfirmModal}
          isDelete={isDelete}
          isMoveToRecycleBin={isMoveToRecycleBin}
        />
      </Fragment>
    );
  }
}
