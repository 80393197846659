import React from 'react';
import { PropTypes } from 'prop-types';

const SwissEmblem = ({ className, ...props }) => {
  return (
    <svg className={className} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
      <path d="M 0 0 l 14 0 l 0 8 Q 14 17 7 18 Q 0 17 0 8 z" fill="#C54333" />
      <path d="M 5.25 3 l 3.5 0 l 0 3.5 l 3.5 0 l 0 3.5 l -3.5 0 l 0 3.5 l -3.5 0 l 0 -3.5 l -3.5 0 l 0 -3.5 l 3.5 0 l 0 -3.5" fill="#ffffff" />
    </svg>
  );
};

SwissEmblem.propTypes = {
  className: PropTypes.string,
};

SwissEmblem.defaultProps = {
  className: '',
};

export default SwissEmblem;
