import { last } from 'lodash/array';

import {
  FETCH_RECEIPTS,
  RESET_RECEIPTS_STATE,
  CHANGE_RECEIPTS_FILTER,
} from './receipt.actions';

const initialState = { list: [], balance: 0, totalAmount: 0 };

export default function receiptReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RECEIPTS: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        balance: action.payload.balance,
        totalAmount: action.payload.count,
      };
    }
    case CHANGE_RECEIPTS_FILTER:
      return {
        ...state,
        list: [...action.payload.results],
        balance: action.payload.balance,
        totalAmount: action.payload.count,
      };
    case RESET_RECEIPTS_STATE:
      return {
        ...state,
        list: [],
        balance: 0,
        totalAmount: 0,
      };
    default:
      return state;
  }
}
