'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'dolly';
var width = 640;
var height = 512;
var aliases = ["dolly-box"];
var unicode = 'f472';
var svgPathData = 'M32 32C32 14.3 46.3 0 64 0h72.9c27.5 0 52 17.6 60.7 43.8L289.7 320c30.1 .5 56.8 14.9 74 37l202.1-67.4c16.8-5.6 34.9 3.5 40.5 20.2s-3.5 34.9-20.2 40.5L384 417.7c-.9 52.2-43.5 94.3-96 94.3c-53 0-96-43-96-96c0-30.8 14.5-58.2 37-75.8L136.9 64H64C46.3 64 32 49.7 32 32zM276.8 134.5c-5.5-16.8 3.7-34.9 20.5-40.3L343 79.4l19.8 60.9 60.9-19.8L403.8 59.6l45.7-14.8c16.8-5.5 34.9 3.7 40.3 20.5l49.4 152.2c5.5 16.8-3.7 34.9-20.5 40.3L366.5 307.2c-16.8 5.5-34.9-3.7-40.3-20.5L276.8 134.5z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faDolly = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;