import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class StopEventPropagation extends PureComponent {
  static propTypes = {
    tag: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.oneOf(['onClick'])).isRequired,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    tag: 'span',
  };

  handleStopPropagation = (event) => event.stopPropagation();

  render() {
    const {
      events, tag, children,
    } = this.props;
    const handlers = events.reduce((props, event) => {
      props[event] = this.handleStopPropagation; // eslint-disable-line
      return props;
    }, {});

    return React.createElement(tag, { ...handlers }, children);
  }
}
