export function getVehicles({ vehicles }) {
  return vehicles.list;
}

export function getTotalAmount({ vehicles }) {
  return vehicles.totalAmount;
}

export function getVehicleById({ vehicles }, id) {
  return vehicles.list.find((vehicle) => vehicle._id === id) || {};
}

export function getCurrentVehicle({ vehicles }) {
  return vehicles.currentVehicle;
}

export function getCurrentVehicleId({ vehicles }) {
  return vehicles.currentVehicle._id;
}
