import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import {
  faBuildings, faEnvelope, faHandsHelping, faHistory, faInfoCircle, faMapMarker,
  faPen, faPhoneAlt, faUser, faMobileAlt, faXmark,
  faBirthdayCake, faIdCard,
} from 'fontawesome/pro-solid-svg-icons';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';
import { toUTCDay } from 'helpers/date';
import polyglot from 'services/localization';
import withDeviceInfo from 'components/higherOrderComponents/withDeviceInfo';
import ButtonModern from 'components/common/buttonModern';
import CustomLink from 'components/common/customLink';
import InteractableDiv from 'components/common/interactableDiv';
import { getClientName } from 'shared-library/src/services/clientService/client.service';
import styles from './clientCard.styles.pcss';

class ClientCard extends Component {
  static propTypes = {
    client: PropTypes.shape({
      _id: PropTypes.string,
      externalId: PropTypes.string,
      UIDNumber: PropTypes.string,
      phone: PropTypes.string,
      mobilePhone: PropTypes.string,
      email: PropTypes.string,
      formOfAddress: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      dateOfBirthday: PropTypes.string,
      address: PropTypes.string,
      toAttention: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      title: PropTypes.string,
      additionalInfo: PropTypes.string,
      removedOn: PropTypes.string,
      taxNumber: PropTypes.string,
      recipientCode: PropTypes.string,
      pec: PropTypes.string,
    }).isRequired,
    showClientDetails: PropTypes.bool,
    match: PropTypes.shape({
      params: PropTypes.object,
      path: PropTypes.string,
      url: PropTypes.string,
    }),
    toggleClientMessageModal: PropTypes.func,
    editClient: PropTypes.func,
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    isSelected: PropTypes.bool,
    isMobileSize: PropTypes.bool.isRequired,
    dataTest: PropTypes.string,
    hideAdditionalData: PropTypes.bool,
    showOnlyBillingAddress: PropTypes.bool,
    onClick: PropTypes.func,
    useHoverStyles: PropTypes.bool,
    showRemoveIcon: PropTypes.bool,
    onRemoveClicked: PropTypes.func,
    ignoreRemovedStyles: PropTypes.bool,
    showNameLinks: PropTypes.bool,
    getRemoveHoverState: PropTypes.func,
    hideEditButton: PropTypes.bool,
  };

  static defaultProps = {
    showClientDetails: false,
    hideAdditionalData: false,
    editClient: PropTypes.func,
    acions: null,
    isSelected: false,
    dataTest: null,
    match: null,
    showOnlyBillingAddress: false,
    onClick: () => {},
    toggleClientMessageModal: null,
    useHoverStyles: true,
    showRemoveIcon: false,
    onRemoveClicked: () => {},
    ignoreRemovedStyles: false,
    showNameLinks: false,
    getRemoveHoverState: () => {},
    hideEditButton: false,
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  state = {
    isRemoveIconHovered: false,
  };

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    const { getRemoveHoverState } = this.props;
    const { isRemoveIconHovered } = this.state;
    if (prevState.isRemoveIconHovered !== isRemoveIconHovered) {
      getRemoveHoverState(isRemoveIconHovered);
    }
  }

  renderClientDetailsHeader = () => {
    const {
      client,
      toggleClientMessageModal,
      editClient, isMobileSize,
      showNameLinks,
      hideEditButton,
    } = this.props;
    const containerWidth = this.containerRef?.current?.getBoundingClientRect().width;

    return (
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <FontAwesomeIcon
            icon={client.formOfAddress === 'company' ? faBuildings : faUser} style={client.formOfAddress === 'company' && { transform: 'scaleX(-1)' }}
            className={styles.headerIcon}
          />
          <div className={styles.headerContentName}>
            {client.formOfAddress ? `${polyglot.t(`editClient.formOfAddressOptions.${client.formOfAddress}`)}` : ''}
            {showNameLinks ? (
              <Link
                to={`/clients/all/${client._id}`}
                className={styles.link}
                disabled={!showNameLinks}
              >
                <div className={styles.headerName}>
                  <div
                    style={ !isMobileSize ? { maxWidth: containerWidth - 215 } : {}}
                    className={styles.truncateFormat}
                  >
                    {`${client?.title}${client.title ? ' ' : ''}${getClientName(client)}`}
                  </div>
                </div>
              </Link>
            ) : (
              <div className={cx(styles.headerName, styles.headerNameWithoutLink)}>
                <div
                  style={ !isMobileSize ? { maxWidth: containerWidth - 215 } : {}}
                  className={styles.truncateFormat}
                >
                  {`${client?.title}${client.title ? ' ' : ''}${getClientName(client)}`}
                </div>
              </div>
            )}

            <div>
              {`${polyglot.t('clients.externalId')} ${client.externalId || ''}`}
            </div>
          </div>
          <div className={styles.headerActions}>
            {this.renderIconAndActions(false, true)}
            {!hideEditButton && (
              <ButtonModern
                size="small"
                styleType="select"
                onClick={editClient}
                title={polyglot.t('actions.edit')}
                >
                {!isMobileSize && (
                  <div className={styles.containerActionText}>
                    {polyglot.t('actions.edit')}
                  </div>
                )}
                <FontAwesomeIcon fixedWidth icon={faPen} className={styles.containerActionIcon} />
              </ButtonModern>
            )}
            <div className={styles.containerAction}>
              <Link
                to={`/clients/all/${client._id}/products`}
                className={styles.noTextDecoration}
              >
                <ButtonModern
                  size="small"
                  styleType="select"
                  title={polyglot.t('actions.history')}
                >
                  {!isMobileSize && (
                    <div className={styles.containerActionText}>
                      {polyglot.t('actions.history')}
                    </div>
                  )}
                  <FontAwesomeIcon
                    fixedWidth
                    icon={faHistory}
                    className={styles.containerActionIcon}
                  />
                </ButtonModern>
              </Link>
            </div>

            {toggleClientMessageModal && (
              <ButtonModern
                size="small"
                styleType="select"
                onClick={() => toggleClientMessageModal()}
              >
                {!isMobileSize && (
                <div className={styles.containerActionText}>
                  {polyglot.t('sendEmail.message')}
                </div>
                )}
                <FontAwesomeIcon
                  fixedWidth
                  icon={faEnvelope}
                  className={styles.containerActionIcon}
                />
              </ButtonModern>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderClientDetailsCard = () => {
    const {
      client,
      isMobileSize,
    } = this.props;

    return (
      <div>
        <div ref={this.containerRef} className={styles.details}>
          {this.renderClientDetailsHeader()}
          <div className={styles.seperator} />
          <div className={styles.detailsContainer}>
            <div className={styles.detailsInfo}>
              <div className={styles.detailsGrid} >

                {(client.toAttention || !isMobileSize) && (
                  <div className={styles.detailsRow}>
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faHandsHelping}
                      className={styles.detailsIcon}
                    />
                    {client.toAttention}
                  </div>
                )}
                {((client.address || client.zipCode || client.city) || !isMobileSize) && (
                  <div className={styles.detailsRow}>
                    <FontAwesomeIcon fixedWidth icon={faMapMarker} className={styles.detailsIcon} />
                    <div className={cx(styles.truncateFormat, styles.truncateFormatDetailsAddress)}>
                      {`${client.address}\n\r${client.zipCode} ${client.city}\n\r${polyglot.t(`client.countries.${client.country}`)}`}
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.detailsGrid}>
                {(client.mobilePhone || !isMobileSize) && (
                  <div className={styles.detailsRow}>
                    <FontAwesomeIcon fixedWidth icon={faMobileAlt} className={styles.detailsIcon} />
                    {<CustomLink value={client.mobilePhone} type={'phone'}/>}
                  </div>
                )}
                {(client.phone || !isMobileSize) && (
                  <div className={styles.detailsRow}>
                    <FontAwesomeIcon fixedWidth icon={faPhoneAlt} className={styles.detailsIcon} />
                    {<CustomLink value={client.phone} type={'phone'}/>}
                  </div>
                )}
                {(client.email || !isMobileSize) && (
                  <div className={styles.detailsRow}>
                    <FontAwesomeIcon fixedWidth icon={faEnvelope} className={styles.detailsIcon} />
                    {<CustomLink value={client.email} type={'mail'} />}
                  </div>
                )}
                {(client.dateOfBirthday || !isMobileSize) && (
                  <div className={styles.detailsRow}>
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faBirthdayCake}
                      className={styles.detailsIcon}
                    />
                    {`${!client.dateOfBirthday || toUTCDay(client.dateOfBirthday) === 'Invalid date' ? '' : toUTCDay(client.dateOfBirthday)}`}
                  </div>
                )}
              </div>
            </div>

            {(client.taxNumber || client.UIDNumber || client.recipientCode || client.pec) && (
              <>
                <div className={styles.detailsCardData}>
                  <div className={styles.detailsRow}>
                    <FontAwesomeIcon fixedWidth icon={faIdCard} className={styles.detailsIcon} />
                    <div className={cx(styles.detailsGroup)}>
                      {client.taxNumber && (
                      <>
                        <div className={styles.detailsGroupRowTitle}>
                          {polyglot.t('editClient.taxNumber')}
                        </div>
                        <div className={styles.detailsGroupRowContent}>
                          {client.taxNumber}
                        </div>
                      </>
                      )}
                      {client.UIDNumber && (
                      <>
                        <div className={styles.detailsGroupRowTitle}>
                          {polyglot.t('editClient.UIDNumber')}
                        </div>
                        <div className={styles.detailsGroupRowContent}>
                          {client.UIDNumber}
                        </div>
                      </>
                      )}
                      {client.recipientCode && (
                      <>
                        <div className={styles.detailsGroupRowTitle}>
                          {polyglot.t('editClient.recipientCode')}
                        </div>
                        <div className={styles.detailsGroupRowContent}>
                          {client.recipientCode}
                        </div>
                      </>
                      )}
                      {client.pec && (
                      <>
                        <div className={styles.detailsGroupRowTitle}>
                          {polyglot.t('editClient.pec')}
                        </div>
                        <div className={styles.detailsGroupRowContent}>
                          {client.pec}
                        </div>
                      </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className={styles.seperator} />
            {(client.additionalInfo || !isMobileSize) && (
            <div className={styles.detailsRow}>
              <FontAwesomeIcon
                fixedWidth
                icon={faInfoCircle}
                className={cx(styles.detailsIcon, styles.iconAlignSpace)}
              />
              {client.additionalInfo}
            </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderCardNameWithLink = () => {
    const {
      client,
      showNameLinks,
    } = this.props;
    if (client.removedOn || !showNameLinks) {
      return (
        <div className={cx(
          styles.containerCardElementName,
          styles.truncateFormat,
          styles.truncateFormatCardName,
        )}>
          {getClientName(client)}
        </div>
      );
    }
    return (
      <Link
          to={`/clients/all/${client._id}`}
          className={styles.link}
          onClick={(e) => e.stopPropagation()}
      >
        <div
          className={cx(
            styles.containerCardElementName,
            styles.truncateFormat,
            styles.truncateFormatCardName,
          )}
        >
          {getClientName(client)}
        </div>
      </Link>
    );
  };

  renderBillingAddressCard = () => {
    const {
      client,
      dataTest,
      onClick,
      useHoverStyles,
      ignoreRemovedStyles,
    } = this.props;

    const {
      isRemoveIconHovered,
    } = this.state;
    return (
      <InteractableDiv
        onClick={onClick}
        className={cx(
          styles.containerCard,
          {
            [styles.containerCardHover]:
            useHoverStyles && !client.removedOn && !isRemoveIconHovered,
          },
          { [styles.containerCardDeleted]: client.removedOn && !ignoreRemovedStyles },
          { [styles.containerCardOnRemove]: isRemoveIconHovered },
        )}
        dataTest={dataTest}
      >
        <div className={styles.containerCardData}>
          {this.renderCardNameWithLink()}
          <div className={styles.containerCardElement}>
            <FontAwesomeIcon fixedWidth icon={faMapMarker} className={styles.icon} />
            {`${client.address.trim() || '-'}\n${client.zipCode || '-'} ${client.city}\n${polyglot.t(`client.countries.${client.country}`) || '-'}`}
          </div>
        </div>
        {this.renderIconAndActions(false)}
      </InteractableDiv>
    );
  };

  renderClientCard = () => {
    const {
      client,
      isSelected,
      dataTest,
      hideAdditionalData,
      onClick,
      useHoverStyles,
      ignoreRemovedStyles,
    } = this.props;
    const {
      isRemoveIconHovered,
    } = this.state;
    return (
      <InteractableDiv
        className={cx(
          styles.containerCard,
          { [styles.containerCardSelected]: isSelected },
          {
            [styles.containerCardHover]:
              useHoverStyles && !client.removedOn && !isRemoveIconHovered,
          },
          { [styles.containerCardDeleted]: client.removedOn && !ignoreRemovedStyles },
          { [styles.containerCardOnRemove]: isRemoveIconHovered },
        )}
        dataTest={dataTest}
        onClick={onClick}
      >
        <div className={styles.containerCardData}>
          {this.renderCardNameWithLink()}
          {!hideAdditionalData && (
          <div className={styles.containerCardElement}>
            <FontAwesomeIcon icon={faMapMarker} className={styles.icon} />
            <div className={styles.truncateFormat}>
              {`${client.address.trim() || '-'}\n${client.zipCode || '-'} ${client.city || '-'}`}
            </div>
          </div>
          )}
          {client.phone && (
          <div className={styles.containerCardElement}>
            <FontAwesomeIcon icon={faPhoneAlt} className={styles.icon} />
            {client.phone}
          </div>
          )}

          {client.mobilePhone && (
            <div className={styles.containerCardElement}>
              <FontAwesomeIcon icon={faMobileAlt} className={styles.icon} />
              {client.mobilePhone}
            </div>
          )}

          {client.email && (
            <div className={styles.containerCardElement}>
              <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
              <div className={styles.truncateFormat}>
                {client.email}
              </div>
            </div>
          )}

          {client.additionalInfo && (
            <div className={styles.containerCardElement}>
              <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />
              <div className={styles.containerCardElementEllipsis}>
                {client.additionalInfo}
              </div>
            </div>
          )}

        </div>
        {this.renderIconAndActions()}
      </InteractableDiv>
    );
  };

  renderIconAndActions = (showIcon = true, icClientDetails = false) => {
    const {
      client,
      actions,
      isSelected,
      showRemoveIcon,
      onRemoveClicked,
      ignoreRemovedStyles,
    } = this.props;
    const {
      isRemoveIconHovered,
    } = this.state;
    const showWhiteIcon
    = (isSelected || client.removedOn || isRemoveIconHovered)
    && !ignoreRemovedStyles;
    const noActionsAndIcon = !actions && !showIcon && !showRemoveIcon;
    if (noActionsAndIcon) return null;
    return (
      <div className={styles.containerCardIconAndActions}>
        {(actions || showRemoveIcon) && (
        <div className={cx(
          styles.containerCardElementActions,
          { [styles.containerCardElementActionsNoPadding]: icClientDetails },
        )}>
          {actions && actions.map((action, i) => {
            return (
              <InteractableDiv
                key={i}
                className={cx(styles.icon, styles.iconAction)}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); action.action(); }}
              >
                {action.icon}
              </InteractableDiv>
            );
          })}
          {showRemoveIcon && (
            <InteractableDiv
              className={cx(styles.icon, styles.iconAction, styles.iconActionRemove)}
              onClick={(e) => { e.preventDefault(); e.stopPropagation(); onRemoveClicked(); }}
              onMouseEnter={(e) => { this.setState({ isRemoveIconHovered: true }); }}
              onMouseLeave={(e) => { this.setState({ isRemoveIconHovered: false }); }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </InteractableDiv>
          )}
        </div>
        )}

        {showIcon && (
          <div className={styles.containerImageUser}>
            <FontAwesomeIcon icon={client.formOfAddress === 'company' ? faBuildings : faUser} className={cx(styles.containerImageUserIcon, { [styles.containerImageUserWhite]: showWhiteIcon })} />
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      showClientDetails,
      showOnlyBillingAddress,
    } = this.props;

    if (showClientDetails) { return this.renderClientDetailsCard(); }
    if (showOnlyBillingAddress) { return this.renderBillingAddressCard(); }
    return this.renderClientCard();
  }
}

export default withRouter(withDeviceInfo(ClientCard));
