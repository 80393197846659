import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import polyglot from 'services/localization';
import FormValidator from 'helpers/formValidation/formValidator';
import ValidatableComponent from 'helpers/formValidation/validatableComponent';

import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faCheck } from 'fontawesome/pro-solid-svg-icons';
import styles from './checkboxModern.styles.pcss';

export default class CheckboxModern extends ValidatableComponent {
  static propTypes = {
    label: PropTypes.string,
    selected: PropTypes.bool,
    onToggle: PropTypes.func,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    disabled: PropTypes.bool,
    lineHeight: PropTypes.string,
    title: PropTypes.string,
    tabIndex: PropTypes.number,
    cursorDefault: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    formValidator: FormValidator.getShape(),
    dataTest: PropTypes.string,
    children: PropTypes.node,
    isCircle: PropTypes.bool,
  };

  static defaultProps = {
    lineHeight: '',
    onToggle: () => {},
    selected: false,
    disabled: false,
    label: '',
    className: '',
    wrapperClassName: '',
    title: polyglot.t('actions.mark'),
    tabIndex: 0,
    cursorDefault: false,
    width: '16px',
    height: '16px',
    formValidator: null,
    dataTest: '',
    children: null,
    isCircle: false,
  };

  onKeyPress = ({ key }) => {
    if ((key === 'Enter')) {
      this.onToggleHandler();
    }
  };

  onToggleHandler = (event) => {
    const { disabled, onToggle, formValidator } = this.props;
    if (!disabled) {
      if (formValidator) {
        formValidator.set(!formValidator.value);
      }
      onToggle(event);
    }
  };

  render() {
    const {
      selected,
      className,
      wrapperClassName,
      disabled,
      cursorDefault,
      tabIndex,
      title,
      lineHeight,
      label,
      width,
      height,
      formValidator,
      dataTest,
      children,
      isCircle,
    } = this.props;
    const currSelected = formValidator ? formValidator.value : selected;
    return (
      <div
        className={cx(styles.checkbox, {
          [styles.checkboxDisabled]: disabled,
          [styles.checkboxPointer]: !cursorDefault,
        }, wrapperClassName)}
        onClick={disabled ? () => {} : this.onToggleHandler}
        onKeyPress={this.onKeyPress}
        role="checkbox"
        aria-checked={currSelected}
        tabIndex={tabIndex}
        data-test={dataTest}
      >
        <div
          title={title}
          style={{ width, height }}
          className={cx(
            styles.checkboxContainer,
            className,
            { [styles.checkboxContainerCircle]: isCircle },
          )}
        >
          {currSelected ? (
            <FontAwesomeIcon className={styles.checkboxContainerIcon} icon={faCheck} />
          ) : (
            <div
              className={cx(
                styles.checkboxContainer,
                styles.checkboxContainerEmpty,
                { [styles.checkboxContainerCircle]: isCircle },
              )}
            />
          )}
        </div>
        <div style={{ lineHeight: lineHeight || '100%' }}>
          {children || label}
        </div>
      </div>
    );
  }
}
