import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import DeviceService from 'services/device.service';

const withDeviceInfo = (WrappedComponent) => {
  class WrapperComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isMobileSize: DeviceService.isMobileSize,
        OS: DeviceService.OS,
        isMobileDevice: DeviceService.isMobile,
        subscription: null,
        isTouchDevice: DeviceService.isTouchDevice,
      };
    }

    componentDidMount() {
      const subscription = DeviceService.addResizeListener(({ width, height }) => {
        this.setState({
          isMobileSize: DeviceService.isMobileSize,
          isMobileDevice: DeviceService.isMobile,
          isTouchDevice: DeviceService.isTouchDevice,
        });
      });
      this.setState({
        subscription,
        OS: DeviceService.OS,
        isMobileDevice: DeviceService.isMobile,
        isTouchDevice: DeviceService.isTouchDevice,
      });
    }

    componentWillUnmount() {
      const { subscription } = this.state;
      if (subscription && subscription.unsubscribe) {
        subscription.unsubscribe();
      }
    }

    render() {
      const {
        isMobileSize, OS, isMobileDevice, isTouchDevice,
      } = this.state;
      return (
        <WrappedComponent
          isMobileSize={isMobileSize}
          OS={OS}
          isMobileDevice={isMobileDevice}
          isTouchDevice={isTouchDevice}
          {...this.props}
        />
      );
    }
  }
  return hoistNonReactStatics(WrapperComponent, WrappedComponent);
};

export default withDeviceInfo;
