import { last } from 'lodash/array';

import { updateAppByAction } from 'resources/utils';
import {
  FETCH_TIRES,
  RESET_TIRES_STATE,
  REMOVE_TIRES,
  UPDATE_TIRE,
  CHANGE_TIRES_FILTER,
  CREATE_TIRE,
} from './tires.actions';

const initialState = { list: [], totalAmount: 0 };
const actionTypesToUpdateApp = [REMOVE_TIRES, UPDATE_TIRE, CREATE_TIRE];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case FETCH_TIRES: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }

    case RESET_TIRES_STATE:
      return {
        ...initialState,
        list: [],
      };

    case UPDATE_TIRE:
      return {
        ...state,
        list: state.list.map((tire) => (tire._id === action.payload._id ? {
          ...tire,
          ...action.payload,
        } : tire)),
      };

    case REMOVE_TIRES:
      return {
        ...state,
        list: state.list.filter((tire) => !action.tireIds.includes(tire._id)),
        totalAmount: state.totalAmount - action.tireIds.length,
      };

    case CHANGE_TIRES_FILTER:
      return {
        ...state,
        list: [...action.payload.results],
        totalAmount: action.payload.count,
      };

    case CREATE_TIRE:
      return {
        ...state,
        list: [...state.list, action.payload],
        totalAmount: state.totalAmount + 1,
      };

    default:
      return state;
  }
};
