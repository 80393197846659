const CALCULATED_POSITION_TYPES = {
  ADD: 'add',
  SUBTRACT: 'subtract',
  ROUND: 'round',
};

const ROUND_TYPES = {
  ROUND: 'round',
  CEIL: 'ceil',
  FLOOR: 'floor',
};

const SUB_ADD_TYPES = {
  PERCENT: 'percent',
  TOTAL: 'total',
};

const SUB_ADD_ON = {
  ALL: 'all',
  PARTS: 'product',
  WORK: 'work',
  OTHER: 'other',
};

module.exports = {
  CALCULATED_POSITION_TYPES,
  ROUND_TYPES,
  SUB_ADD_TYPES,
  SUB_ADD_ON,
};
