import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  createVehicle,
  updateVehicle,
} from 'resources/vehicles/vehicles.actions';

import VehicleEditModal from './vehicleEditModal';

export default connect(null, {
  createVehicle,
  updateVehicle,
})(withRouter(VehicleEditModal));
