import { getCorrectDate } from 'helpers/date';
import polyglot from 'services/localization';
import React from 'react';
import {
  FaCar,
  FaMotorcycle,
  FaBicycle,
  FaTractor,
  FaTruck,
  FaTruckMonster,
  FaShuttleVan,
  FaCaravan,
  FaTrailer,
} from 'react-icons/fa';
import { MdMoped as FaMoped } from 'react-icons/md';

export const getVehicleName = ({
  manufacturer = '',
  model = '',
}) => `${manufacturer || ''} ${model || ''}`.trim();

export const getVehicleLabel = ({
  manufacturer = '',
  model = '',
  license = '',
  dateOfFirstRegistration = '',
}) => [
  `${manufacturer} ${model}`.trim(),
  license,
  getCorrectDate(dateOfFirstRegistration),
].filter((item) => item).join(', ');

export const getShortVehicleLabel = ({
  manufacturer = '',
  model = '',
  license = '',
}) => [
  `${manufacturer} ${model}`.trim(),
  license,
].filter((item) => item).join(', ');

export const getVehicleFormLabel = (formFieldKey) => {
  const title = polyglot.t(`editVehicle.form.${formFieldKey}.title`);
  const key = polyglot.has(`editVehicle.form.${formFieldKey}.key`) ? ` ${polyglot.t(`editVehicle.form.${formFieldKey}.key`)}` : '';
  return `${title}${key}`;
};

export const getIconFromVehicleClass = (carClass) => {
  const garageTypes = [
    { icon: FaCar, vehicleCarClasses: ['M1'] },
    { icon: FaMotorcycle, vehicleCarClasses: ['L1e', 'L2e', 'L3e', 'L4e', 'L5e', 'L6e', 'L7e'] },
    { icon: FaBicycle, vehicleCarClasses: [] },
    { icon: FaTractor, vehicleCarClasses: ['T1', 'T2', 'T4', 'T5'] },
    { icon: FaTruck, vehicleCarClasses: ['N1', 'N2', 'N3', 'C1', 'C2', 'C3', 'C4', 'C5'] },
    { icon: FaTruckMonster, vehicleCarClasses: ['L'] },
    { icon: FaShuttleVan, vehicleCarClasses: ['M3', 'M2'] },
    { icon: FaCaravan, vehicleCarClasses: [] },
    { icon: FaTrailer, vehicleCarClasses: ['O1', 'O2', 'O3', 'O4', 'R1', 'R2', 'R3', 'R4'] },
    { icon: FaMoped, vehicleCarClasses: [] },
  ];
  let [finalGarageType] = garageTypes;
  const carClassFormatted = carClass.split('|')[0].trim();
  garageTypes.forEach((garageType) => {
    garageType.vehicleCarClasses.forEach((vehicleCarClass) => {
      if (vehicleCarClass === carClassFormatted) {
        finalGarageType = garageType;
      }
    });
  });
  return finalGarageType;
};

export const getIconFromGarageType = (garageType) => {
  const garageTypes = [
    { icon: FaCar, id: 'car' },
    { icon: FaMotorcycle, id: 'motorcycle' },
    { icon: FaBicycle, id: 'bicycle' },
    { icon: FaTractor, id: 'tractor' },
    { icon: FaTruck, id: 'truck' },
    { icon: FaTruckMonster, id: 'truck-monster' },
    { icon: FaShuttleVan, id: 'shuttle-van' },
    { icon: FaCaravan, id: 'caravan' },
    { icon: FaTrailer, id: 'trailer' },
    { icon: FaMoped, id: 'moped' },
  ];
  const vehicleIcon
   = garageTypes.find((garageTypeFromArray) => garageTypeFromArray.id === garageType);
  return vehicleIcon || garageTypes[0];
};

export const getSlashIconFromGarageType = (garageType) => {
  const garageTypes = [
    { icon: <i className="fa-kit fa-solid-car-slash"></i>, id: 'car' },
    { icon: <i className="fa-kit fa-solid-motorcycle-slash"></i>, id: 'motorcycle' },
    { icon: <i className="fa-kit fa-solid-bicycle-slash"></i>, id: 'bicycle' },
    { icon: <i className="fa-kit fa-solid-tractor-slash"></i>, id: 'tractor' },
    { icon: <i className="fa-kit fa-solid-truck-slash"></i>, id: 'truck' },
    { icon: <i className="fa-kit fa-solid-truck-monster-slash"></i>, id: 'truck-monster' },
    { icon: <i className="fa-kit fa-solid-van-shuttle-slash"></i>, id: 'shuttle-van' },
    { icon: <i className="fa-kit fa-solid-caravan-slash"></i>, id: 'caravan' },
    { icon: <i className="fa-kit fa-solid-trailer-slash"></i>, id: 'trailer' },
    { icon: <i className="fa-kit fa-solid-moped-slash"></i>, id: 'moped' },
  ];
  const vehicleIcon
  = garageTypes.find((garageTypeFromArray) => garageTypeFromArray.id === garageType);
  return vehicleIcon || garageTypes[0];
};
