import { last } from 'lodash/array';

import {
  FETCH_CASHBOOK,
  RESET_CASHBOOK_STATE,
  CHANGE_CASHBOOK_FILTER,
} from './cashbook.actions';

const initialState = {
  list: [],
  balance: 0,
  startingSum: 0,
  totalAmount: 0,
};

export default function receiptReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CASHBOOK: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }
      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        balance: action.payload.balance,
        startingSum: action.payload.startingSum,
        totalAmount: action.payload.count,
      };
    }
    case CHANGE_CASHBOOK_FILTER:
      return {
        ...state,
        list: [...action.payload.results],
        balance: action.payload.balance,
        startingSum: action.payload.startingSum,
        totalAmount: action.payload.count,
      };
    case RESET_CASHBOOK_STATE:
      return {
        ...state,
        list: [],
        balance: 0,
        startingSum: 0,
        totalAmount: 0,
      };
    default:
      return state;
  }
}
