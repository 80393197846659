import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from './user/user.reducer';
import garage from './garage/garage.reducer';
import clients from './clients/clients.reducer';
import vehicles from './vehicles/vehicles.reducer';
import manufacturers from './manufacturers/manufacturers.reducer';
import invites from './invite/invite.reducer';
import product from './product/product.reducer';
import positionGroup from './positionGroup/positionGroup.reducer';
import suppliers from './suppliers/suppliers.reducer';
import tires from './tires/tires.reducer';
import invoices from './invoice/invoice.reducer';
import offers from './offer/offer.reducer';
import receipts from './receipt/receipt.reducer';
import cashbook from './cashbook/cashbook.reducer';
import printingSettings from './printingSettings/printingSettings.reducer';
import emailEvents from './email/email.reducer';
import printers from './printers/printers.reducer';
import globalSuppliers from './globalSuppliers/globalSuppliers.reducer';
import thirdPartyTools from './thirdPartyTools/thirdPartyTools.reducer';
import calendar from './calendar/calendar.reducer';
import worker from './worker/worker.reducer';
import textBlocks from './textBlock/textBlock.reducer';
import sdi from './sdi/sdi.reducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user,
  garage,
  clients,
  vehicles,
  invites,
  manufacturers,
  product,
  positionGroup,
  suppliers,
  tires,
  invoices,
  offers,
  receipts,
  cashbook,
  printingSettings,
  emailEvents,
  printers,
  globalSuppliers,
  thirdPartyTools,
  calendar,
  worker,
  textBlocks,
  sdi,
});

export default createRootReducer;
