import { addPageSize } from 'resources/utils';
import * as fromUser from 'resources/user/user.selectors';
import * as api from './textBlock.api';

export const FETCH_TEXTBLOCKS = 'fetchTextBlocks';
export const RESET_TEXTBLOCKS_STATE = 'resetTextBlocksState';
export const REMOVE_TEXTBLOCKS = 'removeTextBlocks';

export const fetchTextBlocks = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.fetchTextBlocks(currentGarageId, data)
    .then((payload) => dispatch({ type: FETCH_TEXTBLOCKS, data, payload }));
};

export const createTextBlock = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const currentUserId = fromUser.getCurrentUserId(state);

  return api.createTextBlock(currentGarageId, currentUserId, data);
};

export const updateTextBlock = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const currentUserId = fromUser.getCurrentUserId(state);

  return api.updateTextBlock(currentGarageId, currentUserId, data);
};

export const updateTextBlockScore = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const currentUserId = fromUser.getCurrentUserId(state);

  return api.updateTextBlockScore(currentGarageId, currentUserId, data);
};

export const resetTextBlocksState = () => ({ type: RESET_TEXTBLOCKS_STATE });

export const removeTextBlocks = (textBlockIds) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const currentUserId = fromUser.getCurrentUserId(state);

  return api.removeTextBlocks(currentGarageId, currentUserId, textBlockIds)
    .then((payload) => dispatch({ type: REMOVE_TEXTBLOCKS, textBlockIds, payload }));
};
