import { connect } from 'react-redux';
import * as fromGarage from 'resources/garage/garage.selectors';

import DataTableModal from './dataTableModal';

export default connect(
  (state) => ({
    garageCountry: fromGarage.getCountry(state),
    garageType: fromGarage.getGarageType(state),
    garageId: fromGarage.getGarageId(state),
  }),
  {},
)(DataTableModal);
