import React, { Component } from 'react';

import InputModern from 'components/common/inputModern';
import ButtonModern from 'components/common/buttonModern';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { forgotPassword, checkPasswordToken, resetPassword } from 'resources/account/account.api';
import { Validators, FormValidator, FormValidatorGroup } from 'helpers/formValidation';
import polyglot from 'services/localization';
import Message from 'components/common/message';
import { ActionButtons } from 'components/common/responsiveModal/responsiveModal';
import Logo from 'components/common/logo';
import parseError from 'helpers/parseError';
import toastService from 'helpers/toastService';

import accessStyles from '../../access.styles.pcss';

import BackgroundBlob from '../backgroundBlob';
import PasswordChecker from '../passwordChecker';

import styles from './resetPassword.styles.pcss';

class ResetPassword extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const resetPasswordForm = this.generateResetPasswordForm();
    this.state = {
      sent: false,
      resetPasswordForm,
      token: new URLSearchParams(props.location.search).get('token'),
      tokenPreckeckValid: true,
      password: '',
      passwordValid: false,
    };
    this.validateToken();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ token: new URLSearchParams(location.search).get('token') });
      this.validateToken();
    }
  }

  generateResetPasswordForm = () => {
    const formValidatorGroup = new FormValidatorGroup({
      email: new FormValidator('', [Validators.required(polyglot.t('access.signin.errors.email')), Validators.email()]),
    }, this, {}, this.onSubmit);

    return formValidatorGroup;
  };

  onSubmit = async (values) => {
    const { email } = values;
    await forgotPassword({ email });
    this.setState({ sent: true });
  };

  validateToken = async () => {
    const { token } = this.state;
    if (token) {
      checkPasswordToken({ token }).then(() => {
        this.setState({ tokenPreckeckValid: true });
      }).catch((e) => {
        this.setState({ tokenPreckeckValid: false });
      });
    }
  };

  onResetPassword = async () => {
    const { token, password } = this.state;

    try {
      const { token: resToken } = await resetPassword({ token, password });
      window.location = `${window.config.webUrl}?token=${resToken}`;
    } catch (x) {
      const msg = parseError(x);
      toastService.showError(msg || polyglot.t('access.password.resetError'));
    }
  };

  renderContent = () => {
    const { history } = this.props;
    const {
      resetPasswordForm,
      sent,
      token,
      tokenPreckeckValid,
      password,
      passwordValid,
    } = this.state;
    // Render Email has been sent view
    if (sent) {
      return (
        <>
          <div
            className={accessStyles.layoutContentBodyMessageHeader}
            data-test="emailWasSentTitle"
          >
            {polyglot.t('access.resetPassword.emailSent')}
          </div>
          <div className={cx(accessStyles.layoutContentBodyMessage, styles.resetPasswordEmailInfo)}>
            {polyglot.t('access.resetPassword.emailInfo')}
          </div>
        </>
      );
    }

    // Render reset password view
    if (token) {
      if (!tokenPreckeckValid) {
        return (
          <>
            <Message variant="error">
              {polyglot.t('access.resetPassword.tokenInvalid')}
            </Message>
            <div className={styles.resetPasswordButtonsContainer}>
              <Link to="/access/signin" className={accessStyles.layoutLink}>
                {polyglot.t('access.resetPassword.toLogin')}
              </Link>
              <Link to="/access/reset-password" className={accessStyles.layoutLink}>
                {polyglot.t('access.resetPassword.resetPassword')}
              </Link>
            </div>
          </>
        );
      }
      return (
        <>
          <div
            className={accessStyles.layoutContentBodyMessageHeader}
          >
            {polyglot.t('access.resetPassword.setNewPassword')}
          </div>
          <div className={cx(accessStyles.layoutContentBodyMessage, styles.resetPasswordEmailInfo)}>
            {polyglot.t('access.resetPassword.enterNewPassword')}
          </div>
          <div>
            <InputModern
              value={password}
              label={polyglot.t('users.password')}
              onChange={(event) => this.setState({ password: event.target.value })}
              autoFocus
              type="password"
            />
            <PasswordChecker
              password={password}
              onValidChange={(newValState) => this.setState({ passwordValid: newValState })}
            />
          </div>
          <ButtonModern
            styleType="add"
            onClick={this.onResetPassword}
            disabled={!passwordValid}
          >
            {polyglot.t('access.resetPassword.setPassword')}
          </ButtonModern>
        </>
      );
    }

    // Render reset password email entry view
    return (
      <>
        <div
          className={cx(
            accessStyles.layoutContentBodyMessageHeader,
            styles.resetPasswordHeading,
          )}
        >
          {polyglot.t('access.resetPassword.forgotPassword')}
        </div>
        <div className={cx(accessStyles.layoutContentBodyMessage, styles.resetPasswordInfo)}>
          {polyglot.t('access.resetPassword.enterEmail')}
        </div>
        <InputModern
          formValidator={resetPasswordForm.group.email}
          label={polyglot.t('users.email')}
          key="email"
          autoFocus
          dataTest="resetPasswordEmail"
        />
        <ActionButtons
          onSubmit={resetPasswordForm.submit}
          isSubmitButtonLoading={resetPasswordForm.isSubmitting}
          submitLabel={polyglot.t('access.resetPassword.changePassword')}
          onClose={() => history.push('/access/signin')}
          cancelLabel={polyglot.t('actions.back')}
          dataTestSubmit="resetPasswordSubmitButton"
          dataTestSecondary="resetPasswordCancelButton"
        />
      </>
    );
  };

  render() {
    return (
      <div className={accessStyles.layout}>
        <BackgroundBlob />
        <div className={accessStyles.layoutContent}>
          <div className={accessStyles.layoutContentHeader}>
            <Logo className={accessStyles.layoutContentHeaderLogo} />
          </div>
          <div className={cx(accessStyles.layoutContentBody, styles.resetPasswordBody)}>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
