import React from 'react';
import PropTypes from 'prop-types';
import RcTooltip from 'rc-tooltip';
import PieChart from 'components/common/pieChart';
import ColoredPrice from 'components/common/coloredPrice';
import polyglot from 'services/localization';
import cx from 'classnames';
import { INVOICE_STATUSES } from 'shared-library/src/definitions/invoice';
import styles from './paymentsCircleChart.styles.pcss';

export default class PaymentsCircleChart extends React.PureComponent {
  static propTypes = {
    invoice: PropTypes.object.isRequired,
    pieChartClassName: PropTypes.string,
  };

  static defaultProps = {
    pieChartClassName: styles.pieChart,
  };

  createBalanceTooltip = (invoice) => {
    return (
      <div className={styles.tooltipBalance}>
        <div className={styles.circleTooltipRow}>
          <div className={styles.circleTooltipRowData}>
            {polyglot.t('invoices.payments.invoiceGross')}
            :
          </div>
          <div className={styles.circleTooltipRowDataBalance}>
            <ColoredPrice priceValue={invoice.gross} />
          </div>
        </div>
        <div className={styles.circleTooltipRow}>
          <div className={styles.circleTooltipRowData}>
            {polyglot.t('invoices.statuses.paid')}
            :
          </div>
          <div className={styles.circleTooltipRowDataBalance}>
            <ColoredPrice priceValue={invoice.gross - invoice.openAmount} />
          </div>
        </div>
        <div className={styles.circleTooltipRow}>
          <div className={styles.circleTooltipRowData}>
            {polyglot.t('invoices.statuses.open')}
            :
          </div>
          <div className={styles.circleTooltipRowDataBalance}>
            <ColoredPrice priceValue={invoice.openAmount} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { invoice, pieChartClassName } = this.props;
    return (
      <>
        {[INVOICE_STATUSES.OPEN, INVOICE_STATUSES.PAID].includes(invoice.status)
        && invoice.gross > 0 && (
          <RcTooltip
            align={{ offset: [-80, 7] }}
            placement="left"
            trigger={['hover']}
            destroyTooltipOnHide
            id={`balanceTooltip-${invoice._id}`}
            overlay={this.createBalanceTooltip(invoice)}
          >
            <span>
              {invoice.status === 'paid' ? (
                <>
                  <span data-tip data-for={`balanceTooltip-${invoice._id}`} data-type="light" data-effect="solid" data-border data-place="top">
                    <PieChart
                      className={cx(pieChartClassName, styles.openCircle)}
                      percentage={100}
                    />
                  </span>
                </>
              ) : (
                <>
                  <span data-tip data-for={`balanceTooltip-${invoice._id}`} data-type="light" data-effect="solid" data-border data-place="top">
                    <PieChart
                      className={cx(pieChartClassName, styles.openCircle)}
                      percentage={Math.round(
                        ((invoice.gross - invoice.openAmount) / invoice.gross) * 100,
                      )}
                    />
                  </span>
                </>
              )}
            </span>
          </RcTooltip>
        )}
      </>
    );
  }
}
