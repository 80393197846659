import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import {
  MdStyle as ProductsIcon,
  MdPoll as ReportsIcon,
  MdEmail as MailIcon,
} from 'react-icons/md';
import * as clientActions from 'resources/clients/clients.actions';
import * as vehicleActions from 'resources/vehicles/vehicles.actions';
import * as fromUser from 'resources/user/user.selectors';
import * as fromGarage from 'resources/garage/garage.selectors';
import polyglot from 'services/localization';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { compose } from 'redux';
import withWindowSize from 'components/higherOrderComponents/withWindowSize';
import Overscroll from 'components/common/overscroll';
import {
  faChevronLeft,
  faHome,
  faUsers,
  faWrench,
  faBookOpen,
  faTrash,
  faChevronRight,
  faArrowRightArrowLeft,
  faMoneyBillTransfer,
} from 'fontawesome/pro-solid-svg-icons';
import { faCalendarAlt } from 'fontawesome/pro-regular-svg-icons';
import {
  FaCar,
} from 'react-icons/fa';
import { getFirstStepsStatistics } from 'resources/garage/garage.api';
import { getIconFromGarageType } from 'helpers/vehicle';
import InteractableDiv from 'components/common/interactableDiv';
import OfferIcon from 'components/common/icon/svg/offerIcon';
import InvoiceIcon from 'components/common/icon/svg/invoiceIcon';
import Logo from 'components/common/logo';

import { checkBetaFeatureActive, FEATURE_IDS } from 'shared-library/src/services/betaFeatureService';
import styles from './sidebar.styles.pcss';

const links = [
  {
    pathname: '/firstSteps',
    title: polyglot.t('sidebar.firstSteps'),
    Icon: '',
    permission: 'fetchDashboard',
    testId: 'sidebarLinkFirstSteps',
    enabledOnMobile: true,
  },
  {
    pathname: '/',
    title: polyglot.t('sidebar.dashboard'),
    Icon: <FontAwesomeIcon icon={faHome} className={styles.sidebarButtonIcon} />,
    permission: 'fetchDashboard',
    testId: 'sidebarLinkDashboard',
    enabledOnMobile: true,
  },
  {
    pathname: '/clients',
    title: polyglot.t('sidebar.customers'),
    Icon: <FontAwesomeIcon icon={faUsers} className={styles.sidebarButtonIcon} />,
    permission: 'fetchClients',
    testId: 'sidebarLinkClients',
    enabledOnMobile: true,
  },
  {
    pathname: '/vehicles',
    title: polyglot.t('sidebar.vehicles'),
    Icon: <FaCar className={styles.sidebarButtonIcon} />,
    permission: 'fetchVehicles',
    testId: 'sidebarLinkVehicles',
    enabledOnMobile: true,
  },
  {
    pathname: '/calendar',
    title: polyglot.t('sidebar.calendar'),
    Icon: <FontAwesomeIcon icon={faCalendarAlt} className={styles.sidebarButtonIcon} />,
    permission: 'accessCalendar',
    testId: 'sidebarLinkCalendar',
    enabledOnMobile: true,
  },
  {
    pathname: '/offers',
    title: polyglot.t('sidebar.offers'),
    Icon: <OfferIcon className={styles.sidebarButtonIcon} />,
    permission: 'fetchOffers',
    testId: 'sidebarLinkOffers',
    enabledOnMobile: false,
  },
  {
    pathname: '/invoices/draft',
    title: polyglot.t('sidebar.orders'),
    Icon: <FontAwesomeIcon icon={faWrench} className={styles.sidebarButtonIcon} />,
    permission: 'fetchOrders',
    testId: 'sidebarLinkDraft',
    enabledOnMobile: false,
  },
  {
    pathname: '/invoices',
    title: polyglot.t('sidebar.invoices'),
    Icon: <InvoiceIcon className={styles.sidebarButtonIcon} />,
    permission: 'fetchInvoices',
    testId: 'sidebarLinkInvoices',
    enabledOnMobile: false,
  },
  {
    pathname: '/cashbook',
    title: polyglot.t('sidebar.cashbook'),
    Icon: <FontAwesomeIcon icon={faBookOpen} className={styles.sidebarButtonIcon} />,
    permission: 'fetchCashbook',
    testId: 'sidebarLinkCashbook',
    enabledOnMobile: false,
  },
  {
    pathname: '/products',
    title: polyglot.t('sidebar.products'),
    Icon: <ProductsIcon className={styles.sidebarButtonIcon} />,
    permission: 'fetchProducts',
    testId: 'sidebarLinkProducts',
    enabledOnMobile: true,
  },
  {
    pathname: '/reports',
    title: polyglot.t('reports.reports'),
    Icon: <ReportsIcon className={styles.sidebarButtonIcon} />,
    permission: 'fetchReports',
    testId: 'sidebarLinkReports',
    enabledOnMobile: false,
  },
];

const additionalLinks = [
  {
    pathname: '/banking',
    title: polyglot.t('sidebar.banking'),
    Icon: <FontAwesomeIcon icon={faMoneyBillTransfer} className={styles.sidebarButtonIcon} />,
    permission: 'fetchBanking',
    testId: 'sidebarLinkBanking',
    enabledOnMobile: false,
    isAdditionalLink: true,
    betaFeature: FEATURE_IDS.betaFeatureFinAPI,
  },
  {
    pathname: '/sdi/outgoing',
    title: polyglot.t('sidebar.sdi'),
    Icon: <FontAwesomeIcon icon={faArrowRightArrowLeft} className={styles.sidebarButtonIcon} />,
    permission: 'fetchSdi',
    testId: 'sidebarLinkSdi',
    enabledOnMobile: false,
    isAdditionalLink: true,
  },
  {
    pathname: '/emails',
    title: polyglot.t('emails.title'),
    Icon: <MailIcon className={styles.sidebarButtonIcon} />,
    permission: 'fetchEmails',
    testId: 'sidebarLinkEmails',
    enabledOnMobile: false,
    isAdditionalLink: true,
  },
  {
    pathname: '/bin',
    title: polyglot.t('sidebar.bin'),
    Icon: <FontAwesomeIcon icon={faTrash} className={styles.sidebarButtonIcon} />,
    permission: 'fetchBin',
    testId: 'sidebarLinkBin',
    enabledOnMobile: true,
    isAdditionalLink: true,
  },
];

export class Sidebar extends PureComponent {
  static propTypes = {
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    additionalLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
    isSidebarOpen: PropTypes.bool.isRequired,
    pathname: PropTypes.string.isRequired,
    resetCurrentClient: PropTypes.func.isRequired,
    resetCurrentVehicle: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    touchRegistrationWidth: PropTypes.number,
    garageType: PropTypes.string,
    garage: PropTypes.shape({
      _id: PropTypes.string,
      headerLogo: PropTypes.string,
      subscriptionData: PropTypes.shape({
        status: PropTypes.string,
      }),
      onboarding: PropTypes.shape({}),
    }).isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    mobileViewOption: PropTypes.string.isRequired,
    getUserRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    touchRegistrationWidth: 30,
    garageType: 'car',
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      isHover: false,
      minimized: false,
      initialInset: 0,
      isSwiping: false,
      done: {
        progress: 0,
      },
      onboarding: {
        ocr: false,
        garageData: false,
        receipts: false,
        tariff: false,
        dataDeleted: false,
      },
      isAdditionalLinksModalOpen: false,
    };
  }

  componentDidMount() {
    this.getVehicleIcon();
    this.handleMouseEnterLeave(false);
    this.setupOnboardingProgress();
    this.element = document.getElementById('sidebar');
    window.addEventListener('touchstart', this.onTouchStart);
    setTimeout(() => {
      document.addEventListener('mousedown', this.checkDocumentClick, true);
    }, 10);
  }

  componentDidUpdate(prevProps) {
    const { garage, pathname } = this.props;
    if (garage && (
      !_.isEqual(prevProps.garage?.onboarding, garage?.onboarding)
      || pathname !== prevProps.pathname
    )) {
      this.updateFirstSteps();
    }

    if (this.sideBarDisplayMode() === 4) {
      const { width, isSidebarOpen } = this.props;
      if (prevProps.isSidebarOpen && !isSidebarOpen) {
        setTimeout(() => this.changeSidebarInset(width), 10);
      } else if (!prevProps.isSidebarOpen && isSidebarOpen) {
        setTimeout(() => this.changeSidebarInset(0), 10);
      }
    } else if (this.transformX > 0) {
      this.changeSidebarInset(0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.onTouchStart);
    document.removeEventListener('mousedown', this.checkDocumentClick, true);
  }

  checkDocumentClick = (e) => {
    if (this?.containerRef?.current) {
      if (e.target === this.containerRef.current || this.containerRef.current.contains(e.target)) {
        return;
      }
      this.setState({ isAdditionalLinksModalOpen: false });
    }
  };

  async updateFirstSteps() {
    const { garage } = this.props;
    const { _id: garageId } = garage;

    if (garage.onboarding === null) return;

    const onboarding = await getFirstStepsStatistics(garageId);
    if (onboarding.result !== null) {
      this.setState({ onboarding: onboarding.result }, () => { this.getProgress(); });
    }
  }

  setupOnboardingProgress = async () => {
    const { garage } = this.props;
    const { _id: garageId } = garage;

    if (garage.onboarding === null) return;

    const onboarding = await getFirstStepsStatistics(garageId);
    if (onboarding.result !== null) {
      this.setState({ onboarding: onboarding.result }, async () => {
        if (document.getElementById('myBar')) {
          await this.getProgress();
          const elem = document.getElementById('myBar');
          elem.style.width = `${this.state.done.progress}%`;
        }
      });
    }
  };

  animateProgressBar = (oldProgress, newProgress) => {
    let i = 0;
    if (i === 0) {
      i = 1;
      const { minimized } = this.state;
      const bar = document.getElementById('myBar');
      const percent = document.getElementById('progressPercent');
      if (!percent) return;
      let width = oldProgress;
      const frame = () => {
        if (newProgress > oldProgress) {
          bar.classList.add(styles.sidebarFirstStepsBarShine);
          if (minimized) {
            percent.style.margin = '2.5px 0';
            percent.style.fontSize = '20px';
          } else {
            percent.style.padding = '0 4px 0 0';
            percent.style.fontSize = '22px';
          }
          if (width >= newProgress) {
            i = 0;
            if (minimized) {
              percent.style.margin = '6px 3px';
              percent.style.fontSize = '14px';
            } else {
              percent.style.padding = '12px';
              percent.style.fontSize = '14px';
            }
            bar.classList.remove(styles.sidebarFirstStepsBarShine);
          } else {
            width += 1;
            percent.innerHTML = `${width}%`;
            bar.style.width = `${width}%`;
            requestAnimationFrame(frame);
          }
        } else {
          if (minimized) {
            percent.style.margin = '2.5px 0';
            percent.style.fontSize = '20px';
          } else {
            percent.style.padding = '0 4px 0 0';
            percent.style.fontSize = '22px';
          }
          if (width <= newProgress) {
            i = 0;
            if (minimized) {
              percent.style.margin = '6px 3px';
              percent.style.fontSize = '14px';
            } else {
              percent.style.padding = '12px';
              percent.style.fontSize = '14px';
            }
          } else {
            width -= 1;
            percent.innerHTML = `${width}%`;
            bar.style.width = `${width}%`;
            requestAnimationFrame(frame);
          }
        }
      };
      requestAnimationFrame(frame);
    }
  };

  async getProgress() {
    let progress = 50;
    const done = {};
    const { onboarding } = this.state;
    if (onboarding.ocr === true) {
      progress += 10;
    }
    if (onboarding.receipts === true) {
      progress += 10;
    }
    if (onboarding.garageData === true) {
      progress += 10;
    }
    if (onboarding.tariff === true) {
      progress += 10;
    }
    if (onboarding.dataDeleted === true) {
      progress += 10;
    }
    done.progress = progress;
    await this.setState((prevState) => ({
      done: {
        progress: done.progress,
        progressOld: prevState.done.progress,
      },
    }), () => {
      if (this.state.done.progressOld !== 0) {
        this.animateProgressBar(this.state.done.progressOld, done.progress);
      } else {
        this.animateProgressBar(done.progress, done.progress);
      }
    });
  }

  inputX = 0;

  inputOffsetX = 0;

  sidebarContainerRef = React.createRef();

  transformX = 0;

  swipeDirection = '';

  addTouchListeners = () => {
    window.addEventListener('touchmove', this.onTouchMove, { passive: false });
    window.addEventListener('touchend', this.onTouchEnd);
  };

  removeTouchListeners = () => {
    window.removeEventListener('touchmove', this.onTouchMove, { passive: false });
    window.removeEventListener('touchend', this.onTouchEnd);
  };

  getVehicleIcon = () => {
    const { garageType } = this.props;
    const newLink = links.find((link) => link.testId === 'sidebarLinkVehicles');
    const garageIcon = getIconFromGarageType(garageType);
    newLink.Icon = garageIcon
      .icon({ className: styles.sidebarButtonIcon });
  };

  onLinkClick = (e) => {
    const { resetCurrentClient, resetCurrentVehicle } = this.props;
    if (!e.ctrlKey && !e.altKey && !e.metaKey && !e.shiftKey) {
      resetCurrentClient();
      resetCurrentVehicle();
      if ([1, 3, 4].includes(this.sideBarDisplayMode())) {
        this.minimizeSidebar();
        this.handleMouseEnterLeave(false);
      }
    }
  };

  minimizeSidebar = () => {
    const { toggleSidebar } = this.props;
    toggleSidebar();
    this.setState({ minimized: !this.state.minimized });
  };

  sideBarDisplayMode = () => {
    const { width } = this.props;
    const { isSidebarOpen } = this.props;

    // Desktop
    if (width >= 1401) {
      if (isSidebarOpen) {
        return 2; // Full
      }
      return 1; // Icons only
    }

    // Desktop small
    if (width > 900) {
      if (!isSidebarOpen) {
        // ? return 0; // hidden
      }
      if (this.state.isHover) {
        return 3; // Full + Float over content
      }
      return 1; // Icons only
    }

    return 4; // Mobile and tablet
  };

  getLinkComponent = ({
    pathname,
    title,
    Icon,
    isNew,
    testId,
    enabledOnMobile,
    isAdditionalLink,
    betaFeature,
  }) => {
    if (betaFeature && !checkBetaFeatureActive(window.config.user, betaFeature)) return null;
    const { isMobileSize, mobileViewOption } = this.props;

    if (!enabledOnMobile && isMobileSize && mobileViewOption === 'optionOne') return null;
    const { done } = this.state;
    let isActive = false;
    if (pathname === '/') {
      if (this.props.pathname === '/') {
        isActive = true;
      }
    } else {
      isActive = this.props.pathname.indexOf('/invoices/draft') === 0
        ? pathname === '/invoices/draft' : this.props.pathname.indexOf(pathname) === 0;
    }
    if (pathname === '/firstSteps' && this.props.garage.onboarding === null) {
      return null;
    }
    const linkStyles = cx(styles.sidebarButton, {
      [styles.sidebarButtonActive]: isActive,
    }, {
      [styles.sidebarFirstSteps]: pathname === '/firstSteps',
    });

    return (
      pathname === '/firstSteps' ? (
        <div>
          <Link
            key={pathname}
            className={linkStyles}
            to={pathname}
            onClick={this.onLinkClick}
            data-test={testId}
          >
            {isNew && (
              <div className={styles.isNew}>
                {polyglot.t('misc.new')}
              </div>
            )}
            {this.sideBarDisplayMode() < 2 && (
              <div className={styles.sidebarFirstStepsMini} id="progressPercent">
                {done.progress}
                {' '}
                %
              </div>
            )}
            {this.sideBarDisplayMode() >= 2 && (
              <div className={styles.sidebarFirstStepsHeader}>
                <span className={cx(styles.sidebarButtonText, styles.sidebarFirstStepsTitle)}>
                  {title}
                </span>
                <div className={styles.sidebarFirstStepsTitleProgress} id="progressPercent">
                  {done.progress}
                  {' '}
                  %
                </div>
              </div>
            )}
            <div className={styles.sidebarFirstStepsProgressEmpty}>
              <div id="myBar" className={styles.sidebarFirstStepsProgressDone}>
                <div />
              </div>
            </div>
          </Link>
        </div>
      ) : (
        <Link
          key={pathname}
          className={linkStyles}
          to={pathname}
          onClick={this.onLinkClick}
          data-test={testId}
        >
          {isNew && (
            <div className={styles.isNew}>
              {polyglot.t('misc.new')}
            </div>
          )}
          {Icon}
          {(this.sideBarDisplayMode() >= 2 || isAdditionalLink) && (
            <span className={styles.sidebarButtonText}>
              {title}
            </span>
          )}
        </Link>
      )
    );
  };

  handleMouseEnterLeave = (isEnter) => {
    const { width } = this.props;
    if (width <= 1400) {
      this.setState({ isHover: isEnter });
    } else {
      this.setState({ isHover: false });
    }
  };

  getSideBarHeight = () => {
    if (
      this.sidebarContainerRef
      && this.sidebarContainerRef.current
      && this.sidebarContainerRef.current.offsetHeight
    ) {
      return this.sidebarContainerRef.current.offsetHeight;
    }
    return null;
  };

  calculateInsetPercent = (pixelInset) => {
    const { width } = this.props;
    return (pixelInset / width) * 100;
  };

  getTranslateX = (element) => {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrix(style.transform);
    return matrix.m41;
  };

  changeSidebarInset = (inset, unit = 'px') => {
    const { width } = this.props;
    this.transformX = Math.max(0, Math.min(width, inset));
    this.element.style.transform = `translateX(${this.transformX}${unit})`;
  };

  userMovedRaf = () => {
    const { width } = this.props;
    const { isSwiping, initialInset } = this.state;
    const change = 20;
    const x = this.lerp(this.transformX, this.inputX, 1);
    this.changeSidebarInset(x);
    if (this.swipeDirection || isSwiping) {
      if (!isSwiping) {
        if (initialInset <= 0) { // Swipe started on left side
          if (this.inputX > width * 0.15 && this.swipeDirection === 'right') { // If over 15% of screen and swiping to right
            this.inputX += change;
            if (this.inputX >= width) {
              this.swipeDirection = '';
              setTimeout(() => this.minimizeSidebar(), 100);
            }
          } else {
            // this.changeSidebarInset(0);
            this.inputX -= change;
            if (this.inputX <= 0) this.swipeDirection = '';
          }
        }
        if (initialInset > 0) { // Swipe started on right side
          if (this.inputX < width * 0.85 && this.swipeDirection === 'left') { // If under 85% of screen and swiping to left
            this.inputX -= change;
            if (this.inputX <= 0) {
              this.swipeDirection = '';
              setTimeout(() => this.minimizeSidebar(), 100);
            }
          } else {
            this.inputX += change;
            if (this.inputX >= width) this.swipeDirection = '';
          }
        }
      }
      this.raf = requestAnimationFrame(this.userMovedRaf);
    }
  };

  lerp = (start, end, amt) => {
    return (1 - amt) * start + amt * end;
  };

  onTouchStart = (event) => {
    const { width, touchRegistrationWidth } = this.props;
    const [{ pageX }] = event.touches;

    if ((this.transformX > 0 && pageX > width - touchRegistrationWidth)
      || (this.transformX === 0 && pageX < touchRegistrationWidth)) {
      this.addTouchListeners();
      this.inputOffsetX = pageX <= touchRegistrationWidth ? pageX : pageX - width;
      this.inputX = pageX - this.inputOffsetX;
      this.setState({ initialInset: this.transformX, isSwiping: true });
      this.raf = requestAnimationFrame(this.userMovedRaf);
    }
  };

  onTouchEnd = () => {
    const { isSwiping } = this.state;
    this.removeTouchListeners();
    if (isSwiping) {
      this.setState({ isSwiping: false });
    }
  };

  onTouchMove = (event) => {
    const { isSwiping } = this.state;
    if (!isSwiping) return;
    const [{ pageX }] = event.touches;
    if (pageX > this.inputX + this.inputOffsetX) this.swipeDirection = 'right';
    else this.swipeDirection = 'left';
    this.inputX = pageX - this.inputOffsetX;
  };

  renderHeader = () => {
    const { garage } = this.props;
    const { minimized } = this.state;
    const { headerLogo } = garage;
    const displayMode = this.sideBarDisplayMode();

    const displayMinimizedHeader = displayMode <= 1;

    const logoContent = headerLogo ? (
      <img
        className={cx(
          styles.sidebarHeadingImg,
          displayMinimizedHeader && styles.sidebarHeadingImgMinimized,
        )}
        src={headerLogo}
        alt=""
      />
    ) : <Logo className={styles.sidebarHeadingLogo} hideText={displayMinimizedHeader} />;

    if (displayMode === 4) {
      return logoContent;
    }

    return (
      <div className={styles.sidebarHeading}>
        {logoContent}
        <InteractableDiv
          className={cx(
            styles.sidebarHeadingChevron,
            minimized && styles.sidebarHeadingChevronMaximize,
          )}
          onClick={() => this.minimizeSidebar()}
          dataTest="sidebarHeadingMinimize"
        >
          <FontAwesomeIcon icon={faChevronLeft} style={{ cursor: 'pointer' }} />
        </InteractableDiv>
      </div>
    );
  };

  render() {
    this.getVehicleIcon();
    const { isSwiping, isAdditionalLinksModalOpen } = this.state;
    const { getUserRoles } = this.props;
    const sidebarClasses = cx(styles.sidebar, {
      [styles.sidebarHide]: this.sideBarDisplayMode() === 0,
      [styles.sidebarIsSwiping]: isSwiping || this.swipeDirection,
    });
    const sidebarInnerStyles = cx(styles.sidebarInner, {
      [styles.sidebarInnerFloat]: this.sideBarDisplayMode() === 3,
    });
    const linkComponents = this.props.links.map(this.getLinkComponent);
    const additionalLinkComponents = this.props.additionalLinks.map(this.getLinkComponent);

    return (
      <div
        ref={this.sidebarContainerRef}
        className={sidebarClasses}
        style={this.sideBarDisplayMode() === 2 ? { minWidth: '150px' } : {}}
        onMouseEnter={() => this.handleMouseEnterLeave(true)}
        onMouseLeave={() => this.handleMouseEnterLeave(false)}
        id="sidebar"
      >
        <div
          className={sidebarInnerStyles}
          data-test="sidebarContainer"
        >
          <Overscroll
            overscrollContent={this.renderHeader()}
            overscrollContentDesktop={this.renderHeader()}
            min={10}
            max={80}
            start={30}
            unit="%"
            scrollDisabled={isSwiping}
          >
            {linkComponents}
            {(!getUserRoles.includes('accountant') && !getUserRoles.includes('worker')) && (
              <>
                {this.sideBarDisplayMode() === 4 ? (
                  <>
                    {additionalLinkComponents}
                  </>
                ) : (
                  <>
                    <InteractableDiv
                      className={styles.sidebarAdditionalLinksButton}
                      onClick={() => this.setState({
                        isAdditionalLinksModalOpen: !isAdditionalLinksModalOpen,
                      })}>
                      {(this.sideBarDisplayMode() === 3 || this.sideBarDisplayMode() === 2) && (
                      <div className={styles.sidebarAdditionalLinksText}>
                        {polyglot.t('sidebar.more')}
                      </div>
                      )}

                      <div data-test="additionalLinks" className={cx(styles.sidebarAdditionalLinksIcon)}>
                        <FontAwesomeIcon style={{ height: '14px' }} className="fas fa-bold" icon={faChevronRight} />
                      </div>
                      {isAdditionalLinksModalOpen && (
                      <div
                        ref={this.containerRef}
                        className={styles.sidebarAdditionalLinksContainer}>
                        <div className={styles.sidebarAdditionalLinksArrow} />
                        {additionalLinkComponents}
                      </div>
                      )}
                    </InteractableDiv>
                  </>
                )}
              </>
            )}
          </Overscroll>
        </div>
      </div>
    );
  }
}

export default compose(withWindowSize, connect((state) => ({
  links: links.filter((link) => fromUser.can(state, link.permission)),
  additionalLinks: additionalLinks.filter((link) => fromUser.can(state, link.permission)),
  garageType: fromGarage.getGarageType(state),
  garage: fromGarage.getGarage(state),
  mobileViewOption: fromUser.getMobileViewOption(state),
  getUserRoles: fromUser.getUserRoles(state),
}), {
  resetCurrentClient: clientActions.resetCurrentClient,
  resetCurrentVehicle: vehicleActions.resetCurrentVehicle,
}))(Sidebar);
