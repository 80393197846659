const FEATURE_IDS = Object.freeze({
  betaFeatureSDI: 'betaFeatureSDI',
  betaFeatureFinAPI: 'betaFeatureFinAPI',
});

const FEATURE_ID_ARRAY = Object.freeze(Object.values(FEATURE_IDS));

function checkBetaFeatureActive(user, featureId) {
  const userBetaFeatures = (user && user.betaFeatures) || [];
  return userBetaFeatures.includes(featureId);
}

module.exports = {
  FEATURE_IDS,
  FEATURE_ID_ARRAY,
  checkBetaFeatureActive,
};
