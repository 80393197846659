import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as fromGarage from 'resources/garage/garage.selectors';
import { GARAGE_COUNTRIES } from 'shared-library/src/definitions/garageCountries';
import { getAustrianNumber, get2DecimalsNumber, getPercent } from 'helpers/number';

const NumberString = ({ country, scaleType, children }) => {
  if (scaleType === 'price') {
    return get2DecimalsNumber(children, country);
  }

  if (scaleType === 'percent') {
    return getPercent(children, country);
  }

  if (scaleType === 'integer') {
    return Math.round(children);
  }

  return getAustrianNumber(children, country);
};

NumberString.propTypes = {
  country: PropTypes.oneOf(Object.values(GARAGE_COUNTRIES)).isRequired,
  scaleType: PropTypes.oneOf(['price', 'percent', '']),
  children: PropTypes.number.isRequired,
};

NumberString.defaultProps = {
  scaleType: '',
};

export default connect((state) => ({
  country: fromGarage.getCountry(state),
}))(NumberString);
