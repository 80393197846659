import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { updateApp } from 'resources/utils';

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
  };

  const HOC = class extends Component {
    static propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }).isRequired,
    };

    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        updateApp();
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
  HOC.displayName = 'trackerHOC';
  return HOC;
}
