import { connect } from 'react-redux';

import withDeviceInfo from 'components/higherOrderComponents/withDeviceInfo';
import * as fromUser from 'resources/user/user.selectors';
import { getSubscriptionData } from 'resources/garage/garage.selectors';
import * as fromGarage from 'resources/garage/garage.selectors';
import {
  changeGarage,
  toggleCustomerMode,
  updateUser,
  submitRating,
} from 'resources/user/user.actions';
import { fetchCalendars } from 'resources/calendar/calendar.actions';
import { getCurrentRatingFromState } from 'shared-library/src/definitions/ratings';

import Header from './header';

export default withDeviceInfo(connect((state) => ({
  currentGarageId: fromUser.getCurrentGarageId(state),
  garages: fromUser.getGarages(state),
  roles: fromUser.getUserRoles(state),
  user: fromUser.getCurrentUser(state),
  isCustomerMode: fromUser.isCustomerMode(state),
  subscriptionData: getSubscriptionData(state),
  garage: fromGarage.getGarage(state),
  canAccessCalendar: fromUser.can(state, 'accessCalendar'),
  mobileViewOption: fromUser.getMobileViewOption(state),
  currentRating: getCurrentRatingFromState(state),
}), {
  changeGarage,
  toggleCustomerModeAction: toggleCustomerMode,
  fetchCalendarsAction: fetchCalendars,
  updateUser,
  submitRating,
})(Header));
