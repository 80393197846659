import * as fromUser from '../user/user.selectors';
import * as api from './positionGroup.api';
import { addPageSize } from '../utils';
import * as fromPositionGroup from './positionGroup.selectors';

export const GET_POSITION_GROUPS = 'getPositionGroups';
export const CREATE_POSITION_GROUP = 'createPositionGroup';
export const RESET_POSITION_GROUPS_STATE = 'resetPositionGroupsState';
export const REMOVE_POSITION_GROUP = 'removePositionGroup';
export const UPDATE_POSITION_GROUP = 'updatePositionGroup';
export const CHANGE_POSITION_GROUPS_FILTER = 'changePositionGroupFilter';
export const FETCH_POSITION_GROUPS_COUNT = 'fetchPositionGroupsCount';

export const createPositionGroup = (data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.createPositionGroup(data, garageId)
    .then((payload) => dispatch({ type: CREATE_POSITION_GROUP, payload }));
};

export const resetPositionGroupsState = () => ({ type: RESET_POSITION_GROUPS_STATE });

export const findPositionGroupBySKU = (sku, currentId) => (dispatch, getState) => {
  if (!sku) {
    return Promise.resolve(null);
  }
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.findPositionGroupBySKU({ sku, garageId, currentId });
};

export const isSkuAlreadyUsedInPositionGroups = (sku, currentId) => (dispatch, getState) => {
  if (!sku) {
    return Promise.resolve(null);
  }
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.isSkuAlreadyUsed({ sku, garageId, currentId });
};

export const changePositionGroupFilter = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.getPositionGroups(data)
    .then((payload) => dispatch({ type: CHANGE_POSITION_GROUPS_FILTER, data, payload }));
};

export const findPositionGroupByName = (name) => (dispatch, getState) => {
  if (!name) {
    return Promise.resolve(null);
  }
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getPositionGroupsByName({ name, garageId, pageSize: 6 });
};

export const getPositionGroups = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.getPositionGroups(data)
    .then((payload) => dispatch({ type: GET_POSITION_GROUPS, data, payload }));
};

export const removePositionGroup = (groupId) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.removePositionGroup(groupId, garageId)
    .then(() => dispatch({ type: REMOVE_POSITION_GROUP, groupId }));
};

export const updatePositionGroup = (groupId, newGroup) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  const positionGroup = fromPositionGroup.getPositionGroupById(state, groupId);
  return api.updatePositionGroup(groupId, { ...positionGroup, ...newGroup }, garageId)
    .then((payload) => dispatch({ type: UPDATE_POSITION_GROUP, groupId, payload }));
};

export const fetchPositionGroupsCount = (options = {}) => (dispatch, getState) => {
  const data = options;
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getPositionGroupsCount(garageId, data)
    .then((payload) => dispatch({ type: FETCH_POSITION_GROUPS_COUNT, data, payload }));
};
