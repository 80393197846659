import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import polyglot from 'services/localization';
import styles from './statusLabel.styles.pcss';

export default function StatusLabel({ status, gross, dueDate }) {
  let key;
  switch (status) {
    case 'draft':
    case 'preview':
    case 'paidCredit':
      key = status;
      break;
    case 'open':
      key = moment.utc().isAfter(moment.utc(dueDate)) ? 'overdue' : 'open';
      break;
    case 'paid':
      key = Math.abs(gross) > 0 ? 'paid' : 'paidOut';
      break;
    case 'offer':
      key = status;
      break;
    case 'costListing':
      key = status;
      break;
    default:
      key = status;
  }
  return (
    <div className={cx(styles.label, styles[key])}>
      {polyglot.t(`invoices.statuses.${key}`, 1)}
    </div>
  );
}

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
  gross: PropTypes.number,
  dueDate: PropTypes.string,
};
StatusLabel.defaultProps = {
  gross: 0,
  dueDate: '',
};
