/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import cx from 'classnames';
import polyglot from 'services/localization';
import PropTypes from 'prop-types';
import InputModern from 'components/common/inputModern';
import countries from 'helpers/countries';
import {
  composeParsers,
  toUpperCase,
  onlyLettersAndNumber,
  capitalizeOnFirstInput,
} from 'components/common/form/form.parsers';
import getCityFromZipCode from 'helpers/zipCodeCity';
import { isZipCode, isZipCodeUK } from 'helpers/regexp';
import modalStyles from 'styles/modal.pcss';
import FormValidatorGroup from 'helpers/formValidation/formValidatorGroup';
import FormValidator from 'helpers/formValidation/formValidator';
import Validators from 'helpers/formValidation/validators';
import DropdownModern from 'components/common/dropdownModern';
import DatePickerModern from 'components/common/datePickerModern';
import PhoneNumberService from 'shared-library/src/services/phoneNumberService';
import { CLIENT_FORM_OF_ADDRESS } from 'shared-library/src/definitions/clientFormOfAddress';
import { getUidPlaceholderForCountry } from 'helpers/uid';

import styles from './clientForm.styles.pcss';
import TextareaModern from '../textareaModern';
import CheckboxModern from '../checkboxModern';
import InputDropdownModern from '../inputDropdownModern';

const phoneNumberService = PhoneNumberService.getInstance();

const formOfAddressItems = [{
  key: '',
  label: <span>
&nbsp;
  </span>,
},
{
  key: CLIENT_FORM_OF_ADDRESS.MR,
  label: polyglot.t('editClient.formOfAddressOptions.mr'),
}, {
  key: CLIENT_FORM_OF_ADDRESS.MRS,
  label: polyglot.t('editClient.formOfAddressOptions.mrs'),
}, {
  key: CLIENT_FORM_OF_ADDRESS.COMPANY,
  label: polyglot.t('editClient.formOfAddressOptions.company'),
}, {
  key: CLIENT_FORM_OF_ADDRESS.FAMILY,
  label: polyglot.t('editClient.formOfAddressOptions.family'),
}];

class ClientForm extends PureComponent {
  static propTypes = {
    client: PropTypes.shape({
      _id: PropTypes.string,
      formOfAddress: PropTypes.string,
      title: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      toAttention: PropTypes.string,
      address: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      dateOfBirthday: PropTypes.string,
      UIDNumber: PropTypes.string,
      phone: PropTypes.string,
      mobilePhone: PropTypes.string,
      email: PropTypes.string,
      additionalInfo: PropTypes.string,
      printAdditionalInfo: PropTypes.bool,
      externalId: PropTypes.string,
      recipientCode: PropTypes.string,
      taxNumber: PropTypes.string,
      pec: PropTypes.string,
    }),
    country: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    renderButtons: PropTypes.func,
    formClasses: PropTypes.string,
    buttonsClasses: PropTypes.string,
    searchOnBlur: PropTypes.func,
    renderNotExistingClient: PropTypes.bool,
    isTwoColumnLayout: PropTypes.bool,
    formRef: PropTypes.func,
    className: PropTypes.string,
    garage: PropTypes.shape({
      country: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    client: {},
    renderButtons: () => null,
    searchOnBlur: () => { },
    formClasses: '',
    buttonsClasses: '',
    renderNotExistingClient: false,
    isTwoColumnLayout: false,
    formRef: () => null,
    className: '',
  };

  constructor(props) {
    super(props);
    const { formRef } = props;

    const clientForm = this.generateClientForm();
    this.state = {
      clientForm,
      editExternalIdEnabled: false,
      cities: [],
    };
    formRef(clientForm);
    this.formOfAddressInputRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.focusFormOfAddressInput();
    }, 1);
  }

  componentDidUpdate(prevProps) {
    const { client, formRef } = this.props;
    if (client._id !== prevProps.client._id) {
      const newClientForm = this.generateClientForm();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ clientForm: newClientForm });
      formRef(newClientForm);
    }
  }

  validateRecipientCode = (formVal) => (value) => {
    if (formVal.group?.country?.value !== 'IT') return null;
    if (value && (value?.length !== 7)) {
      return { id: 'wrongLength', message: polyglot.t('editClient.errors.recipientCodeValidation') };
    }
    return null;
  };

  validateUid = (formVal) => (value) => {
    const { group } = formVal;
    const { country } = group || {};
    if (country?.value !== 'IT') return null;

    const uidITRegex = /^(IT){0,1}\d{11}$/;

    if (value && !uidITRegex.test(value)) {
      return { id: 'wrongUID_IT', message: polyglot.t('editClient.errors.UIDItalyValidation') };
    }
    return null;
  };

  generateClientForm = () => {
    const {
      country, client, onSubmit, searchOnBlur,
    } = this.props;
    const maxLen = 200;

    const formValidatorGroup = new FormValidatorGroup({
      externalId: new FormValidator('', [Validators.maxLength(100, polyglot.t('editClient.errors.externalIdValidation')), Validators.mustBeNumber(polyglot.t('editClient.errors.externalIdNumberValidation'))]),
      formOfAddress: new FormValidator(''),
      title: new FormValidator('', [Validators.maxLength(maxLen, polyglot.t('editClient.errors.titleValidation'))]),
      firstName: new FormValidator('', [Validators.maxLength(maxLen, polyglot.t('editClient.errors.firstNameValidation'))]),
      lastName: new FormValidator('', [Validators.required(polyglot.t('editClient.errors.lastnameRequired')), Validators.maxLength(maxLen, polyglot.t('editClient.errors.nameValidation'))]),
      toAttention: new FormValidator('', [Validators.maxLength(maxLen, polyglot.t('editClient.errors.toAttentionValidation'))]),
      address: new FormValidator('', [Validators.maxLength(maxLen, polyglot.t('editClient.errors.addressValidation'))]),
      zipCode: new FormValidator('', []),
      city: new FormValidator('', []),
      country: new FormValidator(country, [Validators.required(polyglot.t('editClient.errors.countryValidation'))]),
      dateOfBirthday: new FormValidator('', [Validators.date(polyglot.t('editClient.errors.dateOfBirthdayValidation'))]),
      taxNumber: new FormValidator('', [Validators.maxLength(maxLen, polyglot.t('editClient.errors.taxNumberValidation'))]),
      phone: new FormValidator('', []),
      mobilePhone: new FormValidator('', [Validators.isMobilePhoneNumber()]),
      email: new FormValidator('', [Validators.multiEmail()]),
      pec: new FormValidator('', [Validators.email()]),
      additionalInfo: new FormValidator(''),
      printAdditionalInfo: new FormValidator(false, []),
    }, this, client, onSubmit, this.validateZip);

    formValidatorGroup.group.recipientCode = new FormValidator(client.recipientCode || '', [this.validateRecipientCode(formValidatorGroup)]);
    formValidatorGroup.group.UIDNumber = new FormValidator(client.UIDNumber || '', [Validators.maxLength(maxLen, polyglot.t('editClient.errors.UIDNumberValidation')), this.validateUid(formValidatorGroup)]);

    formValidatorGroup.onDebounce = searchOnBlur;

    return formValidatorGroup;
  };

  focusFormOfAddressInput = () => {
    if (this.formOfAddressInputRef && this.formOfAddressInputRef.current) {
      this.formOfAddressInputRef.current.focus();
    }
  };

  // Custom zip validation
  validateZip = () => {
    const { clientForm } = this.state;
    const country = clientForm.group.country.value;
    const zipCode = clientForm.group.zipCode.value;

    if (country && zipCode) {
      if (['DE', 'AT', 'CH', 'LI', 'LU'].includes(country)) {
        if (!isZipCode(zipCode)) {
          clientForm.group.zipCode.addError({ id: 'custom', message: polyglot.t('editClient.errors.zipCodeValidation') });
        }
      } else if (country === 'UK' && !isZipCodeUK(zipCode)) {
        clientForm.group.zipCode.addError({ id: 'custom', message: polyglot.t('editClient.errors.zipCodeValidation') });
      }
    }
  };

  onZipChanged = async (newVal) => {
    const { clientForm } = this.state;

    const country = clientForm.group.country.value;
    const currCity = clientForm.group.city.value;
    if (currCity) return;

    this.validateZip();

    this.setState({ cities: [] });
    try {
      const cities = await getCityFromZipCode(newVal, country);
      if (cities?.length > 0) {
        this.setState({ cities });
        clientForm.group.city.set(cities[0].label);
      }
    } catch (error) {
      this.setState({ cities: [] });
    }
  };

  parseMobilePhoneNumber = () => {
    const { garage } = this.props;
    const { clientForm } = this.state;
    const mobilePhone = clientForm.group.mobilePhone.value;
    const country = clientForm.group.country.value;
    if (mobilePhone) {
      const parsedMobilePhone = phoneNumberService
        .parseToValideMobileNumberOrNull(mobilePhone, country, garage.country);
      if (parsedMobilePhone) {
        clientForm.group.mobilePhone.set(parsedMobilePhone);
      }
    }
  };

  render() {
    const {
      client,
      renderButtons,
      formClasses,
      buttonsClasses,
      renderNotExistingClient,
      isTwoColumnLayout,
      className,
      garage,
    } = this.props;
    const { clientForm, editExternalIdEnabled, cities } = this.state;
    const { Form } = clientForm;

    const { formOfAddress } = clientForm.group;
    const isFamilyOrCompany = formOfAddress.value === 'family' || formOfAddress.value === 'company';
    const showItalyFields = clientForm.group.country.value === 'IT' && garage.country === 'IT';
    return (
      <div data-test="addClientModalForm" className={className}>
        <Form>
          <div className={cx(
            styles.clientForm,
            formClasses,
            {

              [styles.clientFormItalyLayoutWithExternalId]: showItalyFields
                && (client._id || renderNotExistingClient),
              [styles.twoColumnLayout]: isTwoColumnLayout,
              [styles.clientFormWithExternalId]: (client._id || renderNotExistingClient),
              [styles.twoColumnLayoutWithExternalId]: isTwoColumnLayout
                && (client._id || renderNotExistingClient),
              [styles.twoColumnLayoutItaly]: isTwoColumnLayout && showItalyFields,
              [styles.twoColumnLayoutItalyWithExternalId]: isTwoColumnLayout && showItalyFields
                && (client._id || renderNotExistingClient),
              [styles.clientFormItalyLayout]: showItalyFields,
            },
          )}
          >
            {(client._id || renderNotExistingClient) && (
              <InputModern
                formValidator={clientForm.group.externalId}
                className={styles.clientFormElement}
                label={polyglot.t('editClient.externalId')}
                onIconClick={() => this.setState({ editExternalIdEnabled: true })}
                type={editExternalIdEnabled ? '' : 'edit'}
                disabled={!editExternalIdEnabled}
              />
            )}

            <div className={cx(styles.clientFormDoubleElementContainer, styles.clientFormElement)}>
              <DropdownModern
                formValidator={clientForm.group.formOfAddress}
                label={polyglot.t('editClient.formOfAddress')}
                className={styles.clientFormDoubleElement}
                items={formOfAddressItems}
                reactRef={this.formOfAddressInputRef}
              />
              {!isFamilyOrCompany && (<InputModern
                formValidator={clientForm.group.title}
                label={polyglot.t('editClient.title')}
                className={styles.clientFormDoubleElement}
                dataTest="clientFormTitle"
              />)}
            </div>
            {!isFamilyOrCompany && (
              <InputModern
                formValidator={clientForm.group.firstName}
                className={styles.clientFormElement}
                label={polyglot.t('editClient.firstName')}
                parse={capitalizeOnFirstInput}
                dataTest="clientFormFirstName"
                autoComplete="noAutoComplete"
              />
            )}
            <InputModern
              formValidator={clientForm.group.lastName}
              className={styles.clientFormElement}
              label={clientForm.group.formOfAddress.value !== 'company'
                ? polyglot.t('editClient.lastName')
                : polyglot.t('editClient.companyName')}
              parse={capitalizeOnFirstInput}
              dataTest="clientFormLastName"
              autoComplete="noAutoComplete"
            />

            <InputModern
              formValidator={clientForm.group.toAttention}
              className={cx(styles.clientFormElement, {
                [styles.clientFormElementToAttention]: !(client._id || renderNotExistingClient),
                [styles.twoColumnLayoutElementToAttention]: isTwoColumnLayout,
                [styles.clientFormElementToAttentionCompanyOrFamily]:
                  isFamilyOrCompany && !(client._id || renderNotExistingClient),
                [styles.clientFormElementToAttentionCompanyOrFamilyEdit]:
                  isFamilyOrCompany && (client._id || renderNotExistingClient),
                [styles.clientFormElementToAttentionEdit]:
                  !isFamilyOrCompany && (client._id || renderNotExistingClient),
              })}
              label={polyglot.t('editClient.toAttention')}
            />

            <InputModern
              formValidator={clientForm.group.address}
              className={styles.clientFormElement}
              label={polyglot.t('editClient.address')}
              parse={capitalizeOnFirstInput}
              dataTest="clientFormAddress"
              autoComplete="noAutoComplete"
            />

            <div className={cx(styles.clientFormDoubleElementContainer, styles.clientFormElement)}>
              <InputModern
                formValidator={clientForm.group.zipCode}
                className={styles.clientFormDoubleElementZip}
                label={polyglot.t('editClient.zipCode')}
                onChange={(value) => this.onZipChanged(value)}
                dataTest="clientFormZipCode"
                isShowArrows={false}
                inputMode="numeric"
              />
              {cities.length > 1 ? (
                <>
                  <InputDropdownModern
                    formValidator={clientForm.group.city}
                    className={styles.clientFormDoubleElementCity}
                    label={polyglot.t('editClient.city')}
                    dataTest="clientFormCity"
                    items={cities}
                  />
                </>
              ) : (
                <InputModern
                  formValidator={clientForm.group.city}
                  className={styles.clientFormDoubleElementCity}
                  label={polyglot.t('editClient.city')}
                  dataTest="clientFormCity"
                  autoComplete="noAutoComplete"
                />
              )}
            </div>

            <DropdownModern
              formValidator={clientForm.group.country}
              className={cx(styles.clientFormElement, {
                [styles.clientFormElementCountry]:
                  (!isFamilyOrCompany && !(client._id || renderNotExistingClient))
                  || (isFamilyOrCompany && (client._id || renderNotExistingClient)),
                [styles.clientFormElementCountryBig]:
                  isFamilyOrCompany && !(client._id || renderNotExistingClient),
                [styles.clientFormElementCountryItalyLayout]: showItalyFields,
                [styles.clientFormElementCountryBigAndItalyLayout]:
                  isFamilyOrCompany && showItalyFields,
              })}
              label={polyglot.t('editClient.country')}
              items={countries}
              dataTest="clientFormCountry"
              onBlur={this.parseMobilePhoneNumber}
            />

            <InputModern
              formValidator={clientForm.group.mobilePhone}
              label={polyglot.t('editClient.mobilePhone')}
              className={styles.clientFormElement}
              dataTest="clientFormMobilePhone"
              type="mobilphone"
              onBlur={this.parseMobilePhoneNumber}
            />

            <InputModern
              formValidator={clientForm.group.phone}
              label={polyglot.t('editClient.phone')}
              className={styles.clientFormElement}
              dataTest="clientFormPhone"
              type="phone"
              autoComplete="noAutoComplete"
            />

            <InputModern
              formValidator={clientForm.group.email}
              className={styles.clientFormElement}
              label={polyglot.t('editClient.email')}
              dataTest="clientFormEmail"
              type="email"
              autoComplete="noAutoComplete"
            />

            <DatePickerModern
              formValidator={clientForm.group.dateOfBirthday}
              className={styles.clientFormElement}
              label={polyglot.t('editClient.dateOfBirthday')}
              dataTest="clientFormDateOfBirthday"
            />

            {!showItalyFields ? (
              <InputModern
                formValidator={clientForm.group.UIDNumber}
                className={styles.clientFormElement}
                label={polyglot.t('editClient.UIDNumber')}
                parse={composeParsers(toUpperCase, onlyLettersAndNumber)}
                placeholder={getUidPlaceholderForCountry(clientForm.group.country.value)}
                dataTest="clientFormUID"
              />
            ) : (
              <>
                <InputModern
                  formValidator={clientForm.group.taxNumber}
                  className={styles.clientFormElement}
                  label={polyglot.t('editClient.taxNumber')}
                  parse={composeParsers(toUpperCase, onlyLettersAndNumber)}
                  placeholder={'MST MXA 90L01 F205 F'}
                  dataTest="clientFormTaxNumber"
                />
                <div className={
                  cx(styles.clientFormDoubleElementContainer, styles.clientFormElement)
                }>
                  <InputModern
                    formValidator={clientForm.group.UIDNumber}
                    className={styles.clientFormDoubleElement}
                    label={polyglot.t('editClient.UIDNumber')}
                    parse={composeParsers(toUpperCase, onlyLettersAndNumber)}
                    placeholder={getUidPlaceholderForCountry(clientForm.group.country.value)}
                    dataTest="clientFormUID"
                  />
                  <InputModern
                    formValidator={clientForm.group.recipientCode}
                    className={styles.clientFormDoubleElement}
                    label={polyglot.t('editClient.recipientCode')}
                    parse={composeParsers(toUpperCase, onlyLettersAndNumber)}
                    placeholder={'1234567'}
                    dataTest="clientFormRecipientCode"
                  />
                </div>
                <InputModern
                  formValidator={clientForm.group.pec}
                  className={styles.clientFormElement}
                  label={polyglot.t('editClient.pec')}
                  placeholder={'mariorossi@pec.it'}
                  dataTest="clientFormPec"
                />
              </>
            )}

            <TextareaModern
              formValidator={clientForm.group.additionalInfo}
              className={cx(styles.clientFormElement, {
                [styles.clientFormElementAdditionalInfo]: !isTwoColumnLayout,
              })}
              label={polyglot.t('editClient.additionalInfo')}
              dataTest="clientFormAdditionalInfo"
            />

            <div className={
              cx(styles.clientFormElement, styles.clientFormElementAdditionalInfoCheckBox)
            }>
              <CheckboxModern
                formValidator={clientForm.group.printAdditionalInfo}
                label={polyglot.t('editClient.printAdditionalInfo')}
              />
            </div>
          </div>

          <div className={cx(modalStyles.footerModern, buttonsClasses)}>
            {renderButtons(clientForm)}
          </div>
        </Form>
      </div>
    );
  }
}

export default ClientForm;
