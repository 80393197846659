import { downloadFile } from 'helpers/file';
import { addPageSize } from 'resources/utils';
import * as fromUser from 'resources/user/user.selectors';
import moment from 'moment';
import { cancelReceipt } from 'resources/receipt/receipt.api';
import * as api from './cashbook.api';

export const FETCH_CASHBOOK = 'fetchCashbook';
export const CHANGE_CASHBOOK_FILTER = 'changeCashbookFilter';
export const RESET_CASHBOOK_STATE = 'resetCashbookState';

export const fetchCashbook = (options) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchCashbook(garageId, data)
    .then((payload) => dispatch({ type: FETCH_CASHBOOK, data, payload }));
};

export const getLatestCashbookEntryDate = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getLatestCashbookEntryDate(garageId).then((payload) => { return payload; });
};

export const getNextCashbookEntryNumber = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getNextCashbookEntryNumber(garageId).then((payload) => { return payload; });
};

export const getCashbookCSV = (data = {}) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  let fileName = 'cashbook.csv';
  if (data.type === 'BMD') {
    fileName = `kassenbuch${
      data.from ? `_${moment(data.from).format('DDMMYYYY')}` : ''
    }${data.to ? `-${moment(data.to).utc().format('DDMMYYYY')}` : ''}.csv`;
  }
  if (data.type === 'DATEV') {
    fileName = `EXTF_kassenbuch${
      data.from ? `_${moment(data.from).format('DDMMYYYY')}` : ''
    }${data.to ? `-${moment(data.to).utc().format('DDMMYYYY')}` : ''}.csv`;
  }
  if (data.type === 'EXCEL') {
    fileName = `kassenbuch${
      data.from ? `_${moment(data.from).format('DDMMYYYY')}` : ''
    }${data.to ? `-${moment(data.to).utc().format('DDMMYYYY')}` : ''}.xlsx`;
  }

  return api
    .getCashbookCSV(garageId, data)
    .then((payload) => downloadFile(payload, fileName));
};

export const createEntry = (data, filters) => (dispatch, getState) => {
  const state = getState();
  const userId = fromUser.getCurrentUserId(state);
  const garageId = fromUser.getCurrentGarageId(state);

  return api.createEntry(garageId, { ...data, userId })
    .then(() => api.fetchCashbook(garageId, addPageSize(filters)))
    .then((payload) => dispatch({ type: CHANGE_CASHBOOK_FILTER, filters, payload }));
};

export const changeCashbookBalance = (data, filters) => (dispatch, getState) => {
  const state = getState();
  const userId = fromUser.getCurrentUserId(state);
  const garageId = fromUser.getCurrentGarageId(state);

  return api.createEntry(garageId, { ...data, userId })
    .then(() => api.fetchCashbook(garageId, addPageSize(filters)))
    .then((payload) => dispatch({ type: CHANGE_CASHBOOK_FILTER, filters, payload }));
};

export const cancelEntry = (cashbook, data) => (dispatch, getState) => {
  const state = getState();
  const userId = fromUser.getCurrentUserId(state);
  const garageId = fromUser.getCurrentGarageId(state);

  let cancelAction;
  if (cashbook.receiptId && !cashbook.referencedCashbookId) {
    cancelAction = cancelReceipt(cashbook.receiptId, garageId, { userId });
  } else if (!cashbook.receiptId) {
    cancelAction = api.cancelEntry(cashbook._id, garageId, { userId });
  } else {
    return Promise.resolve(null);
  }

  return cancelAction
    .then(() => api.fetchCashbook(garageId, addPageSize(data)))
    .then((payload) => dispatch({ type: CHANGE_CASHBOOK_FILTER, data, payload }));
};

export const changeCashbookFilter = (options) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchCashbook(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_CASHBOOK_FILTER, data, payload }));
};

export const resetCashbookState = () => ({ type: RESET_CASHBOOK_STATE });
