import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InfiniteBlock from 'components/common/infiniteBlock';
import { MdArrowDropDown as DropdownIcon } from 'react-icons/md';
import polyglot from 'services/localization';
import Downshift from 'downshift';

import styles from './dropdown.styles.pcss';

export default class Dropdown extends React.PureComponent {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    customProps: PropTypes.shape({}),
    isRow: PropTypes.bool,
    disabled: PropTypes.bool,
    onScroll: PropTypes.func,
    onClick: PropTypes.func,
    isListOnTop: PropTypes.bool,
    dropdownLabel: PropTypes.string,
    isDropdownListWider: PropTypes.bool,
    inputClassName: PropTypes.string,
    dropDownIconClassName: PropTypes.string,
    dropDownClassName: PropTypes.string,
    dataTest: PropTypes.string,
    reactRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
  };

  static defaultProps = {
    errorMessage: '',
    className: '',
    label: '',
    value: '',
    isValid: true,
    tabIndex: 0,
    isRequired: false,
    customProps: {},
    isRow: false,
    disabled: false,
    onScroll: () => Promise.resolve(),
    isListOnTop: false,
    onClick: () => {},
    dropdownLabel: undefined,
    isDropdownListWider: false,
    inputClassName: '',
    dropDownIconClassName: '',
    dropDownClassName: '',
    dataTest: '',
  };

  render() {
    const {
      label,
      items,
      className,
      isRequired,
      errorMessage,
      isValid,
      value,
      tabIndex,
      customProps,
      isRow,
      disabled,
      onScroll,
      isListOnTop,
      onChange,
      onClick,
      isDropdownListWider,
      inputClassName,
      reactRef,
      dropDownIconClassName,
      dropDownClassName,
      dataTest,
    } = this.props;
    let { dropdownLabel } = this.props;

    items.forEach((item) => {
      if (item.key === value) {
        dropdownLabel = item.label;
      }
    });

    return (
      <div
        className={cx(styles.dropdown, className, styles.dropdownLabel, {
          [styles.dropdownLabelColumn]: !isRow,
        })}
      >
        <Downshift
          onChange={(selection) => selection && onChange(selection.key, customProps)}
          itemToString={(item) => (item ? item.key : '')}
          selectedItem={items.find((el) => el.label === value) || ''}
        >
          {({
            getItemProps,
            getToggleButtonProps,
            isOpen,
            selectedItem,
            highlightedIndex,
            setHighlightedIndex,
          }) => (
            <div
              className={styles.dropdownInput}
            >
              <button
                tabIndex={tabIndex}
                type="button"
                style={isDropdownListWider ? { padding: '6px', border: 'none' } : {}}
                className={cx(styles.dropdownInputButton, inputClassName, {
                  [styles.dropdownInputButtonError]: !isValid,
                  [styles.dropdownInputButtonDisabled]: disabled,
                })}
                {...getToggleButtonProps()}
                onClick={(e) => {
                  getToggleButtonProps().onClick(e);
                  onClick(e);
                }}
                ref={reactRef}
                data-test={dataTest}
              >
                {dropdownLabel || value || polyglot.t('actions.select')}
              </button>
              <button
                type="button"
                tabIndex={-1}
                className={cx(styles.dropdownInputButtonIcon, dropDownIconClassName)}
                {...getToggleButtonProps()}
                onClick={(e) => {
                  getToggleButtonProps().onClick(e);
                  onClick(e);
                }}
              >
                {!disabled && (
                  <DropdownIcon
                    className={cx(styles.dropdownInputButtonIconArrow, {
                      [styles.dropdownInputButtonIconArrowOpen]: isOpen,
                    })}
                  />
                )}
              </button>
              {!disabled && isOpen ? (
                <InfiniteBlock
                  onScroll={onScroll}
                  className={cx(styles.dropdownInputList, {
                    [styles.dropdownInputListOnTop]: isListOnTop,
                    [styles.dropdownInputListWider]: isDropdownListWider,
                  })}
                  setHighlightedIndex={setHighlightedIndex}
                >
                  {items
                    .map((item, index) => (
                      <div
                        key={item.key}
                        {...getItemProps({
                          index,
                          item,
                          disabled: !!item.disabled,
                          'data-test': `${dataTest}DropdownItem${index}`,
                        })}
                        className={cx(styles.dropdownInputListItem, dropDownClassName, {
                          [styles.dropdownInputListItemActive]: highlightedIndex === index,
                          [styles.dropdownInputListItemSelected]: selectedItem === item,
                        })}
                      >
                        {item.label}
                      </div>
                    ))}
                </InfiniteBlock>
              ) : null}
            </div>
          )}
        </Downshift>
        {label && (
        <div className={styles.dropdownLabelText}>
          {label}
          {isRequired ? <div className={styles.requiredMark}>*</div> : null}
        </div>
        )}
        {!errorMessage
          ? null
          : <div className={styles.dropdownError}>
            {isValid ? '' : errorMessage}
          </div>}
      </div>
    );
  }
}
