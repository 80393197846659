import { Component } from 'react';
import PropTypes from 'prop-types';

export default class RoleManager extends Component {
  static propTypes = {
    getUserRoles: PropTypes.func.isRequired,
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
    forbiddenRoles: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    contentWhenForbidden: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  };

  static defaultProps = {
    allowedRoles: ['admin', 'office'],
    forbiddenRoles: [],
    contentWhenForbidden: null,
  };

  getRolePermission = () => {
    const { getUserRoles, allowedRoles, forbiddenRoles } = this.props;
    const isRoleAllowed = getUserRoles.some((role) => allowedRoles.includes(role));
    return isRoleAllowed
      ? !getUserRoles.some((role) => forbiddenRoles.includes(role))
      : isRoleAllowed;
  };

  render() {
    const {
      children,
      contentWhenForbidden,
    } = this.props;

    return this.getRolePermission() ? children : contentWhenForbidden;
  }
}
