import React from 'react';
import PropTypes from 'prop-types';

const SmileySad = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15" fill="none"></circle>
    <g clipPath="url(#clip0_35_257)">
      <path d="M0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C11.7565 32 7.68687 30.3143 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16ZM21.1688 24.7437C21.5813 24.375 21.6125 23.7437 21.2437 23.3312C20.2375 22.2125 18.4813 21 16 21C13.5188 21 11.7625 22.2125 10.7563 23.3312C10.3875 23.7437 10.4187 24.375 10.8313 24.7437C11.2438 25.1125 11.875 25.0813 12.2437 24.6688C12.975 23.8563 14.2188 23 16 23C17.7812 23 19.025 23.8563 19.7563 24.6688C20.125 25.0813 20.7563 25.1125 21.1688 24.7437ZM11.025 17C12.1312 17 13.025 16.1063 13.025 15C13.025 14.9062 13.0188 14.8125 13.0063 14.725L13.6875 14.95C14.2125 15.125 14.775 14.8438 14.95 14.3188C15.125 13.7938 14.8438 13.2312 14.3188 13.0562L8.31875 11.0562C7.79375 10.8812 7.23125 11.1625 7.05625 11.6875C6.88125 12.2125 7.1625 12.775 7.6875 12.95L9.60625 13.5875C9.24375 13.95 9.025 14.45 9.025 15C9.025 16.1063 9.91875 17 11.025 17ZM23.025 15C23.025 14.4438 22.8 13.9375 22.4312 13.575L24.3188 12.9438C24.8438 12.7688 25.125 12.2 24.95 11.6812C24.775 11.1625 24.2062 10.875 23.6875 11.05L17.6875 13.05C17.1625 13.225 16.8813 13.7937 17.0562 14.3125C17.2312 14.8313 17.8 15.1188 18.3188 14.9438L19.05 14.7C19.0375 14.7937 19.0312 14.8937 19.0312 14.9937C19.0312 16.1 19.925 16.9937 21.0312 16.9937C22.1375 16.9937 23.0312 16.1 23.0312 14.9937L23.025 15Z" fill="#E3E6E9"/>
    </g>
    <defs>
      <clipPath id="clip0_35_257">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

SmileySad.propTypes = {
  fill: PropTypes.string,
};

SmileySad.defaultProps = {
  fill: '#fff',
};

export default SmileySad;
