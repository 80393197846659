import React from 'react';
import PropTypes from 'prop-types';

const FilePlus = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 20 20" fill={props.color} xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6055 5.08459L12.4187 1.89449C12.2722 1.74801 12.0736 1.66663 11.8653 1.66663H11.6667V5.83329H15.8334V5.63473C15.8334 5.42965 15.752 5.23108 15.6055 5.08459ZM10.625 6.09371V1.66663H4.11462C3.68168 1.66663 3.33337 2.01493 3.33337 2.44788V17.552C3.33337 17.985 3.68168 18.3333 4.11462 18.3333H15.0521C15.4851 18.3333 15.8334 17.985 15.8334 17.552V6.87496H11.4063C10.9766 6.87496 10.625 6.5234 10.625 6.09371ZM12.7084 11.302V12.8645C12.7084 13.0078 12.5912 13.125 12.448 13.125H10.625V14.9479C10.625 15.0911 10.5079 15.2083 10.3646 15.2083H8.80212C8.65889 15.2083 8.54171 15.0911 8.54171 14.9479V13.125H6.71879C6.57556 13.125 6.45837 13.0078 6.45837 12.8645V11.302C6.45837 11.1588 6.57556 11.0416 6.71879 11.0416H8.54171V9.21871C8.54171 9.07548 8.65889 8.95829 8.80212 8.95829H10.3646C10.5079 8.95829 10.625 9.07548 10.625 9.21871V11.0416H12.448C12.5912 11.0416 12.7084 11.1588 12.7084 11.302Z" fill="#4F6374"/>
  </svg>
);

FilePlus.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

FilePlus.defaultProps = {
  width: '20',
  height: '20',
  color: '#4F6374',
};

export default FilePlus;
