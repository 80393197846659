import React from 'react';
import PropTypes from 'prop-types';

const RegistrationIcon = (props) => {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 108 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="50" rx="4" fill="#E3E6E9" />
      <rect x="10" y="11" width="24" height="5" rx="2.5" fill="#454953" />
      <rect x="44" y="11" width="46" height="5" rx="2.5" fill="#454953" />
      <rect x="10" y="22" width="24" height="5" rx="2.5" fill="#454953" />
      <rect x="44" y="22" width="30" height="5" rx="2.5" fill="#454953" />
      <rect x="10" y="33" width="24" height="5" rx="2.5" fill="#454953" />
      <rect x="44" y="33" width="25" height="5" rx="2.5" fill="#454953" />
      <circle cx="92" cy="41" r="16" fill="#429488" />
      <path fillRule="evenodd" clipRule="evenodd" d="M91.9999 31.1539C90.6404 31.1539 89.5383 32.2559 89.5383 33.6154V38.5386H84.6152C83.2558 38.5386 82.1537 39.6407 82.1537 41.0001C82.1537 42.3596 83.2558 43.4617 84.6152 43.4617H89.5383V48.3846C89.5383 49.7441 90.6404 50.8462 91.9999 50.8462C93.3593 50.8462 94.4614 49.7441 94.4614 48.3846V43.4617H99.3845C100.744 43.4617 101.846 42.3596 101.846 41.0001C101.846 39.6407 100.744 38.5386 99.3845 38.5386H94.4614V33.6154C94.4614 32.2559 93.3593 31.1539 91.9999 31.1539Z" fill="white" />
    </svg>
  );
};

RegistrationIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

RegistrationIcon.defaultProps = {
  width: '108',
  height: '57',
};

export default RegistrationIcon;
