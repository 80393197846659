import React, { Component } from 'react';

import InputModern from 'components/common/inputModern';
import ButtonModern from 'components/common/buttonModern';
import ButtonLoading from 'components/common/loading/components/buttonLoading/buttonLoading';
import Message from 'components/common/message';
import Logo from 'components/common/logo';
import { acceptInvite, checkInviteToken } from 'resources/invite/invite.api';
import { Validators, FormValidator, FormValidatorGroup } from 'helpers/formValidation';
import polyglot from 'services/localization';
import PropTypes from 'prop-types';
import PasswordChecker from '../passwordChecker';
import accessStyles from '../../access.styles.pcss';
import BackgroundBlob from '../backgroundBlob';

class AcceptInvite extends Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const acceptInviteForm = this.generateAcceptInvite();
    this.state = {
      acceptInviteForm,
      token: new URLSearchParams(props.location.search).get('token'),
      garageName: null,
      isValidating: true,
    };
  }

  componentDidMount() {
    this.validateToken();
  }

  validateToken = async () => {
    const { token } = this.state;
    if (token) {
      checkInviteToken({ token }).then((res) => {
        this.setState({ garageName: res.garageName });
      }).finally(() => {
        this.setState({ isValidating: false });
      });
    } else {
      this.setState({ isValidating: false });
    }
  };

  generateAcceptInvite = () => {
    const maxLen = 200;
    const formValidatorGroup = new FormValidatorGroup({
      firstName: new FormValidator('', [Validators.required(polyglot.t('users.errors.firstName')), Validators.maxLength(maxLen, polyglot.t('users.errors.firstName'))]),
      lastName: new FormValidator('', [Validators.required(polyglot.t('users.errors.lastName')), Validators.maxLength(maxLen, polyglot.t('users.errors.lastName'))]),
      phone: new FormValidator('', [Validators.maxLength(maxLen, polyglot.t('users.errors.phone'))]),
      password: new FormValidator('', [Validators.required(polyglot.t('users.errors.password')), Validators.password()]),
    }, this, {}, this.onSubmit);

    return formValidatorGroup;
  };

  onSubmit = async (values) => {
    const { token: acceptToken } = this.state;
    await acceptInvite({
      token: acceptToken,
      ...values,
    });
    window.location = `${window.config.webUrl}`;
  };

  renderContent = () => {
    const { acceptInviteForm, garageName, isValidating } = this.state;

    if (isValidating) {
      return <ButtonLoading />;
    }

    if (!garageName) {
      return (
        <Message variant="error">
          {polyglot.t('access.acceptInvite.tokenInvalid')}
        </Message>
      );
    }

    return (
      <>
        <div className={accessStyles.layoutContentBodyMessageHeader}>
          <div>
            {polyglot.t('access.acceptInvite.welcomeTo')}
          </div>
          <div>
            {garageName}
          </div>
        </div>
        <InputModern
          formValidator={acceptInviteForm.group.firstName}
          label={polyglot.t('users.firstName')}
          key="firstName"
          name="firstName"
        />
        <InputModern
          formValidator={acceptInviteForm.group.lastName}
          label={polyglot.t('users.lastName')}
          key="lastName"
          name="lastName"
        />
        <InputModern
          formValidator={acceptInviteForm.group.phone}
          label={polyglot.t('users.phone')}
          key="phone"
          name="phone"
        />
        <div>
          <InputModern
            formValidator={acceptInviteForm.group.password}
            label={polyglot.t('users.password')}
            type="password"
            key="password"
            name="password"
          />
          <PasswordChecker
            password={acceptInviteForm.group.password.value}
          />
        </div>
        <ButtonModern
          type="submit"
          styleType="add"
        >
          {polyglot.t('actions.login')}
        </ButtonModern>
      </>
    );
  };

  render() {
    const { acceptInviteForm: { Form } } = this.state;
    return (
      <div className={accessStyles.layout}>
        <BackgroundBlob />
        <div className={accessStyles.layoutContent}>
          <div className={accessStyles.layoutContentHeader}>
            <Logo className={accessStyles.layoutContentHeaderLogo} />
          </div>
          <Form>
            <div className={accessStyles.layoutContentBody}>
              {this.renderContent()}
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default AcceptInvite;
