import React from 'react';

const CreditCardBack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="164" height="106" viewBox="0 0 164 106" fill="none">
      <rect width="164" height="105.166" rx="10.296" fill="#AAD9D0"/>
      <rect y="13.9731" width="164" height="26.4753" fill="#26282D"/>
      <rect x="7.35449" y="49.2735" width="91.1928" height="13.9731" fill="white"/>
      <path d="M81.6291 53.7349L83.1373 56.1394L84.6455 53.7349H85.6151L83.6329 56.8418L85.6625 60.009H84.6843L83.1373 57.5571L81.5903 60.009H80.6122L82.6418 56.8418L80.6596 53.7349H81.6291ZM87.162 53.7349L88.6702 56.1394L90.1784 53.7349H91.148L89.1658 56.8418L91.1954 60.009H90.2172L88.6702 57.5571L87.1233 60.009H86.1451L88.1747 56.8418L86.1925 53.7349H87.162ZM92.695 53.7349L94.2032 56.1394L95.7114 53.7349H96.6809L94.6987 56.8418L96.7283 60.009H95.7502L94.2032 57.5571L92.6562 60.009H91.678L93.7076 56.8418L91.7254 53.7349H92.695Z" fill="#26282D"/>
      <path d="M102.224 56.2601C102.224 58.2294 100.866 60.1442 98.3855 61.6069C95.922 63.0598 92.4706 63.9821 88.6186 63.9821C84.7667 63.9821 81.3153 63.0598 78.8517 61.6069C76.3715 60.1442 75.0133 58.2294 75.0133 56.2601C75.0133 54.2908 76.3715 52.376 78.8517 50.9133C81.3153 49.4604 84.7667 48.5381 88.6186 48.5381C92.4706 48.5381 95.922 49.4604 98.3855 50.9133C100.866 52.376 102.224 54.2908 102.224 56.2601Z" stroke="#FFB03A" strokeWidth="1.47085"/>
    </svg>
  );
};

export default CreditCardBack;
