import * as fromGarage from 'resources/garage/garage.selectors';
import * as fromUser from 'resources/user/user.selectors';

import * as ebvApi from './ebv.api';

export const createEbvOrder = (vehicleId, type, orderId) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const {
    ebv: { username = '', password = '' } = {},
  } = fromGarage.getIntegrationCredentials(state);

  return ebvApi.createEbvOrder(garageId, {
    username,
    password,
    vehicleId,
    type,
    orderId,
  });
};
