import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InfiniteBlock from 'components/common/infiniteBlock';
import { FaChevronDown as DropdownIcon } from 'react-icons/fa';
import polyglot from 'services/localization';
import FormValidator from 'helpers/formValidation/formValidator';
import Downshift from 'downshift';
import ValidatableComponent from 'helpers/formValidation/validatableComponent';
import styles from './dropdownModern.styles.pcss';
import InteractableDiv from '../interactableDiv';

export default class DropdownModern extends ValidatableComponent {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
      disabled: PropTypes.bool,
      isInfo: PropTypes.bool,
    })).isRequired,
    onChange: PropTypes.func,
    isValid: PropTypes.bool,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    customProps: PropTypes.shape({}),
    isRow: PropTypes.bool,
    disabled: PropTypes.bool,
    onScroll: PropTypes.func,
    onClick: PropTypes.func,
    isListOnTop: PropTypes.bool,
    dropdownLabel: PropTypes.string,
    inputClassName: PropTypes.string,
    dropDownIconClassName: PropTypes.string,
    dropDownClassName: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    formValidator: FormValidator.getShape(),
    dataTest: PropTypes.string,
    labelClassName: PropTypes.string,
    dropDownInputClassName: PropTypes.string,
    dropDownInputListContainerClassName: PropTypes.string,
    allowOverFlow: PropTypes.bool,
    useGrayTheme: PropTypes.bool,
  };

  static defaultProps = {
    errorMessage: '',
    className: '',
    label: '',
    value: '',
    isValid: true,
    tabIndex: 0,
    isRequired: false,
    customProps: {},
    isRow: false,
    disabled: false,
    onScroll: () => Promise.resolve(),
    isListOnTop: false,
    onClick: () => {},
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    dropdownLabel: undefined,
    inputClassName: '',
    dropDownIconClassName: '',
    dropDownClassName: '',
    formValidator: null,
    dataTest: '',
    labelClassName: '',
    dropDownInputClassName: '',
    dropDownInputListContainerClassName: '',
    allowOverflow: false,
    useGrayTheme: false,
  };

  onChangeHandler = (value) => {
    const { onChange, formValidator, customProps } = this.props;
    if (formValidator) {
      formValidator.set(value);
      onChange(formValidator.value, customProps);
    } else {
      onChange(value, customProps);
    }
  };

  render() {
    const {
      label,
      items,
      className,
      errorMessage,
      isValid,
      value,
      tabIndex,
      isRow,
      disabled,
      onScroll,
      isListOnTop,
      onClick,
      inputClassName,
      reactRef,
      dropDownIconClassName,
      dropDownClassName,
      formValidator,
      dataTest,
      labelClassName,
      dropDownInputClassName,
      dropDownInputListContainerClassName,
      allowOverflow,
      useGrayTheme,
    } = this.props;
    let { dropdownLabel } = this.props;
    const currentValue = formValidator ? formValidator.value : value;

    const hasError = super.hasError();

    items.forEach((item) => {
      if (item.key === currentValue) {
        dropdownLabel = item.label;
      }
    });
    return (
      <div
        className={cx(className, styles.dropdownLabel, styles.dropdown, {
          [styles.dropdownLabelColumn]: !isRow,
        })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        data-test={dataTest}
      >
        <Downshift
          onChange={(selection) => selection && this.onChangeHandler(selection.key)}
          itemToString={(item) => (item ? item.key : '')}
          selectedItem={items.find((el) => el.label === currentValue) || ''}
        >
          {({
            getItemProps,
            getToggleButtonProps,
            isOpen,
            closeMenu,
            selectedItem,
            highlightedIndex,
            setHighlightedIndex,
            ...rest
          }) => (
            <div
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              className={cx(
                styles.dropdownInput,
                {
                  [styles.dropdownInputButtonError]: hasError || errorMessage || !isValid,
                  [styles.dropdownInputButtonDisabled]: disabled,
                },
                dropDownInputClassName,
                { [styles.dropdownInputGrayTheme]: useGrayTheme },
              )}
            >
              <button
                tabIndex={tabIndex}
                type="button"
                className={cx(styles.dropdownInputButton, inputClassName)}
                {...getToggleButtonProps()}
                onClick={(e) => {
                  getToggleButtonProps().onClick(e);
                  onClick(e);
                }}
                ref={reactRef}
              >
                {dropdownLabel || currentValue || polyglot.t('actions.select')}
              </button>
              <InteractableDiv
                tabIndex={-1}
                className={cx(styles.dropdownInputButtonIcon, dropDownIconClassName)}
                {...getToggleButtonProps()}
                onClick={(e) => {
                  getToggleButtonProps().onClick(e);
                  onClick(e);
                }}
              >
                {!disabled && (
                  <DropdownIcon
                    className={cx(styles.dropdownInputButtonIconArrow, {
                      [styles.dropdownInputButtonIconArrowOpen]: isOpen,
                    })}
                  />
                )}
              </InteractableDiv>
              {!disabled && isOpen ? (
                <>
                  <InteractableDiv
                    className={styles.dropdownInputListBackground}
                    onClick={closeMenu}
                  />
                  <InfiniteBlock
                    onScroll={onScroll}
                    className={cx(styles.dropdownInputList, {
                      [styles.dropdownInputListOnTop]: isListOnTop,
                      [styles.dropdownInputListAllowOverflow]: allowOverflow,
                    }, dropDownInputListContainerClassName)}
                    setHighlightedIndex={setHighlightedIndex}
                    highlightedIndex={highlightedIndex}
                    dataTest="dropdownModernList"
                  >
                    {items
                      .map((item, index) => (
                        <div
                          key={item.key}
                          {...getItemProps({
                            key: item.key,
                            index,
                            item,
                            disabled: !!item.disabled,
                            'data-test': `${dataTest}DropdownItem${index}`,
                          })}
                          className={cx(styles.dropdownInputListItem, dropDownClassName, {
                            [styles.dropdownInputListItemActive]: highlightedIndex === index,
                            [styles.dropdownInputListItemSelected]: selectedItem === item,
                            [styles.dropdownInputListItemAllowOverflow]: allowOverflow,
                            [styles.dropdownInputListItemInfo]: item.isInfo,
                            [styles.dropdownInputListItemDisabled]: !!item.disabled,
                          })}
                        >
                          {item.label}
                        </div>
                      ))}
                  </InfiniteBlock>
                </>
              ) : null}
            </div>
          )}
        </Downshift>
        {label && (
          <div className={cx(styles.dropdownLabelText, labelClassName)}>
            {label}
            {super.isRequired() ? <div className={styles.requiredMark}>
              *
            </div> : null}
          </div>
        )}

        {super.renderTooltip()}
      </div>
    );
  }
}
