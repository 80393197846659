const { PRODUCT_TYPES } = require('../../definitions/productTypes');

const getSpreadedInvoiceItems = (items) => {
  return items
    .filter(({ type, isInactive }) => type !== PRODUCT_TYPES.CALCULATED || isInactive !== true)
    .reduce((spreadItems, currItem) => {
      if (currItem.type === PRODUCT_TYPES.GROUP) {
        spreadItems.push(...currItem.items);
      } else {
        spreadItems.push(currItem);
      }
      return spreadItems;
    }, []);
};

const amountOfItemsInRange = (items) => {
  return getSpreadedInvoiceItems(items).length <= 500;
};

module.exports = {
  getSpreadedInvoiceItems,
  amountOfItemsInRange,
};
