import * as apiClient from 'resources/api.client';

export function fetchInvites(data) {
  return apiClient.get('invites', data);
}

export function sendInvite(data, garageId) {
  return apiClient.post('invites', { garageId }, data);
}

export function resendInvite(inviteId, garageId) {
  return apiClient.post(`invites/${inviteId}/resend`, { garageId });
}

export function cancelInvites(inviteIds, garageId) {
  return apiClient.post('invites/cancel', { garageId }, { inviteIds });
}

export function acceptInvite(data) {
  return apiClient.post('invites/accept', {}, data, { credentials: 'include' });
}

export function checkInviteToken(data) {
  return apiClient.post('invites/checkToken', {}, data);
}
