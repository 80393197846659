import { downloadFile } from 'helpers/file';
import { getServerCorrectDate } from 'helpers/date';
import { addPageSize } from 'resources/utils';
import * as fromUser from 'resources/user/user.selectors';
import * as api from './vehicles.api';

export const FETCH_VEHICLES = 'fetchVehicles';
export const FETCH_VEHICLES_BY_CLIENT_ID = 'fetchVehiclesByClientId';
export const FETCH_VEHICLES_INSPECTIONS = 'fetchVehiclesInspections';
export const LAZY_FETCH_VEHICLES = 'lazyFetchVehicles';
export const LAZY_FETCH_VEHICLES_BY_CLIENT_ID = 'lazyFetchVehiclesByClientId';
export const LAZY_FETCH_VEHICLES_INSPECTIONS = 'lazyFetchVehiclesInspections';
export const FETCH_VEHICLE = 'fetchVehicle';
export const RESET_VEHICLES_STATE = 'resetVehiclesState';
export const RESET_VEHICLES_STATE_BY_CLIENT_ID = 'resetVehiclesStateByClientId';
export const RESET_VEHICLES_STATE_INSPECTION = 'resetVehiclesStateInspection';
export const RESET_CURRENT_VEHICLE = 'resetCurrentVehicle';
export const UPDATE_CURRENT_VEHICLE = 'updateCurrentVehicle';
export const CREATE_VEHICLE = 'createVehicle';
export const REMOVE_VEHICLE = 'removeVehicle';
export const UPDATE_VEHICLE = 'updateVehicle';
export const CHANGE_VEHICLES_FILTER = 'changeVehiclesFilter';
export const CHANGE_VEHICLES_FILTER_BY_CLIENT_ID = 'changeVehiclesFilterByClientId';
export const CHANGE_VEHICLES_FILTER_INSPECTIONS = 'changeVehiclesFilterInspections';
export const GET_VEHICLES_CSV = 'changeVehiclesFilter';
export const UPDATE_MILEAGE = 'updateMileage';
export const UPDATE_INSPECTION = 'updateInspection';
export const RESTORE_VEHICLE = 'restoreVehicle';

export const fetchVehicles = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);

  return api.fetchVehicles(currentGarageId, data)
    .then((payload) => dispatch({ type: FETCH_VEHICLES, data, payload }));
};

export const fetchVehiclesByClientId = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);

  return api.fetchVehiclesByClientId(currentGarageId, data)
    .then((payload) => dispatch({ type: FETCH_VEHICLES_BY_CLIENT_ID, data, payload }));
};

export const fetchVehiclesInspections = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);

  return api.fetchVehiclesInspections(currentGarageId, data)
    .then((payload) => dispatch({ type: FETCH_VEHICLES_INSPECTIONS, data, payload }));
};

export const lazyFetchVehicles = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);

  return api.fetchVehicles(currentGarageId, data)
    .then((payload) => dispatch({ type: LAZY_FETCH_VEHICLES, data, payload }));
};

export const lazyFetchVehiclesByClientId = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);

  return api.fetchVehiclesByClientId(currentGarageId, data)
    .then((payload) => dispatch({ type: LAZY_FETCH_VEHICLES_BY_CLIENT_ID, data, payload }));
};

export const lazyFetchVehiclesInspections = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);

  return api.fetchVehiclesInspections(currentGarageId, data)
    .then((payload) => dispatch({ type: LAZY_FETCH_VEHICLES, data, payload }));
};

export const fetchVehicle = (id, data = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.fetchVehicle(currentGarageId, id, data)
    .then((payload) => dispatch({ type: FETCH_VEHICLE, payload }));
};

export const resetVehiclesState = () => ({ type: RESET_VEHICLES_STATE });
export const resetVehiclesStateByClientId = () => ({ type: RESET_VEHICLES_STATE_BY_CLIENT_ID });
export const resetVehiclesStateInspection = () => ({ type: RESET_VEHICLES_STATE_INSPECTION });

export const resetCurrentVehicle = () => ({ type: RESET_CURRENT_VEHICLE });

export const updateCurrentVehicle = (vehicle) => ({ type: UPDATE_CURRENT_VEHICLE, vehicle });

export const changeVehiclesFilter = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);
  return api.fetchVehicles(currentGarageId, data)
    .then((payload) => dispatch({ type: CHANGE_VEHICLES_FILTER, data, payload }));
};

export const changeVehiclesFilterByClientId = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);
  return api.fetchVehiclesByClientId(currentGarageId, data)
    .then((payload) => dispatch({ type: CHANGE_VEHICLES_FILTER, data, payload }));
};

export const changeVehiclesFilterInspections = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize(options);
  return api.fetchVehiclesInspections(currentGarageId, data)
    .then((payload) => dispatch({ type: CHANGE_VEHICLES_FILTER_INSPECTIONS, data, payload }));
};

const getValidData = (data) => ({
  ...data,
  clientId: data.client.clientId || data.client._id,
  dateOfFirstRegistration: getServerCorrectDate(data.dateOfFirstRegistration),
  dateOfLastRegistration: getServerCorrectDate(data.dateOfLastRegistration),
  acceptanceDate: getServerCorrectDate(data.acceptanceDate),
  dateOfNextInspection: getServerCorrectDate(data.dateOfNextInspection),
  enginePower: data.enginePower || null,
  ownWeight: data.ownWeight || null,
  maxWeight: data.maxWeight || null,
  engineDisplacement: data.engineDisplacement || null,
  TSNCode: data.TSNCode && data.TSNCode.toUpperCase(),
});

export const createVehicle = (data) => (dispatch, getState) => {
  const validData = getValidData(data);
  return api.createVehicle(fromUser.getCurrentGarageId(getState()), validData)
    .then((payload) => dispatch({ type: CREATE_VEHICLE, data: validData, payload }));
};

export const removeVehicles = (vehicleIds) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.removeVehicles(currentGarageId, vehicleIds)
    .then((payload) => dispatch({ type: REMOVE_VEHICLE, vehicleIds, payload }));
};

export const updateVehicle = (vehicleId, data) => (dispatch, getState) => {
  const validData = getValidData(data);
  return api.updateVehicle(fromUser.getCurrentGarageId(getState()), vehicleId, validData)
    .then((payload) => dispatch({ type: UPDATE_VEHICLE, data: validData, payload }));
};

export const calculateDateOfNextInspectionForCountry
 = (country, vehicleId) => (dispatch, getState) => {
   return api.calculateDateOfNextInspectionForCountry(
     fromUser.getCurrentGarageId(
       getState(),
     ),
     vehicleId,
     country,
   )
     .then((payload) => dispatch({ type: UPDATE_VEHICLE, payload }));
 };

export const updateMileage = (vehicleId, mileage) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.updateMileage(vehicleId, garageId, { mileage })
    .then((payload) => dispatch({ type: UPDATE_MILEAGE, payload }));
};

export const updateInspection = (vehicleId, dateOfNextInspection) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.updateInspection(vehicleId, garageId, { dateOfNextInspection })
    .then((payload) => dispatch({ type: UPDATE_INSPECTION, payload }));
};

export const getVehiclesCSV = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.getVehiclesCSV(currentGarageId, data)
    .then((payload) => downloadFile(payload));
};

export const uploadVehicleImage = (vehicleId, data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.uploadVehicleImage(currentGarageId, vehicleId, data)
    .then((payload) => dispatch({
      type: UPDATE_VEHICLE,
      vehicleId,
      data,
      payload,
    }));
};

export const fetchVehicleImage = (
  clientId,
  fileId,
  name,
  extension,
  location,
) => async (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());
  return api.fetchVehicleImage(currentGarageId, clientId, fileId, name, extension, location);
};

export const removeVehicleImages = (vehicleId, filesToRemove) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.removeVehicleImages(currentGarageId, vehicleId, filesToRemove)
    .then((payload) => dispatch({
      type: UPDATE_VEHICLE,
      vehicleId,
      filesToRemove,
      payload,
    }));
};

export const updateVehicleImage = (vehicleId, imageId, data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.updateVehicleImage(currentGarageId, vehicleId, imageId, data)
    .then((payload) => dispatch({
      type: UPDATE_VEHICLE,
      vehicleId,
      imageId,
      data,
      payload,
    }));
};

export const restoreVehicle = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  return api.restoreVehicle(currentGarageId, data)
    .then((payload) => dispatch({ type: RESTORE_VEHICLE, data, payload }));
};
