const INVOICE_EVENTS = Object.freeze({
  CREATED_INVOICE: 'Created invoice',
  CREATED_COST_LISTING: 'Created cost listing',
  REOPENED_INVOICE: 'Reopened invoice',
  EDITED_INVOICE: 'Edited invoice',
  CLOSED_INVOICE: 'Closed invoice',
  REGISTERED_PAYMENT: 'Registered payment',
  CANCELED_PAYMENT: 'Canceled payment',
  PRINTED_INVOICE: 'Printed invoice',
  SENT_INVOICE_EMAIL: 'Sent invoice email',
  CREATED_DRAFT: 'Created draft',
  PRINTED_DRAFT: 'Printed draft',
  SENT_DRAFT_EMAIL: 'Sent draft email',
  CREATED_PAYMENT_REMINDER: 'Created payment reminder',
  PRINTED_PAYMENT_REMINDER: 'Printed payment reminder',
  SENT_PAYMENT_REMINDER_EMAIL: 'Sent payment reminder email',
  PRINTED_DEMAND_LETTER1: 'Printed demand letter1',
  PRINTED_DEMAND_LETTER2: 'Printed demand letter2',
  PRINTED_DEMAND_LETTER3: 'Printed demand letter3',
  PRINTED_INTERNAL_ORDER: 'Printed internal order',
  SENT_INTERNAL_ORDER_EMAIL: 'Sent internal order email',
  PRINTED_PREVIEW: 'Printed preview',
  SENT_PREVIEW: 'Sent preview email',
  SENT_PICKUP_NOTIFICATION: 'Sent pickup notification',
  CREATED_CANCELLATION_INVOICE: 'Created cancellation invoice',
  COPY_OF_CANCELED_INVOICE_CREATED: 'Copy of canceled invoice created',
  INVOICE_CANCELED: 'Invoice canceled',
  EMAIL_DELIVERED: 'Email delivered',
  EMAIL_OPENED: 'Email opened',
  EMAIL_ERROR: 'Email error',
  CREATED_CREDIT_FOR_INVOICE: 'Created credit for invoice',
  CREATED_CREDIT: 'Created credit',
  COPY_OF_DRAFT_CREATED: 'Copy of draft created',
  COPY_OF_PREVIEW_CREATED: 'Copy of preview created',
  COPY_OF_INVOICE_CREATED: 'Copy of invoice created',
  CREATED_ASSIGNMENT_DECLARATION: 'Created assignment declaration',
  MARKED_INVOICE_AS_PAID: 'Marked invoice as paid',
  SENT_TO_SDI: 'Sent to Sdi',
  SDI_REJECTED: 'Rejected from Sdi',
  SDI_SENT_TO_CUSTOMER: 'Sdi sent to Customer',
  SDI_CANT_DELIVER_TO_CUSTOMER: 'Sdi can not deliver to Customer',
  REGISTERED_DOWNPAYMENT: 'Registered downpayment',
});

module.exports = {
  INVOICE_EVENTS,
};
