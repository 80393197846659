import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './pieChart.styles.pcss';

class PieChart extends Component {
  static propTypes = {
    className: PropTypes.string,
    backgroundColor: PropTypes.string,
    showOutline: PropTypes.bool,
    percentage: PropTypes.number,
  };

  static defaultProps = {
    className: null,
    backgroundColor: 'transparent',
    showOutline: true,
    percentage: 50,
  };

  state = {};

  render() {
    const {
      className, backgroundColor, showOutline, percentage,
    } = this.props;
    const dasharrayVal = (percentage * 31.4) / 100;
    return (
      <div className={cx(styles.pieChart, className)}>
        <svg viewBox="-1 -1 22 22">
          <circle r="10" cx="10" cy="10" fill={backgroundColor} {...(showOutline ? {} : { stroke: 'transparent' })} />
          <circle r="5" cx="10" cy="10" fill="transparent"
            strokeWidth="10"
            strokeDasharray={`${dasharrayVal} 31.4`}
            transform="rotate(-90) translate(-20)" />
        </svg>
      </div>
    );
  }
}

export default PieChart;
