import { FETCH_PRINTERS } from './printers.actions';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRINTERS:
      return Array.isArray(action.payload)
        ? action.payload.map(({ id, name }) => ({ key: id, label: name })) : [];
    default:
      return state;
  }
};
