import { replace } from 'helpers/locale';
import polyglot from 'services/localization';

export const getRemoveEntities = (entitiesName) => replace(polyglot.t('common.removeEntity'), ['entity'], [entitiesName]);

export const getSureRemoveEntities = (entitiesName) => replace(polyglot.t('common.areYouSureRemoveEntity'), ['entity'], [entitiesName.toLowerCase()]);

export const getRemoveEntity = (entityName) => replace(polyglot.t('common.removeEntity'), ['entity'], [entityName]);

export const getMoveToRecycleBinEntity = (entityName) => replace(polyglot.t('common.moveEntityToRecycleBin'), ['entity'], [entityName]);

export const getSureRemoveEntity = (entityName) => replace(polyglot.t('common.areYouSureRemoveEntity'), ['entity'], [entityName.toLowerCase()]);

export const getSureMoveToRecycleBinEntity = (entityName) => replace(polyglot.t('common.areYouSureMoveEntity'), ['entity'], [entityName.toLowerCase()]);

export const getSureLoseChanges = (entityName) => replace(polyglot.t('common.areYouSureLoseChanges'), ['entity'], [entityName]);

export const getTransferOwnershipEntity = (entityName) => replace(polyglot.t('common.transferOwnershipEntity'), ['entity'], [entityName]);

export const getSureTransferOwnershipEntity = (entityName) => replace(polyglot.t('common.areYouSureTransferOwnershipEntity'), ['entity'], [entityName]);
