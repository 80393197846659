import React from 'react';

const Close = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.9397 13.8191C2.13661 14.016 2.40368 14.1266 2.68216 14.1266C2.96064 14.1266 3.22771 14.016 3.42462 13.8191L7.75565 9.48806C7.78847 9.45524 7.83298 9.43681 7.87939 9.43681C7.92581 9.43681 7.97032 9.45524 8.00314 9.48806L12.3342 13.8191C12.5311 14.016 12.7982 14.1266 13.0766 14.1266C13.3551 14.1266 13.6222 14.016 13.8191 13.8191C14.016 13.6222 14.1266 13.3551 14.1266 13.0766C14.1266 12.7982 14.016 12.5311 13.8191 12.3342L9.48806 8.00314C9.45524 7.97032 9.43681 7.92581 9.43681 7.87939C9.43681 7.83298 9.45524 7.78847 9.48806 7.75565L13.8191 3.42462C14.016 3.22771 14.1266 2.96064 14.1266 2.68216C14.1266 2.40368 14.016 2.13661 13.8191 1.9397C13.6222 1.74278 13.3551 1.63216 13.0766 1.63216C12.7982 1.63216 12.5311 1.74278 12.3342 1.9397L8.00314 6.27073C7.97032 6.30355 7.92581 6.32198 7.87939 6.32198C7.83298 6.32198 7.78847 6.30355 7.75565 6.27073L3.42462 1.9397C3.22771 1.74278 2.96064 1.63216 2.68216 1.63216C2.40368 1.63216 2.13661 1.74278 1.9397 1.9397C1.74278 2.13661 1.63216 2.40368 1.63216 2.68216C1.63216 2.96064 1.74278 3.22771 1.9397 3.42462L6.27073 7.75565C6.30354 7.78847 6.32198 7.83298 6.32198 7.87939C6.32198 7.92581 6.30354 7.97032 6.27073 8.00314L1.9397 12.3342C1.74278 12.5311 1.63216 12.7982 1.63216 13.0766C1.63216 13.3551 1.74278 13.6222 1.9397 13.8191Z" fill="#A3A9B9"/>
    </svg>

  );
};

export default Close;
