import { last } from 'lodash';
import {
  FETCH_TEXTBLOCKS,
  RESET_TEXTBLOCKS_STATE,
  REMOVE_TEXTBLOCKS,
} from './textBlock.actions';

const initialState = { list: [], totalAmount: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEXTBLOCKS: {
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }

    case RESET_TEXTBLOCKS_STATE:
      return {
        ...state,
        list: [],
      };

    case REMOVE_TEXTBLOCKS:
      return {
        ...state,
        list: state.list.filter((textBlock) => !action.textBlockIds.includes(textBlock._id)),
        totalAmount: state.totalAmount - action.textBlockIds.length,
      };

    default:
      return state;
  }
};
