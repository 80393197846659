const baseUrl = 'https://api.zippopotam.us/';
const supportedCountries = Object.freeze({
  DE: { length: 5 },
  IT: { length: 5 },
  AT: { length: 4 },
  CH: { length: 4 },
  LI: { length: 4 },
  LU: { length: 4 },
});

export default async function getCityFromZipCode(zipCode, country) {
  if (validateCountryAndZipLength(zipCode, country)) {
    try {
      const zipResult = await (await fetch(`${baseUrl + country}/${zipCode}`)).json();
      return getCitiesFromResponse(zipResult);
    } catch (x) { /* No connection */ }
  }
  return null;
}

function validateCountryAndZipLength(zipCode, country) {
  return (Object.keys(supportedCountries).includes(country)
      && supportedCountries[country].length === zipCode.length);
}

function getCitiesFromResponse(response) {
  if (response?.places) {
    const cities = [];
    response.places.forEach((cityEntry) => {
      const cityName = cityEntry['place name'];
      cities.push({ key: cityName, label: cityName });
    });
    return cities;
  }
  return null;
}
