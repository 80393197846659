import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  MdWarning as WarningIcon,
  MdError as ErrorIcon,
  MdInfo as InfoIcon,
  MdCheckCircle as SuccessIcon,
} from 'react-icons/md';
import styles from './message.styles.pcss';

class Message extends PureComponent {
  static propTypes = {
    variant: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { variant } = this.props;
    return (
      <>
        {variant && variant === 'warning' && (
          <div className={cx(styles.message, styles.warning, this.props.className)}>
            <WarningIcon className={cx(styles.messageIcon, styles.warningIcon)} />
            <div className={styles.messageChildren}>
              {this.props.children}
            </div>
          </div>
        )}
        {variant && variant === 'error' && (
          <div className={cx(styles.message, styles.error, this.props.className)}>
            <ErrorIcon className={cx(styles.messageIcon, styles.errorIcon)} />
            <div className={styles.messageChildren}>
              {this.props.children}
            </div>
          </div>
        )}
        {variant && variant === 'info' && (
          <div className={cx(styles.message, styles.info, this.props.className)}>
            <InfoIcon className={cx(styles.messageIcon, styles.infoIcon)} />
            <div className={styles.messageChildren}>
              {this.props.children}
            </div>
          </div>
        )}
        {variant && variant === 'success' && (
          <div className={cx(styles.message, styles.success, this.props.className)}>
            <SuccessIcon className={cx(styles.messageIcon, styles.successIcon)} />
            <div className={styles.messageChildren}>
              {this.props.children}
            </div>
          </div>
        )}
        {variant && variant === 'warningBordered' && (
          <div className={cx(
            styles.message,
            styles.warning,
            styles.warningBordered,
            this.props.className,
          )}>
            <WarningIcon className={cx(styles.messageIcon, styles.warningIcon)} />
            <div className={styles.messageChildren}>
              {this.props.children}
            </div>
          </div>
        )}
        {variant && variant === 'errorBordered' && (
          <div className={cx(
            styles.message,
            styles.error,
            styles.errorBordered,
            this.props.className,
          )}>
            <ErrorIcon className={cx(styles.messageIcon, styles.errorIcon)} />
            <div className={styles.messageChildren}>
              {this.props.children}
            </div>
          </div>
        )}
        {variant && variant === 'infoBordered' && (
          <div className={cx(
            styles.message,
            styles.info,
            styles.infoBordered,
            this.props.className,
          )}>
            <InfoIcon className={cx(styles.messageIcon, styles.infoIcon)} />
            <div className={styles.messageChildren}>
              {this.props.children}
            </div>
          </div>
        )}
        {variant && variant === 'successBordered' && (
          <div className={cx(
            styles.message,
            styles.success,
            styles.successBordered,
            this.props.className,
          )}>
            <SuccessIcon className={cx(styles.messageIcon, styles.successIcon)} />
            <div className={styles.messageChildren}>
              {this.props.children}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Message;
