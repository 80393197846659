export function getUidPlaceholderForCountry(country) {
  let placeholder;
  switch (country) {
    case 'AT':
      placeholder = 'ATU00000000';
      break;
    case 'CH':
      placeholder = 'CH000.000.000 MWST';
      break;
    case 'DE':
      placeholder = 'DE00000000';
      break;
    case 'LU':
      placeholder = 'LU00000000';
      break;
    case 'LI':
      placeholder = 'CHE000000000';
      break;
    case 'IT':
      placeholder = 'IT00000000000';
      break;
    default:
      placeholder = `${country}00000000`;
      break;
  }
  return placeholder;
}
