import { createFormDataWithAttachments } from 'helpers/file';
import * as apiClient from 'resources/api.client';

export function fetchOffers(garageId, data) {
  return apiClient.get('offers', { garageId, ...data });
}

export function fetchOffer(garageId, offerId, data) {
  return apiClient.get(`offers/${offerId}`, { garageId, ...data });
}

export function getOffersCSV(garageId, data) {
  return apiClient.get('offers/csv', { garageId, ...data });
}

export function createOffer(garageId, data) {
  return apiClient.post('offers', { garageId }, data);
}

export function updateOffer(garageId, offerId, data) {
  return apiClient.put(`offers/${offerId}`, { garageId }, data);
}

export function changeStatus(garageId, offerId, status) {
  return apiClient.post(`offers/${offerId}/convert`, { garageId }, { status });
}

export function removeOffers(garageId, offerIds) {
  return apiClient.post('offers/remove', { garageId }, { offerIds });
}

export function printOffer(garageId, id) {
  return apiClient.post(`offers/${id}/print`, { garageId }, {});
}

export const generateOfferPdf = (garageId, id) => apiClient.post(`offers/${id}/generate-pdf`, { garageId });

export const getOfferPdfUrl = (id, garageId) => apiClient.get(`offers/${id}/pdf-url`, { garageId });

export const sendOfferViaEmail = (offerId, garageId, data) => {
  const body = createFormDataWithAttachments(data);
  return apiClient.postRaw(`offers/${offerId}/send`, { garageId }, body);
};

export function copyIntoOffer(offerId, garageId) {
  return apiClient.post(`offers/${offerId}/copyIntoOffer`, { garageId });
}

export function restoreOffer(garageId, data) {
  return apiClient.put(`offers/${data.offerId}/restore`, { garageId }, data);
}
