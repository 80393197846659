import React, { useState } from 'react';
import PropTypes from 'prop-types';

import toastService from 'helpers/toastService';
import parseError from 'helpers/parseError';
import polyglot from 'services/localization';
import { openHelpArticle } from 'services/supportTools';
import Button from 'components/common/button';
import AddonManager from 'components/common/addonManager';
import NotificationButton from 'components/common/notificationButton';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faPlus } from 'fontawesome/pro-solid-svg-icons';
import ButtonModern from '../buttonModern';

const EbvIntegration = ({
  vehicleId,
  createEbvOrder,
  className,
  isEBVActive,
  type,
  orderId,
  isShownAsModern,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!isEBVActive) {
    return null;
  }

  const handleClick = () => {
    setIsLoading(true);
    return createEbvOrder(vehicleId, type, orderId)
      .then(() => {
        setIsLoading(false);
        toastService.showSuccess(polyglot.t('ebvIntegration.success'));
      })
      .catch((err) => {
        setIsLoading(false);
        const msg = parseError(err);
        if (msg === 'ACCOUNT HAS MULTIPLE PERMISSIONS') {
          toastService.showWarning(
            polyglot.t('ebvIntegration.error'), (
              <NotificationButton
              onClick={() => {
                toastService.dismiss();
                openHelpArticle('8290567');
              }}
              style={
                {
                  backgroundColor: '#EE762F',
                  borderRadius: '5px',
                  border: 'none',
                  maxWidth: '200px',
                  margin: '10px 0',
                  alignItems: 'center',
                }
              }
            >
                {polyglot.t('ebvIntegration.instructions')}
              </NotificationButton>
            ),
          );
        } else {
          toastService.showError(msg);
        }
      });
  };

  return (
    <>
      {isShownAsModern ? (
        <AddonManager
        features={['supplierProducts']}
          actionOnPermission={handleClick}
        >
          {(action) => (
            <ButtonModern isLoading={isLoading} size={'small'} styleType={'select'} onClick={action} className={className}>
              <FontAwesomeIcon icon={faPlus} />
              {polyglot.t('ebvIntegration.button')}
            </ButtonModern>
          )}
        </AddonManager>
      ) : (
        <AddonManager
          features={['supplierProducts']}
          actionOnPermission={handleClick}
        >
          {(action) => (
            <Button styleType="add" onClick={action} className={className} isLoading={isShownAsModern ? false : isLoading}>
              {polyglot.t('ebvIntegration.button')}
            </Button>
          )}

        </AddonManager>
      )}
    </>
  );
};

EbvIntegration.propTypes = {
  vehicleId: PropTypes.string.isRequired,
  createEbvOrder: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  isEBVActive: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  orderId: PropTypes.string,
  isShownAsModern: PropTypes.bool,
};

EbvIntegration.defaultProps = {
  orderId: undefined,
  isShownAsModern: false,
};

export default EbvIntegration;
