import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import polyglot from 'services/localization';
import { getSortedMileages } from 'shared-library/src/services/vehicleService';
import MileageCell from 'components/common/mileageCell';
import MileageModal from 'components/common/mileageModal';
import LinkButton from 'components/common/linkButton';
import FormValidator from 'helpers/formValidation/formValidator';

import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faPen } from 'fontawesome/pro-solid-svg-icons';
import styles from './editMileages.styles.pcss';
import ButtonModern from '../buttonModern';

export default class EditMileages extends PureComponent {
  static propTypes = {
    vehicle: PropTypes.shape({
      _id: PropTypes.string,
      mileage: PropTypes.array,
      dateOfFirstRegistration: PropTypes.string,
    }).isRequired,
    onSave: PropTypes.func,
    onRemove: PropTypes.func,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    isShowLinkInsteadOfButton: PropTypes.bool,
    hideHeader: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    formValidator: FormValidator.getShape(),
    focusOnCloseInputRef: PropTypes.shape({
      current: PropTypes.shape({
        inputRef: PropTypes.shape({
          current: PropTypes.shape({
            focus: PropTypes.func,
          }),
        }),
      }),
    }),
    dataTest: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    tabIndex: 0,
    isShowLinkInsteadOfButton: false,
    hideHeader: false,
    disabled: false,
    formValidator: null,
    onSave: () => {},
    onRemove: () => {},
    focusOnCloseInputRef: null,
    size: 'default',
    dataTest: 'editMileages',
  };

  state = {
    isUpdateMileagesOpen: false,
  };

  onToggleUpdateMileages = () => {
    const { disabled } = this.props;
    if (disabled) return;
    const { isUpdateMileagesOpen } = this.state;
    if (isUpdateMileagesOpen) {
      this.focusRefOnClose();
    }
    this.setState((prevState) => ({ isUpdateMileagesOpen: !prevState.isUpdateMileagesOpen }));
  };

  focusRefOnClose = () => {
    const { focusOnCloseInputRef } = this.props;
    if (focusOnCloseInputRef
    && focusOnCloseInputRef.current
    && focusOnCloseInputRef.current.inputRef
    && focusOnCloseInputRef.current.inputRef.current) {
      focusOnCloseInputRef.current.inputRef.current.focus();
    }
  };

  onRemove = (mileages) => {
    const { onRemove, formValidator } = this.props;
    if (formValidator) formValidator.set(mileages);
    onRemove(mileages);
  };

  onSave = (mileages) => {
    const { onSave, formValidator } = this.props;
    if (formValidator) formValidator.set(mileages);
    onSave(mileages);
  };

  render() {
    const {
      className,
      vehicle,
      tabIndex,
      isShowLinkInsteadOfButton,
      hideHeader,
      disabled,
      formValidator,
      size,
      dataTest,
    } = this.props;
    const { isUpdateMileagesOpen } = this.state;

    const currentMileages = formValidator ? formValidator.value : vehicle.mileage;

    const lastMileage = _.first(getSortedMileages(currentMileages));

    return (
      <Fragment>
        <div data-test={dataTest} className={cx(styles.editMileages, className)}>
          {!hideHeader && (
          <div className={styles.editMileagesLabel}>
            <div className={styles.editMileagesLabelText}>
              {polyglot.t('editVehicle.form.mileage.title')}
            </div>
          </div>
          )}
          {isShowLinkInsteadOfButton ? (
            <LinkButton
              onClick={() => this.onToggleUpdateMileages()}
              type={LinkButton.BUTTON_TYPES.EDIT}
              disabled={disabled}
            >
              {lastMileage ? (
                <MileageCell
                  value={lastMileage.value}
                  operatingHours={lastMileage.operatingHours}
                />
              ) : (
                '---'
              )}
            </LinkButton>
          ) : (
            <ButtonModern
              className={styles.editMileagesButton}
              tabIndex={tabIndex}
              onClick={() => this.onToggleUpdateMileages()}
              styleType="select"
              size={size}
            >
              {lastMileage ? (
                <>
                  <MileageCell
                    value={lastMileage.value}
                    operatingHours={lastMileage.operatingHours}
                  />
                </>
              ) : (
                <div>
                  {polyglot.t('actions.add')}
                </div>
              )}
              <FontAwesomeIcon icon={faPen} />
            </ButtonModern>
          )}
        </div>

        <MileageModal
          isOpen={isUpdateMileagesOpen}
          mileage={currentMileages || []}
          onCancel={this.onToggleUpdateMileages}
          onSave={this.onSave}
          onRemove={this.onRemove}
          dateOfFirstRegistration={vehicle.dateOfFirstRegistration}
        />
      </Fragment>
    );
  }
}
