import * as apiClient from 'resources/api.client';

export function fetchWorkers(garageId) {
  return apiClient.get('workers', { garageId });
}

export function fetchWorker(garageId, workerId) {
  return apiClient.get(`workers/${workerId}`, { garageId });
}

export function createWorker(garageId, data) {
  return apiClient.post('workers', { garageId }, data);
}

export function removeWorker(garageId, data) {
  return apiClient.post('workers/remove', { garageId }, data);
}

export function updateWorker(garageId, data) {
  return apiClient.post(`workers/update/${data._id}`, { garageId }, data);
}
