import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Document, Page } from 'components/pdf';

import Loading from 'components/common/loading/components/tableLoading/tableLoading';
import { carClasses } from 'shared-library/src/definitions/carClasses';
import { getManufacturers } from 'resources/manufacturers/manufacturers.selectors';
import FullImageViewModal from 'components/common/fullImageView';
import RadioButtonModern from 'components/common/radioButtonModern';
import {
  createClientWithDocument,
  createClientWithDocumentConfirmation, fetchClient,
} from 'resources/clients/clients.actions';
import polyglot from 'services/localization';
import { fetchManufacturers } from 'resources/manufacturers/manufacturers.actions';
import { toUTCDay } from 'helpers/date';

import modalStyles from 'styles/modal.pcss';
import toastService from 'helpers/toastService';
import parseError from 'helpers/parseError';
import ClientForm from 'components/common/clientForm';
import VehicleForm from 'components/common/vehicleForm';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import {
  faPlus,
  faUser,
  faCar,
  faAngleLeft,
  faFolderArrowUp,
} from 'fontawesome/pro-solid-svg-icons';
import Icon from 'components/common/icon/Icon';
import ResponsiveModal from 'components/common/responsiveModal';
import DropZone from 'components/common/dropZone/dropZone';
import ButtonModern from 'components/common/buttonModern';
import { fetchSameClients } from 'resources/clients/clients.api';
import * as fromUser from 'resources/user/user.selectors';
import OptionPicker from 'components/common/optionPicker';
import { isISODate } from 'helpers/regexp';
import AddonManager from 'components/common/addonManager';
import ImageEditor from 'components/common/imageEditor';
import withDeviceInfo from 'components/higherOrderComponents/withDeviceInfo';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import InteractableDiv from 'components/common/interactableDiv';
import { isFileSizeValid } from 'shared-library/src/definitions/contentTypes';
import styles from './createClientWithDocumentModal.styles.pcss';

const compareManufacturer = (el1, el2) => {
  if (el1.priority === el2.priority) {
    return el1.key < el2.key ? -1 : 1;
  }
  return (el2.priority - el1.priority);
};

class CreateWithDocumentModal extends PureComponent {
  static propTypes = {
    createClientWithDocumentAction: PropTypes.func.isRequired,
    createClientWithDocumentConfirmationAction: PropTypes.func.isRequired,
    manufacturers: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchManufacturersAction: PropTypes.func.isRequired,
    fetchClientAction: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
    }).isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    clientId: PropTypes.string,
    garageId: PropTypes.string.isRequired,
    isMobileSize: PropTypes.bool,
    isMobileDevice: PropTypes.bool,
    onSuccess: PropTypes.func,
    isRoutingDisabled: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: false,
    onClose: () => {},
    clientId: null,
    isMobileSize: false,
    isMobileDevice: false,
    onSuccess: () => {},
    isRoutingDisabled: false,
  };

  static validFormats = ['.gif', '.png', '.jpeg', '.jpg', '.pdf'];

  static maxPage = 2;

  getInitialState = () => {
    return {
      isFullImageViewOpen: false,
      isConfirmModalOpen: false,
      isProcessing: false,
      isProcessingFinished: false,
      isClientSubmited: false,
      hasPdf: false,
      pdfFile: '',
      pdfLoaded: false,
      image: {},
      client: {
        formOfAddress: '',
        title: '',
        firstName: '',
        lastName: '',
        toAttention: '',
        phone: '',
        email: '',
        address: '',
        zipCode: '',
        city: '',
        country: '',
        UIDNumber: '',
        additionalInfo: '',
        dateOfBirthday: '',
        printAdditionalInfo: false,
      },
      vehicle: {
        client: {},
        model: '',
        license: '',
        mileage: [],
        identificationNumber: '',
        nationalCode: '',
        documentNumberCh: '',
        baseNumberCh: '',
        engineCode: '',
        colorCode: '',
        typeOfTransmission: '',
        engineCategory: '',
        customField: '',
        additionalInfo: '',
        manufacturer: '',
        carClass: '',
        images: [],
        dateOfFirstRegistration: '',
        dateOfLastRegistration: '',
        acceptanceDate: '',
        dateOfNextInspection: '',
        enginePower: null,
        ownWeight: null,
        maxWeight: null,
        engineDisplacement: null,
        HSNCode: '',
        TSNCode: '',
      },
      page: 0, // Indicates whether to show clientForm (0), mergeForm (1) or vehicleForm(2)
      isSubmitting: false,
      files: [],
      selectedClient: null,
      similarClients: [],
      diffFields: {},
      selectedDiffFields: {},
      fileToEdit: null,
      editedImage: null,
      isImageEditorProcessing: false,
    };
  };

  constructor(props) {
    super(props);

    this.dropZoneRef = React.createRef();

    this.state = this.getInitialState();
  }

  componentDidMount = () => {
    this.fetchInitialData();
  };

  componentDidUpdate(prevProps) {
    const { isOpen, clientId } = this.props;
    if (
      (!prevProps.isOpen && isOpen)
      || (prevProps.clientId !== clientId)
    ) {
      this.fetchInitialData();
    }
  }

  fetchInitialData() {
    const {
      manufacturers,
      fetchManufacturersAction,
      clientId,
      fetchClientAction,
      isOpen,
    } = this.props;

    if (!isOpen) return;

    if (!manufacturers.length) {
      fetchManufacturersAction();
    }
    const carClassesMap = carClasses.map((carClass) => { return { key: carClass, label: `${carClass} | ${polyglot.t(`carClass.${carClass}`)}` }; });
    this.setState({ carClasses: carClassesMap });

    if (clientId) {
      fetchClientAction(clientId).then((result) => {
        this.setState({ client: { ...result.payload } });
      });
    }
  }

  onChange = (field, changedField) => {
    this.setState((state) => ({
      [field]: {
        ...state[field],
        ...changedField,
      },
    }));
  };

  onSubmit = (values, formValidator) => {
    const { createClientWithDocumentConfirmationAction, history, isRoutingDisabled } = this.props;
    const { image, page, client } = this.state;

    const vehicle = values;
    const isLastPage = page === 2;

    if (isLastPage) {
      this.setState({ isSubmitting: true });
      vehicle.client = client;
      vehicle.images = [image];
      return createClientWithDocumentConfirmationAction({ client, vehicle, image })
        .then((res) => {
          this.onCloseHandler(false, res.vehicle);
          if (!isRoutingDisabled) {
            if (client._id) {
              history.push(`/vehicles/all/${res.vehicle._id}`);
            } else {
              history.push(`/clients/all/${res.client._id}`);
            }
          }
        })
        .catch((err) => {
          // Check if it is "image has already been removed"-error
          try {
            const errObject = JSON.parse(err.message);
            if (errObject && errObject.status === 404 && errObject.err === 'fileNotFound') {
              this.setState({ isSubmitting: false });
              this.onCloseHandler();
              if (!isRoutingDisabled) { history.push(`/clients/all/${client._id}`); }
              return null;
            }
          } catch (x) {
            // Got different error
          }
          this.setState({ isSubmitting: false });
          throw err;
        });
    }
    // Update client in state after submitting the client form
    this.setState((prevState) => ({ client: { ...prevState.client, ...values } }));
    this.goToPage(2);
    return null;
  };

  getResultScore = (client, vehicle) => {
    let score = 0;

    if (client.zipCode && client.zipCode.length) {
      score += 5;
    }
    if (client.city && client.city.length) {
      score += 5;
    }
    if (client.dateOfBirthday && client.dateOfBirthday.length) {
      score += 2;
    }
    if (client.firstName && client.firstName.length) {
      score += 5;
    }
    if (client.lastName && client.lastName.length) {
      score += 5;
    }

    if (vehicle.identificationNumber && vehicle.identificationNumber.length) {
      score += 2;
    }
    if (vehicle.nationalCode && vehicle.nationalCode.length) {
      score += 2;
    }
    if (vehicle.baseNumberCh && vehicle.baseNumberCh.length) {
      score += 2;
    }
    if (vehicle.documentNumberCh && vehicle.documentNumberCh.length) {
      score += 2;
    }
    if (vehicle.acceptanceDate && vehicle.acceptanceDate.length) {
      score += 2;
    }
    if (vehicle.dateOfFirstRegistration && vehicle.dateOfFirstRegistration.length) {
      score += 4;
    }
    if (vehicle.dateOfLastRegistration && vehicle.dateOfLastRegistration.length) {
      score += 4;
    }
    if (vehicle.enginePower) {
      score += 3;
    }
    if (vehicle.ownWeight) {
      score += 3;
    }
    if (vehicle.engineDisplacement) {
      score += 2;
    }
    if (vehicle.HSNCode && vehicle.HSNCode.length) {
      score += 5;
    }
    if (vehicle.TSNCode && vehicle.TSNCode.length) {
      score += 5;
    }

    return score;
  };

  setSelectedClient = (selectedClient) => {
    const { diffFields } = this.state;
    this.setState({ selectedClient, diffFields: selectedClient ? diffFields : {} });
  };

  loadSimilarClients = async (client) => {
    const { garageId } = this.props;
    const { selectedClient } = this.state;
    let similarClients = [];
    try {
      similarClients = (await fetchSameClients(garageId, { ...client, pageSize: 5 })).results;
      if (selectedClient && !similarClients.find((cli) => cli._id === selectedClient)) {
        this.setSelectedClient(null);
      }
    } catch (x) {
      // Search failed
    }
    this.setState({ similarClients });
    return similarClients;
  };

  onEdit = async (file) => {
    if (!isFileSizeValid(file.size)) {
      toastService.showError(polyglot.t('addImageModal.fileSizeTooLarge'));
      return;
    }
    if (file && file.type === 'application/pdf') {
      this.setState({ hasPdf: true, pdfFile: file });
    } else {
      this.setState({ hasPdf: false, pdfFile: '', fileToEdit: file });
    }
  };

  onUpload = async (file) => {
    const { createClientWithDocumentAction } = this.props;
    const clientFromId = this.state.client;
    this.setState({ isProcessing: true, isProcessingFinished: false, page: 0 });
    if (file && file.type === 'application/pdf') {
      this.setState({ hasPdf: true, pdfFile: file });
    } else {
      try {
        const {
          client, vehicle, image, warning,
        } = await createClientWithDocumentAction(file);
        const score = this.getResultScore(client, vehicle);
        if (score < 25) {
          toastService.showWarning(polyglot.t('createClientWithDocument.badResults'));
        }
        if (warning) {
          toastService.showWarning(warning);
        }
        if (clientFromId._id) {
          this.setState({
            vehicle: {
              ...vehicle,
              manufacturer: this.getManufacturer(vehicle).key,
            },
            image,
            hasPdf: false,
            pdfFile: '',
            isProcessing: false,
            isProcessingFinished: true,
            page: 2,
          });
        } else {
          const similarClients = await this.loadSimilarClients(client);
          this.setState({
            client,
            vehicle: {
              ...vehicle,
              manufacturer: this.getManufacturer(vehicle).key,
            },
            image,
            hasPdf: false,
            pdfFile: '',
            isProcessing: false,
            isProcessingFinished: true,
            similarClients,
          });
        }
      } catch (e) {
        this.setState({ isProcessing: false, isProcessingFinished: false });
        const err = parseError(e);
        if (err) {
          toastService.showError(err);
        } else if (e.error && e.error.message) {
          toastService.showError(`${polyglot.t('createClientWithDocument.ocrError')}: ${e.error.message}`);
        } else {
          toastService.showError(polyglot.t('createClientWithDocument.unknownError'));
        }
      }
    }
  };

  onPdfLoaded = () => {
    const { pdfFile } = this.state;
    const c = this.getCanvas();
    if (c !== null) {
      const convertedPngFile = this.dataURLtoFile(c.toDataURL('image/jpeg'), `${pdfFile.name.split('.pdf')[0]}.jpg`);
      this.onEdit(convertedPngFile);
    }
  };

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  onCloseFullImageViewModal = () => {
    this.setState({ isFullImageViewOpen: false });
  };

  onOpenFullImageViewModal = () => {
    this.setState({ isFullImageViewOpen: true });
  };

  getManufacturer = ({
    manufacturer,
    HSNCode,
    identificationNumber,
  }) => {
    const { manufacturers } = this.props;
    const isManufacturerSet = manufacturer && manufacturer !== 'Sonstiger Hersteller';

    if (!isManufacturerSet) {
      if (HSNCode.length === 4) {
        return manufacturers.find(({ hsn = [] }) => hsn.includes(HSNCode)) || {};
      }
      if (identificationNumber.length >= 6) {
        return manufacturers.find(({ vinStart = [] }) => (
          vinStart.includes(identificationNumber)
          || vinStart.includes(identificationNumber.slice(0, 3)))) || {};
      }
    }

    return {
      key: manufacturer,
    };
  };

  goToPage = (pageToGo) => {
    this.setState({ page: Math.max(Math.min(pageToGo, CreateWithDocumentModal.maxPage), 0) });
  };

  getCurrentTitle = () => {
    const { clientId } = this.props;
    const {
      page,
      isProcessing,
      isProcessingFinished,
      diffFields,
      client,
      fileToEdit,
    } = this.state;

    let title;
    let showBack = true;
    let onBackClicked = () => {};

    switch (page) {
      case 0:
        if (isProcessing) {
          title = 'isProcessing';
          showBack = false;
        } else if (!isProcessingFinished) {
          title = 'recognizeRegistration';
          if (!fileToEdit) {
            showBack = false;
          }
          onBackClicked = () => this.setState(this.getInitialState());
        } else {
          title = 'checkCustomerData';
          onBackClicked = () => this.setState(this.getInitialState());
        }
        break;
      case 1:
        title = 'selectCustomerData';
        onBackClicked = () => {
          this.setState({ client: { ...client, externalId: null, _id: null } });
          this.goToPage(0);
        };
        break;
      case 2:
        title = 'checkVehicleRegistration';
        if (clientId) showBack = false;
        onBackClicked = () => {
          this.setState({ client: { ...client, externalId: null, _id: null } });
          this.loadSimilarClients(client);
          this.goToPage(Object.keys(diffFields).length < 1 ? 0 : 1);
        };
        break;
      default:
        title = '';
        break;
    }

    return (
      <div className={styles.title}>
        {showBack && (
          <FontAwesomeIcon
            className={styles.titleIcon}
            icon={faAngleLeft}
            onClick={onBackClicked}
          />
        )}
        {polyglot.t(`createClientWithDocument.titles.${title}`)}
      </div>
    );
  };

  onCompareClients = () => {
    const {
      clientFormRef,
      similarClients,
      selectedClient,
    } = this.state;

    const formClient = clientFormRef.getValues();
    const selClient = similarClients.find((cli) => cli._id === selectedClient);
    const diffFields = {};
    const selectedDiffFields = {};
    Object.keys(formClient).forEach((key) => {
      if (
        formClient[key]
      ) {
        let formClientVal = formClient[key];
        let selClientVal = selClient[key];
        if (isISODate(selClientVal)) {
          selClientVal = toUTCDay(new Date(selClientVal));
        }
        if (isISODate(formClientVal)) {
          formClientVal = toUTCDay(new Date(formClientVal));
        }

        if (
          formClientVal !== selClientVal
          && clientFormRef.group[key]
          && !clientFormRef.group[key].hasError()
        ) {
          diffFields[key] = { existing: selClientVal, new: formClient[key] };
          selectedDiffFields[key] = 'new';
        }
      }
    });
    if (Object.keys(diffFields).length < 1) {
      this.setState({ diffFields: {}, client: { ...selClient } });
      this.goToPage(2);
    } else {
      this.setState({ diffFields, selectedDiffFields });
      this.goToPage(1);
    }
  };

  onMergeClientData = () => {
    const {
      selectedClient,
      similarClients,
      diffFields,
      selectedDiffFields,
    } = this.state;

    const selClient = similarClients.find((cli) => cli._id === selectedClient);
    Object.keys(selectedDiffFields).forEach((key) => {
      if (selectedDiffFields[key] === 'new') {
        selClient[key] = diffFields[key].new;
      }
    });

    this.setState({ client: { ...selClient } });
    this.goToPage(2);
  };

  renderFilepicker = () => {
    const {
      isMobileDevice,
    } = this.props;

    const {
      files,
      hasPdf,
    } = this.state;
    if (hasPdf) return <Loading withoutBorder />;
    if (isMobileDevice) {
      return (
        <div className={styles.mobileDropZoneContainer}>
          <div className={styles.mobileDropZoneWidth}>
            <DropZone
              removeDefaultStyle
              disableMultiple
              fileExplorerEnabled
              onFilesChanged={(selectedFiles) => this.onEdit(selectedFiles[0])}
              onStatusChanged={(status) => this.setState({ dropZoneStatus: status })}
              returnAs="file"
              accept="image/*"
            >
              <div
              className={cx(
                styles.mobileDropZoneContent,
                styles.mobileDropZoneUploadPhotoContainer,
              )}>
                <FontAwesomeIcon
                  icon={faFolderArrowUp}
                  className={styles.mobileDropZoneUploadIcon}
                />
                <div className={styles.mobileDropZoneHeading}>
                  {polyglot.t('createClientWithDocument.titles.uploadPhoto')}
                </div>
                <div className={styles.mobileDropZoneDescription}>
                  {CreateWithDocumentModal.validFormats.map((el, i) => {
                    return (
                      <span key={i}>
                        {` ${el} `}
                      </span>
                    );
                  })}
                </div>
              </div>
            </DropZone>
          </div>
          <div className={styles.mobileDropZoneWidth}>
            <DropZone
              removeDefaultStyle
              disableMultiple
              capture={'environment'}
              fileExplorerEnabled
              onFilesChanged={(selectedFiles) => this.onEdit(selectedFiles[0])}
              onStatusChanged={(status) => this.setState({ dropZoneStatus: status })}
              returnAs="file"
              accept="image/*"
            >
              <div className={cx(
                styles.mobileDropZoneContent,
                styles.mobileDropZoneTakePhotoContainer,
              )}>
                <i className={cx('fak fa-ocr', styles.mobileDropZoneTakePhotoIcon)} />
                <div className={styles.mobileDropZoneHeading}>
                  {polyglot.t('createClientWithDocument.titles.takePhoto')}
                </div>
              </div>
            </DropZone>
          </div>
        </div>
      );
    }

    return (
      <DropZone
        files={files}
        disableMultiple
        fileExplorerEnabled
        acceptedTypes={CreateWithDocumentModal.validFormats}
        returnAs="file"
        onFilesChanged={(selectedFiles) => this.onEdit(selectedFiles[0])}
        onStatusChanged={(status) => this.setState({ dropZoneStatus: status })}
        className={styles.uploadViewDropZone}
        ref={this.dropZoneRef}
      >
        <div className={styles.uploadViewDropZoneContent}>
          <Icon type="registrationIcon" />
          <div className={styles.uploadViewDropZoneContentMain}>
            {polyglot.t('createClientWithDocument.emptyFormText.dropZoneTitle')}
          </div>
          <div>
            {polyglot.t('createClientWithDocument.emptyFormText.dropZoneAllowedFormats')}
          </div>
          <div>
            {CreateWithDocumentModal.validFormats.map((el, i) => {
              return (
                <span key={i}>
                  {` ${el} `}
                </span>
              );
            })}
          </div>
        </div>
      </DropZone>
    );
  };

  renderFormButtons = (form) => {
    const { isSubmitting, page, selectedClient } = this.state;
    const isLastPage = page === 2;

    return (
      <div className={cx(modalStyles.footerModern, styles.footerMargin)}>
        {!isLastPage && (
          <ButtonModern
            styleType="add"
            className={styles.actionButtonsButton}
            onClick={() => {
              if (selectedClient) {
                this.onCompareClients();
              } else {
                form.submit();
              }
            }}
          >
            <>
              <FontAwesomeIcon
                icon={selectedClient ? faUser : faPlus}
                className={styles.buttonIcon}
              />
              {selectedClient
                ? polyglot.t('createClientWithDocument.selectExisting')
                : polyglot.t('createClientWithDocument.createNew')}
            </>
          </ButtonModern>
        )}
        {isLastPage && (
          <ButtonModern
            onClick={() => form.submit()}
            disabled={isSubmitting}
            styleType="add"
            className={styles.actionButtonsButton}
          >
            <>
              <FontAwesomeIcon
                icon={faCar}
                className={styles.buttonIcon}
              />
              {polyglot.t('actions.saveVehicle')}
            </>
          </ButtonModern>
        )}
        <ButtonModern
          styleType="cancel"
          onClick={() => this.onCloseHandler(true)}
        >
          {polyglot.t('actions.cancel')}
        </ButtonModern>
      </div>
    );
  };

  renderUploadView() {
    const {
      fileToEdit,
      editedImage,
      isImageEditorProcessing,
    } = this.state;
    const { isMobileDevice } = this.props;

    if (fileToEdit) {
      return (
        <div style={{ width: '100%' }}>
          <ImageEditor
            className={styles.imageEditor}
            editEnabled
            rotateEnabled
            // zoomEnabled // TEST
            // hasZoomButtons // TEST
            file={fileToEdit}
            infoText={polyglot.t('createClientWithDocument.imageEditor.info')}
            onImageChanged={(edited) => this.setState({ editedImage: edited })}
            onProcessingChanged={
              (isProcessing) => this.setState({ isImageEditorProcessing: isProcessing })
            }
            autoHeight
          />
          <div className={cx(modalStyles.footerModern, styles.footerMargin)}>
            <ButtonModern
              onClick={async () => {
                const blob = await fetch(editedImage).then((r) => r.blob());
                const fileToUpload = new File([blob], fileToEdit.name, { type: fileToEdit.type });
                this.onUpload(fileToUpload);
              }}
              styleType="add"
              isLoading={isImageEditorProcessing}
            >
              {polyglot.t('createClientWithDocument.imageEditor.startOcr')}
            </ButtonModern>
            <ButtonModern
              styleType="cancel"
              onClick={() => this.onCloseHandler(true)}
            >
              {polyglot.t('actions.cancel')}
            </ButtonModern>
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.renderImagePreview(true)}
        <div>
          <div className={styles.uploadViewHeader}>
            {polyglot.t('createClientWithDocument.emptyFormText.text')}
          </div>
          <div className={styles.uploadViewInfo}>
            <div>
              <span>
                1.
              </span>
              {polyglot.t('createClientWithDocument.emptyFormText.hint1')}
            </div>
            <div>
              <span>
                2.
              </span>
              {polyglot.t('createClientWithDocument.emptyFormText.hint2')}
            </div>
            <div>
              <span>
                3.
              </span>
              {polyglot.t('createClientWithDocument.emptyFormText.hint3')}
            </div>
          </div>
          {this.renderFilepicker()}
          <div className={cx(modalStyles.footerModern, styles.footerMargin)}>
            {!isMobileDevice && (
              <ButtonModern
              onClick={() => this.dropZoneRef.current.filePickerRef.current.click()}
              styleType="add"
              >
                {polyglot.t('createClientWithDocument.emptyFormText.uploadRegistration')}
              </ButtonModern>
            )}
            <ButtonModern
              styleType="cancel"
              onClick={() => this.onCloseHandler(true)}
            >
              {polyglot.t('actions.cancel')}
            </ButtonModern>
          </div>
        </div>
      </div>
    );
  }

  renderCustomerList = () => {
    const { isMobileSize } = this.props;
    const { selectedClient, similarClients } = this.state;

    return (
      <div className={styles.customerList}>
        <InteractableDiv
          className={cx(
            styles.customerListEntry,
            selectedClient === null && styles.customerListEntryActive,
          )}
          onClick={() => this.setSelectedClient(null)}
        >
          <RadioButtonModern
            className={styles.diffBlockSettingsCheckbox}
            id="new"
            checked={selectedClient === null}
            label=""
          />
          <div className={styles.customerListEntryContentHeader}>
            <div>
              {polyglot.t('createClientWithDocument.createNewCustomer')}
            </div>
          </div>
        </InteractableDiv>
        {similarClients.map((client) => {
          const {
            _id,
            firstName,
            lastName,
            dateOfBirthday,
            zipCode,
            city,
            address,
            phone,
            mobilePhone,
            email,
          } = client;
          return (
            <InteractableDiv
              key={_id}
              className={cx(
                styles.customerListEntry,
                selectedClient === _id && styles.customerListEntryActive,
              )}
              onClick={() => this.setSelectedClient(_id)}
            >
              <RadioButtonModern
                className={styles.diffBlockSettingsCheckbox}
                value={_id}
                onChange={({ target }) => this.setSelectedClient(target.value)}
                checked={selectedClient === _id}
                label=""
              />
              <div className={styles.customerListEntryContent}>
                <div>
                  {`${firstName} ${lastName}`}
                </div>
                <div>
                  {dateOfBirthday ? toUTCDay(dateOfBirthday) : ''}
                </div>
                {isMobileSize ? (
                  <>
                    <div>
                      {phone || mobilePhone}
                    </div>
                    <div>
                      {address}
                    </div>
                    <div>
                      {zipCode}
                      {' '}
                      {city}
                    </div>
                    <div>
                      {email}
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {zipCode}
                    </div>
                    <div>
                      {city}
                    </div>
                    <div>
                      {address}
                    </div>
                    <div>
                      {phone || mobilePhone}
                    </div>
                    <div>
                      {email}
                    </div>
                  </>
                )}
              </div>
            </InteractableDiv>
          );
        })}
      </div>
    );
  };

  renderImagePreview(hidden = false) {
    const {
      image,
      pdfFile,
      hasPdf,
    } = this.state;
    return (
      <div
        className={cx(styles.paper, styles.paperFlex, styles.dropStyles)}
        style={hidden ? {
          visibility: 'hidden', height: '0', width: '0', margin: '0',
        } : { minHeight: '482px' }}
      >
        {!hasPdf && image.url && (
          <ImageEditor
            className={styles.imageEditor}
            style={{ height: '100%' }}
            src={image.url}
            zoomEnabled
            hasZoomButtons
          />
        )}
        {hasPdf && (
        <Document
          className={styles.paperPicture}
          file={pdfFile}
        >
          <Page
            className={styles.paperPicture}
            key="page_1"
            pageNumber={1}
            scale={2}
            renderTextLayer={false}
            onRenderSuccess={() => {
              this.onPdfLoaded();
            }}
          />
          <span />
        </Document>
        )}
      </div>
    );
  }

  renderClientView() {
    const {
      client,
      clientFormRef,
      similarClients,
    } = this.state;

    return (
      <div className={styles.clientView}>
        <div className={styles.clientViewFormAndImage}>
          <ClientForm
            className={styles.clientViewFormAndImageForm}
            client={client}
            onSubmit={this.onSubmit}
            searchOnBlur={(cli) => this.loadSimilarClients(cli)}
            isTwoColumnLayout
            formRef={(ref) => this.setState({ clientFormRef: ref })}
          />
          {this.renderImagePreview()}
        </div>
        {!!similarClients.length && (
          <div className={styles.alreadyExists}>
            <div className={styles.alreadyExistsTitle}>
              {polyglot.t('createClientWithDocument.doesCustomerAlreadyExist')}
            </div>
            <div className={styles.alreadyExistsDescription}>
              {polyglot.t('createClientWithDocument.selectOrCreateCustomer')}
            </div>
            <div>
              {this.renderCustomerList()}
            </div>
          </div>
        )}
        {this.renderFormButtons(clientFormRef)}
      </div>
    );
  }

  renderCompareView() {
    const {
      diffFields,
      selectedDiffFields,
    } = this.state;

    const selectedDiffs = { ...selectedDiffFields };

    const diffFieldKeys = Object.keys(diffFields);
    if (diffFieldKeys.length < 1) {
      return null;
    }
    return (
      <div className={styles.compareView}>
        <div className={styles.compareViewText}>
          <div>
            {polyglot.t('createClientWithDocument.selectCustomerData.dataNotMatching')}
          </div>
          <div>
            {polyglot.t('createClientWithDocument.selectCustomerData.pickCorrectData')}
          </div>
        </div>
        <div className={styles.compareViewOptionsAndPreview}>
          <div className={styles.compareViewOptions}>
            <div className={styles.compareViewOptionsTitle}>
              <div>
                {polyglot.t('createClientWithDocument.selectCustomerData.newData')}
              </div>
              <div>
                {polyglot.t('createClientWithDocument.selectCustomerData.existingData')}
              </div>
            </div>
            {diffFieldKeys.map((key, i) => {
              const options = [
                { id: 'new', label: diffFields[key].new },
                { id: 'existing', label: diffFields[key].existing },
              ];
              return (
                <div key={`${key}-${i}`} className={styles.compareViewOption}>
                  <div>
                    {polyglot.t(`editClient.${key}`)}
                  </div>
                  <OptionPicker
                    height="36px"
                    width="100%"
                    modern
                    options={options}
                    onChange={(newType) => {
                      selectedDiffs[key] = newType;
                      this.setState({ selectedDiffFields: selectedDiffs });
                    }}
                    selectedOption={selectedDiffFields[key]}
                  />
                </div>
              );
            })}
          </div>
          {this.renderImagePreview()}
        </div>
        <div className={cx(modalStyles.footerModern, styles.footerMargin)}>
          <ButtonModern
            styleType="add"
            onClick={() => this.onMergeClientData()}
          >
            <>
              <FontAwesomeIcon
                icon={faUser}
                className={styles.buttonIcon}
              />
              {polyglot.t('actions.updateCustomer')}
            </>
          </ButtonModern>
          <ButtonModern
            styleType="cancel"
            onClick={() => this.onCloseHandler(true)}
          >
            {polyglot.t('actions.cancel')}
          </ButtonModern>
        </div>
      </div>
    );
  }

  renderVehicleView() {
    const {
      vehicle,
      client,
    } = this.state;

    const vehicleForForm = { ...vehicle, client };

    return (
      <div className={styles.vehicleFormWithImage}>
        <VehicleForm
          className={styles.vehicleForm}
          vehicle={vehicleForForm}
          client={client}
          onSubmit={this.onSubmit}
          renderButtons={this.renderFormButtons}
          isClientFieldShown={false}
        />
        {this.renderImagePreview()}
      </div>
    );
  }

  renderLoadingView = () => {
    const { editedImage } = this.state;
    return (
      <div className={styles.loading}>
        <div className={styles.loadingAnimContainer}>
          <div className={styles.loadingAnimAnimation}>
            <div className={styles.loadingAnimAnimationBrackets} />
            <div className={styles.loadingAnimAnimationBar} />
          </div>
          <div className={styles.loadingAnimImageContainer}>
            {editedImage && (<img alt="crop" className={styles.loadingAnimImage} src={editedImage} />)}
          </div>
        </div>
        <div className={styles.loadingAppContainer}>
          <div className={styles.loadingAppContainerHeader}>
            {polyglot.t('createClientWithDocument.getEasyWerkstattApp')}
          </div>
          <div className={styles.loadingAppContainerButtons}>
            <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.easywerkstatt.app.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img style={{ width: '165px' }} alt="Jetzt bei Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/at/app/easywerkstatt/id6446981811">
              <img style={{ width: '150px' }} alt="Jetzt bei Apple App Store" src={`${window.config.webUrl}/assets/iosDownload.png`} />
            </a>
          </div>
          {polyglot.t('createClientWithDocument.searchEasywerkstattInAppStore')}
        </div>
      </div>
    );
  };

  renderForm() {
    const {
      isProcessing,
      isProcessingFinished,
      page,
    } = this.state;

    if (isProcessing) {
      return this.renderLoadingView();
    }

    if (!isProcessingFinished) {
      return this.renderUploadView();
    }

    if (isProcessingFinished && page === 0) {
      return this.renderClientView();
    }
    if (page === 1) {
      return this.renderCompareView();
    }
    if (page === 2) {
      return this.renderVehicleView();
    }
    return null;
  }

  getCanvas = () => {
    const c = document.getElementsByTagName('canvas');
    if (c.length) {
      return c[0];
    }
    return null;
  };

  onCloseHandler = (isCancel = false, vehicle) => {
    const { onClose, onSuccess } = this.props;
    this.setState(this.getInitialState());
    if (vehicle?._id) {
      onSuccess(vehicle);
    }
    onClose(isCancel);
  };

  render() {
    const {
      isOpen,
      history,
    } = this.props;
    const {
      image,
      isFullImageViewOpen,
    } = this.state;

    return (
      <ResponsiveModal
        isOpen={isOpen}
        onClose={() => this.onCloseHandler(true)}
        title={this.getCurrentTitle()}
      >
        <AddonManager
          features={['ocr']}
          history={history}
          className={styles.addonManager}
        >
          <div>
            {this.renderForm()}
          </div>

          <FullImageViewModal
            url={image.url}
            isOpen={isFullImageViewOpen}
            onClose={this.onCloseFullImageViewModal}
            name={image.name}
          />
        </AddonManager>
      </ResponsiveModal>
    );
  }
}

export default compose(withDeviceInfo, withRouter, connect((state) => ({
  manufacturers: getManufacturers(state)
    .map(({ name, ...rest }) => ({ key: name, label: name, ...rest })).sort(compareManufacturer),
  garageId: fromUser.getCurrentGarageId(state),
}), {
  createClientWithDocumentAction: createClientWithDocument,
  createClientWithDocumentConfirmationAction: createClientWithDocumentConfirmation,
  fetchManufacturersAction: fetchManufacturers,
  fetchClientAction: fetchClient,
}))(CreateWithDocumentModal);
