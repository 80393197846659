const ACCOUNT_TYPES = ['test', 'paid'];
const PAYMENT_CYCLES = ['monthly', 'yearly'];
const SMALL_BUSINESS_PLAN = 'kleinunternehmer';
const SMALL_BUSINESS_PLAN_YEARLY = 'kleinunternehmer-jährlich';
const COMPLETE_PLAN = 'komplett';
const PREMIUM_PLUS_PLAN = 'premium-plus';
const BASIS_PLAN = 'basis';
const BASIS_PLAN_YEARLY = 'basis-jährlich';
const CREATION_FAILED = 'creation_failed';

const SMALL_BUSINESS_PLANS = [SMALL_BUSINESS_PLAN, SMALL_BUSINESS_PLAN_YEARLY];

const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'active',
  NON_RENEWING: 'non_renewing',
  PAST_DUE: 'past_due',
  TRANSITIONING: 'transitioning',
  IN_TRIAL: 'in_trial',
  TRIALING: 'trialing',
  CANCELLED: 'cancelled',
  UNPAID: 'unpaid',
};

const ACTIVE_STATUSES = [
  SUBSCRIPTION_STATUSES.IN_TRIAL,
  SUBSCRIPTION_STATUSES.TRIALING,
  SUBSCRIPTION_STATUSES.ACTIVE,
  SUBSCRIPTION_STATUSES.NON_RENEWING,
  SUBSCRIPTION_STATUSES.PAST_DUE,
  SUBSCRIPTION_STATUSES.TRANSITIONING,
  SUBSCRIPTION_STATUSES.UNPAID,
];
const TRIAL_STATUSES = [SUBSCRIPTION_STATUSES.IN_TRIAL, SUBSCRIPTION_STATUSES.TRIALING];
const ADDON_NAMES = {
  BOOK_KEEPING: 'book-keeping',
  CALENDAR: 'calendar',
  SUPPLIER_API: 'supplier-api',
  TIRE_STORAGE: 'tire-storeage',
  OCR: 'ocr',
  CERTIFICATE: 'secure-cash-register-at',
  FISKALY_CASH_REGISTER: 'fiskaly-cash-register',
  SDI: 'sdi',
};
const ADDONS = Object.values(ADDON_NAMES);
const ADDONS_WITHOUT_TRIAL = [
  ADDON_NAMES.FISKALY_CASH_REGISTER,
  ADDON_NAMES.CERTIFICATE,
  ADDON_NAMES.SDI,
];

module.exports = {
  ACCOUNT_TYPES,
  PAYMENT_CYCLES,
  SMALL_BUSINESS_PLAN,
  SMALL_BUSINESS_PLAN_YEARLY,
  SMALL_BUSINESS_PLANS,
  COMPLETE_PLAN,
  PREMIUM_PLUS_PLAN,
  BASIS_PLAN,
  BASIS_PLAN_YEARLY,
  ACTIVE_STATUSES,
  TRIAL_STATUSES,
  ADDONS,
  ADDON_NAMES,
  CREATION_FAILED,
  SUBSCRIPTION_STATUSES,
  ADDONS_WITHOUT_TRIAL,
};
