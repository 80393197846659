import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getRequiredFeaturesAndAddons } from 'helpers/featuresAndAddons';

import polyglot from 'services/localization';
import pageStyles from 'styles/page.pcss';

import AccessLimitation from './accessLimitation';
import ResponsiveModal from '../responsiveModal';

export default class AddonManager extends Component {
  static propTypes = {
    subscriptionData: PropTypes.shape({
      planId: PropTypes.string,
      status: PropTypes.string,
      addons: PropTypes.shape(),
    }),
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
    history: PropTypes.shape(),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    actionOnPermission: PropTypes.func,
    className: PropTypes.string,
    empty: PropTypes.bool,
    modalTitle: PropTypes.string,
    showAnyways: PropTypes.bool,
    onClose: PropTypes.bool,
    showAsModal: PropTypes.bool,
  };

  static defaultProps = {
    actionOnPermission: null,
    subscriptionData: {
      planId: '',
      status: '',
      addons: {},
    },
    className: '',
    empty: false,
    history: null,
    modalTitle: '',
    showAnyways: false,
    showAsModal: false,
    onClose: () => null,
  };

  state = {
    isAccessLimitationOpen: false,
  };

  onToggleAccessLimitation = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    this.setState((prevState) => ({ isAccessLimitationOpen: !prevState.isAccessLimitationOpen }));
  };

  render() {
    const {
      children,
      actionOnPermission,
      history,
      className,
      empty,
      showAnyways,
      features,
      subscriptionData,
      onClose,
      showAsModal,
      modalTitle,
    } = this.props;
    const { isAccessLimitationOpen } = this.state;

    const [requiredFeatures, addons] = getRequiredFeaturesAndAddons(features, subscriptionData);

    const isAllowed = !requiredFeatures.length || showAnyways;

    const accessLimitationInModal
      = <AccessLimitation features={requiredFeatures} addons={addons} history={history} />;

    if (!actionOnPermission) {
      return isAllowed ? (
        children
      ) : !empty && (
        <>
          {showAsModal ? (
            <ResponsiveModal
              isOpen
              onClose={onClose}
              title={modalTitle === '' ? polyglot.t('accessLimitation.title') : modalTitle}
            >
              {accessLimitationInModal}
            </ResponsiveModal>
          ) : (
            <AccessLimitation
              className={cx(pageStyles.pageContent, className)}
              features={requiredFeatures}
              addons={addons}
              history={history}
            />
          )}
        </>
      );
    }

    return (
      <Fragment>
        {children(isAllowed ? actionOnPermission : this.onToggleAccessLimitation)}
        <ResponsiveModal
          isOpen={isAccessLimitationOpen}
          onClose={(e) => { this.onToggleAccessLimitation(e); onClose(e); }}
          title={modalTitle === '' ? polyglot.t('accessLimitation.title') : modalTitle}
        >
          {accessLimitationInModal}
        </ResponsiveModal>
      </Fragment>
    );
  }
}
