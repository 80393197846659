import {
  faCheckCircle, faExclamationCircle, faTimesCircle,
} from 'fontawesome/pro-solid-svg-icons';
import {
  MdAcUnit as WinterTireIcon,
  MdWbSunny as SummerTireIcon,
  MdBrightness7 as SpikedTireIcon,
  MdSync as AllYearTireIcon,
} from 'react-icons/md';
import styles from '../components/vehicles/vehiclesList/components/tires/tires.styles.pcss';

export const getTreadDepthRegulationStatus = (selctedTires, garageCountry, customerCountry, vehicleCarClass = 'DEFAULT') => {
  const redOrangeTolerance = 2;

  const statusRed = { icon: faTimesCircle, iconClass: styles.iconRed, class: styles.status_red };

  const statusOrange = {
    icon: faExclamationCircle,
    iconClass: styles.iconOrange,
    class: styles.status_orange,
  };

  const statusGreen = {
    icon: faCheckCircle,
    iconClass: styles.iconGreen,
    class: styles.status_green,
  };

  const defaultVal = {
    SUMMER: { red: 1.6 },
    WINTER: { red: 1.6 },
    ALL: { red: 1.6 },
    SPIKED: { red: 4 },
  };

  // L1, L2
  const firstCarClassGroup = {
    AT: {
      SUMMER: { red: 1 },
      WINTER: { red: 4 },
      ALL: { red: 4 },
      SPIKED: { red: 4 },
    },
    DE: {
      SUMMER: { red: 1 },
      WINTER: { red: 4 },
      ALL: { red: 4 },
      SPIKED: { red: 4 },
    },
    DEFAULT: defaultVal,
  };

  // L3, L4, L5
  const secondCarClassGroup = {
    AT: {
      SUMMER: { red: 1.6 },
      WINTER: { red: 4 },
      ALL: { red: 4 },
      SPIKED: { red: 4 },
    },
    DEFAULT: defaultVal,
  };

  // L6, L7, M1, N1, O1
  const thirdCarClassGroup = {
    AT: {
      SUMMER: { red: 1.6 },
      WINTER: { red: 4 },
      ALL: { red: 4 },
      SPIKED: { red: 4 },
    },
    DEFAULT: defaultVal,
  };

  // M2, M3, N2, N3, O2, O3, O4
  const fourthCarClassGroup = {
    AT: {
      SUMMER: { red: 2 },
      WINTER: { red: 5 },
      ALL: { red: 4 },
      SPIKED: { red: 4 },
    },
    DEFAULT: defaultVal,
  };

  const depths = {
    L1: firstCarClassGroup,
    L2: secondCarClassGroup,
    L3: secondCarClassGroup,
    L4: secondCarClassGroup,
    L5: secondCarClassGroup,
    L6: thirdCarClassGroup,
    L7: thirdCarClassGroup,
    M1: thirdCarClassGroup,
    N1: thirdCarClassGroup,
    O1: thirdCarClassGroup,
    M2: fourthCarClassGroup,
    M3: fourthCarClassGroup,
    N2: fourthCarClassGroup,
    N3: fourthCarClassGroup,
    O2: fourthCarClassGroup,
    O3: fourthCarClassGroup,
    O4: fourthCarClassGroup,
    DEFAULT: secondCarClassGroup,
  };

  let country = customerCountry;
  let carClass = vehicleCarClass;
  if (!depths[carClass]) {
    carClass = 'DEFAULT';
  }
  if (!depths[carClass][country]) {
    country = depths[carClass][garageCountry] ? garageCountry : 'DEFAULT';
  }

  if (selctedTires.treadPatternDepth
    > depths[carClass][country][selctedTires.model.toUpperCase()].red) {
    if (depths[carClass][country][selctedTires.model.toUpperCase()].orange) {
      if (selctedTires.treadPatternDepth
         > depths[carClass][country][selctedTires.model.toUpperCase()].orange) {
        return statusGreen;
      }
    } else if (selctedTires.treadPatternDepth
      > depths[carClass][country][selctedTires.model.toUpperCase()].red
      + redOrangeTolerance) {
      return statusGreen;
    }
    return statusOrange;
  }
  return statusRed;
};

export const getTireIcon = (tire) => {
  let icon = SummerTireIcon;
  switch (tire?.backTires?.model) {
    case 'summer':
      icon = SummerTireIcon;
      break;
    case 'winter':
      icon = WinterTireIcon;
      break;
    case 'all':
      icon = AllYearTireIcon;
      break;
    case 'spiked':
      icon = SpikedTireIcon;
      break;
    default:
      break;
  }
  return icon;
};
