import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TableLoading from 'components/common/loading/components/tableLoading/tableLoading';
import Retry from 'components/common/retry';

import tableStyles from 'styles/table.pcss';

export default class InfiniteTableBody extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    onScroll: PropTypes.func.isRequired,
    onScrollBarWidthUpdated: PropTypes.func,
    className: PropTypes.string,
    isDataLoading: PropTypes.bool,
    setIsScrollLoading: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    children: null,
    isDataLoading: false,
    onScrollBarWidthUpdated: null,
    setIsScrollLoading: () => null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoadingOnScroll: false,
      isErrorOnScroll: false,
      scrollBarWidth: null,
    };

    this.tableBody = React.createRef();
  }

  componentDidMount() {
    this.checkScrollbarWidth();
    window.addEventListener('resize', this.checkScrollbarWidth);
  }

  componentDidUpdate() {
    this.checkScrollbarWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScrollbarWidth);
  }

  checkScrollbarWidth = () => {
    const { scrollBarWidth } = this.state;
    const { onScrollBarWidthUpdated } = this.props;

    if (!this.tableBody.current) return;

    const { offsetWidth, clientWidth } = this.tableBody.current;
    const currentScrollBarWidth = offsetWidth - clientWidth;
    if (
      typeof currentScrollBarWidth !== typeof scrollBarWidth
      || (
        currentScrollBarWidth < scrollBarWidth - 1 || currentScrollBarWidth > scrollBarWidth + 1
      )
    ) {
      this.setState({ scrollBarWidth: currentScrollBarWidth });
      if (onScrollBarWidthUpdated) onScrollBarWidthUpdated(currentScrollBarWidth);
    }
  };

  onScroll = () => {
    const { onScroll, setIsScrollLoading } = this.props;
    const { isLoadingOnScroll } = this.state;

    const { scrollTop, offsetHeight, scrollHeight } = this.tableBody.current;

    // when user uses zoom, browsers calculate scrollTop with error
    // that's why fetch if diffrent between scrollTop+offsetHeight and scrollHeight less when 5
    if (!(Math.abs((scrollTop + offsetHeight) - scrollHeight) <= 5)) {
      return;
    }

    if (!isLoadingOnScroll) {
      setIsScrollLoading(true);
      this.setState({ isLoadingOnScroll: true, isErrorOnScroll: false });
      onScroll()
        .then(() => {
          this.setState({ isLoadingOnScroll: false }); setIsScrollLoading(false);
        })
        .catch(() => {
          this.setState({ isLoadingOnScroll: false, isErrorOnScroll: true });
          setIsScrollLoading(false);
        });
    }
  };

  renderTableBodyContent = () => {
    const { isDataLoading, children } = this.props;
    const { isLoadingOnScroll, isErrorOnScroll } = this.state;

    if (isDataLoading) {
      return <TableLoading />;
    }

    return (
      <Fragment>
        {children}
        {isLoadingOnScroll && <TableLoading isScroll />}
        {!isLoadingOnScroll && isErrorOnScroll && <Retry onRetry={this.onScroll} isSmall />}
      </Fragment>
    );
  };

  render() {
    const { className } = this.props;
    const { isErrorOnScroll } = this.state;

    return (
      <tbody
        className={cx(tableStyles.tableBody, className)}
        onScroll={isErrorOnScroll ? () => {} : this.onScroll}
        ref={this.tableBody}
      >
        {this.renderTableBodyContent()}
      </tbody>
    );
  }
}
