const SUPPORTED_LOCALES = {
  DE_AT: 'de_AT',
  DE_CH: 'de_CH',
  DE_DE: 'de_DE',
  DE_IT: 'de_IT',
  EN_US: 'en_US',
  EN_GB: 'en_GB',
  IT_IT: 'it_IT',
  FR_FR: 'fr_FR',
};

const SUPPORTED_LOCALES_ARRAY = Object.values(SUPPORTED_LOCALES);

const SUPPORTED_SUPPORT_LOCALES_ARRAY = [
  SUPPORTED_LOCALES.DE_AT,
  SUPPORTED_LOCALES.DE_DE,
  SUPPORTED_LOCALES.DE_CH,
  SUPPORTED_LOCALES.DE_IT,
  SUPPORTED_LOCALES.IT_IT,
];

const DEFAULT_LOCALE = 'de_DE';

const mapLocaleToBase = (localeToMap) => {
  return SUPPORTED_LOCALES_ARRAY.includes(localeToMap) ? localeToMap.split('_')[0] : 'de';
};

const getValidEasyWKLocale = (localeToMap) => {
  if (!localeToMap) return DEFAULT_LOCALE;

  let localeToReturn = localeToMap;
  [localeToReturn] = localeToReturn.split(/[;,]/);
  if (localeToReturn === 'de') localeToReturn = 'de_DE';
  if (localeToReturn === 'it') localeToReturn = 'it_IT';
  if (localeToReturn === 'fr') localeToReturn = 'fr_FR';
  if (localeToReturn === 'en' || localeToReturn === 'en_GB') localeToReturn = 'en_US';
  return SUPPORTED_LOCALES_ARRAY.includes(localeToReturn) ? localeToReturn : DEFAULT_LOCALE;
};

const getClientLocale = ({ garage, invoice, client }) => {
  const invoiceLocale = invoice?.locale;
  const clientLocale = client?.locale;
  const garageLocale = garage?.pdfLocalDefault;

  return invoiceLocale || clientLocale || garageLocale || DEFAULT_LOCALE;
};

module.exports = {
  SUPPORTED_LOCALES,
  SUPPORTED_LOCALES_ARRAY,
  SUPPORTED_SUPPORT_LOCALES_ARRAY,
  DEFAULT_LOCALE,
  mapLocaleToBase,
  getValidEasyWKLocale,
  getClientLocale,
};
