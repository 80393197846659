import { connect } from 'react-redux';

import * as fromGarage from 'resources/garage/garage.selectors';
import { compose } from 'redux';
import withWindowSize from 'components/higherOrderComponents/withWindowSize';
import { withRouter } from 'react-router-dom';
import { updateMileage, updateVehicle, calculateDateOfNextInspectionForCountry } from 'resources/vehicles/vehicles.actions';
import { fetchTires, resetTiresState } from 'resources/tires/tires.actions';
import { getTires, getTotalAmount } from 'resources/tires/tires.selectors';
import VehicleCard from './vehicleCard';

export default compose(withWindowSize, withRouter, connect((state) => ({
  garage: fromGarage.getGarage(state),
  tires: getTires(state),
  totalAmountTires: getTotalAmount(state),
}), {
  updateMileageAction: updateMileage,
  updateVehicle,
  fetchTires,
  resetTiresState,
  calculateDateOfNextInspectionForCountry,
}))(VehicleCard);
