import React from 'react';
import PropTypes from 'prop-types';

const CarPlus = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 20 20" fill={props.color} xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6481 8.28125H12.8941L12.4066 7.0625C11.9056 5.80947 10.71 5 9.36026 5H5.63955C4.29013 5 3.09423 5.80947 2.59296 7.0625L2.10546 8.28125H0.351749C0.122941 8.28125 -0.0449308 8.49629 0.0107333 8.71807L0.186515 9.4212C0.22548 9.57764 0.366105 9.68751 0.527531 9.68751H1.11552C0.722062 10.0312 0.468644 10.5307 0.468644 11.0938V12.5C0.468644 12.9723 0.649113 13.3985 0.937395 13.7284V15.3125C0.937395 15.8302 1.35722 16.25 1.8749 16.25H2.8124C3.33007 16.25 3.7499 15.8302 3.7499 15.3125V14.375H11.2499V15.3125C11.2499 15.8302 11.6697 16.25 12.1874 16.25H13.1249C13.6426 16.25 14.0624 15.8302 14.0624 15.3125V13.7284C14.3507 13.3988 14.5312 12.9726 14.5312 12.5V11.0938C14.5312 10.5307 14.2777 10.0312 13.8846 9.68751H14.4726C14.634 9.68751 14.7746 9.57764 14.8136 9.4212L14.9894 8.71807C15.0447 8.49629 14.8769 8.28125 14.6481 8.28125ZM4.33379 7.75889C4.54736 7.2251 5.06445 6.875 5.63955 6.875H9.36026C9.93536 6.875 10.4524 7.2251 10.666 7.75889L11.2499 9.21876H3.7499L4.33379 7.75889ZM2.8124 12.4941C2.2499 12.4941 1.8749 12.1203 1.8749 11.5596C1.8749 10.9988 2.2499 10.625 2.8124 10.625C3.3749 10.625 4.21865 11.4661 4.21865 12.0269C4.21865 12.5876 3.3749 12.4941 2.8124 12.4941ZM12.1874 12.4941C11.6249 12.4941 10.7812 12.5876 10.7812 12.0269C10.7812 11.4661 11.6249 10.625 12.1874 10.625C12.7499 10.625 13.1249 10.9988 13.1249 11.5596C13.1249 12.1203 12.7499 12.4941 12.1874 12.4941Z" />
    <path d="M19.5833 4.58333H17.9166V2.91667C17.9166 2.6875 17.7291 2.5 17.5 2.5H16.6666C16.4375 2.5 16.25 2.6875 16.25 2.91667V4.58333H14.5833C14.3541 4.58333 14.1666 4.77083 14.1666 5V5.83333C14.1666 6.0625 14.3541 6.25 14.5833 6.25H16.25V7.91667C16.25 8.14583 16.4375 8.33333 16.6666 8.33333H17.5C17.7291 8.33333 17.9166 8.14583 17.9166 7.91667V6.25H19.5833C19.8125 6.25 20 6.0625 20 5.83333V5C20 4.77083 19.8125 4.58333 19.5833 4.58333Z" fill="#4F6374"/>
  </svg>
);

CarPlus.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

CarPlus.defaultProps = {
  width: '20',
  height: '20',
  color: '#4F6374',
};

export default CarPlus;
