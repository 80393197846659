import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './textarea.styles.pcss';

export default function Textarea({
  name, label, errorMessage, isValid, className, value, templateFooter, onChange, tabIndex, ...props
}) {
  return (
    <div className={cx(styles.input, className)}>
      <label htmlFor={name} className={styles.inputLabel}>
        {label && <span className={styles.inputLabelText}>{label}</span>}
        <textarea
          className={cx(styles.inputField, styles.inputFieldBorder, {
            [styles.inputFieldInvalid]: !isValid, [styles.templateFooter]: templateFooter,
          })}
          id={name}
          value={value}
          onChange={onChange}
          tabIndex={tabIndex}
          {...props}
        />
      </label>
      {!errorMessage ? null : <div className={styles.inputError}>{isValid ? '' : errorMessage}</div>}
    </div>
  );
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  isValid: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  templateFooter: PropTypes.bool,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

Textarea.defaultProps = {
  label: '',
  errorMessage: '',
  isValid: true,
  className: '',
  value: '',
  tabIndex: 0,
  templateFooter: false,
};
