import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Helmet from 'react-helmet';

import Signin from './components/signin';
import Signup from './components/signup';
import AccpetInvite from './components/acceptInvite';
import ResetPassword from './components/resetPassword';
import styles from './access.styles.pcss';

class Access extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
        </Helmet>
        <div className={styles.access}>
          <Switch>
            <Route path="/access/signup" component={Signup} />
            <Route path="/access/reset-password" component={ResetPassword} />
            <Route path="/access/accept-invite" component={AccpetInvite} />
            <Route path="/access/signin" component={Signin} />
            <Route path="/access" component={Signin} />
          </Switch>
        </div>
      </>
    );
  }
}

export default Access;
