import { CHANGE_CASHBOOK_FILTER } from 'resources/cashbook/cashbook.actions';

import { updateAppByAction } from 'resources/utils';
import { CREATE_SUPPLIER } from 'resources/suppliers/suppliers.actions';
import { CREATE_CLIENT_WITH_DOCUMENT } from 'resources/clients/clients.actions';
import {
  FETCH_GARAGE,
  UPDATE_GARAGE,
  UPDATE_GARAGE_SOCKET,
  UPDATE_SUPPLIERS,
  UPDATE_NUMBER,
  UPDATE_STRIPE_OBJECT,
} from './garage.actions';

const initialState = {
  ...window.config.garage,
  invoiceSettings: {
    ...window.config.garage.invoiceSettings,
    nextInvoiceNumber: 0,
    nextOfferNumber: 0,
    nextDraftNumber: 0,
    nextCashbookNumber: 0,
  },
  createdOn: new Date(window.config.garage.createdOn),
  updatedOn: window.config.garage.updatedOn ? new Date(window.config.garage.updatedOn) : undefined,
};
const actionTypesToUpdateApp = [
  UPDATE_GARAGE,
  UPDATE_SUPPLIERS,
  UPDATE_NUMBER,
  UPDATE_STRIPE_OBJECT,
];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case UPDATE_GARAGE_SOCKET:
    case FETCH_GARAGE:
    case UPDATE_GARAGE:
      return {
        ...state,
        ...action.payload,
        invoiceSettings: {
          ...state.invoiceSettings,
          ...action.payload.invoiceSettings,
        },
      };
    case CREATE_CLIENT_WITH_DOCUMENT:
      return {
        ...state,
        ocrData: {
          ...state.ocrData,
          lastRequest: new Date(),
        },
      };
    case UPDATE_SUPPLIERS:
      return {
        ...state,
        // eslint-disable-next-line max-len
        suppliers: action.payload.suppliers ? [...action.payload.suppliers] : [],
        // eslint-disable-next-line max-len
        usedThirdPartyTools: action.payload.usedThirdPartyTools ? [...action.payload.usedThirdPartyTools] : [],
        // eslint-disable-next-line max-len
        integrationCredentials: action.payload.integrationCredentials ? { ...action.payload.integrationCredentials } : {},
      };
    case UPDATE_NUMBER:
      return {
        ...state,
        invoiceSettings: {
          ...state.invoiceSettings,
          ...action.data,
        },
      };
    case UPDATE_STRIPE_OBJECT:
      return {
        ...state,
        stripeObject: {
          ...state.stripeObject,
          ...action.payload,
        },
      };
    case CHANGE_CASHBOOK_FILTER:
      return {
        ...state,
      };
    case CREATE_SUPPLIER: {
      const nextSupplierAccountNumber = state.invoiceSettings.nextSupplierAccountNumber + 1;

      return {
        ...state,
        invoiceSettings: {
          ...state.invoiceSettings,
          nextSupplierAccountNumber,
        },
      };
    }

    default:
      return state;
  }
};
