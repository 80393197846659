const {
  roundDigits,
  roundTwoDigitsCommercial,
  getNet,
  getGross,
  calculateTotals,
  calculateTotalsIncludingOpenAmount,
  calculateTotalsGroupedByProductType,
  calculateItemPrice,
} = require('./calculateTotals.service');
const { evaluateCalcPosition } = require('./calcPosition.service');
const { recalculateItemTotals } = require('./grossNetRecalculation.service');

module.exports = {
  roundDigits,
  roundTwoDigitsCommercial,
  getNet,
  getGross,
  calculateTotals,
  calculateTotalsIncludingOpenAmount,
  calculateTotalsGroupedByProductType,
  calculateItemPrice,
  evaluateCalcPosition,
  recalculateItemTotals,
};
