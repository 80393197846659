import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InfiniteBlock from 'components/common/infiniteBlock';
import {
  MdArrowDropDown as DropdownIcon,
  MdClear as CloseIcon,
} from 'react-icons/md';
import Downshift from 'downshift';
import Input from 'components/common/input';

import styles from './inputDropdown.styles.pcss';

export default class inputDropdown extends React.PureComponent {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })).isRequired,
    onSelect: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    isRequired: PropTypes.bool,
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
    tabIndex: PropTypes.number,
    preselectFirstItem: PropTypes.bool,
    allowCustom: PropTypes.bool,
    onBlur: PropTypes.func,
    inputClassName: PropTypes.string,
    onScroll: PropTypes.func,
    isListOnTop: PropTypes.bool,
    isDropdownListWider: PropTypes.bool,
    dataTest: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    label: '',
    value: '',
    isRequired: false,
    isValid: true,
    errorMessage: '',
    tabIndex: 0,
    onScroll: () => Promise.resolve(),
    isListOnTop: false,
    isDropdownListWider: false,
    onChange: () => {},
    onBlur: () => {},
    preselectFirstItem: false,
    allowCustom: true,
    inputClassName: '',
    dataTest: '',
  };

  anchorRef = React.createRef();

  arrowButtonRef = React.createRef();

  getLabel = (key) => {
    const { items } = this.props;
    const item = items.find((i) => i.key === key);

    return item ? item.label : key;
  };

  render() {
    const {
      label,
      items,
      className,
      isRequired,
      errorMessage,
      isValid,
      value,
      tabIndex,
      onScroll,
      isListOnTop,
      isDropdownListWider,
      onSelect,
      onChange,
      preselectFirstItem,
      allowCustom,
      onBlur,
      inputClassName,
      dataTest,
    } = this.props;

    return (
      <div className={cx(styles.inputDropdown, className)}>
        <Downshift
          onChange={(selection) => onSelect((selection || '').key)}
          itemToString={(item) => (item ? item.label : '')}
          selectedItem={{ label: this.getLabel(value), key: value }}
        >
          {({
            getInputProps,
            getItemProps,
            getToggleButtonProps,
            isOpen,
            inputValue,
            selectedItem,
            highlightedIndex,
            clearSelection,
            openMenu,
            setHighlightedIndex,
            selectItemAtIndex,
          }) => {
            const filteredItems = items.filter((item) => {
              return item.label.toLowerCase().startsWith((inputValue || '').toLowerCase());
            });

            return (
              <div className={styles.inputDropdownWrapper}>
                <Input
                  tabIndex={tabIndex}
                  ref={this.anchorRef}
                  inputClasses={cx(styles.inputDropdownInputButton, inputClassName)}
                  errorMessage={errorMessage}
                  isValid={isValid}
                  dataTest={dataTest}
                  {...getInputProps({
                    onFocus(e) {
                      if (inputValue) {
                        if (e && e.target && e.target.select) {
                          e.target.select();
                        }
                      }
                      openMenu();
                      if (preselectFirstItem) {
                        setHighlightedIndex(0);
                      }
                    },
                    onBlur() {
                      if (filteredItems.length) {
                        if (inputValue.length !== 0) {
                          selectItemAtIndex(0);
                          onBlur();
                          return;
                        }

                        if (highlightedIndex !== null) {
                          selectItemAtIndex(highlightedIndex);
                          onBlur();
                          return;
                        }

                        clearSelection();
                      } else if (allowCustom) {
                        onSelect(inputValue);
                        onBlur();
                      } else {
                        clearSelection();
                      }
                    },
                    onChange,
                  })}
                />
                {inputValue ? (
                  <button
                    type="button"
                    tabIndex="-1"
                    className={styles.inputDropdownInputButtonIcon}
                    onClick={() => {
                      this.anchorRef.current.inputRef.current.focus();
                      setHighlightedIndex(null);
                      clearSelection();
                      onSelect('');
                      openMenu();
                    }}
                  >
                    <CloseIcon />
                  </button>
                ) : (
                  <button
                    type="button"
                    className={styles.inputDropdownInputButtonIcon}
                    {...getToggleButtonProps({
                      onClick: () => this.arrowButtonRef.current.focus(),
                    })}
                    tabIndex={-1}
                    ref={this.arrowButtonRef}
                  >
                    <DropdownIcon
                      className={cx(styles.inputDropdownInputButtonIconArrow, {
                        [styles.inputDropdownInputButtonIconArrowOpen]: isOpen,
                      })}
                    />
                  </button>
                )}
                {isOpen ? (
                  <InfiniteBlock
                    onScroll={onScroll}
                    className={cx(styles.inputDropdownInputList, {
                      [styles.inputDropdownInputListOnTop]: isListOnTop,
                      [styles.inputDropdownInputListWider]: isDropdownListWider,
                    })}
                    setHighlightedIndex={setHighlightedIndex}
                  >
                    {filteredItems
                      .map((item, index) => (
                        <div
                          key={item.key}
                          {...getItemProps({
                            index,
                            item,
                            'data-test': `${dataTest}DropdownItem${index}`,
                          })}
                          className={cx(styles.inputDropdownInputListItem, {
                            [styles.inputDropdownInputListItemActive]: highlightedIndex === index,
                            [styles.inputDropdownInputListItemSelected]: selectedItem === item,
                          })}
                        >
                          {item.label}
                        </div>
                      ))}
                  </InfiniteBlock>
                ) : null}
              </div>
            );
          }}
        </Downshift>
        {label && (
        <label className={styles.inputDropdownLabel}>
          {label}
          {isRequired ? <div className={styles.requiredMark}>
            *
          </div> : null}
        </label>
        )}
      </div>
    );
  }
}
