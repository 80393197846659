import { last } from 'lodash/array';

import { updateAppByAction } from 'resources/utils';
import {
  FETCH_OFFERS,
  FETCH_OFFER,
  FETCH_OFFER_EMAIL,
  CREATE_OFFER,
  UPDATE_OFFER,
  REMOVE_OFFERS,
  CHANGE_OFFERS_FILTER,
  RESET_OFFERS_STATE,
  UPDATE_OFFER_SOCKET,
  RESTORE_OFFER,
} from './offer.actions';

const initialState = {
  list: [],
  currentOffer: {},
  currentOfferEmail: {},
  totalAmount: 0,
};
const actionTypesToUpdateApp = [CREATE_OFFER, UPDATE_OFFER, REMOVE_OFFERS];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case FETCH_OFFERS: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }
    case CHANGE_OFFERS_FILTER:
      return {
        ...state,
        list: [...action.payload.results],
        totalAmount: action.payload.count,
      };
    case RESET_OFFERS_STATE:
      return {
        ...state,
        list: [],
      };
    case REMOVE_OFFERS:
      return {
        ...state,
        list: state.list.filter((i) => !action.offerIds.includes(i._id)),
        totalAmount: state.totalAmount - action.offerIds.length,
      };
    case FETCH_OFFER:
      return {
        ...state,
        currentOffer: action.payload,
      };
    case FETCH_OFFER_EMAIL:
      return {
        ...state,
        currentOfferEmail: action.payload || {},
      };
    case UPDATE_OFFER_SOCKET:
      return {
        ...state,
        currentOffer: state.currentOffer._id === action.payload._id ? {
          ...state.currentOffer,
          pdf: action.payload.pdf,
          offerEvents: action.payload.offerEvents,
        } : state.currentOffer,
      };
    case UPDATE_OFFER:
      return {
        ...state,
        currentOffer: state.currentOffer._id === action.payload._id ? {
          ...state.currentOffer,
          ...action.payload,
        } : state.currentOffer,
      };
    case RESTORE_OFFER:
      return {
        ...state,
        list: state.list.filter((offer) => action.payload.offer._id !== offer._id),
        totalAmount: state.totalAmount - 1,
      };
    default:
      return state;
  }
};
