import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import tableStyles from './mileageTable.styles.pcss';

const styles = tableStyles;

export default function MileageTable({ header, content }) {
  return (
    <table className={cx(styles.table)}>
      <thead className={cx(styles.tableHeader)}>
        <tr className={cx(styles.tableHeaderRow, styles.tableRow)}>
          {header.map((heading, index) => (
            <td key={index} className={cx(styles.tableCell, styles.tableHeaderCell)}>
              {heading}
            </td>
          ))}
        </tr>
      </thead>
      <tbody className={cx(styles.tableBody)}>
        {content.map((row, rowIndex) => (
          <tr key={rowIndex} className={cx(styles.tableRow, styles.tableBodyRow)}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} className={cx(styles.tableCell, styles.tableBodyCell)}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

MileageTable.propTypes = {
  header: PropTypes.array.isRequired,
  content: PropTypes.array,
};

MileageTable.defaultProps = {
  content: [],
};
