import * as fromGarage from 'resources/garage/garage.selectors';

import * as thirdPartyToolsApi from './thirdPartyTools.api';

export const FETCH_THIRD_PARTY_TOOLS = 'fetchThirdPartyTools';

export const fetchThirdPartyTools = () => (dispatch, getState) => {
  const state = getState();
  const { _id } = fromGarage.getGarage(state);

  return thirdPartyToolsApi
    .fetchThirdPartyTools(_id)
    .then((thirdPartyTools) => dispatch(
      { type: FETCH_THIRD_PARTY_TOOLS, payload: thirdPartyTools },
    ));
};

export const parseBasketFile = (fileString, fileType) => (dispatch, getState) => {
  const state = getState();
  const { _id } = fromGarage.getGarage(state);

  return thirdPartyToolsApi
    .parseBasketFile(_id, fileString, fileType);
};
