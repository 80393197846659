import NumberFormat from 'format-number-with-string';
import moment from 'moment';
import Handlebars from 'handlebars';

import { DATE_FORMAT_MOMENT } from 'constants.js';

function dateHelper(...params) {
  params.pop();

  const [date, format = DATE_FORMAT_MOMENT] = params;

  if (date) {
    return moment.utc(date).format(format);
  }

  return '';
}

function numberHelper(...params) {
  params.pop(); // remove handlebars param

  const [number = 0, format] = params;

  if (!format) {
    return number;
  }

  return NumberFormat((Math.round(number * 100) / 100).toString(), format);
}

Handlebars.registerHelper('date', dateHelper);
Handlebars.registerHelper('format', numberHelper);

function compile(template) {
  return Handlebars.compile(template);
}

export function formatNumber(template, number) {
  return compile(template || '{{ number }}')({
    date: new Date(),
    number,
  });
}
