import * as api from './worker.api';
import * as fromUser from '../user/user.selectors';

export const FETCH_WORKERS = 'fetchWorkers';
export const FETCH_WORKER = 'fetchWorker';
export const CREATE_WORKER = 'createWorker';
export const REMOVE_WORKER = 'removeWorker';
export const UPDATE_WORKER = 'updateWorker';

export const fetchWorkers = () => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.fetchWorkers(currentGarageId)
    .then((payload) => dispatch({ type: FETCH_WORKERS, payload }));
};

export const fetchWorker = (workerId) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  return api.fetchWorker(currentGarageId, workerId)
    .then((payload) => dispatch({ type: FETCH_WORKER, payload }));
};

export const createWorker = (data) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());
  return api.createWorker(currentGarageId, data)
    .then((payload) => dispatch({ type: CREATE_WORKER, data, payload }));
};

export const removeWorker = (data) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());
  return api.removeWorker(currentGarageId, data)
    .then((payload) => dispatch({ type: REMOVE_WORKER, data, payload }));
};

export const updateWorker = (data) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());
  return api.updateWorker(currentGarageId, data)
    .then((payload) => dispatch({ type: UPDATE_WORKER, data, payload }));
};
