import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import cx from 'classnames';
import polyglot from 'services/localization';
import {
  faDolly, faSignInAlt, faSignOutAlt, faTire,
} from 'fontawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { getTreadDepthRegulationStatus, getTireIcon } from 'helpers/tire';
import { getGarage } from 'resources/garage/garage.selectors';
import withDeviceInfo from 'components/higherOrderComponents/withDeviceInfo';
import InteractableDiv from 'components/common/interactableDiv';
import styles from './tireCard.styles.pcss';

class TireCard extends Component {
  static propTypes = {
    tire: PropTypes.shape({}),
    tires: PropTypes.arrayOf(PropTypes.shape({})),
    match: PropTypes.shape({
      params: PropTypes.object,
      path: PropTypes.string,
    }),
    fetchGarage: PropTypes.func.isRequired,
    garage: PropTypes.objectOf({
      country: PropTypes.string,
    }),
    showTireListCards: PropTypes.bool,
    isMobileSize: PropTypes.bool.isRequired,
    onOpenTire: PropTypes.func,
  };

  static defaultProps = {
    showTireListCards: false,
    onOpenTire: () => {},
  };

  componentDidMount() {
  }

  getTreadDepthRegulationStatus = (tire) => {
    const { garage } = this.props;
    const carClassFormatted = tire && tire.vehicle && tire.vehicle.carClass
      ? tire.vehicle.carClass.substr(0, 2)
      : null;
    return getTreadDepthRegulationStatus(
      tire?.frontTires?.treadPatternDepth
        < tire?.backTires?.treadPatternDepth ? tire.frontTires : tire.backTires,
      garage.country,
      tire.client.country,
      carClassFormatted,
    );
  };

  renderTireCard = (tire) => {
    const { onOpenTire } = this.props;
    const status = this.getTreadDepthRegulationStatus(tire);
    const tireIcon = getTireIcon(tire);

    return (
      <>
        <InteractableDiv onClick={() => onOpenTire(tire)} className={styles.containerCardRow}>
          <div className={styles.containerCardData}>
            <div className={styles.containerCardElement}>

              {tire.removedOn ? (
                <div className={cx(styles.tireContainer, styles.tireOutStorage)}>
                  <FontAwesomeIcon className={styles.tireOutStorageIcon} icon={faSignOutAlt} />
                  {polyglot.t('tires.removed')}
                </div>
              ) : (
                <div className={cx(styles.tireContainer, styles.tireInStorage)}>
                  <FontAwesomeIcon className={styles.tireInStorageIcon} icon={faSignInAlt} />
                  {polyglot.t('tires.stored')}
                </div>
              )}
            </div>

            {tire.storageLocation && (
              <div style={{ marginBottom: '5px' }} className={cx(styles.containerCardElement, styles.containerCardElementRight)}>
                <div className={cx(styles.tireRimContainer, styles.tireRimContainerMargin)}>
                  <FontAwesomeIcon className={styles.storageIcon} icon={faDolly} />
                  {tire.storageLocation}
                </div>
              </div>
            )}

            <div className={cx(styles.containerCardElement, styles.tireType)}>
              <div className={cx(styles.containerCardElement, styles.tireTypeElement)}>
                <div className={styles.tireTypeIcon}>
                  {tireIcon()}
                </div>
                <div className={styles.truncateFormat}>
                  {` ${tire.backTires.brand} `}
                  {tire.backTires.type}
                </div>
              </div>

              <div
                className={cx(
                  styles.containerCardElement,
                  styles.containerCardElementRight,
                  styles.tireStatus,
                )}
              >
                <div
                  className={cx(styles.tireStatusField, status.class)}
                >
                  <FontAwesomeIcon
                    className={cx(styles.icon, status.iconClass)}
                    icon={status.icon}
                  />
                  {`${(tire?.frontTires?.treadPatternDepth) || '0'} mm / ${(tire?.backTires?.treadPatternDepth) || '0'} mm`}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.containerImageTire}>
            <FontAwesomeIcon icon={faTire} className={styles.containerImageTireIcon} />
          </div>
        </InteractableDiv>
        <div className={styles.seperator} />
      </>
    );
  };

  renderTireListCards = () => {
    const { tires, isMobileSize, onOpenTire } = this.props;
    return (
      tires.map((tire) => {
        const tireIcon = getTireIcon(tire);
        const status = this.getTreadDepthRegulationStatus(tire);
        if (isMobileSize) return this.renderTireCard(tire);
        return (
          <InteractableDiv
            key={tire._id}
            onClick={() => onOpenTire(tire)}
            className={styles.tireListRow}
          >
            <div className={styles.tireListRowBlock}>
              <div>
                {tire.removedOn ? (
                  <div className={cx(styles.tireContainer, styles.tireOutStorage)}>
                    <FontAwesomeIcon className={styles.tireOutStorageIcon} icon={faSignOutAlt} />
                    {polyglot.t('tires.removed')}
                  </div>
                ) : (
                  <div className={cx(styles.tireContainer, styles.tireInStorage)}>
                    <FontAwesomeIcon className={styles.tireInStorageIcon} icon={faSignInAlt} />
                    {polyglot.t('tires.stored')}
                  </div>
                )}
              </div>

              <div className={styles.tireListRowElement}>
                <div className={styles.tireTypeIcon}>
                  {tireIcon()}
                </div>
                {` ${tire.backTires.brand} `}
                {tire.backTires.type}
              </div>
            </div>

            <div className={styles.tireListRowBlock}>
              {tire.storageLocation && (
                <div>
                  <div className={styles.tireRimContainer}>
                    <FontAwesomeIcon className={styles.storageIcon} icon={faDolly} />
                    {tire.storageLocation}
                  </div>
                </div>
              )}
              <div>
                <div
                  className={cx(styles.tireStatusField, status.class)}
                >
                  <FontAwesomeIcon
                    className={cx(styles.icon, status.iconClass)}
                    icon={status.icon}
                  />
                  {`${(tire?.frontTires?.treadPatternDepth) || '0'} mm / ${(tire?.backTires?.treadPatternDepth) || '0'} mm`}
                </div>
              </div>
            </div>
          </InteractableDiv>
        );
      })
    );
  };

  render() {
    const { tire, showTireListCards } = this.props;
    if (showTireListCards) return this.renderTireListCards();
    return this.renderTireCard(tire);
  }
}

export default withDeviceInfo(connect((state) => ({
  garage: getGarage(state),
}), {
})(TireCard));
