export function getProducts({ product }) {
  return product.list;
}

export function getTotalAmount({ product }) {
  return product.totalAmount;
}
export function getReport({ product }) {
  return product.report;
}

export function getProductById({ product }, id) {
  return product.list.find((p) => p._id === id);
}
