import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottieFiles/Loading.json';

import styles from './tableLoading.styles.pcss';

const TableLoading = ({
  className,
  isScroll,
  children,
  withoutBorder,
  inDropdown,
}) => (
  isScroll ? (
    <div className={cx(
      className,
      styles.onScrollSpinner,
      { [styles.onDropdownSpinner]: inDropdown },
    )}>
      <div className={cx(styles.bounce1, styles.onScrollSpinnerBounce)} />
      <div className={cx(styles.bounce2, styles.onScrollSpinnerBounce)} />
      <div className={styles.onScrollSpinnerBounce} />
    </div>
  ) : (
    <Fragment>
      <div className={cx(styles.lottieContainer, className)}>
        <Player
          autoplay
          loop
          src={animationData}
          style={{ height: '200px', width: '200px' }}
          lottieRef={(instance) => {
            instance.playSegments([[35, 75], [0, 75]], true);
          }}
        />
      </div>
    </Fragment>
  ));

TableLoading.propTypes = {
  className: PropTypes.string,
  isScroll: PropTypes.bool,
  children: PropTypes.node,
  withoutBorder: PropTypes.bool,
  inDropdown: PropTypes.bool,
};

TableLoading.defaultProps = {
  children: null,
  className: '',
  isScroll: false,
  withoutBorder: false,
  inDropdown: false,
};

export default TableLoading;
