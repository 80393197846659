export function getSuppliers({ suppliers }) {
  return suppliers.list;
}

export function getTotalAmount({ suppliers }) {
  return suppliers.totalAmount;
}

export function getSupplierById({ suppliers }, id) {
  return suppliers.list.find((s) => s._id === id);
}
