import React from 'react';
import PropTypes from 'prop-types';

const NoConnectionIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.2013 15.2677C35.2233 16.1072 36.7183 16.0342 37.6498 15.1036C38.7272 14.0267 38.6543 12.2382 37.4658 11.2711C30.9164 5.90571 22.5011 3.91635 14.5647 5.32172L19.2892 10.0483C24.5655 9.99365 29.874 11.7272 34.2013 15.2677ZM30.0579 18.6075C28.633 17.5671 27.0825 16.7825 25.459 16.235L30.8434 21.6186C31.2814 20.5601 31.0244 19.301 30.0579 18.6075ZM23.05 26.3819C21.9463 25.8148 20.7228 25.5193 19.4819 25.5193C18.2409 25.5193 17.0174 25.8148 15.9137 26.3819C14.8362 26.9475 14.6377 28.4259 15.4932 29.2835L18.1767 31.9663C18.8891 32.6779 20.0396 32.6779 20.7491 31.9663L23.4326 29.2835C24.3261 28.4259 24.145 26.9475 23.05 26.3819ZM33.5064 29.4295L5.10991 1.03289C4.94055 0.863826 4.73907 0.7298 4.52007 0.638406C4.29816 0.547011 4.06164 0.5 3.8222 0.5C3.58568 0.5 3.34917 0.547011 3.12725 0.638406C2.90534 0.7298 2.70677 0.863826 2.53742 1.03289C2.36806 1.20196 2.23375 1.40256 2.14323 1.62331C2.04979 1.84406 2.00308 2.08057 2.00308 2.31942C2.00308 2.55857 2.04979 2.79508 2.14323 3.01583C2.23375 3.23658 2.36806 3.43718 2.53742 3.60625L6.80641 7.89478C4.96393 8.80727 3.1769 9.92065 1.53297 11.2711C1.25558 11.5009 1.02781 11.7865 0.864294 12.1094C0.703697 12.4327 0.610278 12.786 0.592759 13.1469C0.575239 13.5078 0.633628 13.8681 0.765026 14.2051C0.896423 14.5421 1.09498 14.8481 1.35194 15.1036C2.28048 16.0342 3.76091 16.1256 4.76245 15.2861C6.58742 13.7896 8.59632 12.6216 10.6958 11.7637L14.7633 15.8336C12.7018 16.381 10.7308 17.2935 8.9438 18.5891C7.68238 19.5016 7.6094 21.345 8.70438 22.44L8.7248 22.4581C9.6183 23.3524 11.0228 23.4435 12.0623 22.6954C14.188 21.206 16.7138 20.3861 19.3096 20.3411L30.9339 31.9663C31.6464 32.6779 32.7939 32.6779 33.5064 31.9663C34.2188 31.2909 34.2188 30.1413 33.5064 29.4295Z" fill="#4F6374" />
  </svg>
);

NoConnectionIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

NoConnectionIcon.defaultProps = {
  width: '39',
  height: '33',
};

export default NoConnectionIcon;
