import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import NumberFormat from 'react-number-format';

import * as fromGarage from 'resources/garage/garage.selectors';
import { GARAGE_COUNTRIES } from 'shared-library/src/definitions/garageCountries';
import { isNumeric } from 'helpers/number';
import { getWidthOfText } from 'helpers/domHelpers';
import RcTooltip from 'rc-tooltip';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { roundDigits } from 'shared-library/src/services/invoiceCalculationService';
import ValidatableComponent from 'helpers/formValidation/validatableComponent';
import polyglot from 'services/localization';
import styles from './numberInputModern.styles.pcss';
import InteractableDiv from '../interactableDiv';

class NumberInputModern extends ValidatableComponent {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.element,
    isRequired: PropTypes.bool,
    inputClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    controlUsingString: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    allowNegative: PropTypes.bool,
    scaleType: PropTypes.oneOf(['integer', 'price', '']),
    errorMessage: PropTypes.string,
    isValid: PropTypes.bool,
    refFunc: PropTypes.func,
    title: PropTypes.string,
    onKeyPress: PropTypes.func,
    onKeyDown: PropTypes.func,
    tabIndex: PropTypes.number,
    country: PropTypes.oneOf(Object.values(GARAGE_COUNTRIES)).isRequired,
    isRow: PropTypes.bool,
    isShowArrows: PropTypes.bool,
    unit: PropTypes.string,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    suffix: PropTypes.string,
    prefix: PropTypes.string,
    autoFocus: PropTypes.bool,
    dataTest: PropTypes.string,
    icon: PropTypes.string,
    hoverIcon: PropTypes.arrayOf({}),
    isMediumSize: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    label: '',
    isRequired: false,
    inputClassName: '',
    labelClassName: '',
    value: '',
    controlUsingString: false,
    disabled: false,
    allowNegative: true,
    scaleType: '',
    errorMessage: '',
    isValid: true,
    refFunc: () => {},
    title: '',
    onKeyPress: () => {},
    onKeyDown: () => {},
    onChange: () => {},
    tabIndex: 0,
    isRow: false,
    autoFocus: false,
    isShowArrows: true,
    unit: '',
    maxValue: null,
    minValue: null,
    suffix: '',
    prefix: '',
    dataTest: '',
    icon: '',
    hoverIcon: null,
    isMediumSize: false,
  };

  constructor(props) {
    super(props);

    const { value, controlUsingString } = this.props;

    let stringValue;
    if (controlUsingString) {
      stringValue = value;
    } else {
      stringValue = isNumeric(parseFloat(value)) ? parseFloat(value) : '';
    }

    this.state = {
      stringValue,
      isFocused: this.props.autoFocus,
      localError: false,
      useIconAnim: true,
    };
  }

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      this.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { autoFocus, id } = this.props;
    if (autoFocus && id !== prevProps.id) {
      this.focus();
    }
  }

  focus = () => {
    setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus();
        this.setState({ isFocused: true });
      }
    }, 1);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, controlUsingString } = this.props;
    if ((!this.state.isFocused || controlUsingString) && value !== nextProps.value) {
      let stringValue;
      if (controlUsingString) {
        stringValue = nextProps.value;
      } else {
        stringValue = isNumeric(parseFloat(nextProps.value)) ? parseFloat(nextProps.value) : '';
      }

      this.setState({
        stringValue,
      });
    }
  }

  getDecimalScale = () => {
    if (this.state.stringValue === '-') {
      return undefined;
    }

    const { scaleType } = this.props;

    if (scaleType === 'integer') {
      return 0;
    }

    if (scaleType === 'price') {
      return 2;
    }

    return undefined;
  };

  changeWithArrow = ({ isIncrement = true, changeBy = 1 } = {}) => {
    const { stringValue: stringValueState } = this.state;
    const { allowNegative, formValidator, minValue } = this.props;
    const stringValue
      = formValidator && (formValidator.value === 0 || formValidator.value)
        ? formValidator.value
        : stringValueState;

    const parsedValue = parseFloat(stringValue);
    const numericValue = isNumeric(parsedValue) ? parseFloat(parsedValue) : 0;
    let newNumericValue = isIncrement ? numericValue + changeBy : numericValue - changeBy;

    if (!isIncrement && !allowNegative && newNumericValue < 0) {
      newNumericValue = 0;
    }

    if (newNumericValue < minValue) {
      newNumericValue = minValue;
    }
    const round = 10 ** 12;
    const newStringValue = (Math.round(newNumericValue * round) / round).toString();
    this.setState({ stringValue: newStringValue });

    if (this.props.controlUsingString) {
      super.onChange(newStringValue);
      return;
    }

    super.onChange(isNumeric(newNumericValue) ? newNumericValue : null);
  };

  handleErrors = (event) => {
    const { formValidator } = this.props;
    if (!formValidator) return;
    if (event.getModifierState('CapsLock')) {
      this.setState({ localError: true });
      formValidator.addError({ id: 'capslock', message: polyglot.t('formValidators.caps') });
    } else if (!(event.ctrlKey || event.metaKey) && /^[a-zA-Z]$/.test(event.key.toLowerCase())) {
      this.setState({ localError: true });
      formValidator.addError({ id: 'letters', message: polyglot.t('formValidators.numbers') });
    } else {
      this.setState({ localError: false });
      formValidator.removeErrors(['letters', 'capslock']);
    }
  };

  onPaste = (event) => {
    let pasteVal = event.clipboardData.getData('text');
    if (pasteVal.match(/.+\.[\d]{0,2}$/gi)) {
      const seperatorIndex = pasteVal.lastIndexOf('.');
      pasteVal = `${pasteVal.substring(0, seperatorIndex)}.${pasteVal.substring(
        seperatorIndex + 1,
      )}`;
      event.preventDefault();
      this.setState({ stringValue: pasteVal });
    }
    if (pasteVal.match(/.+,[\d]{0,2}$/gi)) {
      const seperatorIndex = pasteVal.lastIndexOf(',');
      pasteVal = `${pasteVal.substring(0, seperatorIndex)}.${pasteVal.substring(
        seperatorIndex + 1,
      )}`;
      event.preventDefault();
      this.setState({ stringValue: pasteVal });
    }
  };

  render() {
    const {
      className,
      name,
      label,
      inputClassName,
      labelClassName,
      controlUsingString,
      disabled,
      allowNegative,
      refFunc,
      title,
      onKeyPress,
      onKeyDown,
      tabIndex,
      country,
      isRow,
      scaleType,
      autoFocus,
      isShowArrows,
      unit,
      suffix,
      prefix,
      icon,
      formValidator,
      dataTest,
      arrowStyles,
      hoverIcon,
      maxValue,
      minValue,
      isMediumSize,
    } = this.props;
    const {
      stringValue: stringValueState,
      isFocused,
      isHovered,
      localError,
      useIconAnim,
    } = this.state;
    let stringValue = stringValueState;
    const hasError = super.hasError() || localError;
    if (formValidator) {
      stringValue = formValidator.value || formValidator.value === 0 ? formValidator.value : '';
    }
    const formattedString
      = stringValue === '' ? '' : parseFloat(Math.round(stringValue * 100) / 100).toFixed(2);
    return (
      <div
        className={cx(className, styles.numberInput, {
          [styles.numberInputSelected]: (isHovered || isFocused) && hoverIcon,
        })}
      >
        <label
          htmlFor={name}
          className={cx(styles.numberInputLabel, {
            [styles.numberInputLabelColumn]: !isRow,
          })}
        >
          <div className={styles.numberInputField}>
            <NumberFormat
              data-test={dataTest}
              tabIndex={tabIndex}
              id={name}
              className={cx(
                icon ? styles.numberInputFieldInputIcon : styles.numberInputFieldInput,
                {
                  [styles.numberInputFieldError]: hasError,
                  [styles.numberInputFieldDisabled]: disabled,
                  [styles.numberInputFieldInputWithArrows]: !disabled && isShowArrows,
                  [styles.numberInputFieldInputWithoutArrows]: !isShowArrows,
                  [styles.numberInputFieldWithUnit]: !!unit,
                  [styles.numberInputFieldWithHoverIcon]:
                    hoverIcon && (isHovered || isFocused) && !useIconAnim && !isMediumSize,
                  [styles.numberInputFieldWithHoverIconAnim]:
                    hoverIcon && (isHovered || isFocused) && useIconAnim && !isMediumSize,
                  [styles.numberInputFieldWithHoverIconMedium]:
                  hoverIcon && (isHovered || isFocused) && !useIconAnim && isMediumSize,
                  [styles.numberInputFieldWithHoverIconAnimMedium]:
                  hoverIcon && (isHovered || isFocused) && useIconAnim && isMediumSize,
                },
                inputClassName,
              )}
              style={
                !!unit && !isShowArrows
                  ? {
                    paddingRight: `${21
                        + parseFloat(getWidthOfText(unit, '13px').replace('px', ''))}px`,
                  }
                  : {}
              }
              name={name}
              value={!isFocused && scaleType === 'price' ? formattedString : stringValue}
              isNumericString
              onPaste={this.onPaste}
              onValueChange={({ value, floatValue }) => {
                let newFloatValue = floatValue;
                let newStringValue = value;
                if (maxValue !== null && floatValue > maxValue) {
                  newFloatValue = maxValue;
                  newStringValue = parseFloat(roundDigits(maxValue, 2)).toFixed(2);
                } else if (minValue !== null && floatValue < minValue) {
                  newFloatValue = minValue;
                  newStringValue = parseFloat(roundDigits(minValue, 2)).toFixed(2);
                }

                this.setState({ stringValue: newStringValue });

                if (!isFocused) {
                  return;
                }

                if (controlUsingString) {
                  super.onChange(newStringValue);
                  return;
                }

                super.onChange(isNumeric(newFloatValue) ? newFloatValue : null);
              }}
              disabled={disabled}
              allowNegative={allowNegative}
              decimalSeparator={['CH', 'LI'].includes(country) ? '.' : ','}
              allowedDecimalSeparators={['.', ',']}
              thousandSeparator={['CH', 'LI'].includes(country) ? "'" : ' '}
              decimalScale={this.getDecimalScale()}
              onFocus={(event) => super.onFocus(event)}
              onBlur={(event) => super.onBlur(event)}
              onMouseEnter={(event) => super.onMouseEnter(event)}
              onMouseLeave={(event) => super.onMouseLeave(event)}
              getInputRef={(input) => {
                this.inputRef = input;
                refFunc(input);
              }}
              title={title}
              onKeyPress={onKeyPress}
              onKeyDown={(event) => {
                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                  event.stopPropagation();
                  event.preventDefault();
                  if (event.key === 'ArrowUp') this.changeWithArrow({ isIncrement: true });
                  if (event.key === 'ArrowDown') this.changeWithArrow({ isIncrement: false });
                }
                this.handleErrors(event);
                onKeyDown(event);
              }}
              autoFocus={autoFocus}
              suffix={suffix}
              prefix={prefix}
              inputMode={scaleType === 'integer' ? 'numeric' : 'decimal' }
            />
            {!!icon && (
              <div className={cx(styles.numberInputIcon)}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )}
            {hoverIcon && (
              <InteractableDiv
                tabIndex={-1}
                onBlur={(e) => this.setState({ isHovered: false, useIconAnim: true })}
                onKeyDown={(e) => e.key === 'Enter' && [hoverIcon.onClick(e), e.target.blur()]}
                onFocus={() => this.setState({ isHovered: true, useIconAnim: false })}
                onMouseLeave={() => this.setState({ isHovered: false })}
                onClick={(e) => {
                  setTimeout(() => {
                    this.setState({ isFocused: false });
                  }, 100);
                  hoverIcon.onClick(e);
                }}
              >
                <RcTooltip
                  placement="top"
                  align={{ offset: [0, -10] }}
                  destroyTooltipOnHide
                  overlayClassName={styles.numberInputHoverIconToolTip}
                  overlayInnerStyle={{
                    backgroundColor: 'unset',
                    border: 'none',
                    color: 'white',
                    padding: '7px',
                  }}
                  trigger={['hover', 'focus']}
                  overlay={<div>{hoverIcon.hoverText}</div>}
                >
                  <CSSTransition
                    in={isHovered || isFocused}
                    timeout={{
                      enter: useIconAnim ? 800 : 0,
                      exit: 0,
                    }}
                    unmountOnExit
                    appear
                    classNames={{
                      enterActive: styles.fadeIn,
                      exitActive: styles.fadeOut,
                    }}
                  >
                    <InteractableDiv dataTest={`${dataTest}CalcGross`} className={cx(
                      styles.numberInputHoverIcon,
                      {
                        [styles.numberInputHoverIconMedium]: isMediumSize,
                      },
                    )}>
                      <FontAwesomeIcon
                        onBlur={(e) => this.setState({ isHovered: false, useIconAnim: true })}
                        onMouseEnter={() => this.setState({ isHovered: true })}
                        icon={hoverIcon.icon}
                      />
                    </InteractableDiv>
                  </CSSTransition>
                </RcTooltip>
              </InteractableDiv>
            )}

            {!disabled && isShowArrows && (
              <div className={cx(styles.numberInputArrows, arrowStyles)}>
                <button
                  type="button"
                  className={styles.numberInputArrowButton}
                  onClick={({ shiftKey }) => this.changeWithArrow({ changeBy: shiftKey ? 10 : 1 })}
                  tabIndex="-1"
                >
                  <FaChevronUp />
                </button>
                <button
                  type="button"
                  className={styles.numberInputArrowButton}
                  onClick={({ shiftKey }) =>
                    this.changeWithArrow({ isIncrement: false, changeBy: shiftKey ? 10 : 1 })
                  }
                  tabIndex="-1"
                >
                  <FaChevronDown />
                </button>
              </div>
            )}
            {!!unit && (
              <div
                className={styles.numberInputUnit}
                style={isShowArrows ? { right: '25px' } : { right: '19px' }}
              >
                {unit}
              </div>
            )}
          </div>
          {label && (
            <div className={cx(styles.numberInputLabelText, labelClassName)}>
              {label}
              {super.isRequired() ? <span className={cx(styles.isRequired)}>&nbsp; *</span> : null}
            </div>
          )}
          {super.renderTooltip()}
        </label>
      </div>
    );
  }
}

export default connect((state) => ({
  country: fromGarage.getCountry(state),
}))(NumberInputModern);
