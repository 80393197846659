import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import polyglot from 'services/localization';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import {
  faCar, faFile, faPen, faUsers, faWrench, faXmark,
  faPlus,
} from 'fontawesome/pro-solid-svg-icons';
import { getIconFromGarageType } from 'helpers/vehicle';
import modalStyles from 'styles/modal.pcss';
import { ENTITIES } from 'shared-library/src/definitions/entities';
import withWindowSize from 'components/higherOrderComponents/withWindowSize';
import toastService from 'helpers/toastService';
import CreateClientWithDocumentModal from 'components/clients/createClientWithDocument/createClientWithDocumentModal';
import VehicleCard from 'components/common/pwaCards/vehicleCard';
import { fetchClient, fetchClients } from 'resources/clients/clients.api';
import { fetchVehicle, fetchVehiclesByClientId } from 'resources/vehicles/vehicles.api';
import { fetchInvoicesCalendarModal } from 'resources/invoice/invoice.api';
import styles from './dataTableModal.styles.pcss';
import ResponsiveModal from '../responsiveModal';
import SearchInputModern from '../searchInputModern';
import LazyLoadingList from '../lazyLoadingList';
import ClientCard from '../pwaCards/clientCard/clientCard';
import InvoiceCard from '../pwaCards/invoiceCard/invoiceCard';
import ButtonModern from '../buttonModern';
import TabNavigationModern from '../tabNavigationModern';
import VehicleEditModal from '../vehicleEditModal';
import ClientEditModal from '../clientEditModal';
import InteractableDiv from '../interactableDiv';
import SelectOptionsCard from '../pwaCards/selectOptionsCard';
import Arrow from '../icon/svg/arrow';
import NoEntitiesModern from '../noEntitiesModern';
import Icon from '../icon/Icon';

class DataTableModal extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    style: PropTypes.object.isRequired,
    item: PropTypes.PropTypes.shape({}),
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    fetchClient: PropTypes.func.isRequired,
    fetchClients: PropTypes.func.isRequired,
    fetchVehicle: PropTypes.func.isRequired,
    fetchVehiclesByClientId: PropTypes.func.isRequired,
    fetchInvoices: PropTypes.func.isRequired,
    garageType: PropTypes.string.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
    onDataSelected: PropTypes.func.isRequired,
    preselectedClient: PropTypes.object,
    preselectedVehicle: PropTypes.object,
    preselectedInvoice: PropTypes.object,
    onCreateEntry: PropTypes.bool,
    selectTypes: PropTypes.arrayOf(PropTypes.string),
    garageId: PropTypes.string.isRequired,
    preselectedTab: PropTypes.string,
  };

  // eslint-disable-next-line react/sort-comp
  static ENTITIES = {
    CLIENTS: 'clients',
    VEHICLES: 'vehicles',
    DOCUMENTS: 'documents',
  };

  static defaultProps = {
    isOpen: false,
    onClose: () => null,
    preselectedClient: undefined,
    preselectedVehicle: undefined,
    preselectedInvoice: undefined,
    onCreateEntry: () => null,
    selectTypes: [
      DataTableModal.ENTITIES.CLIENTS,
      DataTableModal.ENTITIES.VEHICLES,
      DataTableModal.ENTITIES.DOCUMENTS,
    ],
    preselectedTab: null,
  };

  static VehicleIcon = <FontAwesomeIcon icon={faUsers} />;

  static tabs = [{
    key: DataTableModal.ENTITIES.CLIENTS,
    icon: <FontAwesomeIcon icon={faUsers} />,
    title: polyglot.t('header.clients'),
    className: styles.headerIcon,
  }, {
    key: DataTableModal.ENTITIES.VEHICLES,
    icon: <FontAwesomeIcon icon={faCar} />,
    title: polyglot.t('header.vehicles'),
  }, {
    key: DataTableModal.ENTITIES.DOCUMENTS,
    icon: <FontAwesomeIcon icon={faFile} />,
    title: polyglot.t('header.documents'),
    className: styles.headerIcon,
  }];

  constructor(props) {
    super(props);

    this.searchInputRef = React.createRef();

    this.state = {
      searchText: '',
      sortByClient: ['lastName', 'firstName'],
      sortByVehicle: ['client.lastName', 'client.firstName'],
      sortByInvoice: ['createdOn'],
      sortDirection: 1,
      isChangeFilterLoading: false,
      isFetchLoading: false,
      isFetchError: false,
      selectedInvoice: props.preselectedInvoice,
      selectedVehicle: props.preselectedVehicle,
      selectedClient: props.preselectedClient,
      selectionChanged: false,
      selectedTab: props.preselectedTab || DataTableModal.ENTITIES.CLIENTS,
      isVehicleEditModalOpen: false,
      isClientEditModalOpen: false,
      isClientAddModalOpen: false,
      isVehicleAddModalOpen: false,
      isSelectDocumentModalOpen: false,
      isSelectVehicleModalOpen: false,
      isCreateClientWithDocumentModalOpen: false,
      clients: [],
      vehicles: [],
      invoices: [],
      totalAmountClients: 0,
      totalAmountVehicles: 0,
      totalAmountInvoices: 0,
      isFetchClientLoading: false,
      isFetchVehicleLoading: false,
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    const { isMobileSize } = this.props;
    const { searchText } = this.state;
    if (prevState.searchText !== searchText && isMobileSize) {
      this.fetchForDataCount();
    }
  }

  fetchForDataCount = async () => {
    await this.fetchClients();
    await this.fetchVehicles();
    await this.fetchInvoices();
  };

  fetchClients = async () => {
    const {
      garageId,
    } = this.props;
    const {
      clients,
      sortByClient,
      sortDirection,
      searchText,
      isFetchLoading,
      selectedClient,
      totalAmountClients,
    } = this.state;
    const clientId = selectedClient?._id || selectedClient?.clientId;
    const allClientsAreFetched = clients.length >= totalAmountClients && clients.length > 0;
    if (allClientsAreFetched || isFetchLoading || clientId) {
      return;
    }
    this.setState({ isFetchLoading: true });
    try {
      const { results, count } = await fetchClients(
        garageId,
        {
          skip: clients.length,
          sortByClient,
          sortDirection,
          searchText,
          clientId,
        },
      );
      this.setState(
        { clients: clients.concat(results), totalAmountClients: count, isFetchLoading: false },
      );
    } catch {
      this.setState({ isFetchError: true, isFetchLoading: false });
    }
  };

  fetchVehicles = async () => {
    const { garageId } = this.props;
    const {
      sortByVehicle,
      sortDirection,
      searchText,
      selectedClient,
      isFetchLoading,
      selectedVehicle,
      vehicles,
      totalAmountVehicles,
    } = this.state;
    const clientId = selectedClient?._id || selectedClient?.clientId;
    const vehicleId = selectedVehicle?._id;
    const allVehiclesAreFetched = vehicles.length >= totalAmountVehicles && vehicles.length > 0;

    if (allVehiclesAreFetched || isFetchLoading || vehicleId) {
      return;
    }
    this.setState({ isFetchLoading: true });
    try {
      const { results, count } = await fetchVehiclesByClientId(
        garageId,
        {
          sortBy: sortByVehicle,
          sortDirection,
          searchText,
          skip: vehicles.length,
          clientId,
          vehicleId,
        },
      );
      this.setState(
        { vehicles: vehicles.concat(results), totalAmountVehicles: count, isFetchLoading: false },
      );
    } catch {
      this.setState({ isFetchError: true, isFetchLoading: false });
    }
  };

  fetchInvoices = async () => {
    const {
      garageId,
    } = this.props;
    const {
      sortByInvoice,
      sortDirection,
      selectedClient,
      searchText,
      isFetchLoading,
      selectedInvoice,
      selectedVehicle,
      invoices,
      totalAmountInvoices,
    } = this.state;
    const invoiceId = selectedInvoice?._id;
    const clientId = selectedClient?._id || selectedClient?.clientId;
    const vehicleId = selectedVehicle?._id;
    const allInvoicesAreFetched = invoices.length >= totalAmountInvoices && invoices.length > 0;
    if (allInvoicesAreFetched || isFetchLoading || invoiceId) {
      return;
    }
    this.setState({ isFetchLoading: true });
    try {
      const { results, count } = await fetchInvoicesCalendarModal(
        garageId,
        {
          sortBy: sortByInvoice,
          sortDirection,
          skip: invoices.length,
          searchText,
          clientId,
          invoiceId,
          vehicleId,
        },
      );
      this.setState(
        { invoices: invoices.concat(results), totalAmountInvoices: count, isFetchLoading: false },
      );
    } catch {
      this.setState({ isFetchError: true, isFetchLoading: false });
    }
  };

  changeInvoicesFilter = async ({
    sortBy, sortDirection, searchText,
  }) => {
    const { garageId } = this.props;
    const { selectedClient, selectedInvoice, selectedVehicle } = this.state;
    const clientId = selectedClient?._id || selectedClient?.clientId;
    const invoiceId = selectedInvoice?._id;
    const vehicleId = selectedVehicle?._id;
    this.setState({ isChangeFilterLoading: true, invoices: [] });
    const fetchQuery = {
      sortBy,
      sortDirection,
      searchText,
      clientId,
      invoiceId,
      vehicleId,
    };
    try {
      const { results, count } = await fetchInvoicesCalendarModal(garageId, fetchQuery);
      this.setState({ invoices: results, totalAmountInvoices: count });
      this.setState({ isChangeFilterLoading: false, isChangeFilterError: false });
    } catch {
      this.setState({ isChangeFilterLoading: false, isChangeFilterError: true });
    }
  };

  changeClientsFilter = async ({
    sortBy, sortDirection, searchText,
  }) => {
    const { garageId } = this.props;
    const { selectedClient } = this.state;
    const clientId = selectedClient?._id || selectedClient?.clientId;
    this.setState({ isChangeFilterLoading: true, clients: [] });
    try {
      const { results, count } = await fetchClients(
        garageId,
        {
          sortBy,
          sortDirection,
          searchText,
          clientId,
        },
      );
      this.setState({ clients: results, totalAmountClients: count });
      this.setState({ isChangeFilterLoading: false });
    } catch {
      this.setState({ isChangeFilterLoading: false, isChangeFilterError: true });
    }
  };

  changeVehiclesFilter = async ({ sortBy, sortDirection, searchText }) => {
    const { garageId } = this.props;
    const { selectedClient, selectedVehicle } = this.state;
    const clientId = selectedClient?._id || selectedClient?.clientId;
    const vehicleId = selectedVehicle?._id;
    this.setState({ isChangeFilterLoading: true, vehicles: [] });
    try {
      const { results, count } = await fetchVehiclesByClientId(
        garageId,
        {
          sortBy, sortDirection, searchText, clientId, vehicleId,
        },
      );
      this.setState({ vehicles: results, totalAmountVehicles: count });
      this.setState({ isChangeFilterLoading: false });
    } catch {
      this.setState({ isChangeFilterLoading: false, isChangeFilterError: true });
    }
  };

  onSearchChange = (searchText) => { this.setState({ searchText, isChangeFilterLoading: true }); };

  newSearch = () => {
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focusInput();
    }
  };

  saveSelectedData = () => {
    const { onDataSelected, onClose, selectTypes } = this.props;
    const { selectedClient, selectedVehicle, selectedInvoice } = this.state;
    const entities = [];
    if (selectedClient?._id) {
      entities.push({
        type: ENTITIES.CLIENT,
        entity: { ...selectedClient, clientId: selectedClient._id },
      });
    } else if (selectTypes.includes(DataTableModal.ENTITIES.CLIENTS)) {
      entities.push({ type: ENTITIES.CLIENT, entity: {} });
    }
    if (selectedVehicle?._id) {
      entities.push({
        type: ENTITIES.VEHICLE,
        entity: {
          ...selectedVehicle,
          client: {
            ...selectedVehicle.client,
            clientId: selectedVehicle.client._id || selectedVehicle.client.clientId,
          },
        },
      });
    } else if (selectTypes.includes(DataTableModal.ENTITIES.VEHICLES)) {
      entities.push({ type: ENTITIES.VEHICLE, entity: {} });
    }
    if (selectedInvoice?._id) {
      entities.push({ type: ENTITIES.INVOICE, entity: selectedInvoice });
    } else if (selectTypes.includes(DataTableModal.ENTITIES.DOCUMENTS)) {
      entities.push({ type: ENTITIES.INVOICE, entity: {} });
    }
    onDataSelected(entities);
    onClose();
  };

  handleClientEditModalSuccess = async (client) => {
    const { selectedVehicle } = this.state;
    this.setState({ isClientEditModalOpen: false });
    if (client?._id) {
      this.setState({ selectedClient: client });
      const hasUpdatedClientSelectedVehicle
        = selectedVehicle?.client?.clientId === client._id
        || selectedVehicle?.client?._id === client._id;
      if (hasUpdatedClientSelectedVehicle) {
        this.setState({ selectedVehicle: { ...selectedVehicle, client } });
      } else {
        setTimeout(() => {
          this.setState({ selectionChanged: client });
        }, 1000);
      }
    }
  };

  handleVehicleEditModalSuccess = async (vehicle) => {
    const { selectedInvoice } = this.state;
    this.setState({ isVehicleEditModalOpen: false });
    if (vehicle?._id) {
      const client = { ...vehicle.client, _id: vehicle.client.clientId };
      this.setState({ selectedClient: client, selectedVehicle: vehicle });
      const hasUpdatedVehicletSelectedInvoice = selectedInvoice?.vehicle?._id === vehicle._id;
      if (hasUpdatedVehicletSelectedInvoice) {
        this.setState({ selectedInvoice: { ...selectedInvoice, vehicle } });
      } else {
        setTimeout(() => {
          this.setState({ selectionChanged: vehicle });
        }, 1000);
      }
    }
  };

  handleVehicleCreateModalSuccess = (vehicle) => {
    this.setState({ isVehicleAddModalOpen: false });
    if (vehicle?._id) this.setState({ selectedVehicle: vehicle });
    if (vehicle.client.clientId) {
      const client = { ...vehicle.client, _id: vehicle.client.clientId };
      this.setState({ selectedClient: client });
    }
    this.setState({ selectedInvoice: undefined, selectionChanged: vehicle });
  };

  handleClientCreateModalSuccess = (client) => {
    this.setState({ isClientAddModalOpen: false });
    if (client?._id) this.setState({ selectedClient: client });
    this.setState({
      selectedInvoice: undefined,
      selectedVehicle: undefined,
      selectionChanged: client,
    });
  };

  toggleSelectModal = (type) => {
    const {
      isSelectVehicleModalOpen,
      isSelectDocumentModalOpen,
    } = this.state;
    switch (type) {
      case DataTableModal.ENTITIES.VEHICLES:
        this.setState({ isSelectVehicleModalOpen: !isSelectVehicleModalOpen });
        break;
      case DataTableModal.ENTITIES.DOCUMENTS:
        this.setState({ isSelectDocumentModalOpen: !isSelectDocumentModalOpen });
        break;
      default:
    }
  };

  toggleAddModal = async (type, status) => {
    const { history, onCreateEntry } = this.props;
    const { selectedClient, selectedVehicle, isCreateClientWithDocumentModalOpen } = this.state;
    const createValues = await onCreateEntry(type);
    let invoiceFormData;
    if (createValues) {
      invoiceFormData = {
        calendarEvent: createValues,
        clientId: selectedClient?._id,
        vehicleId: selectedVehicle?._id,
      };
    }

    const { isClientAddModalOpen, isVehicleAddModalOpen } = this.state;
    switch (type) {
      case DataTableModal.ENTITIES.CLIENTS:
        this.setState({ isClientAddModalOpen: !isClientAddModalOpen });
        break;
      case DataTableModal.ENTITIES.VEHICLES:
        if (status === 'modal') {
          this.setState({ isVehicleAddModalOpen: !isVehicleAddModalOpen });
        } else if (status === 'ocr') {
          this.setState(
            { isCreateClientWithDocumentModalOpen: !isCreateClientWithDocumentModalOpen },
          );
        }
        break;
      case DataTableModal.ENTITIES.DOCUMENTS:
        history.push(`/invoices/${status || 'draft'}/new`, invoiceFormData);
        break;
      default:
    }
  };

  checkAndSetEntity = async (type, entity) => {
    const { garageId } = this.props;
    const { selectedVehicle, selectedClient } = this.state;
    const doesLocalClientExist
    = (entity?.client?._id || entity?.client?.clientId) && !entity?.client?.removedOn;
    const doesLocalVehicleExist = entity?.vehicle?._id && !entity?.vehicle?.removedOn;
    if (doesLocalClientExist
        && type === DataTableModal.ENTITIES.CLIENTS
        && !selectedClient?._id
    ) {
      try {
        this.setState({ isFetchClientLoading: true });
        const client
          = await fetchClient(garageId, entity?.client?._id || entity?.client?.clientId);
        this.setState({ selectedClient: client, isFetchClientLoading: false });
      } catch {
        toastService.showWarning(polyglot.t('calendar.messages.deletedClient'));
        this.setState({
          isFetchClientLoading: false,
          isFetchError: true,
          selectionChanged: {
            change: 'deleteError', entity,
          },
        });
      }
    }

    if (doesLocalVehicleExist
      && doesLocalClientExist
      && type === DataTableModal.ENTITIES.VEHICLES
      && !selectedVehicle?._id
    ) {
      let vehicle;
      try {
        this.setState({ isFetchVehicleLoading: true });
        vehicle = await fetchVehicle(garageId, entity.vehicle._id);
        this.setState({ isFetchVehicleLoading: false });
      } catch {
        toastService.showWarning(polyglot.t('calendar.messages.deletedVehicle'));
        this.setState({
          isFetchVehicleLoading: false,
          isFetchError: true,
          selectionChanged: {
            change: 'deleteError', entity,
          },
        });
      }
      if (vehicle) {
        this.setState({
          selectedVehicle: {
            ...vehicle,
            client: { ...vehicle.client, clientId: vehicle.client._id },
          },
        });
      }
    }
  };

  onCardClick = async (type, entity) => {
    const { isMobileSize, selectTypes } = this.props;
    switch (type) {
      case DataTableModal.ENTITIES.CLIENTS:
        if (!entity?.removedOn) this.setState({ selectedClient: entity });
        if (isMobileSize && selectTypes.includes(DataTableModal.ENTITIES.VEHICLES)) {
          this.setState({ selectedTab: DataTableModal.ENTITIES.VEHICLES });
        }
        break;
      case DataTableModal.ENTITIES.VEHICLES:
        this.setState({ selectedVehicle: entity });
        if (isMobileSize && selectTypes.includes(DataTableModal.ENTITIES.DOCUMENTS)) {
          this.setState({ selectedTab: DataTableModal.ENTITIES.DOCUMENTS });
        }
        await this.checkAndSetEntity(DataTableModal.ENTITIES.CLIENTS, entity);
        break;
      case DataTableModal.ENTITIES.DOCUMENTS:
        this.setState({ selectedInvoice: entity });
        await this.checkAndSetEntity(DataTableModal.ENTITIES.CLIENTS, entity);
        await this.checkAndSetEntity(DataTableModal.ENTITIES.VEHICLES, entity);
        break;
      default:
    }
    this.setState({ selectionChanged: { change: 'select', entity } });
  };

  editEntity = (type) => {
    const { isVehicleEditModalOpen, isClientEditModalOpen } = this.state;
    switch (type) {
      case DataTableModal.ENTITIES.CLIENTS:
        this.setState({ isClientEditModalOpen: !isClientEditModalOpen });
        break;
      case DataTableModal.ENTITIES.VEHICLES:
        this.setState({ isVehicleEditModalOpen: !isVehicleEditModalOpen });
        break;
      default:
    }
  };

  checkIfEntityIsSelected = (entity) => {
    const { selectedClient, selectedVehicle, selectedInvoice } = this.state;
    return (
      (selectedClient?._id === entity?._id || selectedClient?.clientId === entity?._id)
      || selectedVehicle?._id === entity?._id
      || selectedInvoice?._id === entity?._id
    );
  };

  unSelectEntity = (type, entity) => {
    switch (type) {
      case DataTableModal.ENTITIES.CLIENTS:
        this.setState({
          selectedClient: undefined,
          selectedVehicle: undefined,
          selectedInvoice: undefined,
        });
        break;
      case DataTableModal.ENTITIES.VEHICLES:
        this.setState({
          selectedVehicle: undefined,
          selectedInvoice: undefined,
        });
        break;
      case DataTableModal.ENTITIES.DOCUMENTS:
        this.setState({ selectedInvoice: undefined });
        break;
      default:
    }
    this.setState({ selectionChanged: { change: 'unselect', entity } });
  };

  getListProps = (type) => {
    const {
      garageType,
    } = this.props;
    const {
      clients,
      vehicles,
      invoices,
      sortByClient,
      sortByVehicle,
      sortByInvoice,
      sortDirection,
      totalAmountClients,
      totalAmountVehicles,
      totalAmountInvoices,
    } = this.state;
    const listProps = {};
    const VehicleIcon = getIconFromGarageType(garageType).icon;
    const hasMoreClients = clients.length < totalAmountClients && totalAmountClients > 0;
    const hasMoreVehicles = vehicles.length < totalAmountVehicles && totalAmountVehicles > 0;
    const hasMoreInvoices = invoices.length < totalAmountInvoices && totalAmountInvoices > 0;
    switch (type) {
      case DataTableModal.ENTITIES.CLIENTS:
        listProps.headerIcon = <FontAwesomeIcon className={styles.headerIcon} icon={faUsers} />;
        listProps.hasMore = hasMoreClients;
        listProps.fetch = () => this.fetchClients();
        listProps.changeFilter = this.changeClientsFilter;
        listProps.entities = clients;
        listProps.sortBy = sortByClient;
        listProps.sortDirection = sortDirection;
        break;
      case DataTableModal.ENTITIES.VEHICLES:
        listProps.headerIcon = <VehicleIcon className={styles.headerIcon} />;
        listProps.hasMore = hasMoreVehicles;
        listProps.fetch = () => this.fetchVehicles();
        listProps.changeFilter = this.changeVehiclesFilter;
        listProps.entities = vehicles;
        listProps.sortBy = sortByVehicle;
        listProps.sortDirection = sortDirection;
        break;
      case DataTableModal.ENTITIES.DOCUMENTS:
        listProps.headerIcon = <FontAwesomeIcon className={styles.headerIcon} icon={faFile} />;
        listProps.hasMore = hasMoreInvoices;
        listProps.fetch = () => this.fetchInvoices();
        listProps.changeFilter = this.changeInvoicesFilter;
        listProps.entities = invoices;
        listProps.sortBy = sortByInvoice;
        listProps.sortDirection = -1;
        break;
      default:
        listProps.headerIcon = <FontAwesomeIcon className={styles.headerIcon} icon={faUsers} />;
        listProps.hasMore = false;
        listProps.fetch = () => this.fetchClients();
        listProps.changeFilter = this.changeClientsFilter;
        listProps.entities = clients;
        listProps.sortBy = sortByClient;
        listProps.sortDirection = sortDirection;
        break;
    }
    return listProps;
  };

  getCardByType = (type, entity) => {
    let card;
    const actions = [
      { icon: <FontAwesomeIcon icon={faPen} />, action: () => this.editEntity(type) },
      { icon: <FontAwesomeIcon icon={faXmark} />, action: () => this.unSelectEntity(type, entity) },
    ];

    const entityIsRemoved = entity?.removedOn;
    if (entityIsRemoved) {
      actions.shift();
    }

    const isEntitySelected = this.checkIfEntityIsSelected(entity);
    switch (type) {
      case DataTableModal.ENTITIES.CLIENTS:
        card
        = <ClientCard
            isSelected={isEntitySelected}
            actions={isEntitySelected ? actions : null}
            client={entity}
            dataTest={`dataTableClientsList${entity?._id}`}
          />;
        break;
      case DataTableModal.ENTITIES.VEHICLES:
        card
        = <VehicleCard
            vehicle={entity}
            isSelected={isEntitySelected}
            actions={isEntitySelected ? actions : null}
            showVehicleListCards
            useHover
            dataTest={`dataTableVehicleList${entity?._id}`}
          />;
        break;
      case DataTableModal.ENTITIES.DOCUMENTS:
        card
        = <InvoiceCard
            showInvoiceListCards
            invoice={entity}
            isSelected={isEntitySelected}
            actions={isEntitySelected ? actions.slice(-1) : null}
            dataTest={`dataTableInvoicesList${entity?._id}`}
          />;
        break;
      default:
        return <></>;
    }
    return card;
  };

  getSelectedCardByType = (type) => {
    const {
      selectedClient,
      selectedVehicle,
      selectedInvoice,
    } = this.state;
    let selectedCard;
    switch (type) {
      case DataTableModal.ENTITIES.CLIENTS:
        if (selectedClient?._id) selectedCard = this.getCardByType(type, selectedClient);
        break;
      case DataTableModal.ENTITIES.VEHICLES:
        if (selectedVehicle?._id) selectedCard = this.getCardByType(type, selectedVehicle);
        break;
      case DataTableModal.ENTITIES.DOCUMENTS:
        if (selectedInvoice?._id) selectedCard = this.getCardByType(type, selectedInvoice);
        break;
      default:
        selectedCard = undefined;
    }
    return selectedCard;
  };

  getMobileTabs = () => {
    const {
      garageType,
      selectTypes,
    } = this.props;
    const {
      selectedClient,
      selectedVehicle,
      selectedInvoice,
      totalAmountClients,
      totalAmountVehicles,
      totalAmountInvoices,
      searchText,
    } = this.state;
    const VehicleIcon = getIconFromGarageType(garageType).icon;
    const isNoEntitySelected
      = !selectedClient?._id
      && !selectedVehicle?._id
      && !selectedInvoice?._id;
    const noInvoiceClient = selectedInvoice?._id && !selectedInvoice?.client?._id;
    const noInvoiceVehicle = selectedInvoice?._id && !selectedInvoice?.vehicle?._id;
    const tabs = DataTableModal.tabs.filter((tab) => selectTypes.includes(tab.key));
    return tabs.map((tab) => {
      let count;
      let icon;
      switch (tab.key) {
        case DataTableModal.ENTITIES.CLIENTS:
          count = selectedClient?._id ? 1 : totalAmountClients;
          if (noInvoiceClient) count = 0;
          break;
        case DataTableModal.ENTITIES.VEHICLES:
          count = selectedVehicle?._id ? 1 : totalAmountVehicles;
          if (noInvoiceVehicle) count = 0;
          icon = <VehicleIcon className={styles.headerIconVehicle} />;
          break;
        case DataTableModal.ENTITIES.DOCUMENTS:
          count = selectedInvoice?._id ? 1 : totalAmountInvoices;
          break;
        default:
          count = 0;
      }
      return {
        ...tab,
        icon: icon || tab.icon,
        count: isNoEntitySelected && !searchText ? undefined : count,
      };
    });
  };

  renderCardRow = (type, entities) => {
    const CardRow = ({
      index, item, style,
    }) => {
      const entity = item;
      const content = (
        <div
          className={cx(
            styles.cardContainer,
            { [styles.cardContainerFirstEntry]: index === 0 },
            { [styles.cardContainerLastEntry]: index === entities.length - 1 },
          )}
        >
          {this.getCardByType(type, entity)}
        </div>
      );
      return (
        <InteractableDiv
          style={style} onClick={() => { this.onCardClick(type, entity); }}
        >
          {content}
        </InteractableDiv>
      );
    };
    CardRow.displayName = 'CardRow';
    return CardRow;
  };

  renderColumn = (type) => {
    const {
      selectTypes,
    } = this.props;
    const {
      searchText,
      selectionChanged,
      isFetchLoading,
      isChangeFilterLoading,
      selectedInvoice,
      isFetchClientLoading,
      isFetchVehicleLoading,
    } = this.state;
    const listProps = this.getListProps(type);
    const selectedCard = this.getSelectedCardByType(type);
    const noInvoiceClient = type === DataTableModal.ENTITIES.CLIENTS
      && selectedInvoice?._id
      && !selectedInvoice?.client?._id;
    const noInvoiceVehicle = type === DataTableModal.ENTITIES.VEHICLES
      && selectedInvoice?._id
      && !selectedInvoice?.vehicle?._id;
    const isInvoiceEntityRemoved
      = (type === DataTableModal.ENTITIES.CLIENTS && selectedInvoice?.client?.removedOn)
      || (type === DataTableModal.ENTITIES.VEHICLES && selectedInvoice?.vehicle?.removedOn);
    const isLoading = isFetchLoading || isChangeFilterLoading;
    const isSelectFetchOrChangeFilterLoading
    = isFetchClientLoading || isFetchVehicleLoading || isChangeFilterLoading;
    return (
      <div style={{ width: `calc(${100 / selectTypes.length}vw)` }} className={styles.column}>
        <div className={styles.header}>
          {listProps.headerIcon}
          <span className={styles.headerText}>
            {polyglot.t(`header.${type}`)}
          </span>
          {this.renderAddButtons(type)}
        </div>
        {(noInvoiceClient
        || noInvoiceVehicle
        || isInvoiceEntityRemoved
        ) ? (
          <div className={styles.cardContainer}>
            {this.renderInvoiceEntityNotFoundContent(type, isInvoiceEntityRemoved)}
          </div>
          ) : (
            <>
              {selectedCard ? (
                <div className={styles.cardContainer}>
                  {selectedCard}
                </div>
              ) : (
                <div className={styles.listContainer}>
                  <LazyLoadingList
                    items={listProps.entities}
                    fetchItems={listProps.fetch}
                    renderRow={this.renderCardRow(type, listProps.entities)}
                    searchText={searchText}
                    changeFilter={listProps.changeFilter}
                    hasMore={listProps.hasMore || isLoading}
                    sortDirection={listProps.sortDirection}
                    sortBy={listProps.sortBy}
                    additionalSortByElement={selectionChanged}
                    maxHeight={'calc(100vh - 360px)'}
                    emptyContent={!isLoading && this.renderEmptyContent(type)}
                    notFoundContent={!isLoading && this.renderEmptyContent(type)}
                    additionalLoadingProp={isSelectFetchOrChangeFilterLoading}
                  />
                </div>
              )}
            </>
          )}
      </div>
    );
  };

  renderMobileColumn = () => {
    const { width } = this.props;
    const {
      searchText,
      selectionChanged,
      selectedTab,
      selectedInvoice,
      isFetchLoading,
      isChangeFilterLoading,
    } = this.state;
    const listProps = this.getListProps(selectedTab);
    const selectedCard = this.getSelectedCardByType(selectedTab);
    const isLoading = isFetchLoading || isChangeFilterLoading;
    const noInvoiceClient = selectedTab === DataTableModal.ENTITIES.CLIENTS
      && selectedInvoice?._id
      && !selectedInvoice?.client?._id;
    const noInvoiceVehicle = selectedTab === DataTableModal.ENTITIES.VEHICLES
      && selectedInvoice?._id
      && !selectedInvoice?.vehicle?._id;
    const isInvoiceClientRemoved = selectedTab === DataTableModal.ENTITIES.CLIENTS
      && selectedInvoice?.client?.removedOn;
    const isInvoiceVehicleRemoved = selectedTab === DataTableModal.ENTITIES.VEHICLES
      && selectedInvoice?.vehicle?.removedOn;
    return (
      <>
        <TabNavigationModern
          tabs={this.getMobileTabs()}
          selectedTab={selectedTab}
          onChangeTab={(v) => this.setState({ selectedTab: v })}
          className={styles.tabHeaderNavigation}
          showTitleOnSelect={width > 300 && width < 450}
          hideTitle={width < 300}
        />
        {this.renderAddButtons(selectedTab)}
        {(noInvoiceClient
        || noInvoiceVehicle
        || isInvoiceClientRemoved
        || isInvoiceVehicleRemoved) ? (
          <div className={styles.listContainerMobile}>
            {this.renderInvoiceEntityNotFoundContent(selectedTab)}
          </div>
          ) : (
            <>
              {selectedCard ? (
                <div className={styles.listContainerMobile}>
                  <div className={styles.cardContainer}>
                    {selectedCard}
                  </div>
                </div>
              ) : (
                <div className={styles.listContainerMobile}>
                  <LazyLoadingList
                    items={listProps.entities}
                    fetchItems={listProps.fetch}
                    renderRow={this.renderCardRow(selectedTab, listProps.entities)}
                    searchText={searchText}
                    changeFilter={listProps.changeFilter}
                    hasMore={listProps.hasMore}
                    sortDirection={listProps.sortDirection}
                    sortBy={listProps.sortBy}
                    additionalSortByElement={selectionChanged}
                    maxHeight={'calc(100vh - 360px)'}
                    emptyContent={!isLoading && this.renderEmptyContent(selectedTab)}
                    notFoundContent={!isLoading && this.renderEmptyContent(selectedTab)}
                  />
                </div>
              )}
            </>
          )}
      </>
    );
  };

  renderAddButtons = (type) => {
    const { isMobileSize, garageType } = this.props;
    const {
      isSelectVehicleModalOpen,
      isSelectDocumentModalOpen,
    } = this.state;
    const VehicleIcon = getIconFromGarageType(garageType).icon;
    const vehicleActionButtons = [
      { onClick: () => this.toggleAddModal(type, 'modal'), label: polyglot.t('actions.enterData'), icon: VehicleIcon() },
      { onClick: () => this.toggleAddModal(type, 'ocr'), label: polyglot.t('clients.addCustomersWithDocument'), icon: <i className="fak fa-ocr" /> },
    ];
    const documentActionButtons = [
      { onClick: () => this.toggleAddModal(type, 'draft'), label: polyglot.t('actions.createDraft'), icon: faWrench },
      { onClick: () => this.toggleAddModal(type, 'preview'), label: polyglot.t('actions.createInvoice'), icon: faFile },
    ];
    const buttonLabel = isMobileSize ? polyglot.t(`selectModal.add.${type.slice(0, -1)}`) : polyglot.t(`confirm.${type.slice(0, -1)}`);
    return (
      <>
        {type === DataTableModal.ENTITIES.DOCUMENTS || type === DataTableModal.ENTITIES.VEHICLES ? (
          <div className={styles.selectButtonContainer}>
            <ButtonModern
              onClick={() => this.toggleSelectModal(type)}
              size={'small'}
              styleType={'select'}
              className={styles.headerAction}
            >
              <FontAwesomeIcon className={styles.headerAdd} icon={faPlus} />
              {buttonLabel}
            </ButtonModern>
            <SelectOptionsCard
                align="right"
                isOpen={type === DataTableModal.ENTITIES.VEHICLES
                  ? isSelectVehicleModalOpen : isSelectDocumentModalOpen}
                actionElements={type === DataTableModal.ENTITIES.VEHICLES
                  ? vehicleActionButtons : documentActionButtons}
                onClose={() => (isSelectVehicleModalOpen && isSelectDocumentModalOpen)
                    && this.toggleSelectModal(type)
                }
                className={isMobileSize && styles.headerCardContainer}
              />
          </div>
        ) : (
          <ButtonModern
            onClick={() => this.toggleAddModal(type)}
            size={'small'}
            styleType={'select'}
            className={styles.headerAction}
          >
            <FontAwesomeIcon className={styles.headerAdd} icon={faPlus} />
            {buttonLabel}
          </ButtonModern>
        )}
      </>
    );
  };

  renderEmptyContent = (type) => {
    const { isMobileSize } = this.props;
    return (
      <div className={styles.emptyContent}>
        {!isMobileSize && (
        <div className={styles.emptyContentIcon}>
          <Arrow />
        </div>
        )}
        <div>
          {polyglot.t(`selectModal.notFound.${type.slice(0, -1)}`)}
        </div>
        <div>
          {polyglot.t(`selectModal.notFound.add.${type.slice(0, -1)}`)}
        </div>
      </div>
    );
  };

  renderInvoiceEntityNotFoundContent = (type, isEntityRemoved) => {
    return (
      <div className={styles.emptyContent}>
        <div className={styles.emptyContentText}>
          {isEntityRemoved ? polyglot.t(`selectModal.removed.${type}`) : polyglot.t(`selectModal.empty.${type}`)}
        </div>
      </div>
    );
  };

  renderAllNotFoundContent = () => {
    return (
      <div className={styles.noEntities}>
        <NoEntitiesModern
          withoutBorder
        >
          <div className={styles.noEntitiesSearchIcon}>
            <Icon type="search" />
          </div>
          <div className={styles.noEntitiesHeading}>
            {polyglot.t('selectModal.notFound.all')}
          </div>
          <ButtonModern
            styleType={'select'}
            className={styles.noEntitiesButton}
            onClick={() => this.onSearchChange('')}
            >
            {polyglot.t('selectModal.notFound.showAll')}
          </ButtonModern>
          <ButtonModern
            className={styles.noEntitiesButton}
            styleType={'add'}
            onClick={() => this.newSearch()}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>
              {polyglot.t('selectModal.notFound.newSearch')}
            </span>
          </ButtonModern>
        </NoEntitiesModern>
      </div>
    );
  };

  renderEmptyContentCreateButtons = () => {
    const { isClientAddModalOpen } = this.state;
    return (
      <ButtonModern styleType="add" withIcon onClick={() => this.setState({ isClientAddModalOpen: !isClientAddModalOpen }) } dataTest="addCustomerButton">
        <FontAwesomeIcon icon={faPlus} />
        {polyglot.t('clients.addCustomers')}
      </ButtonModern>
    );
  };

  renderFooter = () => {
    const {
      onClose,
    } = this.props;
    const {
      selectedClient,
      selectedInvoice,
      selectedVehicle,
      isFetchLoading,
      isFetchClientLoading,
      isFetchVehicleLoading,
      isChangeFilterLoading,
    } = this.state;
    const dataSelected = selectedClient?._id || selectedInvoice?._id || selectedVehicle?._id;
    const isLoading
    = isFetchLoading || isFetchClientLoading || isFetchVehicleLoading || isChangeFilterLoading;
    return (
      <div className={cx(modalStyles.footerModern, styles.dataTableModalFooter)}>
        {dataSelected ? (
          <ButtonModern isLoading={isLoading} dataTest={'dataTableModalSave'} onClick={() => this.saveSelectedData()} styleType="add">
            {polyglot.t('actions.save')}
          </ButtonModern>
        ) : (
          <ButtonModern onClick={() => this.saveSelectedData()} styleType="add" dataTest={'dataTableModalClose'}>
            {polyglot.t('selectModal.close')}
          </ButtonModern>
        )}

        <ButtonModern withIcon onClick={() => onClose()} styleType="select" dataTest={'dataTableModalCancel'}>
          {polyglot.t('actions.cancel')}
        </ButtonModern>
      </div>
    );
  };

  renderModals = () => {
    const {
      selectedClient,
      selectedVehicle,
      isVehicleEditModalOpen,
      isClientEditModalOpen,
      isVehicleAddModalOpen,
      isClientAddModalOpen,
      isCreateClientWithDocumentModalOpen,
    } = this.state;

    return (
      <>
        {isVehicleAddModalOpen && (
          <VehicleEditModal
            isOpen={isVehicleAddModalOpen}
            client={selectedClient}
            onClose={async () => {
              this.setState({ selectionChanged: Math.random(0, 1000) });
              this.setState({ isVehicleAddModalOpen: !isVehicleAddModalOpen });
            }}
            onSuccess={(vehicle) => this.handleVehicleCreateModalSuccess(vehicle)}
            isRoutingDisabled={true}
          />
        )}

        {isClientAddModalOpen && (
          <ClientEditModal
            isOpen={isClientAddModalOpen}
            onClose={() => this.setState({ isClientAddModalOpen: !isClientAddModalOpen }) }
            onSuccess={(client) => this.handleClientCreateModalSuccess(client)}
            isRoutingDisabled={true}
          />
        )}

        {isVehicleEditModalOpen && (
          <VehicleEditModal
            isOpen={isVehicleEditModalOpen}
            onClose={() => this.setState({ isVehicleEditModalOpen: !isVehicleEditModalOpen }) }
            onSuccess={(vehicle) => this.handleVehicleEditModalSuccess(vehicle)}
            vehicle={selectedVehicle}
            isClientFieldShown={false}
          />
        )}

        {isClientEditModalOpen && (
          <ClientEditModal
            isOpen={isClientEditModalOpen}
            onClose={() => this.setState({ isClientEditModalOpen: !isClientEditModalOpen }) }
            onSuccess={(client) => this.handleClientEditModalSuccess(client)}
            client={selectedClient}
          />
        )}
        {isCreateClientWithDocumentModalOpen && (
          <CreateClientWithDocumentModal
            clientId={selectedClient._id}
            isOpen={isCreateClientWithDocumentModalOpen}
            onClose={() => this.setState({ isCreateClientWithDocumentModalOpen: false })}
            onSuccess={(vehicle) => this.handleVehicleCreateModalSuccess(vehicle)}
            isRoutingDisabled={true}
          />
        )}
      </>
    );
  };

  getModalTitle = () => {
    const { selectTypes, isMobileSize } = this.props;
    let title = polyglot.t('selectModal.title.choose');
    if (isMobileSize) return polyglot.t('selectModal.titleMobile');
    selectTypes.forEach((type, i) => {
      if (selectTypes.length - 1 === i && selectTypes.length > 1) title += ` ${polyglot.t('selectModal.title.and')}`;
      title += ` ${polyglot.t(`selectModal.title.${type}`)}`;
    });
    return title;
  };

  render() {
    const {
      isOpen,
      onClose,
      isMobileSize,
      selectTypes,
    } = this.props;
    const {
      searchText,
      selectedClient,
      selectedInvoice,
      selectedVehicle,
      isChangeFilterLoading,
      isFetchLoading,
      totalAmountClients,
      totalAmountVehicles,
      totalAmountInvoices,
    } = this.state;
    const noEntitiesAndIsNotLoading
    = totalAmountClients <= 0
    && totalAmountVehicles <= 0
    && totalAmountInvoices <= 0
    && !selectedClient?._id
    && !selectedVehicle?._id
    && !selectedInvoice?._id
    && !isChangeFilterLoading
    && !isFetchLoading;
    const showNotFoundContent
    = noEntitiesAndIsNotLoading
    && searchText
    && !isMobileSize;
    return (
      <ResponsiveModal
        isOpen={isOpen}
        title={this.getModalTitle()}
        onClose={onClose}
        width={selectTypes.length * 450}
        dataTest="dataTableModal"
      >
        <>
          <SearchInputModern
            id="searchInputDataTableModal"
            onChange={this.onSearchChange}
            placeholder={polyglot.t('actions.searchPlaceholder')}
            value={searchText}
            dataTest="searchInputDataTableModal"
            showAmount={false}
            className={styles.dataTableModalSearch}
            ref={this.searchInputRef}
          />
          <div className={styles.noEntitiesContainer}>
            {showNotFoundContent && (
              this.renderAllNotFoundContent()
            )}
            {isMobileSize ? (
              <div>
                {this.renderMobileColumn()}
              </div>
            ) : (
              <div className={styles.columns}>
                {selectTypes.includes(DataTableModal.ENTITIES.CLIENTS)
                  && this.renderColumn(DataTableModal.ENTITIES.CLIENTS)}
                {selectTypes.includes(DataTableModal.ENTITIES.VEHICLES)
                  && this.renderColumn(DataTableModal.ENTITIES.VEHICLES)}
                {selectTypes.includes(DataTableModal.ENTITIES.DOCUMENTS)
                  && this.renderColumn(DataTableModal.ENTITIES.DOCUMENTS)}
              </div>
            )}
          </div>

          {this.renderFooter()}
        </>
        {this.renderModals()}
      </ResponsiveModal>
    );
  }
}

export default withWindowSize(DataTableModal);
