import { updateAppByAction } from 'resources/utils';
import {
  FETCH_SETTINGS,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SOCKET,
} from './printingSettings.actions';

const initialState = {
  internalOrder: null,
  invoice: null,
  receipt: null,
  ...window.config.printingSetting,
};
const actionTypesToUpdateApp = [UPDATE_SETTINGS];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case FETCH_SETTINGS:
      return action.payload;
    case UPDATE_SETTINGS_SOCKET:
    case UPDATE_SETTINGS:
      return action.payload;
    default:
      return state;
  }
};
