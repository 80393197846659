const _logs = [];
const logSubscribers = [];
const { config } = window;

const LOG_LEVELS = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

const fireLogsUpdateCallback = (callback) => {
  callback({ logs: _logs }); // Not cloned for performance
};

const fireLogsUpdateCallbacks = () => {
  logSubscribers.forEach((callback) => fireLogsUpdateCallback(callback));
};

const log = ({
  level,
  message,
  timestamp = Date.now(),
}) => {
  if (config.logger.enabled) {
    _logs.unshift({ level, message, timestamp });
    fireLogsUpdateCallbacks();
  }
};

const parseMessage = (message) => {
  return message
    .map((mess) => {
      let toRet = mess;
      if (mess === undefined) toRet = 'undefined';
      else if (mess === null) toRet = 'null';
      else if (Array.isArray(mess)) toRet = JSON.stringify(mess);
      else if (mess.toString) {
        toRet = mess.toString();
        if (toRet.startsWith('[object')) {
          try {
            const stringified = JSON.stringify(mess);
            toRet = `${stringified === '{}' ? `(${toRet})` : ''} ${JSON.stringify(mess)}`;
          } catch (x) {
            toRet = `[Unparsable] ${toRet}`;
          }
        }
      }
      return toRet;
    })
    .join(' ');
};

const logInfo = (...message) => {
  log({
    level: LOG_LEVELS.INFO,
    message: parseMessage(message),
  });
};

const logWarning = (...message) => {
  log({
    level: LOG_LEVELS.WARNING,
    message: parseMessage(message),
  });
};

const logError = (...message) => {
  log({
    level: LOG_LEVELS.ERROR,
    message: parseMessage(message),
  });
};

if (config.logger.enabled) {
  const baseLogFunction = console.log;
  console.log = function consoleLogWrapper(...args) {
    baseLogFunction.apply(console, args);
    logInfo(...args);
  };

  window.addEventListener('error', ({
    message,
    lineno,
    colno,
    filename,
    error,
  }) => {
    logError(`JavaScript error on line: ${lineno} colon: ${colno}.\nSource: ${filename}\nMessage: ${message}\n${error.stack ? `Stack: ${error.stack}` : ''}`);
  });
}

export default {
  addLogsUpdateListener(callback) {
    logSubscribers.push(callback);
    fireLogsUpdateCallback(callback);

    return {
      unsubscribe: () => {
        const objIndex = logSubscribers.indexOf(callback);
        if (objIndex >= 0) {
          logSubscribers.splice(objIndex, 1);
        }
      },
    };
  },
  get logs() {
    return _logs;
  },
  LOG_LEVELS,
  log,
  logInfo,
  logWarning,
  logError,
};
