import * as Sentry from '@sentry/react';

let isSentryInitiated = false;

const allowedErrorTypes = [
  'customForbiddenError',
  'string.empty',
];

export function initiateSentryForWebsite() {
  const {
    version: release,
    sentry: {
      key, project, environment, tracesSampleRate,
    },
    user: { email },
    sentry: { enabled },
  } = window.config;

  if (isSentryInitiated || !enabled) return;

  Sentry.init({
    ignoreErrors: [
      'Non-Error exception captured',
    ],
    dsn: `https://${key}@sentry.io/${project}`,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: tracesSampleRate || 0.2,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    environment,
    release,
    beforeSend(event, hint) {
      if (event?.exception?.values?.[0]?.value && allowedErrorTypes.some((type) => event.exception.values[0].value.includes(`"type":"${type}"`))) {
        return null;
      }
      const isNonErrorException
        = (event?.exception?.values[0]?.value?.startsWith('Non-Error exception captured')
        ) || (hint?.originalException?.message?.startsWith('Non-Error exception captured'));

      if (isNonErrorException) {
        return null;
      }
      return event;
    },
  });
  isSentryInitiated = true;

  Sentry.setUser({ email });
}
