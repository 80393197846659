const engineCategories = [{
  categoryLabel: 'A | Andere',
}, {
  categoryLabel: 'D | Diesel-Motor',
  subCategoryLabel: 'S | Saug-Diesel',
}, {
  categoryLabel: 'D | Diesel-Motor',
  subCategoryLabel: 'TD | Turbo-Diesel',
}, {
  categoryLabel: 'E | Elektromotor',
}, {
  categoryLabel: 'HD | Hybrid Diesel',
}, {
  categoryLabel: 'HB | Hybrid Benzin',
}, {
  categoryLabel: 'O2 | Otto-Motor 2-Takt',
}, {
  categoryLabel: 'O4 | Otto-Motor 4-Takt',
  subCategoryLabel: 'Kat | mit Katalysator',
}, {
  categoryLabel: 'O4 | Otto-Motor 4-Takt',
  subCategoryLabel: 'OKat | ohne Katalysator',
}];

export const engineCategoriesItems = engineCategories
  .map(({ categoryLabel, subCategoryLabel }) => ({
    key: subCategoryLabel ? `${categoryLabel} ${subCategoryLabel}` : categoryLabel,
    label: subCategoryLabel ? `${categoryLabel} ${subCategoryLabel}` : categoryLabel,
  }));

export default engineCategories;
