import React, { Component } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faBell } from 'fontawesome/pro-solid-svg-icons';
import withDeviceInfo from 'components/higherOrderComponents/withDeviceInfo';
import animationData from 'assets/lottieFiles/NotifivationBell.json';
import { Player } from '@lottiefiles/react-lottie-player';

import styles from './notificationEmptyContent.styles.pcss';

class NotificationEmptyContent extends Component {
  static propTypes = {
    haveEverHadNotifications: PropTypes.bool.isRequired,
  };

  render() {
    const { haveEverHadNotifications } = this.props;
    return (
      <div
        className={styles.containerCard}
        data-test={'notification-card-empty'}
      >
        {haveEverHadNotifications ? (
          <div className={styles.containerCardData}>
            <FontAwesomeIcon icon={faBell} className={styles.containerCardDataIcon}/>
            <div className={styles.containerCardDataTitle}>
              {polyglot.t('notification.noNotificationsTitle')}
            </div>
            <div className={styles.containerCardDataText}>
              {polyglot.t('notification.noNotificationsText')}
            </div>
          </div>
        ) : (
          <div className={styles.containerCardData}>
            <Player
              autoplay
              loop
              src={animationData}
              className={styles.containerCardDataPlayer}
            />
            <div className={styles.containerCardDataTitle}>
              {polyglot.t('notification.anyNotificationsTitle')}
            </div>
            <div className={styles.containerCardDataText}>
              {polyglot.t('notification.anyNotificationsText')}
            </div>
          </div>
        )
      }
      </div>
    );
  }
}

export default withDeviceInfo(NotificationEmptyContent);
