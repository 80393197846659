const NOTIFICATION_TYPES = Object.freeze({
  SDI_REJECTED: 'SDI_REJECTED',
  SMS_PICKUP_NOTIFICATION_ERROR: 'SMS_PICKUP_NOTIFICATION_ERROR',
  MAIL_ERROR: 'MAIL_ERROR',
});

function getNamesForObjectValidation() {
  return Object.values(NOTIFICATION_TYPES);
}

module.exports = {
  NOTIFICATION_TYPES,
  getNamesForObjectValidation,
};
