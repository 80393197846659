import * as fromUser from 'resources/user/user.selectors';
import * as api from './printingSettings.api';

export const FETCH_SETTINGS = 'fetchSettings';
export const UPDATE_SETTINGS = 'updateSettings';
export const UPDATE_SETTINGS_SOCKET = 'updateSettingsSocket';

export const fetchSettings = () => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.fetchSettings(garageId)
    .then((payload) => dispatch({ type: FETCH_SETTINGS, payload }));
};

export const updateSettings = (data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.updateSettings(garageId, data)
    .then((payload) => dispatch({ type: UPDATE_SETTINGS, data, payload }));
};

export const generateInvoicePreview = (data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.generateInvoicePreview(garageId, data);
};

export const getInvoicePreviewPdf = () => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.getInvoicePreviewPdf(garageId);
};

export const generateInternalPreview = (data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.generateInternalPreview(garageId, data);
};

export const generateReceiptPreview = (data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.generateReceiptPreview(garageId, data);
};

export const getReceiptPreviewPdf = () => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.getReceiptPreviewPdf(garageId);
};

export const getInternalPreviewPdf = () => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.getInternalPreviewPdf(garageId);
};

export const getCanPrintnodeBeUsed = (status) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getCanPrintnodeBeUsed(garageId, status);
};
