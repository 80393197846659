import PropTypes from 'prop-types';

export default class FormValidator {
  static getShape = () => {
    return PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      set: PropTypes.func,
      hasError: PropTypes.func,
      registerOnUpdateListener: PropTypes.func,
      touched: PropTypes.bool,
      errors: PropTypes.array,
      getCurrentErrorMessage: PropTypes.func,
    });
  };

  #value;

  #touched;

  #dirty;

  #errors;

  /**
   * Create formValidator to use in formValidatorGroups
   * @param {any} initialValue default value of the field
   * @param {ValidatorFunction[]} validators array of validator functions
   */
  constructor(initialValue, validators) {
    this.#value = initialValue;
    this.validators = validators || [];
    this.#touched = false;
    this.#dirty = false;
    this.#errors = [];
    this.onUpdateCallbacks = [];
    this.validate();
  }

  /**
   * Returns whether user has entered and left field already
   */
  get touched() {
    return this.#touched;
  }

  set touched(value) {
    this.#touched = value;
    this.onUpdate();
  }

  /**
   * Return whether user has changed value
   */
  get dirty() {
    return this.#dirty;
  }

  set dirty(value) {
    this.#dirty = value;
    this.onUpdate();
  }

  get value() {
    return this.#value;
  }

  /**
   * Sets value without validation
   */
  set value(val) {
    this.#value = val;
    this.onUpdate();
  }

  get errors() {
    return this.#errors;
  }

  /**
   * Adds a custom errorObject to the formValidator
   * @param {Object<id: string, message: string, tooltip?: string>} error errorObject to add
   */
  addError = (error) => {
    this.#errors.push(error);
    this.onUpdate();
  };

  removeErrors = (errorIds) => {
    this.#errors = this.#errors.filter((error) => !errorIds.includes(error.id));
    this.onUpdate();
  };

  clearErrors = () => {
    this.#errors = [];
    this.onUpdate();
  };

  hasError = (error) => {
    if (!error && this.errors.length > 0) return true;
    return this.errors.find((err) => err.id === error) || false;
  };

  getCurrentErrorMessage = () => {
    if (this.errors.length === 0) return null;
    return this.errors[0].message;
  };

  getCurrentErrorToolTip = () => {
    if (this.errors.length === 0) return null;
    return this.errors[0].tooltip;
  };

  validate = () => {
    this.clearErrors();
    this.validators.forEach((validator) => {
      const res = validator(this.value);
      if (res) this.addError(res);
    });
  };

  onUpdate = () => {
    this.onUpdateCallbacks.forEach((onUpdateCallback) => onUpdateCallback(this));
  };

  registerOnUpdateListener = (callback) => {
    this.onUpdateCallbacks.push(callback);
  };

  removeOnUpdateListener = (callback) => {
    this.onUpdateCallbacks.splice(this.onUpdateCallbacks.indexOf(callback), 1);
  };

  /**
   * Sets the formValidators value
   * @param {any} newValue new field value
   * @param {bool} wasUser indicates whether value was changed by user
   * (will lead to dirty form) defaults to true
   * @returns current formValidator
   */
  set = (newValue, wasUser = true) => {
    this.value = newValue;
    if (wasUser && !this.dirty) this.dirty = true;
    this.validate();
    return this;
  };
}

FormValidator.prototype.toString = function getValidatorValue() {
  return `${this.value}`;
};
