import React from 'react';
import { Switch, Route } from 'react-router-dom';

import withTracker from './withTracker';
import Layout from './components/layout';
import Access from './components/access';
import Maintenance from './components/maintenance';

const routes = () => {
  return (
    <Switch>
      <Route path="/maintenance" component={Maintenance} />
      <Route path="/access" component={Access} />
      <Route path="/" component={withTracker(Layout)} />
    </Switch>
  );
};

export default routes;
