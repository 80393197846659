import { connect } from 'react-redux';

import { getGarage } from 'resources/garage/garage.selectors';

import Notifications from './notifications';

export default connect((state) => ({
  garage: getGarage(state),
}), {
})(Notifications);
