import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import polyglot from 'services/localization';
import { showNewSupportMessage } from 'services/supportTools';
import ButtonModern from 'components/common/buttonModern';
import { SUPPORTED_SUPPORT_LOCALES_ARRAY } from 'shared-library/src/definitions/localization';

import DeviceService from 'services/device.service';
import Figures from './svg/somethingWentWrongFigures';

import Icon from '../icon/Icon';

import styles from './retry.styles.pcss';
import InteractableDiv from '../interactableDiv';

class Retry extends Component {
  static propTypes = {
    className: PropTypes.string,
    onRetry: PropTypes.func,
    isSmall: PropTypes.bool,
    isTablet: PropTypes.bool,
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    isSmall: false,
    isTablet: false,
    errorMessage: '',
    onRetry: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      subscription: null,
    };
  }

  componentDidMount() {
    const { onRetry } = this.props;
    const lastAutoRetry = localStorage.getItem('lastAutoRetry') || 0;

    const subscription = DeviceService.addResizeListener(({ width, height }) => {
      this.setState({ isMobile: width < DeviceService.mobileSize });
    });
    this.setState({
      subscription,
    });

    // Retry if last auto retry was more than 30s ago
    if (Number(lastAutoRetry) + 1000 * 30 < Date.now()) {
      localStorage.setItem('lastAutoRetry', Date.now());
      onRetry();
    }
  }

  componentWillUnmount() {
    const { subscription } = this.state;
    if (subscription && subscription.unsubscribe) {
      subscription.unsubscribe();
    }
  }

  render() {
    const {
      className,
      onRetry,
      isSmall,
      isTablet,
      errorMessage,
    } = this.props;
    const { isMobile } = this.state;

    if (isSmall || isTablet || isMobile) {
      return (
        <div
          className={cx(className, {
            [styles.retry]: !isSmall,
            [styles.retryInside]: isSmall,
            [styles.retryTablet]: isTablet,
          })}
        >
          <div className={styles.cardContentBox}>
            <div>
              <div className={styles.header}>
                {polyglot.t('retry.oops')}
                {' '}
                <Icon type="noConnectionIcon" />
                {' '}
              </div>
            </div>

            <div>
              <div className={styles.subHeaderCard}>
                {errorMessage || polyglot.t('retry.noConnection')}
              </div>
            </div>
            {errorMessage ? console.log(errorMessage) : <div className={styles.checkConnectionCard}>{polyglot.t('retry.checkConnection')}</div>}

            <ButtonModern styleType="add" onClick={onRetry}>
              {polyglot.t('retry.retry')}
            </ButtonModern>
          </div>
        </div>
      );
    }

    return (
      <div className={cx(className, styles.contentBox)}>
        <div>
          <div className={styles.textGrid}>
            <div>
              <div className={styles.header}>{polyglot.t('retry.oops')}</div>
            </div>
            <div>
              <div className={styles.subHeader}>{polyglot.t('retry.noConnection')}</div>
            </div>
            <div className={styles.textShort}>
              {polyglot.t('retry.checkConnection')}
            </div>
          </div>
          <div className={styles.button}>
            <ButtonModern styleType="add" onClick={onRetry}>
              {polyglot.t('retry.retry')}
            </ButtonModern>
            {SUPPORTED_SUPPORT_LOCALES_ARRAY.includes(window.config.user.locale) && (
              <div className={styles.textLong}>
                {polyglot.t('retry.text1')}
                {' '}
                <InteractableDiv className={styles.chat} onClick={() => showNewSupportMessage()}>
                  {polyglot.t('retry.chat')}
                </InteractableDiv>
                {' '}
                {polyglot.t('retry.text2')}
              </div>
            )}
          </div>
        </div>
        <div><Figures /></div>
      </div>
    );
  }
}

export default Retry;
