import { MdInfo as InfoIcon } from 'react-icons/md';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faDolly } from 'fontawesome/pro-solid-svg-icons/faDolly';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './tooltip.styles.pcss';

class Tooltip extends PureComponent {
  static propTypes = {
    variant: PropTypes.string.isRequired,
    isVisable: PropTypes.bool,
    message: PropTypes.any,
    iconPosition: PropTypes.string,
    tooltipPosition: PropTypes.string,
    children: PropTypes.element,
    onClick: PropTypes.func,
    dataTest: PropTypes.string,
  };

  static defaultProps = {
    message: '',
    isVisable: true,
    dataTest: '',
  };

  render() {
    const {
      variant,
      isVisable,
      message,
      children,
      onClick,
      iconPosition,
      tooltipPosition,
      dataTest,
    } = this.props;
    return (
      isVisable && (
        variant === 'error' ? (
          <div data-test={dataTest} className={cx(styles.tooltipError, tooltipPosition)}>
            {message}
          </div>
        ) : (
          <div data-test={dataTest} className={styles.tooltipContainer}>
            {variant === 'info' && (
              <>
                <InfoIcon
                  className={cx(styles.tooltipIcon, iconPosition)}
                />
                <div className={cx(styles.tooltip, tooltipPosition)}>
                  {message}
                </div>
              </>
            )}
            {variant === 'details' && (
              <>
                <div className={cx(styles.tooltipIcon, iconPosition)} />
                <div className={cx(styles.tooltip, tooltipPosition)}>
                  {message}
                </div>
              </>
            )}
            {variant === 'isUnderInventoryMintrue' && (
              <>
                <FontAwesomeIcon
                  onClick={onClick}
                  icon={faDolly}
                  className={cx(styles.tooltipIcon, iconPosition)}
                />
                <div className={cx(styles.tooltip, tooltipPosition)}>
                  {children}
                </div>
              </>
            )}
            {variant === 'isUnderInventoryMinequal' && (
              <>
                <FontAwesomeIcon
                  icon={faDolly}
                  className={cx(styles.tooltipIcon, iconPosition)}
                  onClick={onClick}
                />
                <div className={cx(styles.tooltip, tooltipPosition)}>
                  {children}
                </div>
              </>
            )}
            {variant === 'isUnderInventoryMinempty' && (
              <>
                <FontAwesomeIcon
                  icon={faDolly}
                  className={cx(styles.tooltipIcon, iconPosition)}
                  onClick={onClick}
                />
                <div className={cx(styles.tooltip, tooltipPosition)}>
                  {children}
                </div>
              </>
            )}
            {variant === 'isUnderInventoryMinemptyNow' && (
              <>
                <FontAwesomeIcon
                  icon={faDolly}
                  className={cx(styles.tooltipIcon, iconPosition)}
                  onClick={onClick}
                />
                <div className={cx(styles.tooltip, tooltipPosition)}>
                  {children}
                </div>
              </>
            )}
          </div>
        )
      )
    );
  }
}

export default Tooltip;
