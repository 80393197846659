import * as apiClient from 'resources/api.client';

export function createCalendar(garageId, data) {
  return apiClient.post('calendars', { garageId }, data);
}
export function updateCalendar(garageId, data) {
  return apiClient.put('calendars', { garageId }, data);
}
export function deleteCalendar(garageId, link) {
  return apiClient.post('calendars/delete', { garageId }, { link });
}
export function getCalendars(garageId) {
  return apiClient.get('calendars', { garageId });
}
export function createEvent(garageId, data) {
  return apiClient.post('calendarEntries', { garageId }, data);
}
export function updateEvent(garageId, data) {
  return apiClient.put('calendarEntries', { garageId }, data);
}
export function deleteEvent(garageId, uid) {
  return apiClient.post('calendarEntries/delete', { garageId }, { uid });
}
export function getEvent(garageId, uid) {
  return apiClient.get(`calendarEntries/${uid}`, { garageId });
}
export function getEvents(garageId, {
  from = null,
  to = null,
  calendarIds = [],
  invoiceId = null,
  clientId = null,
  vehicleId = null,
  isHolidays = '',
}) {
  return apiClient.get('calendarEntries', {
    garageId,
    from,
    to,
    calendarIds,
    invoiceId,
    clientId,
    vehicleId,
    isHolidays,
  });
}
