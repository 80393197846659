import React from 'react';

const Search = (props) => (
  <svg width="96" height="100" viewBox="0 0 96 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="58.443" height="10.9581" rx="5.47903" transform="matrix(0.695308 0.718712 -0.695308 0.718712 55.3643 50.1206)" fill="#A3A9B9" />
    <ellipse cx="35.9174" cy="37.1264" rx="35.9174" ry="37.1264" fill="#429488" />
    <ellipse cx="35.9175" cy="37.1264" rx="26.938" ry="27.8448" fill="#F0F2F5" />
  </svg>
);

export default Search;
