import React from 'react';

const ImportLink = (props) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="398.667" height="396" viewBox="0 0 299 297" {...props}>
    <path d="M190.5 50l-50 50L117 76.5C104.1 63.6 93.2 53 92.7 53c-.4 0-.7 34.6-.7 77v77h77c42.4 0 77-.3 77-.8 0-.4-10.6-11.3-23.5-24.2L199 158.5l50-50C276.5 81 299 57.8 299 57c0-2-55.1-57-57-57-.8 0-24 22.5-51.5 50z" />
    <path d="M19.4 85.1c-5.5 1.6-12.7 7.2-15.9 12.3l-3 4.9-.3 86.6c-.2 57.9 0 87.8.7 90.2 1.7 5.6 6.6 11.6 12.3 14.9l5.3 3h87c82.7 0 87.2-.1 91-1.9 5.3-2.5 10.9-8.4 13.7-14.6 2.1-4.7 2.3-6.5 2.6-27.8l.4-22.7H180v10.5c0 12.2-1.1 15.6-6.3 20.2-6 5.2-8.7 5.4-71.3 5.1l-56.9-.3-4.3-2.5c-2.7-1.5-5.4-4.2-7-7l-2.7-4.5v-121l2.2-4.2c1.5-2.7 3.9-5.2 6.9-6.9 4.2-2.5 5.8-2.8 17-3.2l12.4-.5-.2-15.6-.3-15.6-23.5-.2c-12.9-.1-24.9.3-26.6.8z" />
  </svg>
);

export default ImportLink;
