import moment from 'moment';

const ACCOUNT_CREATION_OFFSET = 180;
const MAX_DAYS_IN_PAST = 180;

/**
 * Retrieves the latest rating from the user object.
 * @param {Object} user - The user object.
 * @returns {Object|null} - The latest rating object, or null if no ratings are available.
 */
export function getCurrentRatingFromState({ user }) {
  if (user.currentUser.rating && user.currentUser.rating.length > 0) {
    const sortedRatings = user.currentUser.rating.sort((a, b) =>
      moment(b.timeStamp).diff(moment(a.timeStamp)),
    );
    const [currentRating] = sortedRatings;

    if (
      currentRating &&
      moment().diff(moment(currentRating.timeStamp), 'days') <= MAX_DAYS_IN_PAST
    ) {
      return currentRating.rating ?? -1;
    }
  }
  if (moment().diff(moment(user.currentUser.createdOn), 'days') >= ACCOUNT_CREATION_OFFSET) {
    return 0;
  }
  return -1;
}

export function getCurrentRating(ratings) {
  if (ratings && ratings.length > 0) {
    const sortedRatings = ratings.sort((a, b) => moment(b.timeStamp).diff(moment(a.timeStamp)));
    const [currentRating] = sortedRatings;

    return currentRating.rating ?? -1;
  }
  return 0;
}
