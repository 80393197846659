import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { getRemoveEntity, getSureRemoveEntity } from 'helpers/confirmModalStrings';
import { DATE_FORMAT_MOMENT } from 'constants.js';
import polyglot from 'services/localization';
import * as dateHelper from 'helpers/date';
import { getSortedMileages } from 'shared-library/src/services/vehicleService';
import ActionWithConfirmModal from 'components/common/actionWithConfirmModal';
import StopEventPropagation from 'components/common/stopEventPropagation';
import MileageCell from 'components/common/mileageCell';
import OptionPicker from 'components/common/optionPicker';
import toastService from 'helpers/toastService';
import ResponsiveModal from 'components/common/responsiveModal';
import DatePickerModern from 'components/common/datePickerModern';
import NumberInputModern from 'components/common/numberInputModern';
import { ActionButtons } from '../responsiveModal/responsiveModal';

import MileageTable from './mileageTable';
import styles from './mileageModal.styles.pcss';

export default class MileageModal extends PureComponent {
  // Calculates which option should be used initially.
  // Selects the option according to the type of the most recent mileage.
  static initialSelectedMileage = (props) => {
    const sorted = getSortedMileages(props.mileage);

    // Use the history of mileages if it is available
    if (sorted?.length > 0) {
      return sorted[0].operatingHours
        ? 'operatingHours' : 'meters';
    }

    // Otherwise use the local storage or default to meters
    const mileageMode = localStorage.getItem('mileageMode');
    if (mileageMode === 'meters' || mileageMode === 'operatingHours') return mileageMode;
    return 'meters';
  };

  static propTypes = {
    mileage: PropTypes.arrayOf(PropTypes.object),
    isOpen: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    mileage: [],
  };

  constructor(props) {
    super(props);

    this.initialState = {
      date: moment.utc().format(DATE_FORMAT_MOMENT),
      meters: null,
      operatingHours: null,
      shouldValidate: false,
    };

    this.state = {
      selectedMileage: MileageModal.initialSelectedMileage(props),
      ...this.initialState,
    };
  }

  // Defines the tabs for the OptionPicker
  static options = [{
    id: 'meters',
    label: polyglot.t('mileageModal.meters'),
  },
  {
    id: 'operatingHours',
    label: polyglot.t('mileageModal.operatingHours'),
  }];

  // Sets the mileage values when the user enters them
  onChange = (fieldName, value) => {
    const { shouldValidate } = this.state;
    this.setState({ shouldValidate: value ? true : shouldValidate, [fieldName]: value });
  };

  // Called when the user wants to save a mileage value
  // Also stores the currently selected mileage mode in local storage
  onSave = (e) => {
    const {
      selectedMileage, meters, operatingHours, date,
    } = this.state;
    const { onSave, mileage } = this.props;
    this.setState({ shouldValidate: true }, () => {
      const inputValid = this.validateInput();
      if (inputValid !== '') {
        // Input is invalid
        toastService.showError(inputValid);
        return;
      }

      localStorage.setItem('mileageMode', selectedMileage);

      // Determine the new mileage from the current state
      const newMileageValue
        = selectedMileage === 'meters'
          ? { value: meters, operatingHours: null }
          : { value: null, operatingHours };

      // Add the new mileage value and save them on the server
      onSave([
        {
          date: dateHelper.getServerCorrectDate(date),
          ...newMileageValue,
        },
        ...mileage,
      ]);

      // Close the modal
      this.onClose();
    });
  };

  onClose = () => {
    const { onCancel } = this.props;
    this.setState(this.initialState);
    onCancel();
  };

  // Validate the currently active mileage field
  validateInput = () => {
    const { selectedMileage } = this.state;
    const dateValid = this.validateDate();
    if (dateValid !== '') {
      return dateValid;
    }
    return selectedMileage === 'meters'
      ? this.validateMeters()
      : this.validateHours();
  };

  validateDate = () => {
    const { shouldValidate, date } = this.state;
    return !shouldValidate || dateHelper.isValidDate(date) ? '' : polyglot.t('mileageModal.warningDate');
  };

  // Validates the meters field.
  // Returns '' on no error and the error message otherwise
  validateMeters = () => {
    const { shouldValidate, meters } = this.state;
    if (!shouldValidate) {
      return '';
    }

    if (!meters) {
      return polyglot.t('mileageModal.warningEmptyField');
    }

    return '';
  };

  // Validates the hours field.
  // Returns '' on no error and the error message otherwise
  validateHours = () => {
    const { shouldValidate, operatingHours } = this.state;
    if (!shouldValidate) {
      return '';
    }

    if (!operatingHours) {
      return polyglot.t('mileageModal.warningEmptyField');
    }

    return '';
  };

  // Renders the remove icon and sets up a deletion handler.
  // The index must refer to the corresponding mileage in this.props.mileage
  // and is used to delete the right mileage.
  renderRemoveIcon = (index) => {
    const { mileage, onRemove } = this.props;
    return (
      <StopEventPropagation events={['onClick']}>
        <ActionWithConfirmModal>
          {(onOpenConfirmModal) => (
            <div title={polyglot.t('actions.remove')}>
              <DeleteIcon
                className={cx(styles.mileageIcon, styles.mileageIconAction)}
                onClick={() => onOpenConfirmModal(
                  getRemoveEntity(polyglot.t('confirm.mileage')),
                  getSureRemoveEntity(polyglot.t('confirm.mileage')),
                  () => {
                    // Filter out the index of the mileage corresponding to this
                    // remove icon
                    const updatedMileage = mileage.filter((m, i) => i !== index);
                    onRemove(updatedMileage);
                  },
                  true,
                )}
              />
            </div>
          )}
        </ActionWithConfirmModal>
      </StopEventPropagation>
    );
  };

  // Render the table for the mileage history
  renderTable = () => {
    const { mileage } = this.props;
    if (mileage.length === 0) {
      return '';
    }
    const sortedOperations = getSortedMileages(mileage);

    const header = ['Datum', 'Laufleistung', ''];

    const content = sortedOperations.map(({
      date, value, operatingHours,
    }, index) => {
      const key = `${date}-${value}-${operatingHours}-${index}}`;
      return [
        <span key={key}>
          {dateHelper.toUTCDay(date)}
        </span>,
        <MileageCell key={key} value={value} operatingHours={operatingHours} />,
        this.renderRemoveIcon(index),
      ];
    });

    return (
      <div className={cx(styles.mileageRightElement, styles.mileageFixedWidth)}>
        <MileageTable
          header={header}
          content={content}
        />
      </div>
    );
  };

  // Render the input field for meters/hours
  renderInputField = (fieldName, unit, labelString, errorText) => {
    const { [fieldName]: inputValue } = this.state;

    return (
      <NumberInputModern
        id={fieldName}
        name={fieldName}
        label={(
          <span>
            {polyglot.t(`mileageModal.${fieldName}`)}
          </span>
        )}
        isRequired
        suffix={` ${unit}`}
        value={inputValue}
        onChange={(value) => this.onChange(fieldName, value)}
        onBlur={() => {
          const { selectedMileage } = this.state;
          setTimeout(() => this.state.selectedMileage === selectedMileage && this.props.isOpen
            && this.setState({ shouldValidate: true }), 100);
        }}
        allowNegative={false}
        className={cx(styles.mileageInput, styles.mileageMarginBottom)}
        errorMessage={errorText}
        autoFocus
      />
    );
  };

  onChangeTab = (key) => {
    this.setState({ selectedMileage: key, shouldValidate: false });
  };

  renderTab = () => {
    const { selectedMileage } = this.state;
    switch (selectedMileage) {
      case 'meters': return this.renderInputField(
        'meters',
        'km',
        polyglot.t('mileageModal.meters'),
        this.validateMeters(), // Returns the error message if there is one
      );
      case 'operatingHours': return this.renderInputField(
        'operatingHours',
        'h',
        polyglot.t('mileageModal.operatingHours'),
        this.validateHours(), // Returns the error message if there is one
      );
      default:
        return null;
    }
  };

  render() {
    const { isOpen } = this.props;
    const { date, selectedMileage } = this.state;
    return (
      <ResponsiveModal
        isOpen={isOpen}
        onClose={this.onClose}
        title={polyglot.t('mileageModal.title')}
        className={cx(styles.mileageVisibleOverflow)}
        dataTest={'mileageModal'}
      >

        <div className={cx(styles.mileageContent)}>

          {/* Left side (form to create new mileages) */}
          <div>
            <OptionPicker
              options={MileageModal.options}
              selectedOption={selectedMileage}
              onChange={this.onChangeTab}
              height={32}
              modern
              className={cx(styles.mileageMarginBottom)}
            />
            {this.renderTab()}
            <DatePickerModern
              label={(
                <span>
                  {polyglot.t('mileageModal.date')}
                </span>
              )}
              name="date"
              value={date}
              onChange={(v) => {
                if (typeof v !== 'string') return;
                this.onChange('date', v);
              }}
              className={cx(styles.mileageInput, styles.mileageMarginBottom)}
              errorMessage={this.validateDate()}
            />
            <ActionButtons
              submitLabel={polyglot.t('actions.add')}
              onSubmit={this.onSave}
              onClose={this.onClose}
            />

          </div>

          {/* Right side (mileage history) */}
          {this.renderTable()}
        </div>
      </ResponsiveModal>
    );
  }
}
