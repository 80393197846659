import React from 'react';
import PropTypes from 'prop-types';

import { curriedPrice } from 'helpers/price';

export default function ColoredPrice({
  priceValue, isCanceled, dataTest, style, className, currency,
}) {
  const styles = { ...style };
  if (isCanceled) {
    styles.color = 'rgba(0,0,0,.38)';
    styles.fontstyles = 'italic';
  } else if (Math.round(priceValue * 100) < 0) {
    styles.color = 'red';
  } else if (Math.round(priceValue * 100) > 0) {
    styles.color = 'green';
  }

  return (
    <span className={className} style={styles} data-test={dataTest}>
      {curriedPrice(priceValue, currency)}
    </span>
  );
}

ColoredPrice.propTypes = {
  priceValue: PropTypes.number,
  isCanceled: PropTypes.bool,
  dataTest: PropTypes.string,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  currency: PropTypes.string,
};

ColoredPrice.defaultProps = {
  priceValue: 0,
  isCanceled: false,
  dataTest: '',
  style: {},
  className: '',
  currency: '',
};
