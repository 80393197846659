import * as fromUser from 'resources/user/user.selectors';
import { addPageSize } from 'resources/utils';
import * as api from './sdi.api';

export const FETCH_INVOICES = 'fetchInvoices';
export const CHANGE_INVOICES_FILTER = 'changeInvoicesFilter';
export const RESET_INVOICES_STATE = 'resetInvoicesState';
export const UPDATE_SDI_STATUS_SOCKET = 'updateSdiStatusSocket';

export const sendInvoiceToSdi = (id) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.sendInvoiceToSdi(id, garageId);
};

export const sendManyInvoicesToSdi = (ids) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.sendManyInvoicesToSdi(ids, garageId);
};

export const fetchInvoicesForSdi = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoicesForSdi(garageId, data)
    .then((payload) => dispatch({ type: FETCH_INVOICES, data, payload }));
};

export const fetchInvoiceIdsShouldBeSentToSdi = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoiceIdsShouldBeSentToSdi(garageId);
};

export const changeSdiInvoicesFilter = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInvoicesForSdi(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_INVOICES_FILTER, data, payload }));
};

export const resetSdiInvoicesState = () => ({ type: RESET_INVOICES_STATE });

export const createBusinessRegistryConfiguration = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.createBusinessRegistryConfiguration(garageId);
};
