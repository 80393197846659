// https://en.wikipedia.org/wiki/International_Bank_Account_Number#Modulo_operation_on_IBAN
function modulo(num, mod = 97) {
  let pos = 9;
  let val = parseInt(num.substr(0, pos), 10);

  let tmp = (val % mod).toString();
  let n;

  while (pos < num.length - 7) {
    n = tmp + num.substr(pos, 7);
    pos += 7;

    val = parseInt(n, 10);
    tmp = (val % mod).toString();
  }

  if (pos < num.length) {
    n = tmp + num.substr(pos);
  } else {
    n = tmp;
  }

  return parseInt(n, 10) % mod;
}

// https://en.wikipedia.org/wiki/International_Bank_Account_Number#Validating_the_IBAN
export function isIBAN(string) {
  let iban = string.replace(/\s+/g, '');

  if (!/^[A-Z]{2}\d{2}[A-Z\d]{12,30}$/.test(iban)) {
    return false;
  }

  iban = `${iban.substr(4).toUpperCase()}${iban.substr(0, 4).toUpperCase()}`;

  let sum = '';
  for (let i = 0; i < iban.length; i += 1) {
    if (/[A-Z]/.test(iban[i])) {
      sum += (iban.charCodeAt(i) - 55).toString();
    } else {
      sum += iban[i];
    }
  }

  if (modulo(sum) !== 1) {
    return false;
  }

  return true;
}

export function formatIban(iban) {
  return ((iban || '').replace(/\s/g, '').match(/.{1,4}/g) || []).join(' ');
}
