import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottieFiles/Loading.json';

import Retry from 'components/common/retry';
import pageStyles from 'styles/page.pcss';

import styles from './loading.styles.pcss';

class Loading extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    error: PropTypes.shape({}),
    pastDelay: PropTypes.bool.isRequired,
    timedOut: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: '',
    error: null,
  };

  retry = () => {
    window.location.reload();
  };

  render() {
    const {
      className, error, timedOut, pastDelay,
    } = this.props;

    if (error || timedOut) {
      return (
        <Retry className={cx(pageStyles.page, pageStyles.pageContent)} onRetry={this.retry} />
      );
    }

    if (pastDelay) {
      return (
        <div className={cx(styles.loading, className)}>
          <Player
            autoplay
            loop
            src={animationData}
            style={{ height: '200px', width: '200px' }}
            lottieRef={(instance) => {
              instance.playSegments([[35, 75], [0, 75]], true);
            }}
          />
        </div>
      );
    }

    return null;
  }
}

export default Loading;
