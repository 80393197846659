import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { capitalizeWord } from 'components/common/form/form.parsers';
import withWindowSize from 'components/higherOrderComponents/withWindowSize';
import InteractableDiv from 'components/common/interactableDiv';
import ButtonLoading from 'components/common/loading/components/buttonLoading';
import styles from './select.styles.pcss';

class SelectOptionsCard extends Component {
  static propTypes = {
    actionElements: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectAnimation: PropTypes.string,
    selectedIndex: PropTypes.number,
    onClose: PropTypes.func,
    animate: PropTypes.bool,
    isOpen: PropTypes.bool,
    animationProps: PropTypes.any,
    align: PropTypes.oneOf(['left', 'right', 'default']),
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
  };

  static defaultProps = {
    animate: true,
    isOpen: false,
    align: 'default',
    className: '',
    onClose: () => {},
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();

    this.state = {
      align: props.align === 'default' ? 'right' : props.align,
    };
  }

  componentDidMount() {
    this.getAlignment();
    document.addEventListener('click', this.checkShouldClose, true);
  }

  componentDidUpdate(prevProps) {
    const { width } = this.props;

    if (width !== prevProps.width) {
      this.getAlignment();
    }
  }

  componentWillUnmount() {
    this.onClose();
    document.removeEventListener('click', this.checkShouldClose, true);
  }

  checkShouldClose = (e) => {
    const { isOpen } = this.props;
    if (!isOpen) return;
    if (this.containerRef?.current && !this.containerRef.current.contains(e.target)) {
      setTimeout(() => {
        this.onClose(e);
      }, 1);
    }
  };

  getAlignment = async () => {
    const { align } = this.props;
    if (align !== 'default' || !this.containerRef.current) return;
    const { x } = this.containerRef.current.getBoundingClientRect();
    this.setState({ align: (x < window.innerWidth / 2) ? 'left' : 'right' });
  };

  onClose = (event) => {
    const { onClose } = this.props;
    onClose(event);
  };

  onElementClick = (element, event) => {
    if (element.disabled || element.isLoading) {
      event.stopPropagation();
      return;
    }
    element.onClick(event);
    this.onClose(event);
  };

  render() {
    const {
      actionElements,
      selectAnimation,
      selectedIndex,
      animate,
      isOpen,
      animationProps,
      className,
    } = this.props;
    const {
      align,
    } = this.state;
    let content = (
      <InteractableDiv
        className={cx(
          styles.selectContainer,
          selectAnimation,
          className,
        )}
        tabIndex={-1}
      >
        {actionElements.map((element, i) => (
          <InteractableDiv
            dataTest={element.dataTest}
            key={i}
            style={i === selectedIndex ? { backgroundColor: '#AAD9D0' } : {}}
            onClick={(event) => this.onElementClick(element, event)}
            className={cx(styles.selectElement, {
              [styles.selectElementDisabled]: element.disabled,
            })}
          >
            {element.isLoading ? (
              <ButtonLoading useDarkSpinner />
            ) : (
              <>
                <div className={cx(styles.containerCardElement)}>
                  {element.icon && (
                  <>
                    {element.icon.$$typeof ? (
                      <div className={styles.icon}>
                        {element.icon}
                      </div>
                    ) : (
                      <FontAwesomeIcon icon={element.icon} className={styles.icon} />
                    )}
                  </>
                  )}
                </div>
                <div className={cx(styles.containerCardElement)}>
                  {element.label}
                </div>
              </>
            )}
          </InteractableDiv>
        ))}
      </InteractableDiv>
    );

    if (animate) {
      content = (
        <CSSTransition
          in={isOpen}
          timeout={500}
          mountOnEnter
          unmountOnExit
          appear
          classNames={{
            enterActive: styles.fadeIn,
            exitActive: styles.fadeOut,
          }}
          {...animationProps}
        >
          {content}
        </CSSTransition>
      );
    } else {
      content = (
        <>
          {isOpen && content}
        </>
      );
    }

    content = (
      <div ref={this.containerRef} className={cx(styles.container, styles[`selectAlign${capitalizeWord(align)}`])}>
        {content}
      </div>
    );

    return content;
  }
}

export default withWindowSize(SelectOptionsCard);
