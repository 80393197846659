import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import polyglot from 'services/localization';
import toastService from 'helpers/toastService';

import VehicleForm from 'components/common/vehicleForm';
import ResponsiveModal from 'components/common/responsiveModal';
import _ from 'lodash';
import ButtonModern from '../buttonModern';

class VehicleEditModal extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    createVehicle: PropTypes.func.isRequired,
    updateVehicle: PropTypes.func.isRequired,
    vehicle: PropTypes.shape({
      _id: PropTypes.string,
      identificationNumber: PropTypes.string,
      client: PropTypes.shape({}),
    }),
    client: PropTypes.shape({}),
    selectCustomerDisabled: PropTypes.bool,
    children: PropTypes.element,
    customerLinkDisabled: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    isClientFieldShown: PropTypes.bool,
    isRoutingDisabled: PropTypes.bool,
  };

  static defaultProps = {
    vehicle: undefined,
    selectCustomerDisabled: false,
    customerLinkDisabled: false,
    children: null,
    isOpen: undefined,
    onClose: () => null,
    onSuccess: () => null,
    isClientFieldShown: true,
    client: null,
    isRoutingDisabled: false,
  };

  state = {
    AccessibleComponent: null,
    isModalOpen: false,
  };

  componentDidMount() {
    const { children } = this.props;
    if (children) {
      this.setState({
        AccessibleComponent: React.cloneElement(React.Children.only(children), {
          onClick: this.toggleModal,
        }),
      });
    }
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onCreateFormSucceed = ({ payload: vehicle }) => {
    const { history, isRoutingDisabled, onSuccess } = this.props;
    if (!isRoutingDisabled) { history.push(`/vehicles/all/${vehicle._id}`); } else { this.toggleModal(); }
    onSuccess(vehicle);
  };

  onUpdateFormSucceed = (vehicle) => {
    const { onSuccess } = this.props;
    toastService.showSuccess(polyglot.t('editVehicle.notifications.updated'));
    this.toggleModal();
    onSuccess(vehicle);
  };

  onSubmit = (vehicle) => {
    const {
      createVehicle,
      updateVehicle,
    } = this.props;
    const newVehicle = _.cloneDeep(vehicle);
    if (newVehicle.identificationNumber) {
      newVehicle.identificationNumber = newVehicle.identificationNumber.trim();
    }

    if (newVehicle?._id) {
      return updateVehicle(newVehicle._id, newVehicle)
        .then(({ data: vehicleRes }) => this.onUpdateFormSucceed(vehicleRes));
    }

    return createVehicle(newVehicle).then(this.onCreateFormSucceed);
  };

  renderButtons = (form) => {
    const { isOpen, onClose } = this.props;
    return (
      <Fragment>
        <ButtonModern
          styleType="add"
          dataTest="addVehicleSave"
          type="submit"
          disabled={form.invalid || form.submitting}
        >
          {polyglot.t('actions.save')}
        </ButtonModern>
        <ButtonModern
          styleType="cancel"
          onClick={isOpen === undefined ? this.toggleModal : () => onClose(true)}
        >
          {polyglot.t('actions.cancel')}
        </ButtonModern>
      </Fragment>
    );
  };

  render() {
    const {
      vehicle,
      selectCustomerDisabled,
      customerLinkDisabled,
      isClientFieldShown,
      isOpen,
      onClose,
      client,
    } = this.props;
    const { AccessibleComponent, isModalOpen } = this.state;

    const modalOpen = isOpen === undefined ? isModalOpen : isOpen;
    return (
      <Fragment>
        {AccessibleComponent}
        <ResponsiveModal
          isOpen={modalOpen}
          width={900}
          onClose={isOpen === undefined ? this.toggleModal : () => onClose(true)}
          title={vehicle && !selectCustomerDisabled
            ? polyglot.t('vehicles.updateVehicle')
            : polyglot.t('vehicles.createVehicle')}
        >
          <VehicleForm
            onSubmit={this.onSubmit}
            vehicle={vehicle}
            client={vehicle?.client || client}
            renderButtons={this.renderButtons}
            selectCustomerDisabled={selectCustomerDisabled}
            customerLinkDisabled={customerLinkDisabled}
            isClientFieldShown={isClientFieldShown}
          />
        </ResponsiveModal>
      </Fragment>
    );
  }
}

export default VehicleEditModal;
