import React, { PureComponent } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import { engineCategoriesItems } from 'helpers/engineCategories';
import { toUpperCase, capitalizeOnFirstInput } from 'components/common/form/form.parsers';
import modalStyles from 'styles/modal.pcss';
import { KWtoHP } from 'helpers/convertUnit';
import { getManufacturersDropdown } from 'resources/manufacturers/manufacturers.selectors';
import store from 'resources/store';
import { getHsnTsn } from 'resources/hsnTsn/hsnTsn.api';
import { getNatCode } from 'resources/natCode/natCode.api';
import FormValidator from 'helpers/formValidation/formValidator';
import FormValidatorGroup from 'helpers/formValidation/formValidatorGroup';
import Validators from 'helpers/formValidation/validators';
import InputModern from 'components/common/inputModern';
import EditMileages from 'components/common/editMileages';
import InputDropdownModern from 'components/common/inputDropdownModern';
import DropdownModern from 'components/common/dropdownModern';
import NumberInputModern from 'components/common/numberInputModern';
import DatePickerModern from 'components/common/datePickerModern';
import TextareaModern from 'components/common/textareaModern';
import SelectCustomer from 'components/common/selectCustomerModern';
import { getVehicleFormLabel } from 'helpers/vehicle';

import { getFieldByLabel, getIdentificationNumber } from 'resources/identificationNumber/identificationNumber.api';
import { carClasses, getCarClassesDropdown } from 'shared-library/src/definitions/carClasses';
import { GARAGE_COUNTRIES } from 'shared-library/src/definitions/garageCountries';
import styles from './vehicleForm.styles.pcss';

const typesOfTransmission = [{
  key: '',
  label: polyglot.t('editVehicle.form.typeOfTransmission.choose'),
}, {
  key: 'automatic',
  label: polyglot.t('editVehicle.form.typeOfTransmission.automatic'),
}, {
  key: 'manual',
  label: polyglot.t('editVehicle.form.typeOfTransmission.manual'),
}];

const garageTypes = [
  { vehicleName: 'car', vehicleClass: 'M1' },
  { vehicleName: 'motorcycle', vehicleClass: 'L1e' },
  { vehicleName: 'bicycle', vehicleClass: null },
  { vehicleName: 'tractor', vehicleClass: 'T1' },
  { vehicleName: 'truck', vehicleClass: 'C1' },
  { vehicleName: 'truck-monster', vehicleClass: 'M1' },
  { vehicleName: 'shuttle-van', vehicleClass: 'M2' },
  { vehicleName: 'caravan', vehicleClass: 'M1' },
  { vehicleName: 'trailer', vehicleClass: 'O1' },
  { vehicleName: 'moped', vehicleClass: 'L1e' },
];

class VehicleForm extends PureComponent {
  static propTypes = {
    vehicle: PropTypes.shape({
      _id: PropTypes.string,
      model: PropTypes.string,
      license: PropTypes.string,
      identificationNumber: PropTypes.string,
      nationalCode: PropTypes.string,
      engineCode: PropTypes.string,
      colorCode: PropTypes.string,
      typeOfTransmission: PropTypes.string,
      engineCategory: PropTypes.string,
      customField: PropTypes.string,
      additionalInfo: PropTypes.string,
      manufacturer: PropTypes.string,
      carClass: PropTypes.string,
      dateOfFirstRegistration: PropTypes.string,
      dateOfLastRegistration: PropTypes.string,
      acceptanceDate: PropTypes.string,
      dateOfNextInspection: PropTypes.string,
      HSNCode: PropTypes.string,
      TSNCode: PropTypes.string,
      baseNumberCh: PropTypes.string,
      documentNumberCh: PropTypes.string,
      vehicleVersion: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        size: PropTypes.number,
        createdAt: PropTypes.string,
        filename: PropTypes.string,
        name: PropTypes.string,
      })),
      enginePower: PropTypes.number,
      ownWeight: PropTypes.number,
      maxWeight: PropTypes.number,
      engineDisplacement: PropTypes.number,
      mileage: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        date: PropTypes.string,
        operatingHours: PropTypes.number,
      })),
    }),
    client: PropTypes.shape({
      _id: PropTypes.string,
    }),
    country: PropTypes.string.isRequired,
    defaultCarClass: PropTypes.string.isRequired,
    manufacturers: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      priority: PropTypes.number,
    })).isRequired,
    renderButtons: PropTypes.func,
    buttonsClasses: PropTypes.string,
    selectCustomerDisabled: PropTypes.bool,
    customerLinkDisabled: PropTypes.bool,
    fetchManufacturers: PropTypes.func.isRequired,
    className: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    searchOnBlur: PropTypes.func,
    garage: PropTypes.shape({
      vehicleCustomFieldName: PropTypes.string,
    }).isRequired,
    isClientFieldShown: PropTypes.bool,
  };

  static defaultProps = {
    vehicle: {},
    client: {},
    buttonsClasses: '',
    className: '',
    renderButtons: () => null,
    searchOnBlur: () => {},
    selectCustomerDisabled: false,
    customerLinkDisabled: false,
    isClientFieldShown: true,
  };

  static searchedFields = ['license', 'model', 'manufacturer'];

  constructor(props) {
    super(props);

    this.lastHsnTsnCheckValue = '';

    this.state = {
      vehicleForm: this.generateVehicleForm(),
    };

    this.licenseInputRef = React.createRef();
    this.nationalFieldInputRef = React.createRef();
  }

  componentDidMount() {
    const {
      manufacturers,
      fetchManufacturers,
    } = this.props;

    if (!manufacturers.length) {
      fetchManufacturers();
    }

    setTimeout(() => {
      this.focusLicenseInput();
    }, 100);
  }

  componentDidUpdate(prevProps) {
    const { vehicle } = this.props;
    if (vehicle._id !== prevProps.vehicle._id) {
      const newVehicleForm = this.generateVehicleForm();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ vehicleForm: newVehicleForm });
    }
  }

  generateVehicleForm = () => {
    const {
      vehicle, onSubmit, searchOnBlur, country, client,
    } = this.props;
    const editedClient = {
      country,
      ...client,
      clientId: client ? client._id : null,
    };

    const maxLen = 200;
    const { defaultCarClass } = this.props;
    let vehicleCopy = { ...vehicle };
    if (!vehicleCopy) {
      vehicleCopy = {};
    }
    if (!vehicleCopy._id && !vehicleCopy.carClass) {
      vehicleCopy.carClass = garageTypes
        .find((garageType) => { return garageType.vehicleName === defaultCarClass; }).vehicleClass;
    }
    const formValidatorGroup = new FormValidatorGroup({
      client: new FormValidator(editedClient, []),
      model: new FormValidator('', [Validators.required(polyglot.t('editVehicle.form.model.requiredError')), Validators.maxLength(maxLen, polyglot.t('editVehicle.form.model.validationError'))]),
      license: new FormValidator('', [Validators.maxLength(maxLen, polyglot.t('editVehicle.form.license.error'))]),
      mileage: new FormValidator([], []),
      identificationNumber: new FormValidator('', []),
      nationalCode: new FormValidator('', []),
      engineCode: new FormValidator('', [Validators.maxLength(maxLen, polyglot.t('editVehicle.form.engineCode.error'))]),
      colorCode: new FormValidator('', []),
      typeOfTransmission: new FormValidator('', [Validators.oneOf(typesOfTransmission.map(({ key }) => key), polyglot.t('editVehicle.form.engineCode.typeOfTransition'))]),
      engineCategory: new FormValidator('', []),
      customField: new FormValidator('', []),
      additionalInfo: new FormValidator('', []),
      manufacturer: new FormValidator('', [Validators.required(polyglot.t('editVehicle.form.manufacturer.error'))]),
      carClass: new FormValidator('', []),
      images: new FormValidator([], []),
      dateOfFirstRegistration: new FormValidator('', []),
      dateOfLastRegistration: new FormValidator('', []),
      acceptanceDate: new FormValidator('', []),
      dateOfNextInspection: new FormValidator('', []),
      enginePower: new FormValidator(null, []),
      ownWeight: new FormValidator(null, []),
      maxWeight: new FormValidator(null, []),
      engineDisplacement: new FormValidator(null, []),
      HSNCode: new FormValidator('', []),
      TSNCode: new FormValidator('', []),
      baseNumberCh: new FormValidator('', []),
      documentNumberCh: new FormValidator('', [Validators.maxLength(polyglot.t('editVehicle.form.baseNumberCh.error'))]),
      vehicleVersion: new FormValidator('', []),
    }, this, vehicleCopy, onSubmit, this.validateVehicleForm);

    formValidatorGroup.onDebounce = searchOnBlur;

    return formValidatorGroup;
  };

  // Custom validator
  validateVehicleForm = (vehicleForm) => {
    const { isClientFieldShown } = this.props;
    const { client } = vehicleForm.group;
    const doesClientExist = client.value?.clientId || client.value?._id;
    let error = null;
    if (!doesClientExist && isClientFieldShown) {
      error = { id: 'noClientError', message: polyglot.t('editVehicle.clientsModal.title') };
    }
    return error;
  };

  focusLicenseInput = () => {
    if (this.licenseInputRef
      && this.licenseInputRef.current
      && this.licenseInputRef.current.inputRef
      && this.licenseInputRef.current.inputRef.current) {
      this.licenseInputRef.current.inputRef.current.focus();
    }
  };

  onSubmit = (values) => {
    const { onSubmit } = this.props;

    onSubmit({
      ...values,
    });
  };

  checkIdentificationNumber = (evt) => {
    const { vehicleForm } = this.state;

    const {
      manufacturer,
      ownWeight,
      maxWeight,
      model,
      enginePower,
      engineCode,
      engineDisplacement,
      vehicleVersion,
      engineCategory,
      additionalInfo,
      typeOfTransmission,
    } = vehicleForm.group;

    const engineCategories = ['A | Andere', 'D | Diesel-Motor S | Saug-Diesel', 'D | Diesel-Motor TD | Turbo-Diesel', 'E | Elektromotor', 'HD | Hybrid Diesel', 'HB | Hybrid Benzin'];

    const vinRequestIsUseful = !model.value
     && !manufacturer.value
      && !ownWeight.value
       && !maxWeight.value;

    if (evt && evt.length === 17 && vinRequestIsUseful) {
      getIdentificationNumber(evt).then((r) => {
        if (!r || r.error) return;
        manufacturer.set(!manufacturer.value ? getFieldByLabel(r, 'make') : manufacturer.value);
        model.set(!model.value ? getFieldByLabel(r, 'model') : model.value);
        maxWeight.set(!maxWeight.value ? getFieldByLabel(r, 'max weight (kg)') : maxWeight.value);
        ownWeight.set(!ownWeight.value ? getFieldByLabel(r, 'weight empty (kg)') : ownWeight.value);
        enginePower.set(!enginePower.value ? getFieldByLabel(r, 'engine power (kw)') : enginePower.value);
        engineDisplacement.set(!engineDisplacement.value ? getFieldByLabel(r, 'engine displacement (ccm)') : engineDisplacement.value);
        engineCode.set(!engineCode.value ? getFieldByLabel(r, 'engine code') : engineCode.value);

        const series = getFieldByLabel(r, 'series');
        const seriesDisplay = (model.value !== series ? series : '');
        vehicleVersion.set(!vehicleVersion.value ? seriesDisplay : vehicleVersion.value);
        typeOfTransmission.set(!typeOfTransmission.value ? getFieldByLabel(r, 'transmission', 'manual').toLowerCase() : typeOfTransmission.value);

        const wheel = getFieldByLabel(r, 'wheel size');
        const wheelDisplay = (wheel ? `Reifen Maße: ${wheel}` : '');
        additionalInfo.set(!additionalInfo.value ? wheelDisplay : additionalInfo.value);

        const fuel = getFieldByLabel(r, 'fuel system').toLowerCase();
        const engineTurbine = getFieldByLabel(r, 'engine turbine').toLowerCase();

        if (fuel) {
          if (fuel.includes('diesel')) {
            if (engineTurbine.includes('turbo')) {
              engineCategory.set(!engineCategory.value
                ? engineCategories[2] : engineCategory.value);
            } else if (engineTurbine.includes('suck') || engineTurbine.includes('suction')) {
              engineCategory.set(!engineCategory.value
                ? engineCategories[1] : engineCategory.value);
            }
          }
        }
      }).catch((e) => {
        // Ignore the thrown exception.
      });
    }
  };

  checkHsnTsn = () => {
    const { vehicleForm } = this.state;

    const {
      HSNCode,
      TSNCode,
      manufacturer,
      maxWeight,
      model,
      carClass,
      enginePower,
      engineDisplacement,
      acceptanceDate,
    } = vehicleForm.group;

    const hsnVal = HSNCode.value;
    const tsnVal = TSNCode.value;

    if (hsnVal && tsnVal && hsnVal.length === 4 && tsnVal.length === 3) {
      const hsnTsn = `${hsnVal}/${tsnVal}`;

      if (this.lastHsnTsnCheckValue !== hsnTsn) {
        this.lastHsnTsnCheckValue = hsnTsn;
        getHsnTsn(hsnVal, tsnVal).then((r) => {
          if (r.brand) {
            manufacturer.set(r.brand);
            maxWeight.set(r.maxWeight);
            model.set(r.modelName);
            carClass.set(r.vehicleClass);
            enginePower.set(r.powerKw);
            engineDisplacement.set(r.ccm);
            acceptanceDate.set(r.tsnDate);
          }
        }).catch((e) => {
        });
      }
    }

    const isManufacturerSet = manufacturer.value && manufacturer.value !== 'Sonstiger Hersteller';

    if (hsnVal && hsnVal.length === 4 && !isManufacturerSet) {
      const manufacturers = getManufacturersDropdown(store.getState());
      const foundMan = manufacturers
        .find(({ hsn = [] }) => hsn.includes(hsnVal)) || {};

      if (foundMan && foundMan.key) {
        manufacturer.set(foundMan.key);
      }
    }
  };

  formatBaseNumberCh = () => {
    const { vehicleForm } = this.state;
    const { baseNumberCh } = vehicleForm.group;
    if (/^\d{9}$/.test(baseNumberCh.value)) {
      baseNumberCh.set(baseNumberCh.value.replace(/(\d{3})(\d{3})(\d{3})/, '$1.$2.$3'));
    }
  };

  checkNatCode = async () => {
    const { vehicleForm } = this.state;

    const {
      nationalCode,
      manufacturer,
      maxWeight,
      model,
      enginePower,
      engineDisplacement,
      acceptanceDate,
      ownWeight,
      typeOfTransmission,
    } = vehicleForm.group;

    if (nationalCode && nationalCode.value.length === 6) {
      await getNatCode(nationalCode.value).then((r) => {
        if (r.brand) {
          manufacturer.set(r.brand);
          ownWeight.set(r.nettoWeight);
          maxWeight.set(r.grossWeight);
          model.set(r.model);
          enginePower.set(r.powerKw);
          engineDisplacement.set(r.ccm);
          acceptanceDate.set(r.tsnDate);
          typeOfTransmission.set(r.transmission);
        }
      }).catch((e) => {
      });
    }
  };

  checkVin = () => {
    const { vehicleForm } = this.state;
    const { manufacturer, identificationNumber } = vehicleForm.group;

    const vin = identificationNumber.value;

    const isManufacturerSet = manufacturer.value && manufacturer.value !== 'Sonstiger Hersteller';

    if (vin && vin.length >= 6 && !isManufacturerSet) {
      const manufacturers = getManufacturersDropdown(store.getState());
      const foundMan = manufacturers
        .find(({ vinStart = [] }) => {
          return vinStart.includes(vin) || vinStart.includes(vin.slice(0, 3));
        }) || {};

      if (foundMan && foundMan.key) {
        manufacturer.set(foundMan.key);
      }
    }
  };

  renderNationalField(country) {
    const { vehicleForm } = this.state;
    switch (country) {
      case 'DE':
      case 'LU':
      case 'NL':
        return (
          <>
            <InputModern
              label={getVehicleFormLabel('HSNCode')}
              formValidator={vehicleForm.group.HSNCode}
              onChange={this.checkHsnTsn}
              ref={this.nationalFieldInputRef}
              inputMode="numeric"
            />
            <InputModern
              label={getVehicleFormLabel('TSNCode')}
              formValidator={vehicleForm.group.TSNCode}
              onChange={this.checkHsnTsn}
              parse={toUpperCase}
            />
          </>
        );
      case 'LI':
      case 'CH':
        return (
          <>
            <InputModern
              label={getVehicleFormLabel('baseNumberCh')}
              formValidator={vehicleForm.group.baseNumberCh}
              parse={toUpperCase}
              ref={this.nationalFieldInputRef}
              onBlur={this.formatBaseNumberCh}
            />
            <InputModern
              label={getVehicleFormLabel('documentNumberCh')}
              formValidator={vehicleForm.group.documentNumberCh}
              parse={toUpperCase}
            />
          </>
        );
      case 'AT':
      default:
        return (
          <>
            <InputModern
              label={getVehicleFormLabel('nationalCode')}
              formValidator={vehicleForm.group.nationalCode}
              ref={this.nationalFieldInputRef}
              onChange={this.checkNatCode}
              inputMode="numeric"
            />
          </>
        );
    }
  }

  render() {
    const {
      manufacturers,
      renderButtons,
      buttonsClasses,
      isClientFieldShown,
      selectCustomerDisabled,
      customerLinkDisabled,
      className,
      garage,
      country,
    } = this.props;

    const { vehicleForm } = this.state;
    const {
      license,
      client,
      identificationNumber,
      enginePower,
    } = vehicleForm.group;

    const { Form } = vehicleForm;
    let adjustedCountry = country;
    const isSupportetCountry = Object.values(GARAGE_COUNTRIES).includes(client?.value?.country);
    if (isSupportetCountry) {
      adjustedCountry = client.value.country;
    }
    const carClassesMap = getCarClassesDropdown(polyglot, carClasses);
    if (carClassesMap.length && carClassesMap[0].key !== '') {
      carClassesMap.unshift({ key: '', label: polyglot.t('actions.select') });
    }

    return (
      <div
        className={cx(
          className,
        )}
      >
        <Form>
          {isClientFieldShown ? (
            <SelectCustomer
              className={styles.vehicleFormCustomer}
              client={client.value}
              country={adjustedCountry}
              onClientSelected={(cli) => { client.set(cli); this.focusLicenseInput(); }}
              disabled={selectCustomerDisabled}
              linkDisabled={customerLinkDisabled}
              onChangeEx={this.focusLicenseInput}
            />
          ) : null}
          <div
            className={styles.vehicleForm}
            data-test="addVehicleModalForm"
          >
            <div className={styles.vehicleFormRow}>
              <InputModern
                label={getVehicleFormLabel('license')}
                formValidator={license}
                placeholder={'SD 123AB'}
                parse={toUpperCase}
                ref={this.licenseInputRef}
                dataTest="addVehicleLicenceInput"
              />
              <EditMileages
                vehicle={vehicleForm.getValues()}
                formValidator={vehicleForm.group.mileage}
                focusOnCloseInputRef={this.nationalFieldInputRef}
              />
            </div>
            <div className={styles.vehicleFormRow}>
              {this.renderNationalField(adjustedCountry)}
            </div>
            <div className={styles.vinWrapper}>
              <InputModern
                label={getVehicleFormLabel('identificationNumber')}
                formValidator={identificationNumber}
                onChange={(evt) => this.checkIdentificationNumber(evt)}
                parse={toUpperCase}
                dataTest="addVehicleIdentificationNumberInput"
                onBlur={this.checkVin}
              />
              <span
                className={cx(
                  styles.vinCounter,
                  identificationNumber.value.length === 17 && styles.vinCounterGreen,
                )}
              >
                {identificationNumber.value ? identificationNumber.value.length : 0}
              </span>
            </div>
            <InputDropdownModern
              label={getVehicleFormLabel('manufacturer')}
              formValidator={vehicleForm.group.manufacturer}
              items={manufacturers}
              parse={capitalizeOnFirstInput}
              preselectFirstItem
              dataTest="addVehicleManufacturersInput"
            />
            <InputModern
              label={getVehicleFormLabel('model')}
              formValidator={vehicleForm.group.model}
              parse={capitalizeOnFirstInput}
              dataTest="addVehicleModelInput"
            />
            <InputModern
              label={getVehicleFormLabel('vehicleVersion')}
              formValidator={vehicleForm.group.vehicleVersion}
              parse={toUpperCase}
              dataTest="addVehicleVersionInput"
            />
            <DropdownModern
              label={getVehicleFormLabel('carClass')}
              formValidator={vehicleForm.group.carClass}
              items={carClassesMap}
              allowCustom={false}
              dataTest="addVehicleCarClassInput"
            />
            <DropdownModern
              label={getVehicleFormLabel('typeOfTransmission')}
              formValidator={vehicleForm.group.typeOfTransmission}
              items={typesOfTransmission}
              allowCustom={false}
              dataTest="addVehicleCarTransitionInput"
            />
            <div className={styles.vehicleFormRowBlock}>
              <div className={styles.vehicleFormRow}>
                <NumberInputModern
                  label={getVehicleFormLabel('ownWeight')}
                  formValidator={vehicleForm.group.ownWeight}
                  allowNegative={false}
                  dataTest="ownWeight"
                  suffix=" kg"
                />
                <NumberInputModern
                  label={getVehicleFormLabel('maxWeight')}
                  formValidator={vehicleForm.group.maxWeight}
                  allowNegative={false}
                  dataTest="addVehicleMaxWeightInput"
                  suffix=" kg"
                />
              </div>
              <div className={styles.vehicleFormRow}>
                <NumberInputModern
                  label={getVehicleFormLabel('enginePower')}
                  formValidator={enginePower}
                  dataTest="addVehicleEnginePowerInput"
                  suffix=" kw"
                  unit={`${KWtoHP(enginePower.value || 0)} ${polyglot.t('vehicles.hp')}`}
                />
                <NumberInputModern
                  label={getVehicleFormLabel('engineDisplacement')}
                  formValidator={vehicleForm.group.engineDisplacement}
                  allowNegative={false}
                  dataTest="addVehicleEngineDisplacementInput"
                  suffix=" cm³"
                />
              </div>
            </div>
            <div className={cx(styles.vehicleFormRowBlock, styles.vehicleFormDates)}>
              <div className={styles.vehicleFormRow}>
                {adjustedCountry !== 'AT' && (
                  <DatePickerModern
                    label={getVehicleFormLabel('dateOfNextInspection')}
                    formValidator={vehicleForm.group.dateOfNextInspection}
                    dataTest="addVehicleNextInspectionInput"
                  />
                )}
                <DatePickerModern
                  label={getVehicleFormLabel('dateOfLastRegistration')}
                  formValidator={vehicleForm.group.dateOfLastRegistration}
                  dataTest="addVehicleLastRegistrationInput"
                />
              </div>
              <div className={styles.vehicleFormRow}>
                <DatePickerModern
                  label={getVehicleFormLabel('dateOfFirstRegistration')}
                  formValidator={vehicleForm.group.dateOfFirstRegistration}
                  dataTest="addVehicleFirstRegistrationInput"
                />
                <DatePickerModern
                  label={getVehicleFormLabel('acceptanceDate')}
                  formValidator={vehicleForm.group.acceptanceDate}
                  dataTest="addVehicleAcceptanceDateInput"
                />
              </div>
            </div>
            <InputDropdownModern
              label={getVehicleFormLabel('engineCategory')}
              formValidator={vehicleForm.group.engineCategory}
              items={engineCategoriesItems}
              allowCustom={false}
            />
            <div className={styles.vehicleFormRow}>
              <InputModern
                label={getVehicleFormLabel('engineCode')}
                formValidator={vehicleForm.group.engineCode}
                parse={toUpperCase}
              />
              <InputModern
                label={getVehicleFormLabel('colorCode')}
                formValidator={vehicleForm.group.colorCode}
                dataTest="addVehicleColorCodeInput"
              />
            </div>
            <InputModern
              className={cx(styles.vehicleFormElement)}
              label={garage.vehicleCustomFieldName}
              formValidator={vehicleForm.group.customField}
            />
            <TextareaModern
              className={styles.vehicleFormAdditionalInfo}
              label={getVehicleFormLabel('additionalInfo')}
              formValidator={vehicleForm.group.additionalInfo}
              rows="5"
              dataTest="addVehicleAdditionalInfoInput"
            />
          </div>
          <div className={cx(modalStyles.footerModern, styles.vehicleFormButtons, buttonsClasses)}>
            {renderButtons(vehicleForm)}
          </div>
        </Form>
      </div>
    );
  }
}

export default VehicleForm;
