import { connect } from 'react-redux';
import {
  fetchClients,
  createClient,
  updateClient,
  changeClientsFilter,
  resetClientsState,
} from 'resources/clients/clients.actions';
import ClientsTableModal from './clientsTableModal';

export default connect(null, {
  fetchClients,
  changeClientsFilter,
  resetClientsState,
  createClient,
  updateClient,
})(ClientsTableModal);
