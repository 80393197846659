import React, { Component } from 'react';

import styles from './backgroundBlob.styles.pcss';

class BackgroundBlob extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={styles.backgroundBlob}>
        <div className={styles.backgroundBlobBackground}>
          <div className={styles.backgroundBlobBackgroundBlob}>
            <svg width="801" height="620" viewBox="0 0 801 620" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M783.059 153.159C804.76 211.489 806.448 275.356 789.466 341.617C771.908 410.129 750.968 486.131 685.01 535.745C620.401 584.344 534.94 577.936 456.661 591.953C383.68 605.022 308.836 630.987 243.848 614.743C174.978 597.528 130.398 550.471 92.2493 500.865C48.018 443.35 -21.2423 383.981 7.67247 307.636C36.6929 231.013 151.975 201.252 226.707 145.852C291.963 97.4772 406.937 40.7703 484.084 14.4332C567.282 -13.9698 609.148 4.63648 678.594 32.9749C746.928 60.8595 759.642 90.2208 783.059 153.159Z" fill="#AAD9D0" />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default BackgroundBlob;
