/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { MdGetApp as DownloadIcon, MdLabel as LabelIcon } from 'react-icons/md';
import * as fromVehicles from 'resources/vehicles/vehicles.selectors';
import * as date from 'helpers/date';
import polyglot from 'services/localization';
import NumberInput from 'components/common/numberInput';
import Input from 'components/common/input';
import Dropdown from 'components/common/dropdown';
import StopEventPropagation from 'components/common/stopEventPropagation';
import { downloadFileFromUrlAsBlob } from 'helpers/file';
import {
  printStorageConfirmation,
  getConfirmationPdfUrl,
} from 'resources/tires/tires.actions';
import { printTireLabels } from 'helpers/printTireLabels';
import InputDropdown from 'components/common/inputDropdown';
import Textarea from 'components/common/textarea';
import { changeVehiclesFilterByClientId, resetVehiclesState } from 'resources/vehicles/vehicles.actions';
import ClientsTableModal from 'components/common/clientsTableModal';
import DatePicker from 'components/common/datePicker';
import * as tire from 'shared-library/src/definitions/tire';
import { getIconForModel } from 'helpers/tireIcons';
import pageStyles from 'styles/page.pcss';
import modalStyles from 'styles/modal.pcss';
import ConfirmModal from 'components/common/confirmModal';
import PrintButton from 'components/common/printButton';
import Button from 'components/common/button';
import tableStyles from 'styles/table.pcss';
import LinkButton from 'components/common/linkButton';

import InteractableDiv from 'components/common/interactableDiv/interactableDiv.jsx';
import PreviousTires from './previousTires.jsx'; // eslint-disable-line import/extensions
import styles from './tireModal.styles.pcss';
import ResponsiveModal from '../../../common/responsiveModal';
import { getSuppliersShops, getGarage } from '../../../../resources/garage/garage.selectors';

class TireModal extends PureComponent {
  static propTypes = {
    vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentVehicle: PropTypes.shape({
      _id: PropTypes.string,
      client: PropTypes.shape({
        clientId: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      manufacturer: PropTypes.string,
      model: PropTypes.string,
      license: PropTypes.string,
      carClass: PropTypes.string,
    }).isRequired,
    vehicle: PropTypes.shape({
      _id: PropTypes.string,
      client: PropTypes.shape({
        clientId: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      manufacturer: PropTypes.string,
      model: PropTypes.string,
      license: PropTypes.string,
      carClass: PropTypes.string,
    }).isRequired,
    changeVehiclesFilterAction: PropTypes.func.isRequired,
    resetVehiclesStateAction: PropTypes.func.isRequired,
    defaultData: PropTypes.shape({
      _id: PropTypes.string,
      storedOn: PropTypes.string.isRequired,
      removedOn: PropTypes.string,
      storageLocation: PropTypes.string,
      client: PropTypes.object,
      vehicle: PropTypes.object,
      frontTires: PropTypes.object,
      backTires: PropTypes.object,
      additionalInfo: PropTypes.string,
    }),
    isOpen: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onValidateStorage: PropTypes.func.isRequired,
    resetTireToUpdate: PropTypes.func.isRequired,
    printStorageConfirmationAction: PropTypes.func.isRequired,
    isDYMOPrintingEnabled: PropTypes.bool.isRequired,
    getConfirmationPdfUrlAction: PropTypes.func.isRequired,
    canPrintnodeBeUsed: PropTypes.bool.isRequired,
    activeShops: PropTypes.shape({
      find: PropTypes.func,
    }),
    garage: PropTypes.shape({}),
    country: PropTypes.string,
  };

  static defaultProps = {
    defaultData: {
      storedOn: date.toUTCDay(moment.utc()),
      removedOn: '',
      storageLocation: '',
      client: {},
      vehicle: {},
      frontTires: {
        model: 'summer',
        rimType: 'steel',
        brand: '',
        type: '',
        width: '',
        rim: null,
        crossSection: null,
        maxLoad: '',
        speedIndex: '',
        threadPatternDepth: null,
        year: '',
      },
      backTires: {
        model: 'summer',
        rimType: 'steel',
        brand: '',
        type: '',
        width: '',
        rim: null,
        crossSection: null,
        maxLoad: '',
        speedIndex: '',
        threadPatternDepth: null,
        year: '',
      },
      additionalInfo: '',
      activeShops: null,
      garage: null,
    },
  };

  static tireWidths = tire.WIDTHS.map((width) => ({
    key: width,
    label: width,
  }));

  static tireBrands = tire.BRANDS.map((brand) => ({
    key: brand,
    label: brand,
  }));

  static tireMaxLoadIndexes = Object.keys(tire.MAX_LOAD_INDEXES).map((key) => ({
    key,
    label: tire.MAX_LOAD_INDEXES[key]
      ? `${key}: ${polyglot.t('tires.maxLoad.upTo')} ${tire.MAX_LOAD_INDEXES[key]} ${polyglot.t('tires.maxLoad.unites')}`
      : key,
  }));

  static tireSpeedIndexes = Object.keys(tire.SPEED_INDEXES).map((key) => ({
    key,
    label: tire.SPEED_INDEXES[key]
      ? `${key}: ${polyglot.t('tires.speedIndexes.upTo')} ${tire.SPEED_INDEXES[key]} ${polyglot.t('tires.speedIndexes.unites')}`
      : key,
  }));

  static tireModels = tire.MODELS.map((model) => ({
    key: model,
    label: (
      <>
        {getIconForModel(model)}
        {' '}
        {polyglot.t(`tires.models.${model}`)}
      </>
    ),
  }));

  static tireRims = tire.RIMS.map((rim) => ({
    key: rim,
    label: rim,
  }));

  static tireCrossSections = tire.CROSS_SECTIONS.map((crossSection) => ({
    key: crossSection,
    label: crossSection,
  }));

  static tireRimTypes = tire.RIM_TYPES.map((rimType) => ({
    key: rimType,
    label: polyglot.t(`tires.rimTypes.${rimType}`),
  }));

  state = this.getInitialData();

  downloadConfirmationPdf = async (tires) => {
    const { getConfirmationPdfUrlAction } = this.props;
    const pdfUrl = await getConfirmationPdfUrlAction(tires._id);

    downloadFileFromUrlAsBlob(pdfUrl, `${polyglot.t('tires.form.storageConfirmation')}.pdf`);
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { defaultData, changeVehiclesFilterAction, isOpen } = this.props;
    const { data } = this.state;
    const { defaultData: { client, storedOn, removedOn } } = nextProps;
    const vehicle = nextProps.currentVehicle?._id ? nextProps.currentVehicle : nextProps.vehicle;
    if (defaultData && nextProps.defaultData && defaultData._id !== nextProps.defaultData._id) {
      this.setState({
        data: {
          ...nextProps.defaultData,
          storedOn: date.getCorrectDate(storedOn),
          removedOn: date.getCorrectDate(removedOn),
          vehicles: isOpen ? nextProps.vehicles : null,
        },
      }, () => {
        if (client.clientId) {
          changeVehiclesFilterAction({ clientId: client.clientId });
        }
      });
      return;
    }

    if (!isOpen && nextProps.isOpen
      && !nextProps.defaultData._id && vehicle?._id) {
      const {
        _id: vehicleId, manufacturer, model, license, carClass,
      } = vehicle;
      const { clientId, firstName, lastName } = vehicle.client;
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          vehicle: {
            vehicleId, manufacturer, model, license, carClass,
          },
          vehicles: isOpen ? nextProps.vehicles : null,
          client: { clientId, firstName, lastName },
        },
      }), () => {
        changeVehiclesFilterAction({ clientId });
      });
      return;
    }

    this.setState({ data: { ...data, vehicles: isOpen ? nextProps.vehicles : null } });
  };

  componentWillUnmount = () => {
    const { resetVehiclesStateAction } = this.props;

    resetVehiclesStateAction();
  };

  onValidateSave = () => {
    const { onValidateStorage } = this.props;
    const { data } = this.state;

    onValidateStorage(data)
      .then(({ exists }) => {
        if (exists) {
          this.setState({
            isStorageModalOpen: true,
          });
          return;
        }

        this.onSave(data);
      });
  };

  onSave = () => {
    const { onSave } = this.props;
    const { data } = this.state;

    onSave(data)
      .then(this.resetState);
  };

  renderPdfActions() {
    const { hasChanged, data } = this.state;
    const {
      printStorageConfirmationAction,
      getConfirmationPdfUrlAction,
      canPrintnodeBeUsed,
    } = this.props;

    if (data.status !== 'active' || !data._id || hasChanged) {
      return null;
    }

    return (
      <div className={styles.tireFormButtonsPrintActions}>
        <div className={styles.tireFormButtonsPrintActionsLabel}>
          {
            data.removedOn ? polyglot.t('tires.form.consignmentNote') : polyglot.t('tires.form.storageConfirmation')
          }

        </div>
        <div className={styles.tireFormButtonsPrintActionsButtons}>
          {this.renderLabelAction()}
          <StopEventPropagation events={['onClick']}>
            <div className={styles.printAndDownload} type="button">
              <InteractableDiv onClick={() => this.downloadConfirmationPdf(data)} title={polyglot.t('actions.download')}>
                <DownloadIcon
                  className={cx(
                    tableStyles.tableIcon,
                    tableStyles.tableIconAction,
                  )}
                />
              </InteractableDiv>
            </div>
          </StopEventPropagation>
          <StopEventPropagation events={['onClick']}>
            <PrintButton
              className={cx(styles.printAndDownload, styles.printAndDownloadColors)}
              onPrint={() => printStorageConfirmationAction(data._id)}
              onGetPdfUrl={() => getConfirmationPdfUrlAction(data._id)}
              canPrintnodeBeUsed={canPrintnodeBeUsed}
            />
          </StopEventPropagation>
        </div>
      </div>
    );
  }

  renderLabelAction() {
    const { data } = this.state;
    const { isDYMOPrintingEnabled } = this.props;
    if (data.status !== 'active' || data.removedOn || !isDYMOPrintingEnabled) {
      return null;
    }

    return (
      <React.Fragment>
        <StopEventPropagation events={['onClick']}>
          <div className={styles.printAndDownload} type="button" title={polyglot.t('actions.printLabel')}>
            <LabelIcon
              onClick={() => { printTireLabels(data); }}
              className={cx(tableStyles.tableIcon, tableStyles.tableIconAction)}
            />
          </div>
        </StopEventPropagation>
      </React.Fragment>
    );
  }

  onChangeDate = (fieldName, value) => this.setState((prevState) => (
    { hasChanged: true, data: { ...prevState.data, [fieldName]: value } }));

  onChangeStorageLocation = ({ target }) => {
    this.setState((prevState) => ({
      hasChanged: true,
      data: { ...prevState.data, storageLocation: target.value },
    }));
  };

  onChangeAdditionalInfo = ({ target }) => {
    this.setState((prevState) => ({
      hasChanged: true,
      data: { ...prevState.data, additionalInfo: target.value },
    }));
  };

  onSelectClient = () => {
    this.setState({ isClientsTableModal: true });
  };

  onNoClientSelected = () => {
    this.setState((prevState) => ({
      isClientsTableModal: false,
      hasChanged: true,
      data: {
        ...prevState.data,
        client: {},
        vehicle: {},
      },
    }));
  };

  onClientSelected = (client) => {
    const { changeVehiclesFilterAction } = this.props;
    const validClient = { ...client, clientId: client._id };

    this.setState((prevState) => ({
      isClientsTableModal: false,
      hasChanged: true,
      data: {
        ...prevState.data,
        client: validClient,
        vehicle: prevState.data.client.clientId === validClient.clientId
          ? prevState.data.vehicle : {},
      },
    }));

    changeVehiclesFilterAction({ clientId: validClient.clientId });
  };

  onCancelClientsTableModal = () => {
    this.setState({ isClientsTableModal: false });
  };

  onVehicleSelected = (vehicleId) => {
    const { vehicles } = this.props;

    this.setState((prevState) => ({
      hasChanged: true,
      data: {
        ...prevState.data,
        vehicle: {
          vehicleId,
          ...vehicles.find((vehicle) => vehicle._id === vehicleId),
        },
      },
    }));
  };

  onRemoveNow = () => {
    this.setState((prevState) => (
      { hasChanged: true, data: { ...prevState.data, removedOn: date.toUTCDay(moment.utc()) } }));
  };

  onChangeTiresDropdown = (tiresType, fieldName, value) => {
    const diffrentTiresType = tiresType === 'frontTires' ? 'backTires' : 'frontTires';

    this.setState((prevState) => ({
      hasChanged: true,
      data: {
        ...prevState.data,
        [tiresType]: { ...prevState.data[tiresType], [fieldName]: value },
        [diffrentTiresType]: prevState[diffrentTiresType][fieldName]
          ? prevState.data[diffrentTiresType]
          : { ...prevState.data[diffrentTiresType], [fieldName]: value },
      },
      [tiresType]: { ...prevState[tiresType], [fieldName]: true },
    }));
  };

  onChangeTiresInput = (tiresType, fieldName, value) => {
    const diffrentTiresType = tiresType === 'frontTires' ? 'backTires' : 'frontTires';

    this.setState((prevState) => ({
      hasChanged: true,
      data: {
        ...prevState.data,
        [tiresType]: { ...prevState.data[tiresType], [fieldName]: value },
        [diffrentTiresType]: prevState[diffrentTiresType][fieldName]
          ? prevState.data[diffrentTiresType]
          : { ...prevState.data[diffrentTiresType], [fieldName]: value },
      },
      [tiresType]: { ...prevState[tiresType], [fieldName]: true },
    }));
  };

  onChangeFrontTiresModel = (value) => this.onChangeTiresDropdown('frontTires', 'model', value);

  onChangeFrontTiresRimType = (value) => this.onChangeTiresDropdown('frontTires', 'rimType', value);

  onSelectFrontTiresBrand = (value) => {
    this.onChangeTiresDropdown('frontTires', 'brand', value);
  };

  onChangeFrontTiresWidth = (value) => this.onChangeTiresDropdown('frontTires', 'width', value);

  onChangeFrontTiresRim = (value) => this.onChangeTiresDropdown('frontTires', 'rim', value);

  onChangeFrontTiresCrossSection = (value) => this.onChangeTiresDropdown('frontTires', 'crossSection', value);

  onChangeFrontTiresMaxLoadIndex = (value) => this.onChangeTiresDropdown('frontTires', 'maxLoad', value);

  onChangeFrontTiresSpeedIndex = (value) => this.onChangeTiresDropdown('frontTires', 'speedIndex', value);

  onChangeBackTiresModel = (value) => this.onChangeTiresDropdown('backTires', 'model', value);

  onChangeBackTiresRimType = (value) => this.onChangeTiresDropdown('backTires', 'rimType', value);

  onSelectBackTiresBrand = (value) => {
    this.onChangeTiresDropdown('backTires', 'brand', value);
  };

  onChangeBackTiresWidth = (value) => this.onChangeTiresDropdown('backTires', 'width', value);

  onChangeBackTiresRim = (value) => this.onChangeTiresDropdown('backTires', 'rim', value);

  onChangeBackTiresCrossSection = (value) => this.onChangeTiresDropdown('backTires', 'crossSection', value);

  onChangeBackTiresMaxLoadIndex = (value) => this.onChangeTiresDropdown('backTires', 'maxLoad', value);

  onChangeBackTiresSpeedIndex = (value) => this.onChangeTiresDropdown('backTires', 'speedIndex', value);

  onCancel = () => {
    const { onCancel } = this.props;

    this.resetState();
    onCancel();
  };

  isTyre24Active = () => {
    const { activeShops } = this.props;

    if (activeShops) {
      if (activeShops.find((el) => el.name === 'tyre24')) {
        return true;
      }
    }
    return false;
  };

  tyre24SearchUrls = () => {
    const urls = [];
    const { garage, country } = this.props;
    let baseUrl = 'https://tyre24.alzura.com/';
    if (garage && country === 'DE') {
      baseUrl += 'de/de/';
    } else {
      baseUrl += 'at/de/';
    }

    const frontSearch = this.tyre24SearchString();
    const backSearch = this.tyre24SearchString(true);

    if (frontSearch.result) {
      urls.push({ url: `${baseUrl}redex?search=${encodeURIComponent(frontSearch.result)}`, display: frontSearch.resultPretty });
    }
    if (backSearch.result && backSearch.result !== frontSearch.result) {
      urls.push({ url: `${baseUrl}redex?search=${encodeURIComponent(backSearch.result)}`, display: backSearch.resultPretty });
    }
    if (!urls.length) {
      urls.push({ url: baseUrl, display: '' });
    }
    return urls;
  };

  tyre24SearchString = (isBack = false) => {
    const { data } = this.state;
    const tD = isBack ? data.backTires : data.frontTires;
    let result = '';
    let resultPretty = '';
    if (tD.width && tD.crossSection && tD.rim) {
      if (tD.model === 'summer') {
        result += 'S';
        resultPretty += 'S ';
      }
      if (tD.model === 'winter') {
        result += 'W';
        resultPretty += 'W ';
      }

      result += `${tD.width}${tD.crossSection}${tD.rim}`;
      resultPretty += `${tD.width}/${tD.crossSection}/${tD.rim}`;

      if (tD.maxLoad) {
        result += tD.maxLoad;
        resultPretty += ` ${tD.maxLoad}`;
      }
      if (tD.speedIndex) {
        result += tD.speedIndex;
        resultPretty += ` ${tD.speedIndex}`;
      }
    }
    return { result, resultPretty };
  };

  openTyre24 = (url) => {
    window.open(url);
  };

  onChooseTire = (t) => {
    const { resetTireToUpdate } = this.props;

    resetTireToUpdate(() => {
      const newState = {
        hasChanged: true,
        data: {
          ...t,
          storedOn: '',
          removedOn: '',
          _id: undefined,
        },
      };
      newState.data.frontTires.treadPatternDepth = null;
      newState.data.backTires.treadPatternDepth = null;
      this.setState(newState);
    });
  };

  validateStoredOn = (value) => value !== '';

  resetState = () => this.setState(this.getInitialData());

  getInitialData() {
    const { defaultData } = this.props;

    return {
      isClientsTableModal: false,
      hasChanged: false,
      frontTires: {
        model: false,
        rimType: false,
        brand: false,
        type: false,
        width: false,
        rim: false,
        crossSection: false,
        speedIndex: false,
        treadPatternDepth: false,
        year: false,
        maxLoad: false,
      },
      backTires: {
        model: false,
        rimType: false,
        brand: false,
        type: false,
        width: false,
        rim: false,
        crossSection: false,
        speedIndex: false,
        treadPatternDepth: false,
        year: false,
        maxLoad: false,
      },
      data: {
        ...defaultData,
        storedOn: date.getCorrectDate(defaultData.storedOn),
        removedOn: date.getCorrectDate(defaultData.removedOn),
      },
    };
  }

  renderFormData = () => {
    const { data } = this.state;

    const isStoredOnValid = this.validateStoredOn(data.storedOn);

    return (
      <div className={styles.tireFormColumns}>
        <div className={styles.tireFormColumn}>
          <div className={styles.tireFormRow}>
            <div className={styles.tireFormElement}>
              <DatePicker
                name="storedOn"
                label={polyglot.t('tires.form.storedOn')}
                isRequired
                value={data.storedOn}
                onChange={(value) => this.onChangeDate('storedOn', value)}
                errorText={
                  isStoredOnValid
                    ? ''
                    : polyglot.t('tires.form.storedOnError')
                }
                tabIndex={1}
              />
            </div>
            <div className={styles.tireFormElement}>
              <Input
                tabIndex={1}
                onChange={this.onChangeStorageLocation}
                value={data.storageLocation}
                label={polyglot.t('tires.form.storageLocation')}
                name="storageLocation"
                className={cx(styles.tireFormInput)}
              />
            </div>
          </div>
          <div className={styles.tireFormRow}>
            <div className={styles.tireFormClient}>
              <div className={pageStyles.pageDataElementTitle}>
                {polyglot.t('tires.form.selectClientTitle')}
              </div>
              <LinkButton onClick={this.onSelectClient} type="edit" tabIndex={2}>
                {data.client.clientId ? (
                  `${data.client.lastName} ${data.client.firstName}`.trim()
                ) : (
                  '---'
                )}
              </LinkButton>
            </div>
          </div>
          <div className={styles.tireFormRow}>
            <div className={modalStyles.headerTitle}>
              {polyglot.t('tires.form.frontTires')}
            </div>
          </div>
          <div className={styles.tireFormRow}>
            <Dropdown
              tabIndex={3}
              value={data.frontTires.model}
              items={TireModal.tireModels}
              onChange={this.onChangeFrontTiresModel}
              className={cx(styles.tireFormDropdown, styles.tireFormElement)}
            />
            <Dropdown
              tabIndex={3}
              value={data.frontTires.rimType}
              items={TireModal.tireRimTypes}
              onChange={this.onChangeFrontTiresRimType}
              className={cx(styles.tireFormDropdown, styles.tireFormElement)}
            />
          </div>
          <div className={styles.tireFormRow}>
            <InputDropdown
              tabIndex={4}
              preselectFirstItem
              label={polyglot.t('tires.form.tireBrand')}
              value={data.frontTires.brand || ''}
              items={TireModal.tireBrands}
              onSelect={this.onSelectFrontTiresBrand}
              className={cx(styles.tireFormDropdown, styles.tireFormElement)}
            />
            <Input
              tabIndex={4}
              onChange={({ target }) => this.onChangeTiresInput('frontTires', 'type', target.value)}
              value={data.frontTires.type}
              label={polyglot.t('tires.form.tireType')}
              name="tireType"
              className={cx(styles.tireFormInput, styles.tireFormElement)}
            />
          </div>
          <div className={styles.tireFormRow}>
            <div className={styles.tireFormElements}>
              <Dropdown
                tabIndex={5}
                label={polyglot.t('tires.form.tireDimension')}
                value={data.frontTires.width}
                items={TireModal.tireWidths}
                onChange={this.onChangeFrontTiresWidth}
                className={cx(styles.tireFormDropdown, styles.tireFormDimension)}
              />
              <span className={styles.tireFormSlash}>
                /
              </span>
            </div>
            <div className={styles.tireFormElements}>
              <Dropdown
                tabIndex={5}
                value={data.frontTires.crossSection}
                items={TireModal.tireCrossSections}
                onChange={this.onChangeFrontTiresCrossSection}
                className={cx(styles.tireFormDropdown, styles.tireFormElement, styles.tireFormSmallDropdown)} //eslint-disable-line
              />
              <Dropdown
                tabIndex={5}
                value={data.frontTires.rim}
                items={TireModal.tireRims}
                onChange={this.onChangeFrontTiresRim}
                className={cx(styles.tireFormDropdown, styles.tireFormElement, styles.tireFormSmallDropdown)} //eslint-disable-line
              />
            </div>
          </div>
          <div className={styles.tireFormRow}>
            <Dropdown
              tabIndex={6}
              label={polyglot.t('tires.form.maxLoad')}
              value={`${data.frontTires.maxLoad}`}
              items={TireModal.tireMaxLoadIndexes}
              onChange={this.onChangeFrontTiresMaxLoadIndex}
              className={cx(styles.tireFormInput, styles.tireFormMaxLoad)}
            />
          </div>
          <div className={styles.tireFormRow}>
            <Dropdown
              tabIndex={7}
              label={polyglot.t('tires.form.speedIndex')}
              value={data.frontTires.speedIndex}
              items={TireModal.tireSpeedIndexes}
              onChange={this.onChangeFrontTiresSpeedIndex}
              className={cx(styles.tireFormDropdown, styles.tireFormSpeed)}
            />
          </div>
          <div className={styles.tireFormRow}>
            <div className={styles.tireFormElements}>
              <NumberInput
                tabIndex={8}
                className={cx(styles.tireFormInput, styles.tireFormDimension)}
                name="treadPatternDepth"
                dataTest="treadPatternDepthFront"
                label={polyglot.t('tires.form.treadPatternDepth')}
                value={data.frontTires.treadPatternDepth}
                onChange={(value) => this.onChangeTiresInput('frontTires', 'treadPatternDepth', value)}
                allowNegative={false}
              />
              <span className={styles.tireFormMm}>
                mm
              </span>
            </div>
            <Input
              tabIndex={8}
              onChange={({ target }) => this.onChangeTiresInput('frontTires', 'year', target.value)}
              value={data.frontTires.year}
              label={polyglot.t('tires.form.year')}
              name="year"
              className={cx(styles.tireFormInput, styles.tireFormElement)}
              placeholder={polyglot.t('tires.form.yearPlaceholder')}
            />
          </div>
          <Textarea
            style={{ height: '70px' }}
            tabIndex={9}
            className={styles.description}
            name="additionalInfo"
            label={polyglot.t('tires.form.additionalInfo')}
            value={data.additionalInfo}
            onChange={this.onChangeAdditionalInfo}
            rows="8"
          />
        </div>
        <div className={styles.tireFormColumn}>
          <div className={styles.tireFormRow}>
            <DatePicker
              className={styles.tireFormElement}
              name="removedOn"
              label={polyglot.t('tires.form.removedOn')}
              value={data.removedOn}
              onChange={(value) => this.onChangeDate('removedOn', value)}
              tabIndex={1}
            />
            <Button
              styleType="remove"
              tabIndex={1}
              onClick={this.onRemoveNow}
              className={cx(styles.tireFormRemoveNow, styles.tireFormElement)}
            >
              {polyglot.t('tires.form.removeNow')}
            </Button>
          </div>
          <div className={styles.tireFormRow} style={{ marginTop: '14.4px' }}>
            <div className={styles.tireFormVehicle}>
              {
                data.vehicles && data.client.clientId ? (
                  <Dropdown
                    tabIndex={2}
                    label={polyglot.t('tires.form.selectVehicleTitle')}
                    value={data.vehicle.manufacturer
                      ? `${data.vehicle.license} | ${data.vehicle.manufacturer} ${data.vehicle.model}` : ''}
                    items={data.vehicles.map((vehicle) => ({
                      key: vehicle._id,
                      label: `${vehicle.license} | ${vehicle.manufacturer} ${vehicle.model}`,
                    }))}
                    onChange={this.onVehicleSelected}
                    className={styles.tireFormDropdownVehicle}
                  />
                ) : (
                  <Fragment>
                    <div
                      className={pageStyles.pageDataElementTitle}
                    >
                      {polyglot.t('tires.form.selectVehicleTitle')}
                      <br />
                      <br />
                      {data.vehicles ? polyglot.t('tires.form.pleaseSelectClient') : polyglot.t('loader.loading')}
                    </div>
                  </Fragment>
                )
              }
            </div>
          </div>
          <div className={styles.tireFormRow}>
            <div className={modalStyles.headerTitle}>
              {polyglot.t('tires.form.backTires')}
            </div>
          </div>
          <div className={styles.tireFormRow}>
            <Dropdown
              tabIndex={3}
              value={data.backTires.model}
              items={TireModal.tireModels}
              onChange={this.onChangeBackTiresModel}
              className={cx(styles.tireFormDropdown, styles.tireFormElement)}
            />
            <Dropdown
              tabIndex={3}
              value={data.backTires.rimType}
              items={TireModal.tireRimTypes}
              onChange={this.onChangeBackTiresRimType}
              className={cx(styles.tireFormDropdown, styles.tireFormElement)}
            />
          </div>
          <div className={styles.tireFormRow}>
            <InputDropdown
              tabIndex={4}
              preselectFirstItem
              label={polyglot.t('tires.form.tireBrand')}
              value={data.backTires.brand || ''}
              items={TireModal.tireBrands}
              onSelect={this.onSelectBackTiresBrand}
              className={cx(styles.tireFormDropdown, styles.tireFormElement)}
            />
            <Input
              tabIndex={4}
              onChange={({ target }) => this.onChangeTiresInput('backTires', 'type', target.value)}
              value={data.backTires.type}
              label={polyglot.t('tires.form.tireType')}
              name="tireType"
              className={cx(styles.tireFormInput, styles.tireFormElement)}
            />
          </div>
          <div className={styles.tireFormRow}>
            <div className={styles.tireFormElements}>
              <Dropdown
                tabIndex={5}
                label={polyglot.t('tires.form.tireDimension')}
                value={data.backTires.width}
                items={TireModal.tireWidths}
                onChange={this.onChangeBackTiresWidth}
                className={cx(styles.tireFormDropdown, styles.tireFormDimension)}
              />
              <span className={styles.tireFormSlash}>
                /
              </span>
            </div>
            <div className={styles.tireFormElements}>
              <Dropdown
                tabIndex={5}
                value={data.backTires.crossSection}
                items={TireModal.tireCrossSections}
                onChange={this.onChangeBackTiresCrossSection}
                className={cx(styles.tireFormDropdown, styles.tireFormElement, styles.tireFormSmallDropdown)} //eslint-disable-line
              />
              <Dropdown
                tabIndex={5}
                value={data.backTires.rim}
                items={TireModal.tireRims}
                onChange={this.onChangeBackTiresRim}
                className={cx(styles.tireFormDropdown, styles.tireFormElement, styles.tireFormSmallDropdown)} //eslint-disable-line
              />
            </div>
          </div>
          <div className={styles.tireFormRow}>
            <Dropdown
              tabIndex={6}
              label={polyglot.t('tires.form.maxLoad')}
              value={`${data.backTires.maxLoad}`}
              items={TireModal.tireMaxLoadIndexes}
              onChange={this.onChangeBackTiresMaxLoadIndex}
              className={cx(styles.tireFormInput, styles.tireFormMaxLoad)}
            />
          </div>
          <div className={styles.tireFormRow}>
            <Dropdown
              tabIndex={7}
              label={polyglot.t('tires.form.speedIndex')}
              value={data.backTires.speedIndex}
              items={TireModal.tireSpeedIndexes}
              onChange={this.onChangeBackTiresSpeedIndex}
              className={cx(styles.tireFormDropdown, styles.tireFormSpeed)}
            />
          </div>
          <div className={styles.tireFormRow}>
            <div className={styles.tireFormElements}>
              <NumberInput
                tabIndex={8}
                className={cx(styles.tireFormInput, styles.tireFormDimension)}
                name="treadPatternDepth"
                dataTest='treadPatternDepthRear'
                label={polyglot.t('tires.form.treadPatternDepth')}
                value={data.backTires.treadPatternDepth}
                onChange={(value) => this.onChangeTiresInput('backTires', 'treadPatternDepth', value)}
                allowNegative={false}
              />
              <span className={styles.tireFormMm}>
                mm
              </span>
            </div>
            <Input
              tabIndex={8}
              onChange={({ target }) => this.onChangeTiresInput('backTires', 'year', target.value)}
              value={data.backTires.year}
              label={polyglot.t('tires.form.year')}
              name="year"
              className={cx(styles.tireFormInput, styles.tireFormElement)}
              placeholder={polyglot.t('tires.form.yearPlaceholder')}
            />
          </div>
          {this.isTyre24Active() && this.tyre24SearchUrls().map((el, i) => (
            <InteractableDiv key={el.url + i} onClick={() => this.openTyre24(el.url)} className={pageStyles.pageLink} style={{ cursor: 'pointer', marginBottom: '10px' }}>
              {polyglot.t('tires.orderFromTyre24')}
              {el.display ? `: ${el.display}` : ''}
              {' '}
              &#xbb;
            </InteractableDiv>
          ))}
        </div>
        {this.renderFormButtons()}
      </div>
    );
  };

  renderFormButtons = () => {
    const { data } = this.state;
    const isStoredOnValid = this.validateStoredOn(data.storedOn);
    return (
      <div className={styles.tireFormButtons}>
        {this.renderPdfActions()}
        <div className={styles.tireFormButtonsSaveActions}>
          <Button
            styleType="remove"
            tabIndex={9}
            className={styles.tireFormButton}
            onClick={this.onCancel}
            dataTest="cancelNewTires"
          >
            {polyglot.t('actions.cancel')}
          </Button>
          <Button
            styleType="add"
            tabIndex={9}
            className={styles.tireFormButton}
            disabled={!isStoredOnValid}
            onClick={this.onValidateSave}
            dataTest="addNewTires"
          >
            {polyglot.t('actions.save')}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { data, isStorageModalOpen, isClientsTableModal } = this.state;
    const { isOpen } = this.props;

    return (
      <Fragment>
        <ResponsiveModal
          isOpen={isOpen}
          title={polyglot.t('tires.form.title')}
          onClose={this.onCancel}
        >
          <div className={cx(modalStyles.container, styles.container)}>
            <PreviousTires
              clientId={data.client.clientId}
              vehicleId={data.vehicle.vehicleId}
              onChooseTire={this.onChooseTire}
            />
            {this.renderFormData()}
          </div>
        </ResponsiveModal>
        <ConfirmModal
          isOpen={isStorageModalOpen}
          title={polyglot.t('tires.storageWarningTitle')}
          text={polyglot.t('tires.storageWarningMessage')}
          onClose={() => this.setState({ isStorageModalOpen: false })}
          onProceed={this.onSave}
        />

        <ClientsTableModal
          isOpen={isClientsTableModal}
          client={data.client}
          onCancel={this.onCancelClientsTableModal}
          onClientSelected={this.onClientSelected}
          onRemoveClient={this.onNoClientSelected}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  vehicles: fromVehicles.getVehicles(state),
  currentVehicle: fromVehicles.getCurrentVehicle(state),
  activeShops: getSuppliersShops(state),
  garage: getGarage(state),
}), {
  changeVehiclesFilterAction: changeVehiclesFilterByClientId,
  resetVehiclesStateAction: resetVehiclesState,
  printStorageConfirmationAction: printStorageConfirmation,
  getConfirmationPdfUrlAction: getConfirmationPdfUrl,
})(TireModal);
