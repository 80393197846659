import React, { Component } from 'react';

import InputModern from 'components/common/inputModern';
import ButtonModern from 'components/common/buttonModern';
import CheckboxModern from 'components/common/checkboxModern';
import Logo from 'components/common/logo';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { signin } from 'resources/account/account.api';
import { Validators, FormValidator, FormValidatorGroup } from 'helpers/formValidation';
import polyglot from 'services/localization';
import { identifyUser } from 'services/tracking.service';
import DeviceService from 'services/device.service';

import accessStyles from '../../access.styles.pcss';

import BackgroundBlob from '../backgroundBlob';

import styles from './signin.styles.pcss';

class Signin extends Component {
  constructor(props) {
    super(props);

    const signinForm = this.generateSigninForm();
    this.state = {
      signinForm,
      isConfirm2FactorAuthShown: false,
    };
  }

  generateSigninForm = () => {
    const formValidatorGroup = new FormValidatorGroup({
      email: new FormValidator('', [Validators.required(polyglot.t('access.signin.errors.email')), Validators.email()]),
      password: new FormValidator('', [Validators.required(polyglot.t('access.signin.errors.password'))]),
      emailVerificationCode: new FormValidator(''),
      isRemember: new FormValidator(false),
    }, this, {}, this.onSubmit, this.validateAuthCode);

    return formValidatorGroup;
  };

  validateAuthCode = (formValidator) => {
    const { isConfirm2FactorAuthShown } = this.state;
    const { emailVerificationCode } = formValidator.group;

    if (isConfirm2FactorAuthShown && !emailVerificationCode.value) emailVerificationCode.addError({ id: 'custom', message: 'Required' });
  };

  onSubmit = async (values) => {
    const {
      email, password, emailVerificationCode,
    } = values;
    const { is2FAActive, userId } = await signin({ email, password, emailVerificationCode });

    if (is2FAActive === true) {
      this.setState({ isConfirm2FactorAuthShown: true });
    } else {
      identifyUser(userId);
      window.location = `${window.config.webUrl}`;
    }
  };

  renderContent = () => {
    const { signinForm, isConfirm2FactorAuthShown } = this.state;

    if (isConfirm2FactorAuthShown) {
      return (
        <div className={accessStyles.layoutContentBody}>
          <div className={accessStyles.layoutContentBodyMessage}>
            {polyglot.t('access.signin.authCodeSent')}
          </div>
          <InputModern
            formValidator={signinForm.group.emailVerificationCode}
            placeholder={polyglot.t('common.authCode')}
            key="authCode"
            autoFocus
          />
          <ButtonModern
            onClick={signinForm.submit}
            styleType="add"
            disabled={signinForm.isSubmitting}
          >
            {polyglot.t('actions.login')}
          </ButtonModern>
          <div>
            <div className={accessStyles.layoutContentBodyBottomText}>
              {polyglot.t('access.signin.loginInfo')}
            </div>
          </div>
        </div>
      );
    }

    return (
      <signinForm.Form>
        <div className={accessStyles.layoutContentBody}>
          <div className={accessStyles.layoutContentBodyMessageHeader}>
            {polyglot.t('access.signin.welcomeBack')}
          </div>
          <InputModern
            formValidator={signinForm.group.email}
            label={polyglot.t('users.email')}
            key="email"
            type="email"
            dataTest="loginEmail"
            autoFocus
          />
          <InputModern
            formValidator={signinForm.group.password}
            label={polyglot.t('users.password')}
            upperRightLabel={<Link tabindex="-1" data-test="forgotPasswordLink" className={cx(accessStyles.layoutLink, styles.signinPasswordResetLink)} to="/access/reset-password">
              {polyglot.t('access.resetPassword.forgotPassword')}
            </Link>}
            type="password"
            key="password"
            dataTest="loginPassword"
          />
          <CheckboxModern
            formValidator={signinForm.group.isRemember}
            label={polyglot.t('access.signin.secureDevice')}
          />
          <ButtonModern
            styleType="add"
            dataTest="loginSubmit"
            type="submit"
            isLoading={signinForm.isSubmitting}
          >
            {polyglot.t('actions.login')}
          </ButtonModern>
          {!(DeviceService.isAppleDevice && DeviceService.isPWA) && (
            <div>
              <div className={accessStyles.layoutContentBodyBottomText}>
                {polyglot.t('access.noAccount')}
              </div>
              <Link data-test="signupLink" className={cx(accessStyles.layoutLink, styles.signinSignupLink)} to="/access/signup">
                {polyglot.t('access.registerHere')}
              </Link>
            </div>
          )}
        </div>
      </signinForm.Form>
    );
  };

  render() {
    return (
      <div className={accessStyles.layout}>
        <BackgroundBlob />
        <div className={accessStyles.layoutContent}>
          <div className={accessStyles.layoutContentHeader}>
            <Logo className={accessStyles.layoutContentHeaderLogo} />
          </div>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

export default Signin;
