import { FETCH_GLOBAL_SUPPLIERS } from './globalSuppliers.actions';

const initialState = [...window.config.globalSuppliers];

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GLOBAL_SUPPLIERS:
      return [...action.payload];
    default:
      return state;
  }
};
