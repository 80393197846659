/* eslint-disable import/no-import-module-exports, import/no-webpack-loader-syntax */
import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import Helmet from 'react-helmet';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';

import store, { history } from 'resources/store';
import { getUserAgent } from 'helpers/regexp';
import { bootSupportChat } from 'services/supportTools';
import { socketConnect } from 'services/socket';
import { initiateSentryForWebsite } from 'services/sentry.service';
import Logger from 'components/logger';
import IeSupport from 'components/ie';

import routes from './routes';

import './styles.pcss';
import '!style-loader!css-loader!./fontawesome/custom-icons/custom-icons.pcss';
import '!style-loader!css-loader!./styles/toast.pcss';
import '!style-loader!css-loader!./styles/reactCrop.pcss';
import '!style-loader!css-loader!./styles/rcTooltip.pcss';

bootSupportChat();

Modal.setAppElement('#root');

export const ioClient = socketConnect(store);

const registerServiceWorker = async () => {
  // eslint-disable-next-line no-restricted-syntax
  if ('serviceWorker' in navigator) {
    if (window.config.pwaEnabled) {
      try {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Registered service worker!. Registration:', registration);
      } catch (error) {
        console.error(`Service worker registration failed with ${error}`);
      }
    } else {
      try {
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
          registration.unregister();
        }
        console.log('Service worker unregistered!');
      } catch (x) {
        console.log('Couldnt unregister service worker');
      }
    }
  }
};

registerServiceWorker();
initiateSentryForWebsite();

const renderApp = () => {
  navigator.isIE = () => {
    const [, browser] = getUserAgent(navigator.userAgent) || [];

    if (/trident/i.test(browser)) {
      return true;
    }
    return false;
  };

  const root = createRoot(document.getElementById('root'));
  root.render(
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          easyWerkstatt
        </title>
        <link rel="icon" href="/assets/favicon.ico" />
        <link rel="apple-touch-icon" href="/assets/icons/icon_x192.png" />
        { window.config.pwaEnabled && <link rel="manifest" href="/manifest.webmanifest" /> }
        <meta name="theme-color" content="#3e5a6c"/>
      </Helmet>
      {navigator.isIE() ? (
        <IeSupport />
      ) : (
        <Provider store={store}>
          <ConnectedRouter history={history}>
            {routes()}
          </ConnectedRouter>
        </Provider>
      )}
      {window.config.logger.enabled && <Logger />}
    </>,
  );

  const notificationContainerRoot = createRoot(document.getElementById('notifications-wrapper'));
  notificationContainerRoot.render(<ToastContainer draggable={false} />);
};

renderApp();

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept('./routes', () => {
    renderApp();
  });
}

if (window.Cypress) {
  window.store = store;
}
