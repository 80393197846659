import * as api from './calendar.api';

export const FETCH_CALENDARS = 'fetchCalendars';
export const UPDATE_CALENDAR = 'updateCalendar';
export const RESET_CALENDAR_STATE = 'resetCalendarState';
export const DELETE_CALENDAR = 'deleteCalendar';
export const FETCH_CALENDAR_ENTRIES = 'fetchCalendarEntries';
export const UPDATE_CALENDAR_ENTRY = 'updateCalendarEntry';
export const USE_SYNCED_CALENDAR_ENTRIES = 'useSyncedCalendarEntries';
export const DELETE_CALENDAR_ENTRY = 'deleteCalendarEntry';

export const fetchCalendars = (options = {}) => (dispatch, getState) => {
  return api.getCalendars(window.config.garage._id)
    .then((payload) => dispatch({ type: FETCH_CALENDARS, calendars: payload }))
    .catch((e) => dispatch({ type: FETCH_CALENDARS, calendars: null }));
};

export const createCalendar = (calendar) => (dispatch, getState) => {
  // eslint-disable-next-line no-param-reassign
  calendar.link = `/ewk-calendar-${window.config.garage._id}/${Date.now()}-${calendar.name.replace(/[^\w]/g, '')}/`;
  return api.createCalendar(window.config.garage._id, calendar).then((payload) => {
    dispatch({ type: UPDATE_CALENDAR, payload });
  });
};

export const updateCalendar = (calendar) => (dispatch, getState) => {
  return api.updateCalendar(window.config.garage._id, calendar).then((payload) => {
    dispatch({ type: UPDATE_CALENDAR, payload });
  });
};

export const deleteCalendar = (calendarLink) => (dispatch, getState) => {
  return api.deleteCalendar(window.config.garage._id, calendarLink).then((payload) => {
    dispatch({ type: DELETE_CALENDAR, payload });
  });
};

export const resetCalendarState = () => ({ type: RESET_CALENDAR_STATE });

export const createEvent = (event) => (dispatch, getState) => {
  if (event.link) {
    dispatch({ payload: event, type: UPDATE_CALENDAR_ENTRY, local: true });
  }
  return api.createEvent(window.config.garage._id, event.eventObject || event)
    .then((createdEntry) => dispatch({
      payload: createdEntry,
      type: UPDATE_CALENDAR_ENTRY,
    }))
    .catch((e) => { dispatch({ type: USE_SYNCED_CALENDAR_ENTRIES }); throw e; });
};

export const updateEvent = (event) => (dispatch, getState) => {
  if (event.link) {
    dispatch({ payload: event, type: UPDATE_CALENDAR_ENTRY, local: true });
  }
  return api.updateEvent(window.config.garage._id, event.eventObject || event)
    .then((updatedEntry) => dispatch({
      payload: updatedEntry,
      type: UPDATE_CALENDAR_ENTRY,
    }))
    .catch((e) => { dispatch({ type: USE_SYNCED_CALENDAR_ENTRIES }); throw e; });
};

export const deleteEvent = (uid) => (dispatch, getState) => {
  dispatch({
    payload: { results: [{ eventObject: { uid } }] },
    type: DELETE_CALENDAR_ENTRY,
    local: true,
  });
  return api.deleteEvent(window.config.garage._id, uid)
    .then((deletedEntry) => dispatch({
      type: DELETE_CALENDAR_ENTRY,
      payload: deletedEntry,
    }))
    .catch((e) => { dispatch({ type: USE_SYNCED_CALENDAR_ENTRIES }); throw e; });
};

export const getEvents = ({
  from = null,
  to = null,
  calendarIds = null,
  invoiceId = null,
  clientId = null,
  vehicleId = null,
  isHolidays = '',
  shoulNotDispatch = false,
}) => (dispatch, getState) => {
  return api.getEvents(window.config.garage._id, {
    from,
    to,
    calendarIds,
    invoiceId,
    clientId,
    vehicleId,
    isHolidays,
  }).then((payload) => {
    if (shoulNotDispatch) {
      return {
        entries: payload,
      };
    }
    return dispatch({
      type: FETCH_CALENDAR_ENTRIES,
      entries: payload,
    });
  });
};
