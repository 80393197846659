import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';

import Button from 'components/common/button';

import styles from './noEntity.styles';

const NoEntity = ({
  onRemove,
  onCreate,
  isInvoice,
  isSelectDisabled,
  description,
  createButtonLabel,
  selectButtonLabel,
}) => (
  <div className={styles.noEntityWrapper}>
    <div className={styles.noEntityDescription}>
      <span className={styles.noEntityDescriptionText}>{description}</span>
      <p className={styles.noEntityButton}>
        {polyglot.t('actions.clickOn')}
        <Button
          className={styles.noEntityCreateButton}
          styleType="add"
          onClick={() => onCreate({})}
        >
          {createButtonLabel}
        </Button>
      </p>
    </div>
    <div className={styles.noEntitySelectButtonWrapper}>
      <Button
        styleType="add"
        onClick={onRemove}
        disabled={isSelectDisabled}
      >
        {selectButtonLabel}
      </Button>
    </div>
  </div>
);

NoEntity.propTypes = {
  isInvoice: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isSelectDisabled: PropTypes.bool,
  description: PropTypes.string.isRequired,
  createButtonLabel: PropTypes.string.isRequired,
  selectButtonLabel: PropTypes.string.isRequired,
};

NoEntity.defaultProps = {
  isSelectDisabled: false,
  isInvoice: false,
};

export default NoEntity;
