const CLIENT_FORM_OF_ADDRESS = Object.freeze({
  MR: 'mr',
  MRS: 'mrs',
  COMPANY: 'company',
  FAMILY: 'family',
});

// eslint-disable-next-line complexity
const parseFormOfAddress = (formOfAddressVal) => {
  if (!formOfAddressVal) return '';
  switch (formOfAddressVal.toLowerCase().replace(/\./gi, '')) {
    case 'herr':
    case 'mr':
    case 'mister':
    case 'hr':
      return CLIENT_FORM_OF_ADDRESS.MR;
    case 'frau':
    case 'mrs':
    case 'ms':
    case 'missess':
    case 'miss':
    case 'fr':
      return CLIENT_FORM_OF_ADDRESS.MRS;
    case 'firma':
    case 'company':
    case 'co':
    case 'fa':
      return CLIENT_FORM_OF_ADDRESS.COMPANY;
    case 'familie':
    case 'family':
    case 'fam':
      return CLIENT_FORM_OF_ADDRESS.FAMILY;
    default:
      return '';
  }
};

module.exports = {
  CLIENT_FORM_OF_ADDRESS,
  parseFormOfAddress,
};
