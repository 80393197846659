import { addPageSize } from 'resources/utils';
import { downloadFile } from 'helpers/file';
import * as api from './product.api';
import * as fromUser from '../user/user.selectors';
import * as fromProduct from './product.selectors';
import * as fromGarage from '../garage/garage.selectors';

export const CREATE_PRODUCT = 'createProduct';
export const FETCH_PRODUCTS = 'fetchProducts';
export const FETCH_REPORT = 'fetchReport';
export const CHANGE_REPORT_FILTER = 'changeReportFilter';
export const RESET_PRODUCTS_STATE = 'resetProductsState';
export const CHANGE_PRODUCTS_FILTER = 'changeProductsFilter';
export const REMOVE_PRODUCTS = 'removeProducts';
export const UPDATE_PRODUCT = 'updateProduct';
export const REMOVE_NONE_PRODUCTS = 'removeNoneProducts';
export const REMOVE_NONE_OTHER_PRODUCTS = 'removeNoneOtherProducts';
export const REMOVE_ISPART_PRODUCTS = 'removeIsPartProducts';
export const RESTORE_PRODUCT = 'restoreProduct';
export const FETCH_PRODUCT_COUNTS_BY_STATUS = 'fetchProductCountsByStatus';

const fixTaxRate = (data, state) => {
  const taxRates = fromGarage.getTaxRates(state);
  const defaultTaxRate = fromGarage.getDefaultTaxRate(state);

  return {
    ...data,
    vatRateId: data.vatRateId === defaultTaxRate._id ? null : data.vatRateId,
    vatRate: (taxRates.find(({ _id }) => _id === data.vatRateId) || defaultTaxRate).rate,
  };
};

export const createProduct = (data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const validProduct = fixTaxRate(data, state);

  return api.createProduct(validProduct, garageId)
    .then((payload) => dispatch({ type: CREATE_PRODUCT, payload }));
};

export const findProductsBySKU = (sku) => (dispatch, getState) => {
  if (!sku) {
    return Promise.resolve(null);
  }
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.findProductsBySKU({ sku, garageId, pageSize: 6 }).then(({ products }) => products);
};

export const isSkuAlreadyUsedInProducts = (sku, currentId) => (dispatch, getState) => {
  if (!sku) {
    return Promise.resolve(null);
  }
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.isSkuAlreadyUsed({ sku, garageId, currentId });
};

export const findProductByName = (name) => (dispatch, getState) => {
  if (!name) {
    return Promise.resolve(null);
  }
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.fetchProductsByName({ name, garageId, pageSize: 6 });
};

export const searchProductsByNameAndSKU = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.searchProductsByNameAndSKU(data)
    .then((payload) => dispatch({ type: FETCH_PRODUCTS, data, payload }));
};

export const fetchProducts = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.fetchProducts(data)
    .then((payload) => dispatch({ type: FETCH_PRODUCTS, data, payload }));
};

export const fetchAutoPositionProducts = () => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.fetchAutoPositionProducts(garageId);
};

export const fetchReport = (options = {}) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  const data = addPageSize({ ...options, garageId });

  return api.fetchReport(garageId, data)
    .then((payload) => dispatch({ type: FETCH_REPORT, data, payload }));
};

export const changeReportFilter = (options = {}) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  const data = addPageSize({ ...options, garageId });

  return api.fetchReport(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_REPORT_FILTER, data, payload }));
};

export const getProductsReportCSV = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getProductsReportCSV(garageId, data)
    .then((payload) => downloadFile(payload, 'products-sales.csv'));
};

export const getProductsSellsReportExcel = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getProductsSellsReportExcel(garageId, data)
    .then((payload) => downloadFile(payload, 'products-sales.xlsx'));
};

export const getValueOfInventory = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getValueOfInventory(garageId);
};

export const resetProductsState = () => ({ type: RESET_PRODUCTS_STATE });

export const removeNoneProducts = () => ({ type: REMOVE_NONE_PRODUCTS });

export const removeNoneOtherProducts = () => ({ type: REMOVE_NONE_OTHER_PRODUCTS });

export const removeIsPartProducts = () => ({ type: REMOVE_ISPART_PRODUCTS });

export const changeProductsFilterNameAndSKU = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.searchProductsByNameAndSKU(data)
    .then((payload) => dispatch({ type: CHANGE_PRODUCTS_FILTER, data, payload }));
};

export const changeProductsFilter = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.fetchProducts(data)
    .then((payload) => dispatch({ type: CHANGE_PRODUCTS_FILTER, data, payload }));
};

export const fetchProductCountsByStatus = (options = {}) => (dispatch, getState) => {
  const data = options;
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchProductCountsByStatus(garageId, data)
    .then((payload) => dispatch({ type: FETCH_PRODUCT_COUNTS_BY_STATUS, data, payload }));
};

export const updateProduct = (productId, newFields) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  const product = fromProduct.getProductById(state, productId);
  const newProduct = fixTaxRate({ ...product, ...newFields }, state);

  return api.updateProduct(productId, newProduct, garageId)
    .then((payload) => dispatch({ type: UPDATE_PRODUCT, productId, payload }));
};

export const removeProducts = (productIds) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.removeProducts(productIds, garageId)
    .then(() => dispatch({ type: REMOVE_PRODUCTS, productIds }));
};

export const getArticlesCSV = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getArticlesCSV(garageId, data)
    .then((payload) => downloadFile(payload, 'articles.csv'));
};

export const getProductsExcel = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getProductsExcel(garageId, data)
    .then((payload) => downloadFile(payload, 'articles.xlsx'));
};

export const fetchInventoryForProducts = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchInventoryForProducts(garageId, data);
};

export const restoreProduct = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  return api.restoreProduct(currentGarageId, data)
    .then((payload) => dispatch({ type: RESTORE_PRODUCT, data, payload }));
};
