export function selectText(nodeName) {
  const node = document.getElementById(nodeName);

  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn('Could not select text in node: Unsupported browser.');
  }
}
const div = document.createElement('div');
div.setAttribute('style', 'position: absolute; visibility: hidden; height: auto; width: auto; white-space: nowrap;');
document.body.append(div);

export function getWidthOfText(text, fontSize) {
  div.innerHTML = text;
  div.style.fontSize = fontSize;
  const width = `${div.clientWidth + 1}px`;
  div.innerHTML = '';
  return width;
}
