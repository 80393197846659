import React from 'react';

const BlobV2 = () => {
  return (
    <svg width="220" height="200" viewBox="0 0 220 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M108.91 0.187223C141.72 -2.40132 168.61 22.2922 188.94 46.9907C209.033 71.4008 227.529 102.042 216.894 131.372C206.919 158.885 170.423 162.617 142.644 174.676C116.171 186.169 91.1118 206.423 63.3963 198.008C31.9885 188.472 4.74179 162.651 0.393689 131.488C-3.68208 102.277 24.7947 81.5747 43.9216 58.4319C63.2826 35.0058 77.7423 2.64619 108.91 0.187223Z" fill="#AAD9D0" />
    </svg>
  );
};

export default BlobV2;
