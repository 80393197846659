import DeviceService from 'services/device.service';
import localStorage from './localStorage';

const checkIsTrackingEnabled = () => {
  return window.config.tracking.trackingEnabled && !DeviceService.isPWA;
};

function checkAndIdentifyUser() {
  const userIdForSegmentIsMissing = !localStorage.getItem('ajs_user_id')?.length > 0;
  if (userIdForSegmentIsMissing) {
    const {
      user,
    } = window.config;
    if (user._id.length > 0) {
      localStorage.setItem('ajs_user_id', user._id);
    }
  }
}

export const track = ({ event, properties, identifyUser = true }) => {
  if (!checkIsTrackingEnabled()) return;
  try {
    let props = properties;
    if (identifyUser) {
      checkAndIdentifyUser();
    }
    if (window.config.isShadowLogin) {
      props = { ...props, isShadow: true };
    }
    window.analytics.track(event, props);
  } catch (err) {
    console.error('Tracking service web', err.message);
  }
};

export const identifyUser = (userId) => {
  if (!checkIsTrackingEnabled()) return;
  window.analytics?.identify(userId);
};

export const urlHasChanged = (url) => {
  if (!checkIsTrackingEnabled()) return;
  try {
    localStorage.setItem('segment', url);
    checkAndIdentifyUser();
    window.analytics?.page(url);
  } catch (err) {
    console.error('Tracking Url Changed API', err.message);
  }
};
