import toastService from 'helpers/toastService';
import parseError, { getErrors } from 'helpers/parseError';
import polyglot from 'services/localization';

// Remove this and the Form method for pure javascript form handling
import React from 'react';

export default class FormValidatorGroup {
  #initialValues;

  #isSubmitting;

  #debounceTimer;

  #valuesAfterCreation;

  /**
   * Create formValidatorGroup to use as Form in Components.
   * @param {FormValidator[]} formValidatorGroup array of formValidators used in formGroup
   * @param {Component} component Context of current form component
   * @param {Object<any>} initialValues Initial values of formItem.
   * (For instance client, vehicle, product, etc.)
   * @param {function(currentValues, formValidatorGroup)} onSubmit Callback on submit.
   * Add error to group or throw error to fail validation
   * @param {function(formValidatorGroup)} onValidate Callback on validate.
   * Add error or return errorObject to fail validation
   */
  constructor(formValidatorGroup, component, initialValues, onSubmit, onValidate) {
    this.group = formValidatorGroup;
    this.#initialValues = initialValues;
    this.#valuesAfterCreation = {};
    this.submitCount = 0;
    // Hook triggered on Debounce
    this.onDebounce = () => {};
    // Hook triggered on Reset
    this.onReset = () => {};

    Object.keys(this.group).forEach((validatorKey) => {
      const currentValidator = this.group[validatorKey];
      // Set initial
      if (initialValues && initialValues[validatorKey] != null) {
        currentValidator.set(initialValues[validatorKey], false);
      }
      // Save initial Form values
      this.#valuesAfterCreation[validatorKey] = currentValidator.value;
      // Set update listener for each validator
      this.group[validatorKey].registerOnUpdateListener((form) => {
        component.forceUpdate();
        this.submitCount = 0;
        if (this.#debounceTimer) {
          clearTimeout(this.#debounceTimer);
        }
        this.#debounceTimer = setTimeout(() => this.onDebounce(this.getValues()), 500);
      });
    });
    this.component = component;
    this.onSubmitCallback = onSubmit;
    this.onValidateCallback = onValidate;
    this.#isSubmitting = false;
  }

  get isSubmitting() {
    return this.#isSubmitting;
  }

  get wasTouched() {
    let wasTouched = false;
    Object.keys(this.group).forEach((key) => {
      if (this.group[key].touched) wasTouched = true;
    });
    return wasTouched;
  }

  get isDirty() {
    let isDirty = false;
    Object.keys(this.group).forEach((key) => {
      if (this.group[key].dirty) isDirty = true;
    });
    return isDirty;
  }

  get hasErrors() {
    let hasErrors = false;
    Object.keys(this.group).forEach((key) => {
      if (this.group[key].errors.length > 0) {
        hasErrors = true;
      }
    });
    return hasErrors;
  }

  get initialValues() {
    return { ...this.#initialValues };
  }

  setIsSubmitting = (newIsSubmitting) => {
    this.#isSubmitting = newIsSubmitting;
    this.component.forceUpdate();
  };

  getFormError = () => {
    const groupKeys = Object.keys(this.group);
    for (let i = 0; i < groupKeys.length; i += 1) {
      const key = groupKeys[i];
      if (this.group[key].errors.length > 0) {
        return this.group[key].errors[0];
      }
    }
    return null;
  };

  set = (validatorName, value) => {
    this.group[validatorName].set(value);
    return this;
  };

  reset = (toOverride = {}) => {
    this.submitCount = 0;
    const toOverrideKeys = Object.keys(toOverride);
    Object.keys(this.group).forEach((validatorKey) => {
      this.group[validatorKey].set(toOverrideKeys.includes(validatorKey)
        ? toOverride[validatorKey] : this.#valuesAfterCreation[validatorKey], false);
    });
    this.resetDirtyAndTouched();
    // Call onReset with currentForm, valuesAfterCreation and initialObjectValues
    if (this.onReset) this.onReset(this, this.#valuesAfterCreation, this.#initialValues);
  };

  resetDirtyAndTouched = () => {
    Object.keys(this.group).forEach((key) => {
      this.group[key].dirty = false;
      this.group[key].touched = false;
    });
  };

  // Parses api errors and adds them to form
  parseError = (err, isInternal = false) => {
    const errors = getErrors(err);
    if (Array.isArray(errors)) {
      errors.forEach((error) => {
        const [key] = Object.keys(error);
        if (this.group[key]) {
          this.group[key].addError({ id: 'custom', message: error[key] });
        }
      });
      if (isInternal) {
        toastService.showError(parseError(err) || polyglot.t('genericError'));
      }
    } else {
      toastService.showError(polyglot.t('genericError'));
    }
  };

  submit = async (event = null) => {
    if (event) {
      if (event.preventDefault) event.preventDefault();
      if (event.stopPropagation) event.stopPropagation();
    }
    this.setIsSubmitting(true);
    // Only validate if user has changed any value since last submit
    let customError;
    if (this.submitCount === 0) {
      customError = await Promise.resolve(this.validate());
    } else if (this.onValidateCallback) {
      customError = await Promise.resolve(this.onValidateCallback(this));
    }
    const currentError = this.getFormError() || customError;

    // If form is invalid - show Toast
    if (currentError) {
      this.setIsSubmitting(false);
      if (this.submitCount > -1) {
        toastService.showError(currentError.message);
      }
      this.submitCount += 1;
      return;
    }

    try {
      await this.onSubmitCallback(this.getValues(), this);
      // If submit succeeded, set dirty and touched to false
      this.resetDirtyAndTouched();
    } catch (err) {
      // Parse errors and add to form
      this.parseError(err, true);
    }
    this.setIsSubmitting(false);
    this.submitCount += 1;
  };

  getValues = () => {
    const retObj = {};
    Object.keys(this.group).forEach((validator) => {
      retObj[validator] = this.group[validator].value;
    });
    return {
      ...this.#initialValues,
      ...retObj,
    };
  };

  validate = () => {
    Object.keys(this.group).forEach((validator) => {
      this.group[validator].validate();
      this.group[validator].touched = true;
    });
    if (this.onValidateCallback) return this.onValidateCallback(this);
    return null;
  };

  setValidator = (validatorName, validator) => {
    this.group[validatorName] = validator;
    return this;
  };

  Form = (props) => {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <form onSubmit={this.submit}>
        {props.children}
      </form>
    );
  };
}
