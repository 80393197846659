import { connect } from 'react-redux';
import { getCurrentClient } from 'resources/clients/clients.selectors';
import { getCurrentVehicle } from 'resources/vehicles/vehicles.selectors';

import Breadcrumbs from './breadcrumbs';

export default connect((state) => ({
  currentClient: getCurrentClient(state),
  currentVehicle: getCurrentVehicle(state),
}))(Breadcrumbs);
