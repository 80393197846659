import * as apiClient from 'resources/api.client';

export function createSupplier(data, garageId) {
  return apiClient.post('suppliers', { garageId }, data);
}

export function fetchSuppliers(data) {
  return apiClient.get('suppliers', data);
}

export function updateSupplier(supplierId, supplier, garageId) {
  return apiClient.put(`suppliers/${supplierId}`, { garageId }, supplier);
}

export function removeSuppliers(supplierIds, garageId) {
  return apiClient.post('suppliers/remove', { garageId }, { supplierIds });
}

export function fetchSuppliersCount(garageId, data) {
  return apiClient.get('suppliers/count', { garageId, ...data });
}
