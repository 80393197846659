/* eslint-disable valid-typeof */
import { replaceObj } from 'helpers/locale';
import {
  isEmail, isDate, isISODate, isURL, isBic, onlyContainsBasicChars, startsOrEndsWithPoint,
} from 'helpers/regexp';
import polyglot from 'services/localization';
import PhoneNumberService from 'shared-library/src/services/phoneNumberService';
import { isIBAN } from 'helpers/iban';

const phoneNumberService = PhoneNumberService.getInstance();

export default class Validators {
  static minLength = (min, errorMsg) => (value) => {
    if (!value || value.length < min) return { id: 'minLength', message: errorMsg || replaceObj(polyglot.t('formValidators.minLength'), { min }) };
    return null;
  };

  static maxLength = (max, errorMsg) => (value) => {
    if (value && value.length > max) return { id: 'maxLength', message: errorMsg || replaceObj(polyglot.t('formValidators.maxLength'), { max }) };
    return null;
  };

  static email = (errorMsg) => (value) => {
    if (value && !isEmail(value)) return { id: 'email', message: errorMsg || polyglot.t('formValidators.email') };
    return null;
  };

  static mustOnlyContainBasicChars = (errorMsg) => (value) => {
    if (value && (!onlyContainsBasicChars(value) || startsOrEndsWithPoint(value))) return { id: 'mustOnlyContainBasicChars', message: errorMsg || polyglot.t('formValidators.mustOnlyContainBasicChars') };
    return null;
  };

  static required = (errorMsg) => {
    function required(value) {
      if (!value && value !== 0) return { id: 'required', message: errorMsg || polyglot.t('formValidators.required') };
      return null;
    }
    // Add custom prop validatorId to be able to check if it is in validators array
    required.validatorId = 'required';
    return required;
  };

  static mustBeNumber = (errorMsg) => (value) => {
    if (value && Number.isNaN(+value)) return { id: 'mustBeNumber', message: errorMsg || polyglot.t('formValidators.mustBeNumber') };
    return null;
  };

  static oneOf = (allowedValues, errorMsg) => (value) => {
    if (value && !allowedValues.includes(value)) return { id: 'oneOf', message: errorMsg || replaceObj(polyglot.t('formValidators.oneOf'), { allowedValues }) };
    return null;
  };

  static allowRegex = (allowedValuesRegex, errorMsg) => (value) => {
    const parsedRegex = new RegExp(allowedValuesRegex);

    if (value && !parsedRegex.test(value)) return { id: 'allowRegex', message: errorMsg || polyglot.t('formValidators.allowRegex') };
    return null;
  };

  static mustBeValidFilename = (errorMsg) => (value) => {
    if (Validators.allowRegex(/^[^/|<>:?*#\\]*$/)(value)) return { id: 'mustBeValidFilename', message: errorMsg || polyglot.t('formValidators.mustBeValidFilename') };
    return null;
  };

  static typeOf = (allowedTypes, errorMsg) => (value) => {
    if (Array.isArray(allowedTypes)) {
      for (let i = 0; i < allowedTypes.length; i += 1) {
        if (typeof value === allowedTypes[i]) return null;
      }
    } else if (typeof value === allowedTypes) {
      return null;
    }
    return { id: 'typeOf', message: errorMsg || `Must have type of ${allowedTypes}` };
  };

  static multiEmail = (errorMessage) => (value) => {
    if (value) {
      const emailParts = value.replace(/ /g, '').replace(/,/g, ';').split(';');
      if (emailParts.length > 10) return { id: 'multiEmail', message: polyglot.t('formValidators.tooManyEmails') };
      // eslint-disable-next-line no-restricted-syntax
      for (let i = 0; i < emailParts.length; i += 1) {
        if (!isEmail(emailParts[i])) {
          return { id: 'multiEmail', message: errorMessage || polyglot.t('formValidators.multiEmail') };
        }
      }
    }
    return null;
  };

  static date = (errorMessage) => (value) => {
    const checkVal = value?.toISOString ? value.toISOString() : value;
    if (checkVal && (!isDate(checkVal) && !isISODate(checkVal))) return { id: 'date', message: errorMessage || polyglot.t('formValidators.date') };
    return null;
  };

  static iban = (errorMsg) => (value) => {
    if (value && !isIBAN(value)) return { id: 'iban', message: errorMsg || polyglot.t('garages.errors.iban') };
    return null;
  };

  static website = (errorMsg) => (value) => {
    if (value && !isURL(value)) return { id: 'website', message: errorMsg || polyglot.t('garages.errors.website') };
    return null;
  };

  static bic = (errorMsg) => (value) => {
    if (value && !isBic(value)) return { id: 'bic', message: errorMsg || polyglot.t('garages.errors.bic') };
    return null;
  };

  static minLetters = (min, errorMsg) => (value) => {
    if (value && value.replace(/[^a-zöäüA-ZÖÄÜß]/g, '').length < min) return { id: 'minLetters', message: errorMsg || replaceObj(polyglot.t('formValidators.minLetters'), { min }) };
    return null;
  };

  static minDigits = (min, errorMsg) => (value) => {
    if (value && value.replace(/[\D]/g, '').length < min) return { id: 'minDigits', message: errorMsg || replaceObj(polyglot.t('formValidators.minDigits'), { min }) };
    return null;
  };

  static password = (errorMsg) => (value) => {
    const result = Validators.minLength(8)(value)
                || Validators.minLetters(1)(value)
                || Validators.minDigits(1)(value);
    if (result) return errorMsg || result;
    return null;
  };

  static isMobilePhoneNumber = (errorMsg) => (value) => {
    if (!phoneNumberService.isNullOrValideMobileNumber(value)) return { id: 'isMobilePhoneNumber', message: errorMsg || polyglot.t('formValidators.isMobilePhoneNumber') };
    return null;
  };
}
