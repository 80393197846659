/* eslint-disable no-param-reassign */

const { getNet, getGross } = require('./calculateTotals.service');

function recalculatePrices(item, fromGrossToNet) {
  item.unitPrice = fromGrossToNet
    ? getNet(item.unitPrice, item.vat.rate)
    : getGross(item.unitPrice, item.vat.rate);
  item.price = fromGrossToNet
    ? getNet(item.price, item.vat.rate)
    : getGross(item.price, item.vat.rate);
  item.positionPrice = item.price * item.quantity;
  item.purchasePrice = fromGrossToNet
    ? getNet(item.purchasePrice, item.vat.rate)
    : getGross(item.purchasePrice, item.vat.rate);
}

function recalculateItemTotals(items, fromGrossToNet) {
  const newItems = items.map((item) => {
    if (!item.isInactive) {
      if (item.type === 'group') {
        item.items.map((groupItem) => {
          recalculatePrices(groupItem, fromGrossToNet);
          return groupItem;
        });
      } else {
        recalculatePrices(item, fromGrossToNet);
      }
    }
    return item;
  });
  return newItems;
}

module.exports = {
  recalculateItemTotals,
};
