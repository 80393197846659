import React from 'react';

const Arrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="110" viewBox="0 0 102 120" fill="none">
    <path style={{
      stroke: '#4F6374', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2', fill: 'none', transform: 'rotate(-15deg) translate(2px, 20px)', scale: '4',
    }} d="M 0 0 q 12 -2 17.3 -13 M 12.7 -10 l 4.6 -3 l -0.5 5"/>
  </svg>
);

export default Arrow;
