import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import polyglot from 'services/localization';
import localStorage from 'services/localStorage';
import * as fromOffers from 'resources/offer/offer.selectors';
import {
  fetchOffers,
  changeOffersFilter,
  resetOffersState,
} from 'resources/offer/offer.actions';
import { toUTCDay } from 'helpers/date';
import NoEntities from 'components/common/noEntities';
import SearchInput from 'components/common/searchInput';
import SortableTableTitle from 'components/common/sortableTableTitle';
import Button from 'components/common/button';
import InfiniteTableBody from 'components/common/infiniteTableBody';
import TableLoading from 'components/common/loading/components/tableLoading/tableLoading';
import ColoredPrice from 'components/common/coloredPrice';
import StatusLabel from 'components/invoices/statusLabel';
import Retry from 'components/common/retry';

import pageStyles from 'styles/page.pcss';
import tableStyles from 'styles/table.pcss';

class VehicleOffers extends PureComponent {
  static propTypes = {
    offers: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchOffersAction: PropTypes.func.isRequired,
    resetOffersStateAction: PropTypes.func.isRequired,
    totalAmount: PropTypes.number.isRequired,
    changeOffersFilterAction: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
    }).isRequired,
  };

  static sortableTableTitles = [{
    title: polyglot.t('invoices.fields.status'),
    keys: ['status'],
  }, {
    title: polyglot.t('invoices.fields.number'),
    keys: ['numberNumeric'],
  }, {
    title: polyglot.t('invoices.fields.createdOn'),
    keys: ['createdOn'],
  }, {
    title: polyglot.t('invoices.fields.vehicle'),
    keys: ['vehicle.manufacturer'],
  }, {
    title: polyglot.t('invoices.fields.gross'),
    keys: ['gross'],
    align: 'right',
  }];

  constructor(props) {
    super(props);

    const { history } = props;

    this.state = {
      searchText: '',
      ...localStorage.getItem(history.location.pathname),
      sortBy: ['status', 'numericNumber', 'createdOn'],
      sortDirection: -1,
      isNoOffers: false,
      isFetchLoading: false,
      isFetchError: false,
      isChangeFilterLoading: false,
      isChangeFilterError: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { history } = this.props;
    const { searchText } = this.state;

    if (searchText !== nextState.searchText) {
      localStorage.setItem(history.location.pathname, {
        searchText: nextState.searchText,
      });
    }
  }

  componentWillUnmount = () => {
    const { resetOffersStateAction } = this.props;

    resetOffersStateAction();
  };

  onChangeSort = ({ sortBy, sortDirection }) => {
    const { searchText } = this.state;
    this.setState({ sortBy, sortDirection, isChangeFilterLoading: true });
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  onSearchChange = (searchText) => this.setState({ searchText, isChangeFilterLoading: true });

  onSearchDebounce = () => {
    const { sortBy, sortDirection, searchText } = this.state;
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  onRetryChangeFilter = () => {
    this.setState({ isChangeFilterLoading: true });
    const { sortBy, sortDirection, searchText } = this.state;
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  onScroll = () => {
    const {
      offers,
      totalAmount,
      match,
      fetchOffersAction,
    } = this.props;
    const { sortBy, sortDirection, searchText } = this.state;

    if (offers.length >= totalAmount) {
      return Promise.resolve();
    }

    return fetchOffersAction({
      skip: offers.length,
      sortBy,
      sortDirection,
      searchText,
      vehicleId: match.params.id,
    });
  };

  onOpenOffer = (offerName, offerId) => {
    const { history, match } = this.props;

    if (offerName === 'draft' || offerName === 'preview') {
      return history.push(
        `/invoices/${offerName}/new`,
        { vehicleId: match.params.id },
      );
    }

    return history.push(
      `/offers/${offerId || 'new'}`,
      { vehicleId: match.params.id },
    );
  };

  fetch = () => {
    const { match, fetchOffersAction } = this.props;
    const { sortBy, sortDirection, searchText } = this.state;

    this.setState({ isFetchLoading: true });
    fetchOffersAction({
      sortBy, sortDirection, searchText, vehicleId: match.params.id,
    }, true)
      .then(({ payload }) => this.setState({
        isNoOffers: !payload.isExists,
        isFetchLoading: false,
        isFetchError: false,
      }))
      .catch(() => this.setState({ isFetchError: true, isFetchLoading: false }));
  };

  changeFilter = ({ sortBy, sortDirection, searchText }) => {
    const { match, changeOffersFilterAction } = this.props;
    changeOffersFilterAction({
      sortBy, sortDirection, searchText, vehicleId: match.params.id,
    })
      .then(() => this.setState({ isChangeFilterLoading: false, isChangeFilterError: false }))
      .catch(() => this.setState({ isChangeFilterLoading: false, isChangeFilterError: true }));
  };

  renderTableBody = () => {
    const { offers } = this.props;
    const { isChangeFilterLoading, isChangeFilterError } = this.state;

    if (!isChangeFilterLoading && isChangeFilterError) {
      return <Retry onRetry={this.onRetryChangeFilter} />;
    }

    if (!offers.length) {
      return <NoEntities withoutBorder noEntitiesText={polyglot.t('vehicleOffers.notFound')} />;
    }

    return (
      <InfiniteTableBody isDataLoading={isChangeFilterLoading} onScroll={this.onScroll}>
        {offers.map(({
          _id: offerId, status, number, createdOn, vehicle, gross,
        }) => (
          <tr
            key={offerId}
            className={tableStyles.tableRow}
            onClick={() => this.onOpenOffer(status, offerId)}
          >
            <td className={tableStyles.tableElement}>
              <StatusLabel
                status={'offer'}
                gross={gross}
              />
            </td>
            <td className={tableStyles.tableElement}>
              {number || '---'}
            </td>
            <td className={tableStyles.tableElement}>
              {createdOn ? toUTCDay(createdOn) : '---'}
            </td>
            <td className={tableStyles.tableElement}>
              {vehicle.manufacturer || '---'}
            </td>
            <td className={cx(tableStyles.tableElement, tableStyles.tableRight)}>
              <ColoredPrice priceValue={gross || 0} />
            </td>
          </tr>
        ))}
      </InfiniteTableBody>
    );
  };

  renderCreateButtons = () => (
    <div className={pageStyles.pageActionsButtons}>
      {
        ['offer', 'draft', 'preview'].map((offerName) => (
          <Button
            key={offerName}
            styleType="add"
            onClick={() => this.onOpenOffer(offerName)}
          >
            {polyglot.t(`clientInvoices.create.${offerName}`)}
          </Button>
        ))
      }
    </div>
  );

  render() {
    const {
      searchText,
      isFetchLoading,
      isNoOffers,
      isFetchError,
      sortDirection,
      sortBy,
    } = this.state;

    const { totalAmount } = this.props;

    if (isFetchLoading) {
      return (<TableLoading>
        {this.renderCreateButtons()}
      </TableLoading>);
    }

    if (isFetchError) {
      return <Retry className={cx(pageStyles.page, pageStyles.pageContent)} onRetry={this.fetch} />;
    }

    if (isNoOffers) {
      return (
        <NoEntities noEntitiesText={polyglot.t('vehicleOffers.thereAreNoVehicleOffers')}>
          {this.renderCreateButtons()}
        </NoEntities>
      );
    }

    const sortableTableTitles = VehicleOffers.sortableTableTitles.map(({ title, keys, align }) => (
      <SortableTableTitle
        key={title}
        onChangeSort={this.onChangeSort}
        title={title}
        sortKeys={keys}
        sortBy={sortBy}
        sortDirection={sortDirection}
        align={align}
      />
    ));

    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.pageContent}>
          <div className={pageStyles.pageActions}>
            <div className={pageStyles.pageActionsSearch}>
              <SearchInput
                onChange={this.onSearchChange}
                onDebounce={this.onSearchDebounce}
                placeholder={polyglot.t('actions.searchPlaceholder')}
                value={searchText}
                totalAmount={totalAmount}
              />
            </div>
            <div className={pageStyles.pageActionsButtons}>
              {this.renderCreateButtons()}
            </div>
          </div>

          <table className={tableStyles.table}>
            <thead className={tableStyles.tableHeader}>
              <tr className={tableStyles.tableLightRow}>
                {sortableTableTitles}
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  offers: fromOffers.getOffers(state),
  totalAmount: fromOffers.getTotalAmount(state),
}), {
  fetchOffersAction: fetchOffers,
  changeOffersFilterAction: changeOffersFilter,
  resetOffersStateAction: resetOffersState,
})(VehicleOffers);
