import * as apiClient from 'resources/api.client';

export function fetchThirdPartyTools(garageId) {
  return apiClient.get('third-party-tools', { garageId });
}

export function parseBasketFile(garageId, fileString, fileType) {
  return apiClient.post(
    'third-party-tools/baskets/parseFile',
    { garageId },
    { fileString, fileType },
  );
}
