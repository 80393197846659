import { last } from 'lodash/array';

import { updateAppByAction } from 'resources/utils';
import {
  CREATE_SUPPLIER,
  FETCH_SUPPLIERS,
  RESET_SUPPLIERS_STATE,
  REMOVE_SUPPLIERS,
  UPDATE_SUPPLIER,
  CHANGE_SUPPLIERS_FILTER,
} from './suppliers.actions';

const initialProductsCount = {
  product: null,
  work: null,
  other: null,
  positionGroups: null,
  suppliers: null,
  all: null,
};

const initialState = {
  list: [],
  totalAmount: 0,
  productsCounts: { ...initialProductsCount },
};
const actionTypesToUpdateApp = [CREATE_SUPPLIER, REMOVE_SUPPLIERS, UPDATE_SUPPLIER];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case CREATE_SUPPLIER:
      return {
        ...state,
        list: [...state.list, action.payload],
        totalAmount: state.totalAmount + 1,
      };

    case FETCH_SUPPLIERS: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
        productsCounts: action.data.productsCounts || { ...initialProductsCount },
      };
    }

    case RESET_SUPPLIERS_STATE:
      return {
        ...initialState,
      };

    case UPDATE_SUPPLIER:
      return {
        ...state,
        list: state.list.map((s) => (
          s._id === action.supplierId ? action.payload : s
        )),
      };

    case REMOVE_SUPPLIERS:
      return {
        ...state,
        list: state.list.filter((s) => !action.supplierIds.includes(s._id)),
        totalAmount: state.totalAmount - action.supplierIds.length,
      };

    case CHANGE_SUPPLIERS_FILTER:
      return {
        ...state,
        list: action.payload.results || [],
        totalAmount: action.payload.count,
      };

    default:
      return state;
  }
};
