import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './notificationButton.styles.pcss';

const NotificationButton = ({
  className,
  children,
  onClick,
  style,
  value,
}) => (
  <button
    className={cx(className, styles.notificationButton)}
    onClick={onClick}
    type="button"
    style={style}
    value={value}
  >
    {children}
  </button>
);

NotificationButton.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

NotificationButton.defaultProps = {
  className: '',
  value: '',
};

export default NotificationButton;
