import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './buttonLoading.styles.pcss';

const ButtonLoading = ({ className, useDarkSpinner }) => (
  <div className={cx(
    styles.skFadingCircle,
    useDarkSpinner && styles.skFadingCircleDark,
    className,
  )}>
    <div className={cx(styles.skCircle1, styles.skCircle)} />
    <div className={cx(styles.skCircle2, styles.skCircle)} />
    <div className={cx(styles.skCircle3, styles.skCircle)} />
    <div className={cx(styles.skCircle4, styles.skCircle)} />
    <div className={cx(styles.skCircle5, styles.skCircle)} />
    <div className={cx(styles.skCircle6, styles.skCircle)} />
    <div className={cx(styles.skCircle7, styles.skCircle)} />
    <div className={cx(styles.skCircle8, styles.skCircle)} />
    <div className={cx(styles.skCircle9, styles.skCircle)} />
    <div className={cx(styles.skCircle10, styles.skCircle)} />
    <div className={cx(styles.skCircle11, styles.skCircle)} />
    <div className={cx(styles.skCircle12, styles.skCircle)} />
  </div>
);

ButtonLoading.propTypes = {
  className: PropTypes.string,
  useDarkSpinner: PropTypes.bool,
};

ButtonLoading.defaultProps = {
  className: '',
  useDarkSpinner: false,
};

export default ButtonLoading;
