import * as apiClient from 'resources/api.client';

export const sendInvoiceToSdi = (invoiceId, garageId) => {
  return apiClient.post('sdi/send-invoice-to-sdi', { garageId }, { invoiceId });
};

export const sendManyInvoicesToSdi = (invoiceIds, garageId) => {
  return apiClient.post('sdi/send-many-invoices-to-sdi', { garageId }, { invoiceIds });
};

export const fetchInvoicesForSdi = (garageId, data) => {
  return apiClient.get('sdi/invoices', { garageId, ...data });
};

export const fetchInvoiceIdsShouldBeSentToSdi = (garageId) => {
  return apiClient.get('sdi/invoices-should-be-sent-to-sdi', { garageId });
};

export const fetchIncomingSdiInvoices = (garageId, options = {}) => {
  return apiClient.get('sdi/incoming-invoices', { garageId, ...options });
};

export const updateStatusForIncomingSdiInvoices = (garageId, invoiceIds, status) => {
  return apiClient.post('sdi/update-incoming-invoice-status', { garageId }, { invoiceIds, status });
};

export const getIncomingSdiInvoicePdf = (garageId, invoiceId) => {
  return apiClient.get('sdi/incoming-invoice-pdf', { garageId, invoiceId });
};

export const fetchSdiInvoiceCsv = (garageId, data) => {
  return apiClient.post('sdi/incoming-invoice-csv', { garageId }, data);
};

export const createBusinessRegistryConfiguration = (garageId) => {
  return apiClient.post('sdi/create-business-registry-configuration', { garageId });
};
