import _ from 'lodash';

export function getGarage({ garage }) {
  return garage;
}

export function getGarageId({ garage }) {
  return garage._id;
}

export function getInvoiceSettings({ garage }) {
  return garage.invoiceSettings;
}

export function getNetOrGross({ garage }) {
  return garage.netOrGross;
}

export function getDefaultTaxRate({ garage }) {
  return _.cloneDeep(garage.invoiceSettings.defaultTaxRate);
}

export function getTaxRates({ garage }) {
  return garage.invoiceSettings.taxRates;
}

export function getGarageType({ garage }) {
  return garage.garageType;
}

export const getEmailSettings = ({ garage }) => garage.emailSettings;

export const getSMSSettings = ({ garage }) => { return garage.smsSettings; };

export const getCountry = ({ garage }) => garage.country;

export const getSubscriptionData = ({ garage }) => garage.subscriptionData;

export const getStandardSupplierNumber = ({ garage }) => (
  garage.invoiceSettings.standardSupplierNumber
);

export const getStandardBankNumber = ({ garage }) => garage.invoiceSettings.standardBankNumber;

export const getUnknownAccount = ({ garage }) => garage.invoiceSettings.unknownAccount;

export const getAccountingType = ({ garage }) => garage.invoiceSettings.accountingType;

export const getIntegrationCredentials = ({ garage }) => garage.integrationCredentials || {};

export const getSuppliers = ({ garage }) => garage.suppliers || [];

export const getMyposData
  = ({ garage }) => ((garage.integrationCredentials && garage.integrationCredentials.mypos)
    ? garage.integrationCredentials.mypos
    : {});

export const isThirdPartyToolActive = (thirdPartyToolName, {
  garage: { usedThirdPartyTools = [], integrationCredentials = {} },
  thirdPartyTools = [],
}) => {
  const { [thirdPartyToolName]: { username, password } = {} } = integrationCredentials;
  const { [thirdPartyToolName]: { customerId, userId } = {} } = integrationCredentials;
  const hasCredentials = (username && password) || (customerId, userId);
  return !!hasCredentials;
};

export const getSuppliersShops = ({
  globalSuppliers = [],
  garage: { suppliers = [], integrationCredentials = {} },
}) => globalSuppliers
  .filter(({ _id, hasCredentials, name }) => {
    const { [name]: { username, password } = {} } = integrationCredentials;
    return hasCredentials && username && password;
  });

export const getOwnerId = ({ garage }) => garage.ownerId || '';

export const getCredentialsForSupplier = (supplierName, { garage }) => {
  return garage.integrationCredentials
  && Object.keys(garage.integrationCredentials).includes(supplierName)
    ? garage.integrationCredentials[supplierName]
    : null;
};
