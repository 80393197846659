import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import toastService from 'helpers/toastService';
import ButtonModern from 'components/common/buttonModern';
import modalStyles from 'styles/modal.pcss';
import { faSave, faPlus } from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import cx from 'classnames';
import ClientForm from '../clientForm';
import ResponsiveModal from '../responsiveModal';
import styles from './clientEditModal.styles.pcss';

class ClientEditModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    client: PropTypes.shape({
      _id: PropTypes.string,
      formOfAddress: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      toAttention: PropTypes.string,
      phone: PropTypes.string.isRequired,
      mobilePhone: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      address: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string.isRequired,
      dateOfBirthday: PropTypes.string.isRequired,
      UIDNumber: PropTypes.string.isRequired,
      externalId: PropTypes.string.isRequired,
      additionalInfo: PropTypes.string.isRequired,
      printAdditionalInfo: PropTypes.bool.isRequired,
    }),
    createClient: PropTypes.func.isRequired,
    updateClient: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    isRoutingDisabled: PropTypes.bool,
  };

  static defaultProps = {
    client: undefined,
    onSuccess: () => null,
    isRoutingDisabled: false,
  };

  onCreateFormSucceed = ({ payload: client }) => {
    const {
      history,
      match: { params: { tab } },
      isRoutingDisabled,
      onSuccess,
    } = this.props;
    onSuccess(client);
    if (!isRoutingDisabled) { history.push(`/clients/${tab || 'all'}/${client._id}`); }
  };

  onUpdateFormSucceed = (client) => {
    const { onSuccess } = this.props;
    toastService.showSuccess(polyglot.t('editClient.notifications.updated'));
    onSuccess(client);
  };

  onSubmit = (values) => {
    const {
      createClient,
      updateClient,
      client: { _id } = {},
    } = this.props;

    if (_id) {
      return updateClient(values).then(({ data: client }) => this.onUpdateFormSucceed(client));
    }

    return createClient(values).then(this.onCreateFormSucceed);
  };

  render() {
    const {
      isOpen,
      onClose,
      client,
    } = this.props;

    return (
      <ResponsiveModal
        width={916}
        isOpen={isOpen}
        onClose={() => onClose(true)}
        title={client
          ? polyglot.t('createClientWithDocument.update')
          : polyglot.t('createClientWithDocument.createTitle')}
      >
        <ClientForm
          onSubmit={this.onSubmit}
          client={client}
          renderButtons={(clientForm) => (
            <>
              <ButtonModern
                styleType="add"
                disabled={clientForm.isSubmitting}
                dataTest="clientSaveButton"
                type="submit"
              >
                <FontAwesomeIcon
                  icon={client ? faSave : faPlus}
                  className={cx(modalStyles.footerModernButtonIconRightPadding, styles.icon)}
                />
                {client
                  ? polyglot.t('actions.saveChanges')
                  : polyglot.t('createClientWithDocument.create')}
              </ButtonModern>

              <ButtonModern
                styleType="cancel"
                onClick={() => onClose(true)}
              >
                {polyglot.t('actions.discard')}
              </ButtonModern>
            </>
          )}
        />
      </ResponsiveModal>
    );
  }
}

export default ClientEditModal;
