import React, { PureComponent } from 'react';
import polyglot from 'services/localization';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faBell, faChevronDown, faSpinner } from 'fontawesome/pro-solid-svg-icons';
import { SOCKET_MESSAGE_TYPES } from 'shared-library/src/definitions/socketMessageTypes';
import { ACTIVE_STATUSES } from 'shared-library/src/definitions/subscription';
import toastService from 'helpers/toastService';
import parseError from 'helpers/parseError';
import InteractableDiv from 'components/common/interactableDiv';
import * as notificationApi from 'resources/notifications/notifications.api';
import { ioClient } from 'index.jsx';

import NotificationEmptyContent from './cards/notificationEmptyContent';
import NotificationsCard from './cards/notificationsCard';
import styles from './notifications.styles';

export default class Notifications extends PureComponent {
  static propTypes = {
    garage: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      subscriptionData: PropTypes.shape({
        status: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.notificationsModalRef = React.createRef();
    this.state = {
      isLoading: true,
      isOpen: false,
      notifications: [],
      processedNotifications: [],
      isProcessedNotificationsLoading: false,
      totalAmountProcessedNotifications: 0,
    };
  }

  componentDidMount() {
    this.fetch();
    ioClient.on(SOCKET_MESSAGE_TYPES.NOTIFICATION, this.fetch);
  }

  componentWillUnmount() {
    ioClient.removeListener(SOCKET_MESSAGE_TYPES.NOTIFICATION, this.fetch);
  }

  onClose = () => {
    this.setState({ isOpen: false });
  };

  checkDocumentClick = (e) => {
    const { isOpen } = this.state;
    if (!isOpen) return;
    if (this.notificationsModalRef?.current
      && !this.notificationsModalRef.current.contains(e.target)) {
      document.removeEventListener('click', this.checkDocumentClick, true);
      setTimeout(() => {
        this.onClose();
      }, 1);
    }
  };

  onBellClick = () => {
    const { isOpen } = this.state;
    if (!isOpen) {
      document.addEventListener('click', this.checkDocumentClick, true);
      this.setState({ isOpen: true });
    }
  };

  fetch = async (iteration = 1) => {
    const { garage } = this.props;
    if (ACTIVE_STATUSES.includes(garage.subscriptionData.status)) {
      this.setState({ processedNotifications: [] });
      try {
        const notifications = await notificationApi.getNotifications(garage._id);
        const haveEverHadNotifications = notifications.length === 0
          ? (await notificationApi.haveEverHadNotifications(garage._id))?.haveEverHadNotifications
          : true;
        this.setState({ notifications, haveEverHadNotifications, isLoading: false });
      } catch (e) {
        if (iteration > 10) {
          return;
        }
        setTimeout(() => {
          this.fetch(iteration + 1);
        }, iteration * 2000);
      }
    }
  };

  fetchProcessedNotifications = async () => {
    const { garage } = this.props;
    const { haveEverHadNotifications, processedNotifications } = this.state;
    if (!haveEverHadNotifications) return;
    this.setState({ isProcessedNotificationsLoading: true });
    try {
      const { results: newProcessedNotifications, count: totalAmountProcessedNotifications }
        = await notificationApi.getProcessedNotifications(
          garage._id,
          { skip: processedNotifications.length, limit: 10 },
        );

      const concatedNotifications = processedNotifications.concat(newProcessedNotifications);
      this.setState({
        processedNotifications: concatedNotifications,
        isProcessedNotificationsLoading: false,
        totalAmountProcessedNotifications,
      });
    } catch (e) {
      const msg = parseError(e);
      toastService.showError(msg || polyglot.t('notification.fetchError'));
      this.setState({ isProcessedNotificationsLoading: false });
    }
  };

  onCardClick = async (id) => {
    const { garage } = this.props;
    await notificationApi.markAsProcessed(garage._id, id);
    this.onClose();
    this.fetch();
  };

  renderNotificationCards = (notifications) => {
    const { history } = this.props;
    return (
      <>
        {notifications.map((notification) => (
          <NotificationsCard
            key={`notification${notification._id}`}
            notification={notification}
            history={history}
            onClick={this.onCardClick}
            isProcessed={!!notification.processedOn}
          />
        ))}
      </>
    );
  };

  renderFetchMoreNotificationsButton = () => {
    const { processedNotifications, haveEverHadNotifications } = this.state;
    const { isProcessedNotificationsLoading, totalAmountProcessedNotifications } = this.state;
    const hasMore = processedNotifications.length < totalAmountProcessedNotifications;
    const shouldNotShowMoreButton
      = !haveEverHadNotifications || (processedNotifications.length !== 0 && !hasMore);
    if (shouldNotShowMoreButton) return null;
    return (
      <InteractableDiv
        className={styles.notificationShowMore}
        onClick={() => this.fetchProcessedNotifications()}
        disabled={isProcessedNotificationsLoading}
      >
        <div>
          {polyglot.t('notification.showMore')}
        </div>
        <FontAwesomeIcon
          className={styles.notificationShowMoreIcon}
          icon={isProcessedNotificationsLoading ? faSpinner : faChevronDown}
          spin={isProcessedNotificationsLoading}
        />
      </InteractableDiv>
    );
  };

  render() {
    const {
      notifications,
      processedNotifications,
      isOpen,
      haveEverHadNotifications,
      isLoading,
    } = this.state;

    return (
      <>
        <InteractableDiv
          className={styles.notificationIconContainer}
          onClick={this.onBellClick}
        >
          <FontAwesomeIcon icon={faBell} className={styles.notificationIcon} />

          {notifications?.length > 0 && (
            <>
              <div className={styles.notificationIconCircle}>
                {notifications.length}
              </div>
            </>
          )}
        </InteractableDiv>
        {isOpen && (
          <div ref={this.notificationsModalRef} className={styles.notificationModalOuter}>
            <div className={styles.notificationModal}>
              <div className={styles.notificationHeader}>
                <span className={styles.notificationHeaderText}>
                  <FontAwesomeIcon icon={faBell} className={styles.notificationHeaderBell} />
                  {polyglot.t('notification.heading')}
                </span>
              </div>
              <div className={styles.notificationListContainer}>
                <div className={styles.notificationList}>
                  { (!isLoading) && (notifications.length > 0
                    ? this.renderNotificationCards(notifications)
                    : (processedNotifications.length === 0 && <NotificationEmptyContent
                      haveEverHadNotifications={haveEverHadNotifications}/>))}
                  {this.renderNotificationCards(processedNotifications)}
                </div>
              </div>

              {this.renderFetchMoreNotificationsButton()}
            </div>
          </div>
        )}
      </>
    );
  }
}
