import { connect } from 'react-redux';

import { getUserRoles, getMobileViewOption } from 'resources/user/user.selectors';
import { getSubscriptionData, getGarage } from 'resources/garage/garage.selectors';
import { updateLastLogin } from 'resources/user/user.actions';

import Layout from './layout';

export default connect((state) => ({
  roles: getUserRoles(state),
  subscriptionData: getSubscriptionData(state),
  garage: getGarage(state),
  mobileViewOption: getMobileViewOption(state),
}), {
  updateLastLogin,
})(Layout);
