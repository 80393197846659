import * as apiClient from 'resources/api.client';

export function fetchTextBlocks(garageId, data) {
  return apiClient.get('textBlocks', { garageId, ...data });
}

export function createTextBlock(garageId, userId, data) {
  return apiClient.post('textBlocks', { garageId, userId }, data);
}

export function updateTextBlock(garageId, userId, data) {
  return apiClient.put(`textBlocks/${data._id}`, { garageId, userId }, data);
}

export function updateTextBlockScore(garageId, userId, data) {
  return apiClient.put(`textBlocks/${data._id}/score`, { garageId, userId }, data);
}

export function removeTextBlocks(garageId, userId, textBlockIds) {
  return apiClient.post('textBlocks/remove', { garageId, userId }, { textBlockIds });
}
