import React from 'react';
import PropTypes from 'prop-types';
import styles from './radioButtonModern.styles.pcss';

export default function RadioButtonModern({
  id, value, onChange, checked, label, className, dataTest, ...props
}) {
  return (
    <div className={styles.radio}>
      <label className={styles.radioLabel}>
        <input
          tabIndex={0}
          className={styles.radioInput}
          type="radio"
          value={value}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') onChange(e);
          }}
          checked={checked}
          data-test={dataTest}
          {...props}
        />
        {label}
      </label>
    </div>
  );
}

RadioButtonModern.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  className: PropTypes.string,
  dataTest: PropTypes.string,
};
RadioButtonModern.defaultProps = {
  id: '',
  value: '',
  className: '',
  dataTest: '',
};
