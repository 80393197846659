import React from 'react';

const BlobV4 = () => {
  return (
    <svg width="534" height="311" viewBox="0 0 534 311" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M281.867 307.449C194.872 318.661 127.386 297.473 71.1528 275.754C24.5837 257.767 -0.716854 230.868 0.560481 198.801C1.72359 169.6 41.352 140.867 77.6864 111.723C122.529 75.7556 153.277 31.8633 232.172 13.0256C316.611 -7.1358 409.645 -2.08772 467.279 18.7005C520.207 37.7912 501.431 78.9441 509.203 112.948C517.336 148.529 556.675 181.454 513.018 218.806C464.931 259.947 373.579 295.629 281.867 307.449Z" fill="#E3EFED" />
    </svg>

  );
};

export default BlobV4;
