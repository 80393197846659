import Intercom, { showArticle, showNewMessage, showSpace } from '@intercom/messenger-js-sdk';

// eslint-disable-next-line import/prefer-default-export
export function bootSupportChat() {
  const { disabled, ...supportChatConfig } = window.config.supportChat;
  if (disabled) {
    console.log('Support chat has been disabled for the environment'); // eslint-disable-line
    return;
  }
  Intercom(supportChatConfig);
}

export function openHelpArticle(articleId) {
  showArticle(articleId);
}

export function showNewSupportMessage(prePopulatedContent) {
  showNewMessage(prePopulatedContent);
}

export function showSupportSpace(spaceId) {
  showSpace(spaceId);
}
