import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import polyglot from 'services/localization';
import _ from 'lodash';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import localStorage from 'services/localStorage';
import {
  getMoveToRecycleBinEntity,
} from 'helpers/confirmModalStrings';
import NoEntities from 'components/common/noEntities';
import SearchInput from 'components/common/searchInput';
import TableLoading from 'components/common/loading/components/tableLoading/tableLoading';
import SortableTableTitle from 'components/common/sortableTableTitle';
import StopEventPropagation from 'components/common/stopEventPropagation';
import ActionWithConfirmModal from 'components/common/actionWithConfirmModal';
import InfiniteTableBody from 'components/common/infiniteTableBody';
import MileageCell from 'components/common/mileageCell';
import Retry from 'components/common/retry';
import { toUTCDay } from 'helpers/date';

import pageStyles from 'styles/page.pcss';
import tableStyles from 'styles/table.pcss';
import VehicleEditModal from 'components/common/vehicleEditModal';
import Button from 'components/common/button';

class VehiclesList extends PureComponent {
  static propTypes = {
    currentClient: PropTypes.object.isRequired,
    vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchVehiclesByClientId: PropTypes.func.isRequired,
    lazyFetchVehiclesByClientId: PropTypes.func.isRequired,
    resetVehiclesStateByClientId: PropTypes.func.isRequired,
    totalAmount: PropTypes.number.isRequired,
    changeVehiclesFilterByClientId: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
    }).isRequired,
    removeVehicles: PropTypes.func.isRequired,
    fetchClient: PropTypes.func.isRequired,
  };

  static sortableTableTitles = [{
    title: polyglot.t('clientVehicles.manufacturer'),
    keys: ['manufacturer'],
  }, {
    title: polyglot.t('clientVehicles.model'),
    keys: ['model'],
  }, {
    title: polyglot.t('clientVehicles.license'),
    keys: ['license'],
  }, {
    title: polyglot.t('clientVehicles.dateOfFirstRegistration'),
    keys: ['dateOfFirstRegistration'],
  }];

  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      ...localStorage.getItem('/vehicles'),
      sortBy: ['manufacturer'],
      sortDirection: 1,
      isNoVehicles: false,
      isFetchLoading: false,
      isFetchError: false,
      isChangeFilterLoading: false,
      isChangeFilterError: false,
      vehicle: { },
    };
  }

  componentDidMount() {
    this.setInitialClient();
    this.fetch();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { searchText } = this.state;

    if (searchText !== nextState.searchText) {
      localStorage.setItem('/vehicles', {
        searchText: nextState.searchText,
      });
    }
  }

  componentWillUnmount = () => {
    const { resetVehiclesStateByClientId } = this.props;
    resetVehiclesStateByClientId();
  };

  onChangeSort = ({ sortBy, sortDirection }) => {
    const { searchText } = this.state;
    this.setState({ sortBy, sortDirection, isChangeFilterLoading: true });
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  onSearchChange = (searchText) => this.setState({ searchText, isChangeFilterLoading: true });

  onSearchDebounce = () => {
    const { sortBy, sortDirection, searchText } = this.state;
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  onScroll = () => {
    const {
      vehicles,
      totalAmount,
      match,
      lazyFetchVehiclesByClientId,
    } = this.props;

    const { sortBy, sortDirection, searchText } = this.state;

    if (vehicles.length >= totalAmount) {
      return Promise.resolve();
    }

    return lazyFetchVehiclesByClientId({
      skip: vehicles.length,
      sortBy,
      sortDirection,
      searchText,
      clientId: match.params.id,
    });
  };

  onTableRowClick = (vehicle) => {
    const { history } = this.props;
    history.push(`/vehicles/all/${vehicle._id}`);
  };

  onRemoveVehicle = (vehicleId) => {
    const { removeVehicles } = this.props;

    removeVehicles([vehicleId]);
  };

  renderRemoveIcon = (onOpenConfirmModal, vehicleId) => (
    <div title={polyglot.t('actions.remove')}>
      <DeleteIcon
        className={cx(tableStyles.tableIcon, tableStyles.tableIconAction)}
        onClick={() => onOpenConfirmModal(
          getMoveToRecycleBinEntity(polyglot.t('confirm.vehicle')),
          (
            <>
              <div style={{ marginBottom: '30px' }}>
                {(polyglot.t('recycleBin.move.info'))}
              </div>
              {(polyglot.t('recycleBin.move.vehicle.sure'))}
            </>
          ),
          () => this.onRemoveVehicle(vehicleId),
          true,
        )}
      />
    </div>
  );

  onRetryChangeFilter = () => {
    this.setState({ isChangeFilterLoading: true });
    const { sortBy, sortDirection, searchText } = this.state;
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  setInitialClient = async () => {
    const {
      match: {
        params,
      },
      currentClient,
      fetchClient,
    } = this.props;

    let client = currentClient._id === params.id ? currentClient : undefined;

    if (!client) {
      client = (await fetchClient(params.id)).payload;
    }

    this.setState({ vehicle: { client: { ...client, clientId: client._id } } });
  };

  fetch = () => {
    const { match, fetchVehiclesByClientId } = this.props;
    const { sortBy, sortDirection, searchText } = this.state;

    this.setState({ isFetchLoading: true });
    fetchVehiclesByClientId({
      sortBy, sortDirection, searchText, clientId: match.params.id,
    })
      .then(({ payload }) => this.setState({
        isNoVehicles: !payload.isExists,
        isFetchLoading: false,
        isFetchError: false,
      }))
      .catch(() => this.setState({ isFetchError: true, isFetchLoading: false }));
  };

  changeFilter = ({ sortBy, sortDirection, searchText }) => {
    const { match, changeVehiclesFilterByClientId } = this.props;
    changeVehiclesFilterByClientId({
      sortBy, sortDirection, searchText, clientId: match.params.id,
    })
      .then(() => this.setState({ isChangeFilterLoading: false, isChangeFilterError: false }))
      .catch(() => this.setState({ isChangeFilterLoading: false, isChangeFilterError: true }));
  };

  renderTableBody = () => {
    const { vehicles } = this.props;
    const { isChangeFilterLoading, isChangeFilterError } = this.state;

    if (!isChangeFilterLoading && isChangeFilterError) {
      return <Retry onRetry={this.onRetryChangeFilter} />;
    }

    if (!vehicles.length) {
      return <NoEntities withoutBorder noEntitiesText={polyglot.t('clientVehicles.notFound')} />;
    }

    return (
      <InfiniteTableBody isDataLoading={isChangeFilterLoading} onScroll={this.onScroll}>
        {vehicles.map((vehicle) => {
          const lastMileage = _.first(vehicle.mileage);
          return (
            <tr
              key={vehicle._id}
              className={tableStyles.tableRow}
              onClick={() => this.onTableRowClick(vehicle)}
            >
              <td className={tableStyles.tableElement}>
                {vehicle.manufacturer}
              </td>
              <td className={tableStyles.tableElement}>
                {vehicle.model}
              </td>
              <td className={tableStyles.tableElement}>
                {vehicle.license || '---'}
              </td>
              <td className={tableStyles.tableElement}>
                {vehicle.dateOfFirstRegistration
                  ? toUTCDay(vehicle.dateOfFirstRegistration)
                  : '---'}
              </td>
              <td className={cx(tableStyles.tableElement, tableStyles.tableRight)}>
                {lastMileage ? (
                  <MileageCell
                    value={lastMileage.value}
                    operatingHours={lastMileage.operatingHours}
                  />
                ) : (
                  '---'
                )}
              </td>
              <td className={cx(tableStyles.tableElement, tableStyles.tableRemove)}>
                <StopEventPropagation events={['onClick']}>
                  <ActionWithConfirmModal>
                    {(onOpenConfirmModal) => this.renderRemoveIcon(onOpenConfirmModal, vehicle._id)}
                  </ActionWithConfirmModal>
                </StopEventPropagation>
              </td>
            </tr>
          );
        })}
      </InfiniteTableBody>
    );
  };

  renderCreateButton = () => {
    const { vehicle } = this.state;
    return (
      <VehicleEditModal
        vehicle={vehicle}
        selectCustomerDisabled
        customerLinkDisabled
      >
        <Button styleType="add">
          {polyglot.t('vehicles.addVehicle')}
        </Button>
      </VehicleEditModal>
    );
  };

  render() {
    const {
      searchText,
      isFetchLoading,
      isNoVehicles,
      isFetchError,
      sortBy,
      sortDirection,
    } = this.state;
    const { totalAmount } = this.props;

    if (isFetchLoading) {
      return (<TableLoading>
        {this.renderCreateButton()}
      </TableLoading>);
    }

    if (isFetchError) {
      return <Retry className={cx(pageStyles.page, pageStyles.pageContent)} onRetry={this.fetch} />;
    }

    if (isNoVehicles) {
      return (
        <NoEntities noEntitiesText={polyglot.t('clientVehicles.thereAreNoClientVehicles')}>
          {this.renderCreateButton()}
        </NoEntities>
      );
    }

    const sortableTableTitles = VehiclesList.sortableTableTitles.map(({ title, keys }) => (
      <SortableTableTitle
        key={title}
        onChangeSort={this.onChangeSort}
        title={title}
        sortKeys={keys}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
    ));

    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.pageContent}>
          <div className={pageStyles.pageActions}>
            <div className={pageStyles.pageActionsSearch}>
              <SearchInput
                onChange={this.onSearchChange}
                onDebounce={this.onSearchDebounce}
                placeholder={polyglot.t('actions.searchPlaceholder')}
                value={searchText}
                totalAmount={totalAmount}
              />
            </div>
            {this.renderCreateButton()}
          </div>

          <table className={tableStyles.table}>
            <thead className={tableStyles.tableHeader}>
              <tr className={tableStyles.tableLightRow}>
                {sortableTableTitles}
                <td className={cx(tableStyles.tableElement, tableStyles.tableRight)}>
                  {polyglot.t('vehicles.mileage')}
                </td>
                <td className={cx(tableStyles.tableElement, tableStyles.tableRemove)} />
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  }
}

export default VehiclesList;
