import { connect } from 'react-redux';
import { createTire, updateTire, validateTireStorage } from 'resources/tires/tires.actions';
import { getCanPrintnodeBeUsed } from 'resources/printingSettings/printingSettings.actions';
import * as fromPrintingSettings
  from 'resources/printingSettings/printingSettings.selectors';

import TireModalContainer from './tireModalContainer';

export default connect((state) => ({
  isDYMOPrintingEnabled: fromPrintingSettings.getSettings(state).isDYMOPrintingEnabled,
}), {
  createTire,
  updateTire,
  validateTireStorage,
  getCanPrintnodeBeUsed,
})(TireModalContainer);
