import DeviceService from 'services/device.service';

let isCurrentlyMobile = false;
const currentSelector = '.intercom-lightweight-app';

function waitForBeacon(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    return null;
  });
}

export const hideSupportBeaconOnMobile = async () => {
  const beacon = await waitForBeacon(currentSelector);

  if (DeviceService.isMobileSize && beacon) {
    beacon.style.display = 'none';
    isCurrentlyMobile = true;
  } else {
    beacon.style.display = 'unset';
    isCurrentlyMobile = false;
  }
};

DeviceService.addResizeListener(() => {
  if (DeviceService.isMobileSize !== isCurrentlyMobile) {
    hideSupportBeaconOnMobile();
  }
});

export const hideSupportBeacon = async () => {
  const beacon = await waitForBeacon(currentSelector);
  if (beacon) {
    beacon.style.display = 'none';
    isCurrentlyMobile = true;
  }
};

export const showSupportBeacon = async () => {
  const beacon = await waitForBeacon(currentSelector);
  if (beacon) {
    beacon.style.display = 'unset';
  }
};
