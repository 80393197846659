export function getOffers({ offers }) {
  return offers.list;
}

export function getTotalAmount({ offers }) {
  return offers.totalAmount;
}

export function getCurrentOffer({ offers }) {
  return offers.currentOffer;
}
