import React, { Component } from 'react';
import cx from 'classnames';

import { faCheckCircle } from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import { hasEightPasswordChars, hasOnePasswordLetter, hasOnePasswordDigit } from 'helpers/regexp';

import styles from './passwordChecker.styles.pcss';

class PasswordChecker extends Component {
  static propTypes = {
    password: PropTypes.string,
    onValidChange: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    password: '',
    onValidChange: () => null,
    className: '',
  };

  static checks = [
    { id: 'minLettersValid', validate: hasOnePasswordLetter },
    { id: 'minLengthValid', validate: hasEightPasswordChars },
    { id: 'minDigitsValid', validate: hasOnePasswordDigit },
  ];

  constructor(props) {
    super(props);
    this.state = {
      minLengthValid: false,
      minLettersValid: false,
      minDigitsValid: false,
    };
  }

  componentDidMount() {
    this.checkAll();
  }

  componentDidUpdate(prevProps) {
    const { password } = this.props;
    if (password !== prevProps.password) {
      this.checkAll();
    }
  }

  checkAll = () => {
    const { password, onValidChange } = this.props;
    const checks = {};
    let isValid = true;
    PasswordChecker.checks.forEach((check) => {
      const validationRes = check.validate(password);
      checks[check.id] = validationRes;
      if (!validationRes) isValid = false;
    });
    onValidChange(isValid);
    this.setState({ ...checks });
  };

  renderCheck = (check) => {
    const isValid = this.state[check.id];

    return (
      <div className={styles.passwordCheckerIcon}>
        {isValid ? (
          <FontAwesomeIcon icon={faCheckCircle} className={styles.passwordCheckerIconPass} />
        ) : (
          <div className={styles.passwordCheckerIconFailed} />
        )}
        <div>
          {polyglot.t(`access.password.${check.id}`)}
        </div>
      </div>
    );
  };

  render() {
    const { className } = this.props;
    return (
      <div className={cx(styles.passwordChecker, className)}>
        {PasswordChecker.checks.map((check) => this.renderCheck(check))}
      </div>
    );
  }
}

export default PasswordChecker;
