import polyglot from 'services/localization';

export default [
  {
    key: 'AT',
    label: polyglot.t('editClient.countryOptions.Austria'),
  },
  {
    key: 'DE',
    label: polyglot.t('editClient.countryOptions.Germany'),
  },
  {
    key: 'CH',
    label: polyglot.t('editClient.countryOptions.Switzerland'),
  },
  {
    key: 'IT',
    label: polyglot.t('editClient.countryOptions.Italy'),
  },
  {
    key: 'LI',
    label: polyglot.t('editClient.countryOptions.Liechtenstein'),
  },
  {
    key: 'LU',
    label: polyglot.t('editClient.countryOptions.Luxembourg'),
  },
  {
    key: 'AL',
    label: polyglot.t('editClient.countryOptions.Albania'),
  },
  {
    key: 'BE',
    label: polyglot.t('editClient.countryOptions.Belgium'),
  },
  {
    key: 'BA',
    label: polyglot.t('editClient.countryOptions.Bosnia'),
  },
  {
    key: 'BG',
    label: polyglot.t('editClient.countryOptions.Bulgaria'),
  },
  {
    key: 'DK',
    label: polyglot.t('editClient.countryOptions.Denmark'),
  },
  {
    key: 'EE',
    label: polyglot.t('editClient.countryOptions.Estonia'),
  },
  {
    key: 'FI',
    label: polyglot.t('editClient.countryOptions.Finland'),
  },
  {
    key: 'FR',
    label: polyglot.t('editClient.countryOptions.France'),
  },
  {
    key: 'EL',
    label: polyglot.t('editClient.countryOptions.Greece'),
  },
  {
    key: 'UK',
    label: polyglot.t('editClient.countryOptions.UnitedKingdom'),
  },
  {
    key: 'IE',
    label: polyglot.t('editClient.countryOptions.Ireland'),
  },
  {
    key: 'IS',
    label: polyglot.t('editClient.countryOptions.Iceland'),
  },
  {
    key: 'HR',
    label: polyglot.t('editClient.countryOptions.Croatia'),
  },
  {
    key: 'LT',
    label: polyglot.t('editClient.countryOptions.Lithuania'),
  },
  {
    key: 'LV',
    label: polyglot.t('editClient.countryOptions.Latvia'),
  },
  {
    key: 'MT',
    label: polyglot.t('editClient.countryOptions.Malta'),
  },
  {
    key: 'ME',
    label: polyglot.t('editClient.countryOptions.Montenegro'),
  },
  {
    key: 'NL',
    label: polyglot.t('editClient.countryOptions.Netherlands'),
  },
  {
    key: 'NO',
    label: polyglot.t('editClient.countryOptions.Norway'),
  },
  {
    key: 'PL',
    label: polyglot.t('editClient.countryOptions.Poland'),
  },
  {
    key: 'PT',
    label: polyglot.t('editClient.countryOptions.Portugal'),
  },
  {
    key: 'RO',
    label: polyglot.t('editClient.countryOptions.Romania'),
  },
  {
    key: 'RU',
    label: polyglot.t('editClient.countryOptions.Russia'),
  },
  {
    key: 'RS',
    label: polyglot.t('editClient.countryOptions.Serbia'),
  },
  {
    key: 'SE',
    label: polyglot.t('editClient.countryOptions.Sweden'),
  },
  {
    key: 'SI',
    label: polyglot.t('editClient.countryOptions.Slovenia'),
  },
  {
    key: 'SK',
    label: polyglot.t('editClient.countryOptions.Slovakia'),
  },
  {
    key: 'ES',
    label: polyglot.t('editClient.countryOptions.Spain'),
  },
  {
    key: 'TR',
    label: polyglot.t('editClient.countryOptions.Turkey'),
  },
  {
    key: 'CZ',
    label: polyglot.t('editClient.countryOptions.CzechRepublic'),
  },
  {
    key: 'UKR',
    label: polyglot.t('editClient.countryOptions.Ukraine'),
  },
  {
    key: 'HU',
    label: polyglot.t('editClient.countryOptions.Hungary'),
  },
  {
    key: 'CY',
    label: polyglot.t('editClient.countryOptions.Cyprus'),
  },
  {
    key: 'OTHER',
    label: polyglot.t('editClient.countryOptions.Other'),
  },
];
