import { createFormDataWithAttachments } from 'helpers/file';
import * as apiClient from 'resources/api.client';

export function fetchReceipts(garageId, data) {
  return apiClient.get('receipts', { garageId, ...data });
}

export function getReceiptsCSV(garageId, data) {
  return apiClient.get('receipts/csv', { garageId, ...data });
}

export function getDepData(garageId) {
  return apiClient.get('receipts/dep', { garageId });
}

export function cancelReceipt(receiptId, garageId, data) {
  return apiClient.post(`receipts/${receiptId}/cancel`, { garageId }, data);
}

export function getPaymentsSum(garageId, data) {
  return apiClient.get('receipts/get-payments-sum', { garageId, ...data });
}

export function getTotalPaymentTaxSums(garageId, data) {
  return apiClient.get('receipts/get-tax-sums', { garageId, ...data });
}

export function createZeroReceipt(garageId) {
  return apiClient.post('receipts/create-zero-receipt', { garageId });
}

export function printReceipt(garageId, id) {
  return apiClient.post(`receipts/${id}/print`, { garageId }, {});
}

export const getReceiptPdfUrl = (id, garageId) => apiClient.get(`receipts/${id}/pdf-url`, { garageId });

export function sendEmail(receiptId, garageId, data) {
  const body = createFormDataWithAttachments(data);
  return apiClient.postRaw(`receipts/${receiptId}/send`, { garageId }, body);
}

export const getCardPaymentsCSV = (garageId, data) => {
  return apiClient.get('receipts/card-payments/csv', { garageId, ...data });
};
