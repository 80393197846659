import React from 'react';

const EditorList = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.904 25.904" width="512" height="512" {...props}>
    <path d="M9.012 12h10v2h-10z" />
    <path d="M13.022 22H3.012c-.551 0-1-.449-1-1V4h20v8.435a3.04 3.04 0 0 1 1.343-.322c.224 0 .443.028.657.075V3a3 3 0 0 0-3-3h-18a3 3 0 0 0-3 3v18c0 1.656 1.344 3 3 3h9.425l.585-2z" data-original="#030104" data-old_color="#030104" />
    <path d="M9.012 8h10v2h-10zM9.012 18h7.096l2.01-2H9.012zM5.012 12h2v2h-2zM5.012 8h2v2h-2zM5.012 16h2v2h-2z" />
    <path d="M25.576 15.934l-1.517-1.52a1.062 1.062 0 0 0-1.507-.009l-1.22 1.228 3.03 3.043 1.221-1.229a1.071 1.071 0 0 0-.007-1.513zM15.357 21.502a.431.431 0 0 0-.15.213L14.15 25.33a.448.448 0 0 0 .555.555l3.602-1.062a.433.433 0 0 0 .204-.138l4.939-4.915-3.163-3.175-4.93 4.907zm.479 3.116l-.422-.425.599-2.047 1.532.316.303 1.562-2.012.594z" />
  </svg>
);

export default EditorList;
