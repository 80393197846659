import * as fromGarage from 'resources/garage/garage.selectors';

import * as globalSuppliersApi from './globalSuppliers.api';

export const FETCH_GLOBAL_SUPPLIERS = 'fetchGlobalSuppliers';

export const fetchGlobalSuppliers = () => (dispatch, getState) => {
  const state = getState();
  const { _id } = fromGarage.getGarage(state);

  return globalSuppliersApi
    .fetchGlobalSuppliers(_id)
    .then((globalSuppliers) => dispatch({
      type: FETCH_GLOBAL_SUPPLIERS,
      payload: globalSuppliers,
    }));
};
