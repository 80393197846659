import React from 'react';
import {
  MdAcUnit as WinterTireIcon,
  MdWbSunny as SummerTireIcon,
  MdBrightness7 as SpikedTireIcon,
  MdSync as AllYearTireIcon,
} from 'react-icons/md';

export function getIconForModel(model) {
  let iconsToReturn;

  switch (model) {
    case 'summer':
      iconsToReturn = <SummerTireIcon />;
      break;
    case 'winter':
      iconsToReturn = <WinterTireIcon />;
      break;
    case 'spiked':
      iconsToReturn = <SpikedTireIcon />;
      break;
    case 'all':
      iconsToReturn = <AllYearTireIcon />;
      break;
    default:
      break;
  }

  return iconsToReturn;
}
