import { ADDON_NAMES } from 'shared-library/src/definitions/subscription';
import { hasAddon } from './permissions';

export const getAddonByFeature = (feature) => {
  switch (feature) {
    case 'cashbook':
    case 'invoiceExporting':
    case 'cardPayments':
      return ADDON_NAMES.BOOK_KEEPING;
    case 'calendar':
    case 'workingHours':
      return ADDON_NAMES.CALENDAR;
    case 'supplierProducts':
    case 'webshopAPI':
      return ADDON_NAMES.SUPPLIER_API;
    case 'tires':
      return ADDON_NAMES.TIRE_STORAGE;
    case 'ocr':
      return ADDON_NAMES.OCR;
    case 'cashRegisterAT':
      return ADDON_NAMES.CERTIFICATE;
    case 'sdi':
      return ADDON_NAMES.SDI;
    default:
      return '';
  }
};

export const getRequiredFeaturesAndAddons = (features, subscriptionData) => {
  const pairs = features.map((feature) => [feature, getAddonByFeature(feature)]);
  const filteredPairs = pairs.filter(
    ([, addon]) => !hasAddon(subscriptionData, addon),
  );
  const resultFeatures = [];
  const resultAddons = [];
  filteredPairs.forEach(([f, a]) => {
    resultFeatures.push(f);
    resultAddons.push(a);
  });
  return [resultFeatures, resultAddons];
};

export const checkAreFeaturesEnabled = (features, subscriptionData) => {
  return getRequiredFeaturesAndAddons(features, subscriptionData)[0].length === 0;
};
