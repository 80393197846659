const ARTICLE_LABEL = `<?xml version="1.0" encoding="utf-8"?>
<DieCutLabel Version="8.0" Units="twips">
<PaperOrientation>Landscape</PaperOrientation>
<Id>LargeAddress</Id>
<IsOutlined>false</IsOutlined>
<PaperName>30321 Large Address</PaperName>
<DrawCommands>
  <RoundRectangle X="0" Y="0" Width="2025" Height="5020" Rx="270" Ry="270" />
</DrawCommands>
<ObjectInfo>
  <TextObject>
    <Name>TEXT</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Left</HorizontalAlignment>
    <VerticalAlignment>Middle</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">ARTICLE_DESCRIPTION</String>
        <Attributes>
          <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
  </TextObject>
  <Bounds X="322" Y="255" Width="2880" Height="510" />
</ObjectInfo>
<ObjectInfo>
  <TextObject>
    <Name>TEXT_1</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Left</HorizontalAlignment>
    <VerticalAlignment>Middle</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">ARTICLE_PRICE</String>
        <Attributes>
          <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
  </TextObject>
  <Bounds X="3382" Y="255" Width="1440" Height="510" />
</ObjectInfo>
<ObjectInfo>
  <BarcodeObject>
    <Name>BARCODE</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>True</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <Text>BARCODE-DATA</Text>
    <Type>Code39</Type>
    <Size>Medium</Size>
    <TextPosition>Bottom</TextPosition>
    <TextFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />
    <CheckSumFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />
    <TextEmbedding>None</TextEmbedding>
    <ECLevel>0</ECLevel>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <QuietZonesPadding Left="0" Top="0" Right="0" Bottom="0" />
  </BarcodeObject>
  <Bounds X="322" Y="955.000000000001" Width="4473" Height="790" />
</ObjectInfo>
</DieCutLabel>`;

const TIRE_LABEL = `<?xml version="1.0" encoding="utf-8"?>
<DieCutLabel Version="8.0" Units="twips">
<PaperOrientation>Portrait</PaperOrientation>
<Id>LargeAddress</Id>
<IsOutlined>false</IsOutlined>
<PaperName>30321 Large Address</PaperName>
<DrawCommands>
  <RoundRectangle X="0" Y="0" Width="2025" Height="5020" Rx="270" Ry="270" />
</DrawCommands>
<ObjectInfo>
  <TextObject>
    <Name>TEXT</Name>
    <ForeColor Alpha="255" Red="255" Green="255" Blue="255" />
    <BackColor Alpha="255" Red="0" Green="0" Blue="0" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Middle</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{LABEL}}</String>
        <Attributes>
          <Font Family="Arial" Size="20" Bold="True" Italic="False" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="255" Green="255" Blue="255" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
  </TextObject>
  <Bounds X="86" Y="322" Width="1882" Height="880.772946859902" />
</ObjectInfo>
<ObjectInfo>
  <TextObject>
    <Name>TEXT_1</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{LICENSE_LABEL}}</String>
        <Attributes>
          <Font Family="Arial" Size="12" Bold="False" Italic="True" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
  </TextObject>
  <Bounds X="86" Y="1290.99099099095" Width="1882" Height="290" />
</ObjectInfo>
<ObjectInfo>
  <AddressObject>
    <Name>ADRESSE</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>True</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{LICENSE}}</String>
        <Attributes>
          <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
    <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>
    <BarcodePosition>AboveAddress</BarcodePosition>
    <LineFonts>
      <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
    </LineFonts>
  </AddressObject>
  <Bounds X="86" Y="1630.00030517578" Width="1882" Height="340" />
</ObjectInfo>
<ObjectInfo>
  <ShapeObject Stroke="SolidLine">
    <Name>FORM</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <ShapeType>HorizontalLine</ShapeType>
    <LineWidth>15</LineWidth>
    <LineAlignment>Center</LineAlignment>
    <FillColor Alpha="0" Red="255" Green="255" Blue="255" />
  </ShapeObject>
  <Bounds X="86" Y="1990.00030517578" Width="1882" Height="15" />
</ObjectInfo>
<ObjectInfo>
  <ShapeObject Stroke="SolidLine">
    <Name>FORM_1</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <ShapeType>HorizontalLine</ShapeType>
    <LineWidth>15</LineWidth>
    <LineAlignment>Center</LineAlignment>
    <FillColor Alpha="0" Red="255" Green="255" Blue="255" />
  </ShapeObject>
  <Bounds X="86" Y="2755.00030517578" Width="1882" Height="15" />
</ObjectInfo>
<ObjectInfo>
  <AddressObject>
    <Name>ADRESSE_1</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>True</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{NAME}}</String>
        <Attributes>
          <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
    <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>
    <BarcodePosition>AboveAddress</BarcodePosition>
    <LineFonts>
      <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
    </LineFonts>
  </AddressObject>
  <Bounds X="86" Y="2395.00030517578" Width="1882" Height="340" />
</ObjectInfo>
<ObjectInfo>
  <TextObject>
    <Name>TEXT__1</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{NAME_LABEL}}</String>
        <Attributes>
          <Font Family="Arial" Size="12" Bold="False" Italic="True" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
  </TextObject>
  <Bounds X="86" Y="2055.99099099095" Width="1882" Height="290" />
</ObjectInfo>
<ObjectInfo>
  <ShapeObject Stroke="SolidLine">
    <Name>FORM_2</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <ShapeType>HorizontalLine</ShapeType>
    <LineWidth>15</LineWidth>
    <LineAlignment>Center</LineAlignment>
    <FillColor Alpha="0" Red="255" Green="255" Blue="255" />
  </ShapeObject>
  <Bounds X="86" Y="3515.00030517578" Width="1882" Height="15" />
</ObjectInfo>
<ObjectInfo>
  <AddressObject>
    <Name>ADRESSE_2</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>True</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{DIMENSION}}</String>
        <Attributes>
          <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
    <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>
    <BarcodePosition>AboveAddress</BarcodePosition>
    <LineFonts>
      <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
    </LineFonts>
  </AddressObject>
  <Bounds X="86" Y="3155.00030517578" Width="1882" Height="340" />
</ObjectInfo>
<ObjectInfo>
  <TextObject>
    <Name>TEXT__2</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{DIMENSION_LABEL}}</String>
        <Attributes>
          <Font Family="Arial" Size="12" Bold="False" Italic="True" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
  </TextObject>
  <Bounds X="86" Y="2815.99099099095" Width="1882" Height="290" />
</ObjectInfo>
<ObjectInfo>
  <ShapeObject Stroke="SolidLine">
    <Name>FORM_3</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <ShapeType>HorizontalLine</ShapeType>
    <LineWidth>15</LineWidth>
    <LineAlignment>Center</LineAlignment>
    <FillColor Alpha="0" Red="255" Green="255" Blue="255" />
  </ShapeObject>
  <Bounds X="86" Y="4265.00030517578" Width="1882" Height="15" />
</ObjectInfo>
<ObjectInfo>
  <AddressObject>
    <Name>ADRESSE_3</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>True</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{STOREAGE}}</String>
        <Attributes>
          <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
    <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>
    <BarcodePosition>AboveAddress</BarcodePosition>
    <LineFonts>
      <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
    </LineFonts>
  </AddressObject>
  <Bounds X="86" Y="3905.00030517578" Width="1882" Height="340" />
</ObjectInfo>
<ObjectInfo>
  <TextObject>
    <Name>TEXT__3</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{STORAGE_LABEL}}</String>
        <Attributes>
          <Font Family="Arial" Size="12" Bold="False" Italic="True" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
  </TextObject>
  <Bounds X="86" Y="3565.99099099095" Width="1882" Height="290" />
</ObjectInfo>
<ObjectInfo>
  <AddressObject>
    <Name>ADRESSE_4</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>True</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{DATE}}</String>
        <Attributes>
          <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
    <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>
    <BarcodePosition>AboveAddress</BarcodePosition>
    <LineFonts>
      <Font Family="Arial" Size="14" Bold="True" Italic="False" Underline="False" Strikeout="False" />
    </LineFonts>
  </AddressObject>
  <Bounds X="86" Y="4636.43884892086" Width="1882" Height="298.561151079136" />
</ObjectInfo>
<ObjectInfo>
  <TextObject>
    <Name>TEXT__4</Name>
    <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
    <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
    <LinkedObjectName />
    <Rotation>Rotation0</Rotation>
    <IsMirrored>False</IsMirrored>
    <IsVariable>False</IsVariable>
    <GroupID>-1</GroupID>
    <IsOutlined>False</IsOutlined>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Top</VerticalAlignment>
    <TextFitMode>ShrinkToFit</TextFitMode>
    <UseFullFontHeight>True</UseFullFontHeight>
    <Verticalized>False</Verticalized>
    <StyledText>
      <Element>
        <String xml:space="preserve">{{DATE_LABEL}}</String>
        <Attributes>
          <Font Family="Arial" Size="12" Bold="False" Italic="True" Underline="False" Strikeout="False" />
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
        </Attributes>
      </Element>
    </StyledText>
  </TextObject>
  <Bounds X="86" Y="4325.05543401661" Width="1882" Height="290" />
</ObjectInfo>
</DieCutLabel>`;

const ARTICLE_CONNECT = `<?xml version="1.0" encoding="utf-8"?>
<DesktopLabel Version="1">
  <DYMOLabel Version="3">
    <Description>DYMO Label</Description>
    <Orientation>Landscape</Orientation>
    <LabelName>LargeAddressS0722400</LabelName>
    <InitialLength>0</InitialLength>
    <BorderStyle>SolidLine</BorderStyle>
    <DYMORect>
      <DYMOPoint>
        <X>0.2233333</X>
        <Y>0.06</Y>
      </DYMOPoint>
      <Size>
        <Width>3.203333</Width>
        <Height>1.306667</Height>
      </Size>
    </DYMORect>
    <BorderColor>
      <SolidColorBrush>
        <Color A="1" R="0" G="0" B="0"></Color>
      </SolidColorBrush>
    </BorderColor>
    <BorderThickness>1</BorderThickness>
    <Show_Border>False</Show_Border>
    <DynamicLayoutManager>
      <RotationBehavior>ClearObjects</RotationBehavior>
      <LabelObjects>
        <BarcodeObject>
          <Name>IBarcodeObject0</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="1" R="1" G="1" B="1"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <BarcodeFormat>Code128Auto</BarcodeFormat>
          <Data>
            <MultiDataString>
              <DataString>BARCODE-DATA</DataString>
            </MultiDataString>
          </Data>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <Size>SmallMedium</Size>
          <TextPosition>Bottom</TextPosition>
          <FontInfo>
            <FontName>Arial</FontName>
            <FontSize>12</FontSize>
            <IsBold>False</IsBold>
            <IsItalic>False</IsItalic>
            <IsUnderline>False</IsUnderline>
            <FontBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FontBrush>
          </FontInfo>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.2233334</X>
              <Y>0.7427269</Y>
            </DYMOPoint>
            <Size>
              <Width>3.058623</Width>
              <Height>0.5283987</Height>
            </Size>
          </ObjectLayout>
        </BarcodeObject>
        <TextObject>
          <Name>ITextObject3</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>ARTICLE_DESCRIPTION</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>12</FontSize>
                  <IsBold>True</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.2233334</X>
              <Y>0.1364332</Y>
            </DYMOPoint>
            <Size>
              <Width>1.930684</Width>
              <Height>0.4523681</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <TextObject>
          <Name>ITextObject33</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>ARTICLE_PRICE</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>11.5</FontSize>
                  <IsBold>True</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>2.190262</X>
              <Y>0.1491723</Y>
            </DYMOPoint>
            <Size>
              <Width>1.216406</Width>
              <Height>0.4268901</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
      </LabelObjects>
    </DynamicLayoutManager>
  </DYMOLabel>
  <LabelApplication>Blank</LabelApplication>
  <DataTable>
    <Columns></Columns>
    <Rows></Rows>
  </DataTable>
</DesktopLabel>`;

const TIRE_CONNECT = `<?xml version="1.0" encoding="utf-8"?>
<DesktopLabel Version="1">
  <DYMOLabel Version="3">
    <Description>DYMO Label</Description>
    <Orientation>Portrait</Orientation>
    <LabelName>LargeAddressS0722400</LabelName>
    <InitialLength>0</InitialLength>
    <BorderStyle>SolidLine</BorderStyle>
    <DYMORect>
      <DYMOPoint>
        <X>0.04333337</X>
        <Y>0.2233333</Y>
      </DYMOPoint>
      <Size>
        <Width>1.306667</Width>
        <Height>3.203333</Height>
      </Size>
    </DYMORect>
    <BorderColor>
      <SolidColorBrush>
        <Color A="1" R="0" G="0" B="0"></Color>
      </SolidColorBrush>
    </BorderColor>
    <BorderThickness>1</BorderThickness>
    <Show_Border>False</Show_Border>
    <DynamicLayoutManager>
      <RotationBehavior>ClearObjects</RotationBehavior>
      <LabelObjects>
        <ShapeObject>
          <Name>IShapeObject0</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <StrokeWidth>1</StrokeWidth>
          <DashPattern>SolidLine</DashPattern>
          <ShapeType>Rectangle</ShapeType>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333337</X>
              <Y>0.2233334</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.729056</Height>
            </Size>
          </ObjectLayout>
        </ShapeObject>
        <TextObject>
          <Name>ITextObject0</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{LABEL}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>24</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="1" G="1" B="1"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333336</X>
              <Y>0.2233334</Y>
            </DYMOPoint>
            <Size>
              <Width>1.299835</Width>
              <Height>0.7163194</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <TextObject>
          <Name>ITextObject1</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{LICENSE_LABEL}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>12.8</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>True</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333336</X>
              <Y>0.9523897</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.2428396</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <TextObject>
          <Name>ITextObject2</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{LICENSE}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>13</FontSize>
                  <IsBold>True</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333336</X>
              <Y>1.195229</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.245898</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <LineObject>
          <Name>ILineObject0</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <StrokeWidth>1</StrokeWidth>
          <DashPattern>SolidLine</DashPattern>
          <LineType>Horizontal</LineType>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333336</X>
              <Y>1.38233</Y>
            </DYMOPoint>
            <Size>
              <Width>1.298474</Width>
              <Height>0.1175937</Height>
            </Size>
          </ObjectLayout>
        </LineObject>
        <TextObject>
          <Name>ITextObject3</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{NAME_LABEL}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>12.8</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>True</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333333</X>
              <Y>1.441127</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.2428396</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <TextObject>
          <Name>ITextObject4</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{NAME}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>13</FontSize>
                  <IsBold>True</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333327</X>
              <Y>1.683966</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.245898</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <LineObject>
          <Name>ILineObject1</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <StrokeWidth>1</StrokeWidth>
          <DashPattern>SolidLine</DashPattern>
          <LineType>Horizontal</LineType>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.05152614</X>
              <Y>1.871068</Y>
            </DYMOPoint>
            <Size>
              <Width>1.298474</Width>
              <Height>0.1175937</Height>
            </Size>
          </ObjectLayout>
        </LineObject>
        <TextObject>
          <Name>ITextObject5</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{DIMENSION_LABEL}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>12.8</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>True</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333336</X>
              <Y>1.929864</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.2428396</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <TextObject>
          <Name>ITextObject6</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{DIMENSION}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>12.9</FontSize>
                  <IsBold>True</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333307</X>
              <Y>2.172704</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.245898</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <LineObject>
          <Name>ILineObject2</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <StrokeWidth>1</StrokeWidth>
          <DashPattern>SolidLine</DashPattern>
          <LineType>Horizontal</LineType>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.05152592</X>
              <Y>2.359806</Y>
            </DYMOPoint>
            <Size>
              <Width>1.298474</Width>
              <Height>0.1175937</Height>
            </Size>
          </ObjectLayout>
        </LineObject>
        <TextObject>
          <Name>ITextObject7</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{STORAGE_LABEL}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>12.8</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>True</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333322</X>
              <Y>2.418602</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.2428396</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <TextObject>
          <Name>ITextObject8</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{STOREAGE}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>12.9</FontSize>
                  <IsBold>True</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333328</X>
              <Y>2.661442</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.245898</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <LineObject>
          <Name>ILineObject3</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <StrokeWidth>1</StrokeWidth>
          <DashPattern>SolidLine</DashPattern>
          <LineType>Horizontal</LineType>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333307</X>
              <Y>2.848543</Y>
            </DYMOPoint>
            <Size>
              <Width>1.298474</Width>
              <Height>0.1175937</Height>
            </Size>
          </ObjectLayout>
        </LineObject>
        <TextObject>
          <Name>ITextObject9</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{DATE_LABEL}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>10.2</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>True</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333307</X>
              <Y>2.90734</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.2428396</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <TextObject>
          <Name>ITextObject10</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>{{DATE}}</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>13</FontSize>
                  <IsBold>True</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.04333262</X>
              <Y>3.150179</Y>
            </DYMOPoint>
            <Size>
              <Width>1.306667</Width>
              <Height>0.245898</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
      </LabelObjects>
    </DynamicLayoutManager>
  </DYMOLabel>
  <LabelApplication>Blank</LabelApplication>
  <DataTable>
    <Columns></Columns>
    <Rows></Rows>
  </DataTable>
</DesktopLabel>`;

const PredefinedLabels = {
  dymoLabel: {
    ARTICLE: ARTICLE_LABEL,
    TIRE: TIRE_LABEL,
  },
  dymoConnect: {
    ARTICLE: ARTICLE_CONNECT,
    TIRE: TIRE_CONNECT,
  },
};

Object.freeze(PredefinedLabels);

export default PredefinedLabels;
