import { connect } from 'react-redux';
import * as fromGarage from 'resources/garage/garage.selectors';
import { stripeCheckout, getPriceList } from 'resources/garage/garage.actions';
import AccessLimitation from './accessLimitation';

export default connect((state) => ({
  garage: fromGarage.getGarage(state),
  subscriptionData: fromGarage.getSubscriptionData(state),
}), {
  stripeCheckout,
  getPriceList,
})(AccessLimitation);
