const CONTENT_TYPES = Object.freeze({
  PDF: 'application/pdf',
  PNG: 'image/png',
  JPG: 'image/jpeg',
  JPEG: 'image/jpeg',
  GIF: 'image/gif',
  SVG: 'image/svg+xml',
  ZIP: 'application/zip',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv',
  TXT: 'text/plain',
});

function getContentTypeByExtension(extension) {
  return CONTENT_TYPES[extension.toUpperCase().replace(/\./, '')];
}

function isFileSizeValid(size, maxSize = 7 * 1000 * 1000) {
  // maxSize in bytes default -> 7MB
  return size < maxSize;
}

module.exports = {
  CONTENT_TYPES,
  getContentTypeByExtension,
  isFileSizeValid,
};
