import { last } from 'lodash/array';

import { updateAppByAction } from 'resources/utils';
import {
  FETCH_INVITES,
  SEND_INVITE,
  CANCEL_INVITES,
  CHANGE_INVITES_FILTER,
  RESET_INVITES_STATE,
} from './invite.actions';

const initialState = { list: [], totalAmount: 0 };
const actionTypesToUpdateApp = [SEND_INVITE, CANCEL_INVITES];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case FETCH_INVITES: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }
    case CHANGE_INVITES_FILTER:
      return {
        ...state,
        list: [...action.payload.results],
        totalAmount: action.payload.count,
      };
    case RESET_INVITES_STATE:
      return {
        ...state,
        list: [],
      };
    case SEND_INVITE:
      return {
        ...state,
        list: action.payload ? [...state.list, action.payload] : state.list,
      };
    case CANCEL_INVITES:
      return {
        ...state,
        list: state.list.filter((invite) => !action.inviteIds.includes(invite._id)),
        totalAmount: state.totalAmount - action.inviteIds.length,
      };

    default:
      return state;
  }
};
