import { connect } from 'react-redux';
import { getCurrentVehicleId } from 'resources/vehicles/vehicles.selectors';
import { getTires, getTotalAmount } from 'resources/tires/tires.selectors';
import { fetchVehicle } from 'resources/vehicles/vehicles.actions';
import {
  fetchTires,
  changeTiresFilter,
  resetTiresState,
  printStorageConfirmation,
  getConfirmationPdfUrl,
} from 'resources/tires/tires.actions';
import { getCanPrintnodeBeUsed } from 'resources/printingSettings/printingSettings.actions';

import VehicleTires from './vehicleTires';

export default connect((state) => ({
  tires: getTires(state),
  totalAmount: getTotalAmount(state),
  currentVehicleId: getCurrentVehicleId(state),
}), {
  fetchTires,
  changeTiresFilter,
  resetTiresState,
  fetchVehicle,
  printStorageConfirmation,
  getConfirmationPdfUrl,
  getCanPrintnodeBeUsed,
})(VehicleTires);
