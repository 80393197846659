import * as apiClient from 'resources/api.client';

export const getIdentificationNumber = (identification) => apiClient.get(`vin/${identification.toUpperCase()}`, {});

export const getFieldByLabel = (json, label, defaultValue) => {
  if (label && json.decode) {
    for (let i = 0; i < json.decode.length; i += 1) {
      if (json.decode[i].label && json.decode[i].label.toLowerCase() === label) {
        return json.decode[i].value;
      }
    }
  }
  return (defaultValue || '');
};
