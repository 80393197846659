import React, { PureComponent } from 'react';

import DownloadManager from 'helpers/downloadManager';
import styles from './downloadDisplay.styles.pcss';

class DownloadDisplay extends PureComponent {
  constructor(props) {
    super(props);
    DownloadManager.registerUpdateCallback(this.onDownloadUpdate);
    this.state = { downloads: DownloadManager.getDownloads() };
  }

  onDownloadUpdate = (downloads) => {
    this.setState({ downloads });
  };

  render() {
    const { downloads } = this.state;
    return (
      <div style={{ display: downloads && downloads.length > 0 ? 'block' : 'none' }} className={styles.displayContainer}>
        <div className={styles.displayContainerContent}>
          {downloads.map((download) => {
            return (
              <div key={download.id} className={styles.displayContainerContentCard}>
                {download.displayText}
                <div
                  style={{ width: `${Math.round((download.progress / download.maxProgress) * 100)}%` }}
                  className={styles.displayContainerContentCardDownloadBar}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DownloadDisplay;
