import React from 'react';
import PropTypes from 'prop-types';

const SmileyMeh = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15" fill="none"></circle>
    <g clipPath="url(#clip0_35_262)">
      <path d="M16 0C12.8355 0 9.74207 0.938383 7.11088 2.69649C4.4797 4.45459 2.42894 6.95344 1.21793 9.87706C0.00693255 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77486 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.9951 11.758 30.3078 7.69121 27.3083 4.69169C24.3088 1.69216 20.242 0.00488612 16 0ZM10.4615 11.0769C10.8267 11.0769 11.1836 11.1852 11.4872 11.3881C11.7908 11.5909 12.0274 11.8792 12.1672 12.2166C12.3069 12.5539 12.3435 12.9251 12.2722 13.2832C12.201 13.6414 12.0252 13.9703 11.767 14.2285C11.5088 14.4867 11.1798 14.6625 10.8217 14.7338C10.4636 14.805 10.0924 14.7684 9.75505 14.6287C9.41771 14.489 9.12938 14.2523 8.92652 13.9487C8.72366 13.6451 8.61539 13.2882 8.61539 12.9231C8.61539 12.4334 8.80989 11.9639 9.15611 11.6176C9.50234 11.2714 9.97191 11.0769 10.4615 11.0769ZM22.1538 22.1538H9.84616C9.51974 22.1538 9.20669 22.0242 8.97587 21.7934C8.74506 21.5625 8.61539 21.2495 8.61539 20.9231C8.61539 20.5967 8.74506 20.2836 8.97587 20.0528C9.20669 19.822 9.51974 19.6923 9.84616 19.6923H22.1538C22.4803 19.6923 22.7933 19.822 23.0241 20.0528C23.2549 20.2836 23.3846 20.5967 23.3846 20.9231C23.3846 21.2495 23.2549 21.5625 23.0241 21.7934C22.7933 22.0242 22.4803 22.1538 22.1538 22.1538ZM21.5385 14.7692C21.1733 14.7692 20.8164 14.661 20.5128 14.4581C20.2092 14.2552 19.9726 13.9669 19.8328 13.6296C19.6931 13.2922 19.6565 12.921 19.7278 12.5629C19.799 12.2048 19.9748 11.8758 20.233 11.6176C20.4912 11.3595 20.8202 11.1836 21.1783 11.1124C21.5364 11.0412 21.9076 11.0777 22.245 11.2175C22.5823 11.3572 22.8706 11.5938 23.0735 11.8974C23.2763 12.201 23.3846 12.5579 23.3846 12.9231C23.3846 13.4127 23.1901 13.8823 22.8439 14.2285C22.4977 14.5747 22.0281 14.7692 21.5385 14.7692Z" fill="#E3E6E9"/>
    </g>
    <defs>
      <clipPath id="clip0_35_262">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

SmileyMeh.propTypes = {
  fill: PropTypes.string,
};

SmileyMeh.defaultProps = {
  fill: '#fff',
};

export default SmileyMeh;
