import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import { connect } from 'react-redux';
import cx from 'classnames';

import { fetchPreviousTires } from 'resources/tires/tires.actions';
import TableLoading from 'components/common/loading/components/tableLoading/tableLoading';
import InfiniteTableBody from 'components/common/infiniteTableBody';
import { toUTCDay } from 'helpers/date';
import tableStyles from 'styles/table.pcss';

import styles from './previousTires.styles.pcss';

class PreviousTires extends PureComponent {
  static propTypes = {
    fetchPreviousTires: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    vehicleId: PropTypes.string.isRequired,
    onChooseTire: PropTypes.func.isRequired,
  };

  state = {
    previousTires: [],
    totalAmount: 0,
    isFetchLoading: false,
  };

  componentDidMount() {
    const { clientId } = this.props;
    if (!clientId) {
      return;
    }
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (!!prevProps.clientId && !this.props.clientId) {
      this.setState({ previousTires: [] });
    } else if (
      prevProps.clientId !== this.props.clientId
      || prevProps.vehicleId !== this.props.vehicleId
    ) {
      this.fetch();
    }
  }

  onScroll = () => {
    const { clientId, vehicleId } = this.props;
    const { previousTires, totalAmount } = this.state;
    if (previousTires.length >= totalAmount) {
      return Promise.resolve();
    }
    return this.props
      .fetchPreviousTires({ skip: previousTires.length, clientId, vehicleId })
      .then(({ results, count }) => this.setState((prevState) => ({
        previousTires: [...prevState.previousTires, ...results],
        totalAmount: count,
      })));
  };

  getDescription = ({ storedOn, storageLocation, frontTires: { brand, type } }) => {
    let result = toUTCDay(storedOn);
    if (storageLocation) {
      result += ` (${storageLocation})`;
    }
    if (brand || type) {
      result += ` ${brand || ''}${brand && type ? '/' : ''}${type || ''}`;
    }
    return result;
  };

  fetch = () => {
    const { clientId, vehicleId } = this.props;
    this.setState({ isFetchLoading: true });
    this.props
      .fetchPreviousTires({ clientId, vehicleId })
      .then(({ results: previousTires, count }) =>
        this.setState({ previousTires, totalAmount: count, isFetchLoading: false }))
      .catch(() => this.setState({ isFetchLoading: false }));
  };

  renderContent = () => {
    const { clientId, onChooseTire } = this.props;
    const { previousTires, isFetchLoading } = this.state;

    if (isFetchLoading) {
      return <TableLoading />;
    }

    if (!clientId) {
      return (
        <div className={styles.previousText}>
          {polyglot.t('tires.form.pleaseSelectClient')}
        </div>
      );
    }

    if (!previousTires.length) {
      return (
        <div className={styles.previousText}>
          {polyglot.t('tires.form.noPreviousTires')}
        </div>
      );
    }

    return (
      <div className={styles.previousTable}>
        <table className={tableStyles.table}>
          <InfiniteTableBody onScroll={this.onScroll}>
            {previousTires.map((previousTire, index) => (
              <tr key={index} className={tableStyles.tableRow}>
                <td className={cx(tableStyles.tableElement, styles.previousCell)}>
                  <button
                    className={styles.previousButton}
                    onClick={() => onChooseTire(previousTire)}
                  >
                    {this.getDescription(previousTire)}
                  </button>
                </td>
              </tr>
            ))}
          </InfiniteTableBody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.previous}>
        <div className={styles.previousTitle}>
          {polyglot.t('tires.form.previouslyStoredTires')}
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

export default connect(
  null,
  { fetchPreviousTires },
)(PreviousTires);
