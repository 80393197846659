export const compareManufacturer = (el1, el2) => {
  if (el1.priority === el2.priority) {
    return el1.key < el2.key ? -1 : 1;
  }
  return (el2.priority - el1.priority);
};

export function getManufacturers({ manufacturers }) {
  return manufacturers;
}

export const getManufacturersDropdown = ({ manufacturers = [] }) => manufacturers
  .map(({ name, ...rest }) => ({ key: name, label: name, ...rest }))
  .sort(compareManufacturer);
