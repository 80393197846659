import { connect } from 'react-redux';
import { getVehicles, getTotalAmount } from 'resources/vehicles/vehicles.selectors';
import {
  fetchVehiclesByClientId,
  lazyFetchVehiclesByClientId,
  resetVehiclesStateByClientId,
  changeVehiclesFilterByClientId,
  removeVehicles,
} from 'resources/vehicles/vehicles.actions';
import { fetchClient } from 'resources/clients/clients.actions';
import * as fromClient from 'resources/clients/clients.selectors';

import VehiclesList from './vehiclesList';

export default connect((state) => ({
  currentClient: fromClient.getCurrentClient(state),
  vehicles: getVehicles(state),
  totalAmount: getTotalAmount(state),
}), {
  fetchClient,
  fetchVehiclesByClientId,
  lazyFetchVehiclesByClientId,
  resetVehiclesStateByClientId,
  changeVehiclesFilterByClientId,
  removeVehicles,
})(VehiclesList);
