import { addPageSize } from 'resources/utils';
import * as api from './suppliers.api';
import * as fromUser from '../user/user.selectors';
import * as fromSupplier from './suppliers.selectors';

export const CREATE_SUPPLIER = 'createSupplier';
export const FETCH_SUPPLIERS = 'fetchSuppliers';
export const RESET_SUPPLIERS_STATE = 'resetSuppliersState';
export const CHANGE_SUPPLIERS_FILTER = 'changeSuppliersFilter';
export const REMOVE_SUPPLIERS = 'removeSuppliers';
export const UPDATE_SUPPLIER = 'updateSupplier';
export const FETCH_SUPPLIERS_COUNT = 'fetchSuppliersCount';

export const createSupplier = (data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.createSupplier(data, garageId)
    .then((payload) => dispatch({ type: CREATE_SUPPLIER, payload }));
};

export const fetchSuppliers = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.fetchSuppliers(data)
    .then((payload) => dispatch({ type: FETCH_SUPPLIERS, data, payload }));
};

export const fetchSuppliersCount = (options = {}) => (dispatch, getState) => {
  const data = options;
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchSuppliersCount(garageId, data)
    .then((payload) => dispatch({ type: FETCH_SUPPLIERS_COUNT, data, payload }));
};

export const resetSuppliersState = () => ({ type: RESET_SUPPLIERS_STATE });

export const changeSuppliersFilter = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const data = addPageSize({ ...options, garageId });

  return api.fetchSuppliers(data)
    .then((payload) => dispatch({ type: CHANGE_SUPPLIERS_FILTER, data, payload }));
};

export const updateSupplier = (supplierId, newFields) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);
  const supplier = fromSupplier.getSupplierById(state, supplierId);
  const newSupplier = { ...supplier, ...newFields };

  return api.updateSupplier(supplierId, newSupplier, garageId)
    .then((payload) => dispatch({ type: UPDATE_SUPPLIER, supplierId, payload }));
};

export const removeSuppliers = (supplierIds) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.removeSuppliers(supplierIds, garageId)
    .then(() => dispatch({ type: REMOVE_SUPPLIERS, supplierIds }));
};
