import _ from 'lodash';

import {
  FETCH_CALENDARS,
  RESET_CALENDAR_STATE,
  UPDATE_CALENDAR,
  DELETE_CALENDAR,
  FETCH_CALENDAR_ENTRIES,
  UPDATE_CALENDAR_ENTRY,
  USE_SYNCED_CALENDAR_ENTRIES,
  DELETE_CALENDAR_ENTRY,
} from './calendar.actions';

const initialState = {
  calendars: null,
  entries: {},
  entriesSynced: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CALENDARS: {
      return {
        ...state,
        calendars: action.calendars,
      };
    }
    case UPDATE_CALENDAR: {
      return {
        ...state,
        calendars: [
          ...state.calendars.filter((c) => c.link !== action.payload.link),
          action.payload,
        ],
      };
    }
    case DELETE_CALENDAR: {
      return {
        ...state,
        calendars: state.calendars
          .filter((c) => !action.payload.find((del) => del.link === c.link)),
      };
    }
    case RESET_CALENDAR_STATE:
      return initialState;
    case FETCH_CALENDAR_ENTRIES: {
      return {
        ...state,
        entries: {
          ...state.entries,
          ...action.entries,
        },
        entriesSynced: {
          ...state.entries,
          ...action.entries,
        },
      };
    }
    case UPDATE_CALENDAR_ENTRY: {
      const { payload } = action;
      const filteredEntries = Object.keys(state.entries).reduce((curr, key) => {
        return {
          ...curr,
          [key]: state.entries[key]
            .filter((ent) => ent.eventObject.uid !== payload.eventObject.uid),
        };
      }, {});
      if (!filteredEntries[action.payload.eventObject.calendarId]) {
        filteredEntries[action.payload.eventObject.calendarId] = [];
      }
      filteredEntries[action.payload.eventObject.calendarId].push(action.payload);
      return {
        ...state,
        entries: filteredEntries,
        entriesSynced: action.local ? state.entriesSynced : _.cloneDeep(filteredEntries),
      };
    }
    case USE_SYNCED_CALENDAR_ENTRIES:
      return {
        ...state,
        entries: _.cloneDeep(state.entriesSynced),
      };
    case DELETE_CALENDAR_ENTRY: {
      const { payload } = action;
      const filteredEntries = Object.keys(state.entries).reduce((curr, key) => {
        return {
          ...curr,
          [key]: state.entries[key].filter(
            (ent) => !payload.results
              .find((delEnt) => delEnt.eventObject.uid === ent.eventObject.uid),
          ),
        };
      }, {});
      return {
        ...state,
        entries: filteredEntries,
        entriesSynced: action.local ? state.entriesSynced : _.cloneDeep(filteredEntries),
      };
    }
    default:
      return state;
  }
};
