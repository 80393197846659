import moment from 'moment';
import { downloadFile } from 'helpers/file';
import { addPageSize } from 'resources/utils';
import * as fromUser from 'resources/user/user.selectors';
import * as api from './receipt.api';

export const FETCH_RECEIPTS = 'fetchReceipts';
export const CHANGE_RECEIPTS_FILTER = 'changeReceiptsFilter';
export const RESET_RECEIPTS_STATE = 'resetReceiptsState';
export const PRINT_RECEIPT = 'printReceipt';
export const SEND_RECEIPT_EMAIL = 'SEND_RECEIPT_EMAIL';

export const fetchReceipts = (options = {}) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  const data = addPageSize(options);

  return api.fetchReceipts(garageId, data)
    .then((payload) => dispatch({ type: FETCH_RECEIPTS, data, payload }));
};

export const getReceiptsCSV = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getReceiptsCSV(garageId, data)
    .then((payload) => downloadFile(payload, 'cashregister.csv'));
};

export const getDepData = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getDepData(garageId)
    .then((payload) => downloadFile(JSON.stringify(payload, null, '  '), 'dep-export.json'));
};

export const cancelReceipt = (receiptId, data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.cancelReceipt(receiptId, garageId, {})
    .then(() => api.fetchReceipts(garageId, data))
    .then((payload) => dispatch({ type: CHANGE_RECEIPTS_FILTER, data, payload }));
};

export const createZeroReceipt = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.createZeroReceipt(garageId);
};

export const changeReceiptsFilter = (options = {}) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  const data = addPageSize(options);

  return api.fetchReceipts(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_RECEIPTS_FILTER, data, payload }));
};

export const resetReceiptsState = () => ({ type: RESET_RECEIPTS_STATE });

export const printReceipt = (id) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.printReceipt(garageId, id)
    .then((payload) => dispatch({ type: PRINT_RECEIPT, id, payload }));
};

export const getReceiptPdfUrl = (id) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getReceiptPdfUrl(id, garageId);
};

export const getPaymentsSum = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getPaymentsSum(garageId, data);
};

export const getTotalPaymentTaxSums = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.getTotalPaymentTaxSums(garageId, data);
};

export const sendReceiptViaEmail = (invoiceId, data) => (dispatch, getState) => {
  return api.sendEmail(invoiceId, fromUser.getCurrentGarageId(getState()), data)
    .then((payload) => dispatch({ type: SEND_RECEIPT_EMAIL, payload }));
};

export const getCardPaymentsCSV = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());

  let fileName = data.type === 'BMD'
    ? `kartenzahlungen${
      data.from ? `_${moment(data.from).format('DDMMYYYY')}` : ''
    }${data.to ? `-${moment(data.to).utc().format('DDMMYYYY')}` : ''}.csv`
    : 'card-payments.csv';

  if (data.type === 'DATEV') {
    fileName = `EXTF_${fileName}`;
  }

  return api.getCardPaymentsCSV(garageId, data)
    .then((payload) => downloadFile(payload, fileName));
};
