import React from 'react';

const CashbookArrow = (props) => (
  <svg width="200" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M192.995 12l-7.837-8.76L199.25 12l-14.092 8.76 7.837-8.76z" />
    <path stroke="#000" strokeWidth="1.5" d="M5 12h190" />
    <path d="M7.005 12l7.837 8.76L.75 12l14.092-8.76L7.005 12z" />
  </svg>
);

export default CashbookArrow;
