import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faCheckCircle } from 'fontawesome/pro-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';

import { ADDONS } from 'shared-library/src/definitions/subscription';
import polyglot from 'services/localization';
import DeviceService from 'services/device.service';
import ButtonModern from 'components/common/buttonModern';
import CheckboxModern from 'components/common/checkboxModern';
import RoleManager from 'components/common/roleManager';
import { replace } from 'helpers/locale';
import { getSign } from 'helpers/price';
import { validatePaymentData } from 'helpers/subscription';
import toastService from 'helpers/toastService';

import styles from './accessLimitation.styles.pcss';

const { stripe: stripeConfig } = window.config;
const stripePromise = loadStripe(stripeConfig.publicKey);

class AccessLimitation extends Component {
  static propTypes = {
    className: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
    addons: PropTypes.arrayOf(PropTypes.string).isRequired,
    history: PropTypes.shape().isRequired,
    stripeCheckout: PropTypes.func.isRequired,
    getPriceList: PropTypes.func.isRequired,
    garage: PropTypes.func.isRequired,
    subscriptionData: PropTypes.shape({
      status: PropTypes.string.isRequired,
      addons: PropTypes.shape().isRequired,
      planId: PropTypes.string,
      hasPrimaryPaymentSource: PropTypes.bool,
    }).isRequired,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);
    this.getAddonsObject = (subscriptionData) => {
      const addonsObject = ADDONS
        .reduce((acc, addon) => ({ ...acc, [addon]: false }), {});
      if (subscriptionData && subscriptionData.addons) {
        Object.entries(subscriptionData.addons)
          .forEach(([addonId, addonValue]) => {
            if (addonsObject[addonId] === undefined) {
              return;
            }
            addonsObject[addonId] = addonValue;
          });
      }
      return addonsObject;
    };
  }

  state = {
    checked: false,
    isLoading: false,
    prices: {},
  };

  componentDidMount() {
    this.getPriceList();
  }

  onToggle = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  };

  async updateSubscription() {
    const {
      subscriptionData,
      garage,
      stripeCheckout,
      addons: propAddons,
    } = this.props;
    this.setState({ isLoading: true });
    const addons = this.getAddonsObject(subscriptionData);
    for (let i = 0; i < propAddons.length; i += 1) {
      addons[propAddons[i]] = true;
    }
    await stripeCheckout({
      planId: subscriptionData.planId,
      addons: Object.keys(addons).filter((key) => addons[key]),
      interval: garage.subscriptionData.paymentCycle,
    }).then(async (reply) => {
      const stripe = await stripePromise;
      const test = await validatePaymentData(garage, stripe);
      if (test.success) toastService.showSuccess(polyglot.t('subscription.bookedSuccessfully'));
      else toastService.showError(polyglot.t(`subscription.${test.message}`));
    }).catch((err) => {
      toastService.showError(polyglot.t('subscription.bookingError'));
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  getPriceList = async () => {
    const { getPriceList } = this.props;
    const prices = await getPriceList();
    this.setState({ prices });
  };

  getAddonPrice = (addon) => {
    const { garage } = this.props;
    const { prices } = this.state;
    let priceText = '';
    if (prices?.priceByAddon?.[addon]) {
      priceText = prices.priceByAddon[addon][garage.subscriptionData.paymentCycle];
    }

    priceText = `${priceText}${getSign(garage.country)} ${polyglot.t(`subscription.${garage.subscriptionData.paymentCycle}`)}`;

    return priceText;
  };

  render() {
    const {
      features,
      className,
      addons,
    } = this.props;
    if ((DeviceService.isMobile || DeviceService.isAppleDevice) && DeviceService.isPWA) {
      return (
        <div className={cx(styles.accessLimitationModal, className)}>
          <div className={styles.accessLimitationBorder}>
            <div className={styles.accessLimitationDescriptionHeader}>
              {polyglot.t('common.entityIsInactive', { entity: polyglot.t(`features.${features[0]}`) })}
            </div>
          </div>
        </div>
      );
    }

    const {
      checked,
      isLoading,
    } = this.state;
    return (
      <div className={cx(styles.accessLimitationModal, className)}>
        <div className={styles.accessLimitationBorder}>
          <div className={styles.accessLimitationDescriptionHeader}>
            {replace(
              polyglot.t('accessLimitation.addon'),
              ['addonName'],
              [addons.map((addon) => polyglot.t(`subscription.${addon}`)).join(', ')],
            )}
            <div className={styles.accessLimitationDescriptionCheck}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>
          </div>
          <div className={styles.accessLimitationDescription}>
            {polyglot.t(`accessLimitation.description.${features[0]}`)}
          </div>
          <RoleManager
            allowedRoles={['admin']}
            contentWhenForbidden={(
              <p>
                {polyglot.t('accessLimitation.onlyAdminsCanPurchaseAddons')}
              </p>
            )}
          >
            <CheckboxModern
              label={replace(
                polyglot.t('accessLimitation.youCanBuyItNow'),
                ['addonName', 'priceText'],
                [addons.map((addon) => polyglot.t(`subscription.${addon}`)).join(', '), addons.map((addon) => this.getAddonPrice(addon)).join(', ')],
              )}
              onToggle={() => this.onToggle()}
              selected={checked}
              className={styles.accessLimitationCheckbox}
            />
            <ButtonModern styleType="add" disabled={!checked} isLoading={isLoading} onClick={() => this.updateSubscription()}>
              {polyglot.t('accessLimitation.bookNow')}
            </ButtonModern>
          </RoleManager>
        </div>
      </div>
    );
  }
}
export default AccessLimitation;
