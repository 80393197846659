import { updateAppByAction } from 'resources/utils';
import {
  FETCH_WORKERS,
  CREATE_WORKER,
  REMOVE_WORKER,
  FETCH_WORKER,
  UPDATE_WORKER,
} from './worker.actions';

const initialState = { list: [], currentWorker: {} };
const actionTypesToUpdateApp = [
  CREATE_WORKER,
  REMOVE_WORKER,
  UPDATE_WORKER,
];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case FETCH_WORKERS: {
      return {
        ...state,
        list: [...action.payload],
      };
    }
    case CREATE_WORKER: {
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    }
    case REMOVE_WORKER:
      return {
        ...state,
        list: state.list.filter((worker) => !action.data.workerId.includes(worker._id)),
      };
    case FETCH_WORKER:
      return {
        ...state,
        currentWorker: action.payload,
      };
    case UPDATE_WORKER:
      return {
        ...state,
        currentWorker: action.data,
        list: state.list.map((worker) => (worker._id === action.data._id ? {
          ...worker,
          ...action.data,
        } : worker)),
      };
    default:
      return state;
  }
};
