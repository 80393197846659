import { last } from 'lodash/array';

import { updateAppByAction } from 'resources/utils';
import {
  FETCH_INVOICES,
  CHANGE_INVOICES_FILTER,
  RESET_INVOICES_STATE,
  UPDATE_SDI_STATUS_SOCKET,
} from './sdi.actions';

const initialInvoiceCount = {
  draft: null,
  preview: null,
  open: null,
  overdue: null,
  paid: null,
  paidCredit: null,
  all: null,
};

const initialState = {
  list: [],
  totalAmount: 0,
  invoiceCounts: { ...initialInvoiceCount },
};
const actionTypesToUpdateApp = [];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);
  switch (action.type) {
    case FETCH_INVOICES:
    {
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }
    case CHANGE_INVOICES_FILTER:
      return {
        ...state,
        list: [...action.payload.results],
        totalAmount: action.payload.count,
        invoiceCounts: action.data.invoiceCounts || { ...initialInvoiceCount },
      };
    case RESET_INVOICES_STATE:
      return {
        ...state,
        list: [],
        invoiceCounts: { ...initialInvoiceCount },
      };
    case UPDATE_SDI_STATUS_SOCKET:
      return {
        ...state,
        list: state.list.map((invoice) => {
          if (invoice._id === action.payload.invoiceId) {
            return {
              ...invoice,
              sdi: {
                ...invoice.sdi,
                status: action.payload.sdiStatus,
              },
            };
          }
          return invoice;
        }),
      };
    default:
      return state;
  }
};
