import { FETCH_THIRD_PARTY_TOOLS } from './thirdPartyTools.actions';

const initialState = [...window.config.thirdPartyTools];

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_THIRD_PARTY_TOOLS:
      return [...action.payload];
    default:
      return state;
  }
};
