import * as fromUser from 'resources/user/user.selectors';
import { addPageSize } from 'resources/utils';
import * as api from './email.api';

export const FETCH_EMAILS = 'fetchEmails';
export const RESET_EMAILS_STATE = 'resetEmailsState';
export const CHANGE_EMAILS_FILTER = 'changeEmailsFilter';

export const fetchEmailEvents = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchEmails(garageId, data)
    .then((payload) => dispatch({ type: FETCH_EMAILS, data, payload }));
};

export const changeEmailsFilter = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const garageId = fromUser.getCurrentGarageId(getState());

  return api.fetchEmails(garageId, data)
    .then((payload) => dispatch({ type: CHANGE_EMAILS_FILTER, data, payload }));
};

export const resetEmailsState = () => ({ type: RESET_EMAILS_STATE });
