import { getFormattedAustrianNumber, getFormattedSwissNumber } from './regexp';

export const isNumeric = (value) => !Number.isNaN(parseFloat(value)) && Number.isFinite(value);

export const getAustrianNumber = (number, country) => {
  if (!isNumeric(parseFloat(number))) {
    return '-';
  }

  const parts = number.toString().split('.');
  parts[0] = ['CH', 'LI'].includes(country)
    ? getFormattedSwissNumber(parts[0])
    : getFormattedAustrianNumber(parts[0]);
  return parts.join(['CH', 'LI'].includes(country) ? '.' : ',');
};

export const get2DecimalsNumber = (number, country) => (isNumeric(parseFloat(number))
  ? getAustrianNumber((Math.round(number * 100) / 100).toFixed(2), country)
  : '');

export const convertToCurrencyOfCountry = (number, country) => {
  const numberWith2Decimals = getAustrianNumber(
    get2DecimalsNumber(number, country),
    country,
  );
  return numberWith2Decimals + (['CH', 'LI'].includes(country) ? ' CHF' : ' €');
};

export const getPercent = (number, country) => {
  return number % 1 === 0 || Number.isNaN(number % 1)
    ? `${getAustrianNumber(number, country)}%`
    : `${getAustrianNumber(number.toFixed(2), country)}%`;
};
