import { connect } from 'react-redux';

import { isThirdPartyToolActive } from 'resources/garage/garage.selectors';
import { createEbvOrder } from 'resources/thirdPartyTools/ebv/ebv.actions';

import EbvIntegration from './ebvIntegration';

export default connect(
  (state) => ({
    isEBVActive: isThirdPartyToolActive('ebv', state),
  }),
  { createEbvOrder },
)(EbvIntegration);
