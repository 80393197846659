import * as apiClient from 'resources/api.client';

export function createProduct(data, garageId) {
  return apiClient.post('products', { garageId }, data);
}

export function fetchProducts(data) {
  return apiClient.get('products', data);
}

export function fetchProductsByName(data) {
  return apiClient.get('products/name', data);
}

export function searchProductsByNameAndSKU(data) {
  return apiClient.get('products/name-and-sku', data);
}

export function findProductsBySKU(data) {
  return apiClient.get('products/sku', data);
}

export function isSkuAlreadyUsed(data) {
  return apiClient.get('products/is-sku-used', data);
}

export function fetchProductCountsByStatus(garageId, data) {
  return apiClient.get('products/counts', { garageId, ...data });
}

export function getProductById(productId, garageId) {
  return apiClient.get(`products/${productId}`, { garageId });
}

export function fetchAutoPositionProducts(garageId) {
  return apiClient.get('products/auto-position-products', { garageId });
}

export function fetchReport(garageId, data) {
  return apiClient.get('products/report', { garageId, ...data });
}

export function updateProduct(productId, product, garageId) {
  return apiClient.put(`products/${productId}`, { garageId }, product);
}

export function removeProducts(productIds, garageId) {
  return apiClient.post('products/remove', { garageId }, { productIds });
}

export function getArticlesCSV(garageId, data) {
  return apiClient.get('products/csv', { garageId, ...data });
}

export function getProductsExcel(garageId, data) {
  return apiClient.get('products/excel', { garageId, ...data });
}

export function fetchInventoryForProducts(garageId, productSkus) {
  return apiClient.post('products/inventory', { garageId }, { productSkus });
}

export function getProductsReportCSV(garageId, data) {
  return apiClient.get('products/report/csv', { garageId, ...data });
}

export function getProductsSellsReportExcel(garageId, data) {
  return apiClient.get('products/report/excel', { garageId, ...data });
}

export function getValueOfInventory(garageId) {
  return apiClient.get('products/valueOfInventory', { garageId });
}

export function restoreProduct(garageId, data) {
  return apiClient.put(`products/${data.productId}/restore`, { garageId }, data);
}
