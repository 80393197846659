import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import polyglot from 'services/localization';
import parseError from 'helpers/parseError';
import toastService from 'helpers/toastService';
import TireModal from './components/tireModal';

class TireModalContainer extends PureComponent {
  static propTypes = {
    createTire: PropTypes.func.isRequired,
    updateTire: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    isDYMOPrintingEnabled: PropTypes.bool.isRequired,
    validateTireStorage: PropTypes.func.isRequired,
    getCanPrintnodeBeUsed: PropTypes.func.isRequired,
    vehicle: PropTypes.shape({}),
  };

  static defaultProps = {
    vehicle: null,
  };

  state = {
    tireToUpdate: undefined,
    isTireModal: false,
    canPrintnodeBeUsed: false,
  };

  componentDidMount() {
    const { getCanPrintnodeBeUsed } = this.props;

    getCanPrintnodeBeUsed('tire')
      .then((canPrintnodeBeUsed) => {
        this.setState({
          canPrintnodeBeUsed,
        });
      })
      .catch(() => {
        this.setState({
          canPrintnodeBeUsed: false,
        });
      });
  }

  onCancelTireModal = () => {
    this.setState({ isTireModal: false, tireToUpdate: undefined });
  };

  onSaveTireModal = (tire) => {
    const { tireToUpdate } = this.state;
    const { updateTire, createTire } = this.props;

    if (tireToUpdate) {
      return updateTire(tire)
        .then(({ data }) => {
          this.setState({ tireToUpdate: data });
          toastService.showSuccess(polyglot.t('tires.updated'));
        })
        .catch((err) => {
          const msg = parseError(err);
          toastService.showError(msg || polyglot.t('misc.unknownError'));
        });
    }

    return createTire(tire)
      .then(({ payload }) => {
        this.setState({ tireToUpdate: payload });

        toastService.showSuccess(polyglot.t('tires.created'));
      })
      .catch((err) => {
        const msg = parseError(err);
        toastService.showError(msg || polyglot.t('misc.unknownError'));
      });
  };

  onOpenTire = (tire) => {
    this.setState({ isTireModal: true, tireToUpdate: tire && tire._id ? tire : undefined });
  };

  resetTireToUpdate = (callback) => this.setState({ tireToUpdate: undefined }, callback);

  render() {
    const { isTireModal, tireToUpdate, canPrintnodeBeUsed } = this.state;
    const {
      render, validateTireStorage, vehicle, isDYMOPrintingEnabled,
    } = this.props;

    return (
      <Fragment>
        {render(this.onOpenTire)}

        <TireModal
          isOpen={isTireModal}
          onCancel={this.onCancelTireModal}
          onSave={this.onSaveTireModal}
          onValidateStorage={validateTireStorage}
          defaultData={tireToUpdate}
          resetTireToUpdate={this.resetTireToUpdate}
          canPrintnodeBeUsed={canPrintnodeBeUsed}
          vehicle={vehicle}
          isDYMOPrintingEnabled={isDYMOPrintingEnabled}
        />
      </Fragment>
    );
  }
}

export default TireModalContainer;
