import { createFormDataWithAttachments } from 'helpers/file';
import * as apiClient from 'resources/api.client';

export function fetchInvoices(garageId, data) {
  return apiClient.get('invoices', { garageId, ...data });
}

export function fetchInvoicesOfClient(garageId, data) {
  return apiClient.get('invoices/client', { garageId, ...data });
}

export function fetchInvoicesOfVehicle(garageId, data) {
  return apiClient.get('invoices/vehicle', { garageId, ...data });
}

export function fetchInvoicesCalendarModal(garageId, data) {
  return apiClient.get('invoices/calendarModal', { garageId, ...data });
}

export function fetchInvoicesProductHistory(garageId, data) {
  return apiClient.get('invoices/productHistory', { garageId, ...data });
}

export function fetchInvoiceCountByStatus(garageId, data) {
  return apiClient.get('invoices/counts', { garageId, ...data });
}

export function fetchInvoicesOfCustomer(customerId, garageId) {
  return apiClient.get(`invoices/customer/${customerId}`, { garageId });
}

export function fetchInvoice(garageId, invoiceId, data) {
  return apiClient.get(`invoices/${invoiceId}`, { garageId, ...data });
}

export function getInvoicesCSV(garageId, data) {
  return apiClient.get('invoices/csv', { garageId, ...data });
}

export function getInvoicesExcel(garageId, data) {
  return apiClient.get('invoices/excel', { garageId, ...data });
}

export function getSalesReport(garageId, data) {
  return apiClient.get('invoices/sales', { garageId, ...data });
}
export function getRevenueReportByClient(garageId, data) {
  return apiClient.get('invoices/sales/clients', { garageId, ...data });
}
export function getRevenueReportByType(garageId, data) {
  return apiClient.get('invoices/sales/type', { garageId, ...data });
}
export function getSalesReportCSV(garageId, data) {
  return apiClient.get('invoices/sales/csv', { garageId, ...data });
}

export function getDiscounts(garageId, sku, clientId = null) {
  return apiClient.get('invoices/discounts', { garageId, sku, clientId });
}

export function getReport(garageId, data) {
  return apiClient.get('invoices/report', { garageId, status: ['open', 'paid', 'paidCredit'], ...data });
}

export function getEarnings(garageId, data) {
  return apiClient.get('invoices/earnings', { garageId, ...data });
}

export function getReportCSV(garageId, { from, to, type }) {
  return apiClient
    .get('invoices/report/csv', {
      garageId, from, to, type,
    });
}

export function getInvoicePDFNamesAndURL(garageId, { from, to }) {
  return apiClient
    .get('invoices/pdfNamesAndURLs', {
      garageId, from, to,
    });
}

export function getEarningsCSV(garageId, { from, to, type }) {
  return apiClient
    .get('invoices/earnings/csv', {
      garageId, from, to, type,
    });
}

export function getOpenAmount(garageId, data) {
  return apiClient.get('invoices/open-amount', { garageId, ...data });
}

export function getTurnover(garageId, data) {
  return apiClient.get('invoices/turnover', { garageId, ...data });
}

export function createInvoice(garageId, data) {
  return apiClient.post('invoices', { garageId }, data);
}

export function updateInvoice(garageId, invoiceId, data) {
  return apiClient.put(`invoices/${invoiceId}`, { garageId }, data);
}

export function uploadInvoiceImage(garageId, invoiceId, data) {
  const body = new FormData();
  Object.keys(data).forEach((key) => {
    body.append(key, data[key]);
  });

  return apiClient.postRaw(`invoices/${invoiceId}/files/upload`, { garageId }, body);
}

export const fetchInvoiceImage = async (
  garageId,
  invoiceId,
  fileId,
  name,
  extension,
  location,
) => {
  return apiClient.post(`invoices/${invoiceId}/files`, {
    garageId,
    fileId,
    name,
    extension,
    location,
  });
};

export function removeInvoiceImages(garageId, invoiceId, filesToRemove) {
  return apiClient.post(`invoices/${invoiceId}/files/remove`, { garageId }, { filesToRemove });
}

export function updateInvoiceImage(garageId, invoiceId, imageId, data) {
  return apiClient.put(`invoices/${invoiceId}/files`, { garageId, fileId: imageId }, data);
}

export function changeStatus(garageId, invoiceId, data) {
  return apiClient.post(`invoices/${invoiceId}/change-status`, { garageId }, data);
}

export function convertToCostListing(garageId, invoiceId, data) {
  return apiClient.post(`invoices/${invoiceId}/convert-to-costListing`, { garageId }, data);
}

export function reopenInvoice(garageId, invoiceId) {
  return apiClient.post(`invoices/${invoiceId}/reopen`, { garageId });
}

export function cancelInvoice(garageId, invoiceId, data) {
  return apiClient.post(`invoices/${invoiceId}/cancel`, { garageId }, data);
}

export function removeInvoices(garageId, invoiceIds) {
  return apiClient.post('invoices/remove', { garageId }, { invoiceIds });
}

export function addPayment(invoiceId, garageId, data) {
  return apiClient.post(`invoices/${invoiceId}/payments`, { garageId }, data);
}

export function cancelPayment(invoiceId, paymentId, garageId, data = {}) {
  return apiClient.post(`invoices/${invoiceId}/payments/${paymentId}/cancel`, { garageId }, data);
}

export function cancelMyPosPayment(myposUid, garageId, data = {}) {
  return apiClient.post(`invoices/mypos/payments/${myposUid}/cancel`, { garageId }, data);
}

export function checkMyPosPayment(myposUid, garageId) {
  return apiClient.get(`invoices/mypos/payments/${myposUid}/checkStatus`, { garageId });
}

export function sendEmail(invoiceId, garageId, data) {
  const body = createFormDataWithAttachments(data);
  return apiClient.postRaw(`invoices/${invoiceId}/send`, { garageId }, body);
}

export function sendSMS(garageId, invoiceId, data) {
  return apiClient.post(`invoices/${invoiceId}/send-pickup-notification`, { garageId }, data);
}

export function printInvoice(garageId, id, status) {
  return apiClient.post(`invoices/${id}/print`, { garageId, status }, {});
}

export const generateInvoicePdf = (garageId, id, status) => apiClient.post(`invoices/${id}/generate-pdf`, { garageId, status });

export const generateInvoiceReminderPdf = (garageId, id, dunningStatus) => apiClient.post(`invoices/${id}/generate-reminder-pdf`, { garageId, dunningStatus });

export const getInvoicePdfUrl = (id, status, garageId, printVersion = false) => apiClient.get(`invoices/${id}/pdf-url`, { status, garageId, printVersion });

export const generateInvoiceFatturaPaXml = (invoiceId, garageId) => {
  return apiClient.get('sdi/generate-fattura-pa-xml', { garageId, invoiceId });
};

export const generateEInvoice = (invoiceId, garageId) => {
  return apiClient.get('eInvoice/generateEInvoice', { garageId, invoiceId });
};

export function addInvoiceEvent(invoiceId, garageId, eventName) {
  return apiClient.post(`invoices/${invoiceId}/addInvoiceEvent`, { garageId, eventName });
}

export function copyIntoDraft(invoiceId, garageId) {
  return apiClient.post(`invoices/${invoiceId}/copyIntoDraft`, { garageId });
}

export function copyInvoiceIntoOffer(invoiceId, garageId) {
  return apiClient.post(`invoices/${invoiceId}/copyInvoiceIntoOffer`, { garageId });
}

export function generateAssignmentDeclarationPdf(garageId, invoiceId, caseOfDamage = '', signature = null) {
  return apiClient.post(`invoices/${invoiceId}/generate-assignment-declaration-pdf`, { garageId }, { caseOfDamage, signature });
}

export function restoreInvoice(garageId, data) {
  return apiClient.put(`invoices/${data.invoiceId}/restore`, { garageId }, data);
}

export function updateInvoiceNotesAndTasks(garageId, invoiceId, notesAndTasks) {
  return apiClient.put(`invoices/${invoiceId}/updateNotesAndTasks`, { garageId }, { notesAndTasks });
}
