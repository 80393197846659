import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  MdKeyboardArrowDown as ArrowDown,
  MdKeyboardArrowUp as ArrowUp,
} from 'react-icons/md';
import _ from 'lodash';
import cx from 'classnames';

import tableStyles from 'styles/table.pcss';
import buttonStyles from 'styles/button.pcss';
import internalStyles from './sortableTableTitle.styles.pcss';

export default class SortableTableTitle extends PureComponent {
  static propTypes = {
    onChangeSort: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    sortKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    sortBy: PropTypes.arrayOf(PropTypes.string).isRequired,
    sortDirection: PropTypes.number.isRequired,
    className: PropTypes.string,
    align: PropTypes.oneOf(['right', 'center', '']),
    width: PropTypes.string,
    styles: PropTypes.object,
    dataTest: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    align: '',
    width: '',
    styles: {},
    dataTest: '',
    disabled: false,
  };

  onChangeSort = () => {
    let { sortBy, sortDirection } = this.props;
    if (_.isEqual(this.props.sortKeys, this.props.sortBy)) {
      sortDirection = sortDirection === 1 ? -1 : 1;
    } else {
      sortBy = this.props.sortKeys;
      sortDirection = 1;
    }

    this.props.onChangeSort({ sortBy, sortDirection });
  };

  render() {
    const {
      sortDirection, className, width, align, title, sortKeys, sortBy, styles, dataTest, disabled,
    } = this.props;

    const SortArrow = sortDirection === 1 ? ArrowUp : ArrowDown;

    return (
      <td
        className={cx(tableStyles.tableElement, className, styles.title, {
          [styles.elementWide]: width === 'wide',
          [styles.elementNarrow]: width === 'narrow',
          [styles.elementMedium]: width === 'medium',
          [internalStyles.elementRight]: align === 'right',
          [internalStyles.elementCenter]: align === 'center',
        })}
        style={styles}
      >
        <button
          type="button"
          className={cx(buttonStyles.textButton, styles.titleButton, {
            [buttonStyles.textButtonRight]: align === 'right',
            [buttonStyles.textButtonCenter]: align === 'center',
          })}
          onClick={this.onChangeSort}
          data-test={dataTest}
          disabled={disabled}
        >
          <span className={cx(styles.titleButtonText)}>
            {title}
          </span>
          {_.isEqual(sortKeys, sortBy) ? (
            <SortArrow className={cx(tableStyles.tableIcon, styles.titleIcon)} />
          ) : null}
        </button>
      </td>
    );
  }
}
