const GARAGE_COUNTRIES = Object.freeze({
  AT: 'AT',
  CH: 'CH',
  DE: 'DE',
  IT: 'IT',
  LI: 'LI',
  LU: 'LU',
});

function supportedGarageCountries(garage) {
  return Object.values(GARAGE_COUNTRIES).includes(garage.country);
}

function hasCashRegisterService(garage) {
  return garage && (garage.country === 'AT' || garage.country === 'DE');
}

module.exports = {
  GARAGE_COUNTRIES,
  hasCashRegisterService,
  supportedGarageCountries,
};
