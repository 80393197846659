/* eslint-disable no-undef */

import polyglot from 'services/localization';
import xml2js from 'xml2js';
import { promisify } from 'util';
import Dymo from './dymo';
import PredefinedLabels from './predefinedLabels';

const parseString = promisify(xml2js.parseString);
const dymo = new Dymo();

const formatXML = (value) => {
  return value
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
};

const printArticle = async (articleNumber, articlePrice, articleDescription, printer) => {
  const labelXml = PredefinedLabels[Dymo.currentWebserviceVersion].ARTICLE
    .replace('ARTICLE_NUMBER', formatXML(articleNumber))
    .replace('ARTICLE_PRICE', formatXML(articlePrice))
    .replace('ARTICLE_DESCRIPTION', formatXML(articleDescription))
    .replace('BARCODE-DATA', formatXML(articleNumber));
  await dymo.print(printer, labelXml);
};

const printTireLabel = async (label, license, name, dimension, storeage, date, printer) => {
  const labelXml = PredefinedLabels[Dymo.currentWebserviceVersion].TIRE
    .replace('{{LABEL}}', formatXML(label))
    .replace('{{LICENSE_LABEL}}', polyglot.t('tires.label.license'))
    .replace('{{LICENSE}}', formatXML(license))
    .replace('{{NAME_LABEL}}', polyglot.t('tires.label.name'))
    .replace('{{NAME}}', formatXML(name))
    .replace('{{DIMENSION_LABEL}}', polyglot.t('tires.label.dimension'))
    .replace('{{DIMENSION}}', formatXML(dimension))
    .replace('{{STORAGE_LABEL}}', polyglot.t('tires.label.storage'))
    .replace('{{STOREAGE}}', formatXML(storeage))
    .replace('{{DATE_LABEL}}', polyglot.t('tires.label.date'))
    .replace('{{DATE}}', formatXML(date));
  await dymo.print(printer, labelXml);
};

const getPrinters = async () => {
  let printers;
  try {
    printers = await dymo.getPrinters();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Dymo Webservice getPrinters Error: ', err);
    return [];
  }
  printers = printers.substring(printers.indexOf('<'), printers.lastIndexOf('>') + 1).replace(/\\n/, '').replace(/\\/g, '');
  // eslint-disable-next-line no-console
  console.log('Dymo Webservice response: ', printers);
  if (printers && printers !== '<Printers />') {
    printers = await parseString(printers);
    printers = printers.Printers.LabelWriterPrinter.map((printer) => {
      return { name: printer.Name[0], isConnected: printer.IsConnected[0] };
    });
  } else {
    printers = [];
  }
  return printers;
};

const getActivePrinter = async () => {
  try {
    const printers = await getPrinters();
    return printers.find((printer) => printer.isConnected.toLowerCase() === 'true');
  } catch (x) {
    return null;
  }
};

const DymoPrintService = {
  printArticle,
  printTireLabel,
  getPrinters,
  getActivePrinter,
};

Object.freeze(DymoPrintService);

export default DymoPrintService;
