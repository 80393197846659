import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FormValidator from 'helpers/formValidation/formValidator';
import ValidatableComponent from 'helpers/formValidation/validatableComponent';
import styles from './textareaModern.styles.pcss';

export default class TextareaModern extends ValidatableComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    isValid: PropTypes.bool,
    className: PropTypes.string,
    textAreaClassName: PropTypes.string,
    value: PropTypes.string,
    templateFooter: PropTypes.bool,
    tabIndex: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    formValidator: FormValidator.getShape(),
    textAreaStyle: PropTypes.object,
    useAutoResize: PropTypes.bool,
    autoResizeMinHeight: PropTypes.number,
    autoResizeMaxHeight: PropTypes.number,
    dataTest: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    errorMessage: '',
    isValid: true,
    className: '',
    textAreaClassName: '',
    value: '',
    tabIndex: 0,
    templateFooter: false,
    formValidator: null,
    textAreaStyle: {},
    onFocus: () => {},
    onBlur: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onChange: () => {},
    useAutoResize: false,
    autoResizeMinHeight: undefined,
    autoResizeMaxHeight: undefined,
    dataTest: '',
  };

  textAreaRef = React.createRef();

  adjustTextAreaHeight = async () => {
    const { autoResizeMinHeight, autoResizeMaxHeight, useAutoResize } = this.props;
    if (!useAutoResize) return;
    if (!this.textAreaRef.current) return;
    this.textAreaRef.current.style.height = autoResizeMinHeight ? `${autoResizeMinHeight}px` : 'auto';
    this.textAreaRef.current.style.height = `${Math.max(this.textAreaRef.current.scrollHeight + 2, autoResizeMinHeight || 0)}px`;
    if (autoResizeMaxHeight) {
      this.textAreaRef.current.style.maxHeight = `${autoResizeMaxHeight}px`;
    }
  };

  componentDidMount() {
    this.adjustTextAreaHeight();
  }

  componentDidUpdate(prevProps, ...rest) {
    super.componentDidUpdate(prevProps, ...rest);
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.adjustTextAreaHeight();
    }
  }

  onChange = (event) => {
    const {
      onChange,
      formValidator,
    } = this.props;
    if (formValidator) {
      const val = event.target.value;
      formValidator.set(val);
      onChange(val);
    } else {
      onChange(event);
    }
  };

  render() {
    const {
      name,
      label,
      className,
      textAreaClassName,
      value,
      rows,
      templateFooter,
      tabIndex,
      formValidator,
      textAreaStyle,
      dataTest,
      ...props
    } = this.props;
    const hasError = super.hasError();
    return (
      <div className={cx(styles.input, className)}>
        <label htmlFor={name} className={styles.inputLabel}>
          {label && <span className={styles.inputLabelText}>
            {label}
          </span>}
          <textarea
            className={cx(styles.inputField, styles.inputFieldBorder, {
              [styles.inputFieldInvalid]: hasError, [styles.templateFooter]: templateFooter,
            }, textAreaClassName)}
            style={textAreaStyle}
            id={name}
            value={formValidator ? formValidator.value : value}
            rows={rows}
            ref={this.textAreaRef}
            tabIndex={tabIndex}
            data-test={dataTest}
            {..._.omit(props, ['autoResizeMinHeight', 'isValid', 'textAreaStyle', 'errorMessage'])}
            onChange={this.onChange}
            onFocus={(e) => this.onFocus(e)}
            onBlur={this.onBlur}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          />
        </label>
        {super.renderTooltip()}
      </div>
    );
  }
}
