import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MdGetApp as DownloadIcon } from 'react-icons/md';
import polyglot from 'services/localization';

import NoEntities from 'components/common/noEntities';
import SearchInput from 'components/common/searchInput';
import Button from 'components/common/button';
import PrintButton from 'components/common/printButton';
import SortableTableTitle from 'components/common/sortableTableTitle';
import TableLoading from 'components/common/loading/components/tableLoading/tableLoading';
import InfiniteTableBody from 'components/common/infiniteTableBody';
import StopEventPropagation from 'components/common/stopEventPropagation';
import { getTireConfirmationPdf } from 'helpers/file';
import * as date from 'helpers/date';
import TireModalContainer from 'components/vehicles/tireModalContainer';
import tireStyles from 'components/vehicles/vehiclesList/components/tires/tires.styles.pcss';
import Retry from 'components/common/retry';
import pageStyles from 'styles/page.pcss';
import tableStyles from 'styles/table.pcss';

class VehicleTires extends PureComponent {
  static propTypes = {
    currentVehicleId: PropTypes.string,
    fetchVehicle: PropTypes.func.isRequired,
    tires: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchTires: PropTypes.func.isRequired,
    changeTiresFilter: PropTypes.func.isRequired,
    resetTiresState: PropTypes.func.isRequired,
    printStorageConfirmation: PropTypes.func.isRequired,
    getConfirmationPdfUrl: PropTypes.func.isRequired,
    totalAmount: PropTypes.number.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    getCanPrintnodeBeUsed: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentVehicleId: '',
  };

  static sortableTableTitles = [{
    title: polyglot.t('tires.brandAndType'),
    keys: ['frontTires.brand', 'frontTires.type'],
  }, {
    title: polyglot.t('tires.storageLocation'),
    keys: ['storageLocation'],
  }, {
    title: polyglot.t('tires.username'),
    keys: ['client.lastName', 'client.firstName'],
  }, {
    title: polyglot.t('tires.license'),
    keys: ['vehicle.license'],
  }];

  state = {
    searchText: '',
    sortBy: ['frontTires.brand', 'frontTires.type'],
    sortDirection: 1,
    isNoTires: false,
    isFetchLoading: false,
    isFetchError: false,
    isChangeFilterLoading: false,
    isChangeFilterError: false,
    canPrintnodeBeUsed: false,
  };

  componentDidMount() {
    const {
      match,
      currentVehicleId,
      fetchVehicle,
      getCanPrintnodeBeUsed,
      resetTiresState,
    } = this.props;
    resetTiresState();
    this.fetch();

    if (!currentVehicleId || currentVehicleId !== match.params.id) {
      fetchVehicle(match.params.id);
    }

    getCanPrintnodeBeUsed('tire')
      .then((canPrintnodeBeUsed) => {
        this.setState({
          canPrintnodeBeUsed,
        });
      })
      .catch(() => {
        this.setState({
          canPrintnodeBeUsed: false,
        });
      });
  }

  componentWillUnmount = () => {
  };

  onSearchChange = (searchText) => this.setState({ searchText, isChangeFilterLoading: true });

  onSearchDebounce = () => {
    const { sortBy, sortDirection, searchText } = this.state;
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  onChangeSort = ({ sortBy, sortDirection }) => {
    const { searchText } = this.state;
    this.setState({ sortBy, sortDirection, isChangeFilterLoading: true });
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  onScroll = () => {
    const {
      tires,
      totalAmount,
      match,
      fetchTires,
    } = this.props;
    const { sortBy, sortDirection, searchText } = this.state;

    if (tires.length >= totalAmount) {
      return Promise.resolve();
    }

    return fetchTires({
      skip: tires.length,
      sortBy,
      sortDirection,
      searchText,
      vehicleId: match.params.id,
    });
  };

  onRetryChangeFilter = () => {
    this.setState({ isChangeFilterLoading: true });
    const { sortBy, sortDirection, searchText } = this.state;
    this.changeFilter({ sortBy, sortDirection, searchText });
  };

  fetch = () => {
    const { match, fetchTires } = this.props;
    const { sortBy, sortDirection, searchText } = this.state;

    this.setState({ isFetchLoading: true });
    fetchTires({
      sortBy, sortDirection, searchText, vehicleId: match.params.id,
    })
      .then(({ payload }) => this.setState({
        isNoTires: !payload.results.length,
        isFetchLoading: false,
        isFetchError: false,
      }))
      .catch(() => this.setState({ isFetchError: true, isFetchLoading: false }));
  };

  changeFilter = ({ sortBy, sortDirection, searchText }) => {
    const { match, changeTiresFilter } = this.props;

    changeTiresFilter({
      sortBy, sortDirection, searchText, vehicleId: match.params.id,
    })
      .then(() => this.setState({ isChangeFilterLoading: false, isChangeFilterError: false }))
      .catch(() => this.setState({ isChangeFilterLoading: false, isChangeFilterError: true }));
  };

  renderCreateButton = (onOpenTire) => (
    <Button
      styleType="add"
      onClick={onOpenTire}
    >
      {polyglot.t('vehicleTires.addTire')}
    </Button>
  );

  renderPdfActions(tire) {
    if (tire.status !== 'active' || tire.removedOn) {
      return null;
    }

    const { printStorageConfirmation, getConfirmationPdfUrl } = this.props;
    const { canPrintnodeBeUsed } = this.state;

    return (
      <React.Fragment>
        <StopEventPropagation events={['onClick']}>
          <PrintButton
            onPrint={() => printStorageConfirmation(tire._id)}
            onGetPdfUrl={() => getConfirmationPdfUrl(tire._id)}
            canPrintnodeBeUsed={canPrintnodeBeUsed}
          />
        </StopEventPropagation>
        <StopEventPropagation events={['onClick']}>
          <a href={getTireConfirmationPdf(tire._id)} title={polyglot.t('actions.download')}>
            <DownloadIcon
              className={cx(tableStyles.tableIcon, tableStyles.tableIconAction)}
            />
          </a>
        </StopEventPropagation>
      </React.Fragment>
    );
  }

  renderTableBody = (onOpenTire) => {
    const { tires } = this.props;
    const { isChangeFilterLoading, isChangeFilterError } = this.state;

    if (!isChangeFilterLoading && isChangeFilterError) {
      return <Retry onRetry={this.onRetryChangeFilter} />;
    }

    if (!tires.length) {
      return <NoEntities withoutBorder noEntitiesText={polyglot.t('vehicleTires.notFound')} />;
    }

    return (
      <InfiniteTableBody isDataLoading={isChangeFilterLoading} onScroll={this.onScroll}>
        {tires.map((initialTire) => {
          const tire = {
            ...initialTire,
            storedOn: date.toUTCDay(initialTire.storedOn),
            removedOn: initialTire.removedOn
              ? date.toUTCDay(initialTire.removedOn)
              : '',
          };

          return (
            <tr
              key={tire._id}
              className={cx(
                tableStyles.tableRow,
                { [tableStyles.tableRowRemoved]: tire.removedOn },
              )}
              onClick={() => onOpenTire(tire)}
            >
              <td className={cx(tableStyles.tableElement, tableStyles.tableSelector)} />
              <td className={cx(tableStyles.tableElement, tableStyles.tiresStatus)}>
                <span
                  className={cx(
                    tireStyles.tiresStatusLabel,
                    { [tireStyles.tiresStatusLabelRemoved]: tire.removedOn },
                  )}
                >
                  {polyglot.t(`tires.${tire.removedOn ? 'removed' : 'stored'}`)}
                </span>
              </td>
              <td className={tableStyles.tableElement}>
                {tire.storedOn || '---'}
              </td>
              <td className={tableStyles.tableElement}>
                {tire.removedOn || '---'}
              </td>
              <td className={tableStyles.tableElement}>
                {`${tire.frontTires.brand || '---'} / ${tire.frontTires.type || '---'}`}
              </td>
              <td className={tableStyles.tableElement}>
                {tire.storageLocation || '---'}
              </td>
              <td className={tableStyles.tableElement}>
                {tire.client.lastName ? `${tire.client.lastName} ${tire.client.firstName}` : '---'}
              </td>
              <td className={tableStyles.tableElement}>
                {tire.vehicle.license || '---'}
              </td>
              <td className={cx(tableStyles.tableElement, tableStyles.tablePdfActions)}>
                {this.renderPdfActions(tire)}
              </td>
            </tr>
          );
        })}
      </InfiniteTableBody>
    );
  };

  renderContent = () => {
    const content = (onOpenTire) => {
      const {
        searchText, sortBy, sortDirection, isFetchLoading, isNoTires, isFetchError,
      } = this.state;
      const { totalAmount } = this.props;

      if (isFetchLoading) {
        return (
          <div className={pageStyles.page}>
            <TableLoading>
              {this.renderCreateButton()}
            </TableLoading>
          </div>
        );
      }

      if (isFetchError) {
        return <Retry className={pageStyles.pageContent} onRetry={this.fetch} />;
      }

      if (isNoTires) {
        return (
          <div className={pageStyles.page}>
            <NoEntities noEntitiesText={polyglot.t('vehicleTires.thereAreNoVehicleTires')}>
              {this.renderCreateButton(onOpenTire)}
            </NoEntities>
          </div>
        );
      }

      return (
        <div className={pageStyles.page}>
          <div className={pageStyles.pageContent}>
            <div className={pageStyles.pageActions}>
              <div className={pageStyles.pageActionsSearch}>
                <SearchInput
                  onChange={this.onSearchChange}
                  onDebounce={this.onSearchDebounce}
                  placeholder={polyglot.t('actions.searchPlaceholder')}
                  value={searchText}
                  totalAmount={totalAmount}
                />
              </div>
              <div className={pageStyles.pageActionsButtons}>
                {this.renderCreateButton(onOpenTire)}
              </div>
            </div>

            <table className={tableStyles.table}>
              <thead className={tableStyles.tableHeader}>
                <tr className={tableStyles.tableLightRow}>
                  <td className={cx(tableStyles.tableElement, tableStyles.tableSelector)} />
                  <td className={cx(tableStyles.tableElement, tableStyles.tiresStatus)}>
                    {polyglot.t('tires.status')}
                  </td>
                  <td className={tableStyles.tableElement}>
                    {polyglot.t('tires.storedOn')}
                  </td>
                  <td className={tableStyles.tableElement}>
                    {polyglot.t('tires.removedOn')}
                  </td>
                  {
                    VehicleTires.sortableTableTitles.map(({ title, keys }) => (
                      <SortableTableTitle
                        key={title}
                        onChangeSort={this.onChangeSort}
                        title={title}
                        sortKeys={keys}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                      />
                    ))
                  }
                  <td className={cx(tableStyles.tableElement, tableStyles.tablePdfActions)} />
                </tr>
              </thead>
              {this.renderTableBody(onOpenTire)}
            </table>
          </div>
        </div>
      );
    };
    content.displayName = 'tiresContent';
    return content;
  };

  render() {
    return (
      <TireModalContainer render={this.renderContent()} />
    );
  }
}

export default VehicleTires;
