function convertToCurrencyOfCountry(number, country) {
  const numberWith2Decimals = getAustrianNumber(get2DecimalsNumber(number, country), country);
  return numberWith2Decimals + (['CH', 'LI'].includes(country) ? ' CHF' : ' €');
}

function getAustrianNumber(number, country) {
  if (!isNumeric(parseFloat(number))) {
    return '-';
  }

  const parts = number.toString().split('.');
  parts[0] = ['CH', 'LI'].includes(country)
    ? getFormattedSwissNumber(parts[0])
    : getFormattedAustrianNumber(parts[0]);
  return parts.join(['CH', 'LI'].includes(country) ? '.' : ',');
}

function get2DecimalsNumber(number, country) {
  return isNumeric(parseFloat(number))
    ? getAustrianNumber((Math.round(number * 100) / 100).toFixed(2), country)
    : '';
}

function isNumeric(value) {
  return !Number.isNaN(parseFloat(value)) && Number.isFinite(value);
}

function getFormattedAustrianNumber(string) {
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

function getFormattedSwissNumber(string) {
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

function roundNumberToNearestMultipleOf(number, multipleOf, roundingType = 'round') {
  return (Math[roundingType](number / multipleOf) * multipleOf).toFixed(2);
}

module.exports = {
  convertToCurrencyOfCountry,
  getAustrianNumber,
  roundNumberToNearestMultipleOf,
  ROUNDING_TYPES: { ROUND: 'round', FLOOR: 'floor', CEIL: 'ceil' },
};
