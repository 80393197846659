export function getInvoices({ invoices }) {
  return invoices.list;
}

export function getTotalAmount({ invoices }) {
  return invoices.totalAmount;
}

export function getSalesReport({ invoices }) {
  return invoices.sales;
}

export function getSalesSum({ invoices }) {
  return invoices.sales.reduce(
    (result, sale) => {
      let { revenue, sales } = result;
      if (sale.revenue) {
        revenue = Math.round(revenue * 100 + sale.revenue * 100) / 100;
      }
      if (sale.sales) {
        sales = Math.round(sales * 100 + sale.sales * 100) / 100;
      }

      return { revenue, sales };
    },
    { revenue: 0, sales: 0 },
  );
}

export function getCurrentInvoice({ invoices }) {
  return invoices.currentInvoice;
}

export function getCurrentInvoiceEmail({ invoices }) {
  return invoices.currentInvoiceEmail;
}

export function getIsGross({ invoices }) {
  return false;
}

export function getCurrentIntegration({ invoices }) {
  return invoices.currentIntegration;
}

export function getInvoiceCounts({ invoices }) {
  return invoices.invoiceCounts;
}
