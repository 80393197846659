import React from 'react';
import PropTypes from 'prop-types';

const TachoBar = (props) => (
  <>
    <svg width="192" height="96" viewBox="0 0 192 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      {props.startColorMaterial ? (
        <>
          <linearGradient id="gradientMaterial" className={props.className}>
            <stop className={props.startColorMaterial} offset="0%" />
            <stop className={props.startColorMaterial} offset={`${props.percentRevenueMaterial}%`} />
            <stop className={props.endColorMaterial} offset={`${props.percentMaterial}%`} />
          </linearGradient>
          <path d="M14.4 96C6.4471 96 -0.110788 89.517 1.07765 81.6534C4.12314 61.5021 13.531 42.7045 28.1177 28.1178C46.1212 10.1143 70.5392 1.92224e-06 96 0C121.461 -1.92224e-06 145.879 10.1143 163.882 28.1177C178.469 42.7045 187.877 61.5021 190.922 81.6534C192.111 89.517 185.553 96 177.6 96C169.647 96 163.355 89.4804 161.663 81.7094C158.945 69.2205 152.696 57.6607 143.518 48.4824C130.915 35.88 113.823 28.8 96 28.8C78.1774 28.8 61.0849 35.88 48.4824 48.4824C39.3042 57.6607 33.0551 69.2205 30.3368 81.7094C28.6454 89.4804 22.3529 96 14.4 96Z" fill="#F0F2F5"/>
          <path className={props.className} strokeLinecap={'round'} strokeDasharray={'200%'} strokeDashoffset={props.offsetMaterial} strokeWidth={'30px'} stroke="url(#gradientMaterial)" d="M 15 81 A 84 90 0 0 1 177 81" />
        </>
      ) : (
        <>
          <linearGradient id="gradientWork" className={props.className}>
            <stop className={props.startColorWork} offset="0%" />
            <stop className={props.endColorWork} offset={`${props.percentWork}`} />
          </linearGradient>
          <path d="M14.4 96C6.4471 96 -0.110788 89.517 1.07765 81.6534C4.12314 61.5021 13.531 42.7045 28.1177 28.1178C46.1212 10.1143 70.5392 1.92224e-06 96 0C121.461 -1.92224e-06 145.879 10.1143 163.882 28.1177C178.469 42.7045 187.877 61.5021 190.922 81.6534C192.111 89.517 185.553 96 177.6 96C169.647 96 163.355 89.4804 161.663 81.7094C158.945 69.2205 152.696 57.6607 143.518 48.4824C130.915 35.88 113.823 28.8 96 28.8C78.1774 28.8 61.0849 35.88 48.4824 48.4824C39.3042 57.6607 33.0551 69.2205 30.3368 81.7094C28.6454 89.4804 22.3529 96 14.4 96Z" fill="#F0F2F5"/>
          <path className={props.className} strokeLinecap={'round'} strokeDasharray={'200%'} strokeDashoffset={props.offsetWork} strokeWidth={'30px'} stroke="url(#gradientWork)" d="M 15 81 A 84 90 0 0 1 177 81" />
        </>
      )}
    </svg>
  </>
);

TachoBar.propTypes = {
  className: PropTypes.string,
  startColorMaterial: PropTypes.string,
  endColorMaterial: PropTypes.string,
  percentMaterial: PropTypes.number,
  percentRevenueMaterial: PropTypes.number,
  offsetMaterial: PropTypes.string,
  startColorWork: PropTypes.string,
  endColorWork: PropTypes.string,
  percentWork: PropTypes.number,
  offsetWork: PropTypes.string,
};

export default TachoBar;
