/* eslint global-require: 0, import/no-dynamic-require: 0 */
import Polyglot from 'node-polyglot';
import moment from 'moment';
import momentTz from 'moment-timezone';

import { getValidEasyWKLocale, mapLocaleToBase } from 'shared-library/src/definitions/localization';

let locale = window.config.user.locale || navigator?.language?.replace('-', '_');

locale = getValidEasyWKLocale(locale);

const getSimpleLocalizeTranslations = async (params) => {
  if (window.config.isProd) {
    return require(`client/locales/${params}.json`);
  }
  const response = await fetch(`https://cdn.simplelocalize.io/fa12ef21fbad4bfe859fce875852ee17/_latest/${params}/web`);
  const jsonResponse = await response.json();
  return jsonResponse;
};

let phrases;
let extendPhrases = {};
if ((locale || '').slice(0, 2) === 'de') {
  phrases = await getSimpleLocalizeTranslations('de_DE');
  extendPhrases = await getSimpleLocalizeTranslations(`${locale}`);
} else {
  phrases = await getSimpleLocalizeTranslations(`${locale || 'de_DE'}`);
}
const shortLocale = mapLocaleToBase(locale);
moment.locale(shortLocale);
momentTz.locale(shortLocale);

const polyglot = new Polyglot({
  phrases: JSON.parse(JSON.stringify(phrases)),
  locale: shortLocale,
});

polyglot.extend(JSON.parse(JSON.stringify(extendPhrases)));

function getLocale() {
  return locale;
}

function getShortLocale() {
  return shortLocale;
}

function getLocaleCountry() {
  return locale.split('_')[1] || 'AT';
}

export {
  getLocale,
  getLocaleCountry,
  getShortLocale,
};

export default polyglot;
