import React from 'react';
import cx from 'classnames';
import polyglot from 'services/localization';
import {
  FaChrome as Chrome,
  FaInternetExplorer as InternetExplorer,
  FaExclamationTriangle as Attention,
} from 'react-icons/fa';
import pageStyles from 'styles/page.pcss';
import styles from './ie.styles.pcss';

export default function IeSupport() {
  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <div className={styles.headerIcons}>
          <a className={styles.headerLink} href="https://easywerkstatt.com/">
            <img
              className={styles.headerLogoIcon}
              height="36"
              src={`${window.config.webUrl}/assets/logo_light.png`}
              alt=""
            />
            easyWerkstatt
          </a>
        </div>
      </div>
      <div className={cx(pageStyles.page, pageStyles.pageContent)}>
        <div className={pageStyles.pageTitle}>
          {polyglot.t('layout.support')}
        </div>
        <div className={styles.content}>
          <div className={styles.contentIcon}>
            <InternetExplorer className={styles.iconIe} />
            <Attention className={styles.iconAttention} />
          </div>
          <div className={styles.contentWarning}>
            {polyglot.t('layout.warning')}
          </div>
          <div className={styles.contentAdvice}>
            {polyglot.t('layout.advice')}
          </div>
          <a
            className={styles.button}
            href="https://www.google.com/chrome/"
          >
            <Chrome className={styles.icon} />
            {polyglot.t('layout.download')}
          </a>
          <div className={styles.contentInfo}>
            {polyglot.t('layout.info')}
          </div>
          <div className={styles.contentFooterText}>
            {polyglot.t('layout.footerText')}
          </div>
        </div>
      </div>
    </div>
  );
}
