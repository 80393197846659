import React from 'react';

export default [{
  key: 'en_US',
  label: 'English',
}, {
  key: 'de_DE',
  label: 'Deutsch (Deutschland)',
}, {
  key: 'de_CH',
  label: 'Deutsch (Schweiz)',
}, {
  key: 'de_AT',
  label: 'Deutsch (Österreich)',
}, {
  key: 'de_IT',
  label: 'Deutsch (Südtirol)',
}, {
  key: 'it_IT',
  label: 'Italiano',
}, {
  key: 'fr_FR',
  label: 'Français',
}];

export function replace(string = '', placeholders, values) {
  let result = string;

  placeholders.forEach((placeholder, i) => {
    result = result.replace(`{{{${placeholder}}}}`, values[i]);
  });

  return result;
}

/**
 * Replaces values in a string with ones defined in an Object
 * @param {String} string String to replace in
 * @param {Object<key, value>} toReplace Object of values to replace and new values to replace with
 */
export function replaceObj(string = '', toReplace) {
  let res = string;
  Object.keys(toReplace).forEach((key) => {
    const pattern = new RegExp(`{{{${key}}}}`, 'g');
    res = res.replace(pattern, toReplace[key]);
  });
  return res;
}

export function replaceToBoldObj(string = '') {
  if (!string.match(/<b>/)) return string;
  let refactorString = string;
  const boldTexts = string.match(/<b>(.*?)<\/b>/g).map((val) => {
    return val;
  });
  for (const boldText of boldTexts) {
    refactorString = refactorString.replace(boldText, '|||');
  }
  const texts = refactorString.split('|||');
  const res = texts.map((text, i) => {
    return (
      <>
        {text}
        <b>
          {boldTexts[i]?.replace(/<\/?b>/g, '')}
        </b>
      </>
    );
  });
  return res;
}

export function replaceLinksToLinkObjects(string = '', linkTexts = []) {
  const linkStyle = {
    color: 'var(--primary-color)',
    fontWeight: 500,
    textDecoration: 'none',
  };

  const hoverStyle = {
    color: '#397f75',
  };

  let refactorString = string;
  // eslint-disable-next-line no-useless-escape
  const fullLinkTexts = string.match(/(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[a-zA-Z0-9.\/?=&_-]*)?/g)?.map((val) => {
    return val;
  });
  if (!fullLinkTexts) {
    return string;
  }
  for (const linkText of fullLinkTexts) {
    refactorString = refactorString.replace(linkText, '|||');
  }
  const texts = refactorString.split('|||');
  const res = texts.map((text, i) => {
    return (
      <>
        {text}
        <a
          target="_blank"
          rel="noreferrer"
          href={fullLinkTexts[i]}
          style={linkStyle}
          onMouseEnter={(e) => { e.target.style.color = hoverStyle.color; }}
          onMouseLeave={(e) => { e.target.style.color = linkStyle.color; }}
        >
          {linkTexts?.[i] || fullLinkTexts[i]}
        </a>
      </>
    );
  });
  return res;
}
