import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { MdOpenInNew as OpenIcon } from 'react-icons/md';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faUser, faTimes } from 'fontawesome/pro-solid-svg-icons';

import styles from './selectCustomer.styles.pcss';
import ButtonModern from '../buttonModern';
import DataTableModal from '../dataTableModal';

class SelectCustomer extends PureComponent {
  static propTypes = {
    client: PropTypes.shape({
      _id: PropTypes.string,
      clientId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      removedOn: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    onClientSelected: PropTypes.func.isRequired,
    className: PropTypes.string,
    country: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    isSmallInlineSelection: PropTypes.bool,
    linkDisabled: PropTypes.bool,
  };

  static defaultProps = {
    client: {},
    className: '',
    disabled: false,
    isRequired: true,
    isSmallInlineSelection: false,
    linkDisabled: false,
  };

  state = {
    isModalClientsTableOpen: false,
  };

  onSelectModalToggle = (isModalClientsTableOpen) => {
    this.setState({
      isModalClientsTableOpen,
    });
  };

  onClientSelected = (client) => {
    const { onClientSelected } = this.props;

    onClientSelected({
      ...client,
      clientId: client._id,
    });
    this.onSelectModalToggle(false);
  };

  onRemoveClient = () => {
    const { onClientSelected, country } = this.props;

    onClientSelected({ country });
  };

  render() {
    const {
      className,
      client,
      isRequired,
      isSmallInlineSelection,
      linkDisabled,
      disabled,
    } = this.props;
    const { isModalClientsTableOpen } = this.state;
    const isDisabled = client.removedOn || disabled;

    return (
      <div
        className={cx(
          styles.selectButton,
          {
            [styles.selectButtonNotAllowed]: isDisabled,
          },
          className,
        )}
      >
        <ButtonModern
          style={isSmallInlineSelection ? {
            height: '1.7em', margin: '0 0px', padding: '5px', fontSize: '9pt',
          } : {}}
          styleType="select"
          onClick={() => this.onSelectModalToggle(true)}
          dataTest="vehicleFormAddClient"
          disabled={isDisabled}
          className={{
            [styles.selectButtonDisabled]: isDisabled,
          }}
        >
          {client.clientId ? (
            <>
              {linkDisabled || isDisabled ? (
                <FontAwesomeIcon icon={faUser} />
              ) : (
                <Link to={`/clients/all/${client.clientId}`}>
                  <OpenIcon className={styles.selectButtonOpen} />
                </Link>
              )}
              <div className={styles.selectButtonText}>
                {`${client.lastName} ${client.firstName}`}
              </div>
              {!isDisabled && (
                <FontAwesomeIcon
                  icon={faTimes}
                  className={styles.selectButtonCancel}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.onRemoveClient(event);
                  }}
                />
              )}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faUser} />
              <div style={{ display: 'flex' }}>
                {polyglot.t('editVehicle.form.selectClient.selectButton')}
                <div style={{ color: 'red' }}>
                  {isRequired && ' *'}
                </div>
              </div>
            </>
          )}
        </ButtonModern>
        {isModalClientsTableOpen && (
          <DataTableModal
            isOpen={isModalClientsTableOpen}
            onClose={() => this.setState({ isModalClientsTableOpen: !isModalClientsTableOpen })}
            onDataSelected={(values) => this.onClientSelected(values[0].entity)}
            selectTypes={[DataTableModal.ENTITIES.CLIENTS]}
          />
        )}
      </div>
    );
  }
}

export default SelectCustomer;
