import * as apiClient from 'resources/api.client';

export const buildUrl = (url) => `account${url}`;

export const signup = (body) => apiClient.post(buildUrl('/signup'), {}, body, { credentials: 'include' });
export const validateEmail = (body) => apiClient.post(buildUrl('/signup/validate-email'), {}, body);
export const signin = (body) => apiClient.post(buildUrl('/signin'), {}, body, { credentials: 'include' });
export const forgotPassword = (body) => apiClient.post(buildUrl('/forgot-password'), {}, body);
export const resetPassword = (body) => apiClient.post(buildUrl('/reset-password'), {}, body);
export const checkPasswordToken = (body) => apiClient.post(buildUrl('/check-password-token'), {}, body);
