import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

import polyglot from 'services/localization';

import { formatNumber } from 'helpers/invoiceNumberTemplate';
import toastService from 'helpers/toastService';
import parseError from 'helpers/parseError';
import NumberInputModern from 'components/common/numberInputModern';
import { updateNumber } from 'resources/garage/garage.actions';
import ButtonModern from 'components/common/buttonModern';
import ResponsiveModal from '../../common/responsiveModal';
import styles from './newYearModal.styles.pcss';

class NewYearModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    updateNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { invoiceSettings } = window.config.garage;
    this.state = {
      invoiceNumber: invoiceSettings.nextInvoiceNumber,
      cashbookNumber: invoiceSettings.nextCashbookNumber,
    };
  }

  validateNumber = (number) => number !== null;

  async onSave() {
    const { invoiceNumber, cashbookNumber } = this.state;
    const respOne = await this.saveNumber('nextInvoiceNumber', invoiceNumber);
    const respTwo = await this.saveNumber('nextCashbookNumber', cashbookNumber);

    if (respOne.err || respTwo.err) {
      toastService.showError(`${polyglot.t('newYearModal.updateError')} ${respOne.msg || respTwo.msg || ''}`);
    } else {
      toastService.showSuccess(`${polyglot.t('newYearModal.updateSuccess')}`);
      this.props.onClose();
    }
  }

  saveNumber = (name, value) => {
    return this.props.updateNumber(name, value)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        const msg = parseError(err);
        return { err: true, msg };
      });
  };

  render() {
    const { invoiceNumberTemplate } = window.config.garage.invoiceSettings;
    const { invoiceNumber, cashbookNumber } = this.state;

    return (
      <ResponsiveModal
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          title={polyglot.t('newYearModal.title')}
          width={400}
        >
        <div className={styles.formHeader}>
          {polyglot.t('newYearModal.contentHeader')}
        </div>
        <div className={styles.selectorContainer}>
          <div className={styles.selectorContainerElement}>
            <NumberInputModern
                className={cx(styles.formItem, styles.selectorContainerNumberSelector)}
                name="counter"
                label={polyglot.t('invoices.nextInvoiceNumber')}
                value={invoiceNumber}
                onChange={(newNumber) => { this.setState({ invoiceNumber: newNumber }); }}
                allowNegative={false}
                scaleType="integer"
                errorMessage={this.validateNumber(invoiceNumber) ? '' : polyglot.t('newYearModal.numberError')}
              />
            <div className={styles.selectorContainerNumber}>
              <div className={styles.selectorContainerNumberPreview}>
                {`${polyglot.t('actions.preview')}:`}
              </div>
              <div className={styles.selectorContainerNumberPreviewNumber}>
                {formatNumber(invoiceNumberTemplate, invoiceNumber)}
              </div>
            </div>
          </div>
          <div>
            <NumberInputModern
                className={cx(styles.formItem, styles.selectorContainerNumberSelector)}
                name="counter"
                label={polyglot.t('invoices.nextCashbookNumber')}
                value={cashbookNumber}
                onChange={(newNumber) => { this.setState({ cashbookNumber: newNumber }); }}
                allowNegative={false}
                scaleType="integer"
                errorMessage={this.validateNumber(cashbookNumber) ? '' : polyglot.t('newYearModal.numberError')}
              />
          </div>
        </div>
        <div className={styles.formInfo}>
          {polyglot.t('newYearModal.info')}
        </div>
        <div className={styles.selectorContainerButtons}>
          <ButtonModern
              styleType="add"
              onClick={() => this.onSave()}
              title={polyglot.t('actions.save')}
            >
            {polyglot.t('actions.save')}
          </ButtonModern>
          <ButtonModern
              styleType="select"
              onClick={this.props.onClose}
              title={polyglot.t('actions.cancel')}
            >
            {polyglot.t('actions.cancel')}
          </ButtonModern>
        </div>
      </ResponsiveModal>
    );
  }
}

export default connect(
  (state) => {},
  { updateNumber },
)(NewYearModal);
