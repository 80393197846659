import React from 'react';
import PropTypes from 'prop-types';

const TachoNeedle = (props) => (
  <svg width="19" height="54" viewBox="0 0 19 54" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className} transform={`rotate(${props.rotation})`}>
    <path d="M5.96194 51.7021L1.6976 47.4377C0.629812 46.3699 0.101806 44.8768 0.260988 43.3751L4.3481 4.8193C4.9826 -1.16628 13.7048 -1.13478 14.2961 4.85523L18.159 43.9896C18.3061 45.4798 17.7776 46.9575 16.7188 48.0163L13.033 51.7021C11.0804 53.6547 7.91457 53.6547 5.96194 51.7021Z" fill="#636977"/>
  </svg>
);

TachoNeedle.propTypes = {
  className: PropTypes.string,
  rotation: PropTypes.number,
};

export default TachoNeedle;
