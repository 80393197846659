/* eslint-disable camelcase */
import { getCustomerWithPaymentData } from 'resources/garage/garage.api';
import moment from 'moment';

export const validatePaymentData = async (garage, stripe, initial = true) => {
  // Get paymentData form API
  const customer = await getCustomerWithPaymentData(garage._id, garage.stripeObject.id);
  const [subscription] = customer.subscriptions.data;
  if (subscription && subscription.status !== 'active') {
    const { latest_invoice } = subscription;
    const { payment_intent } = latest_invoice;

    if (payment_intent) {
      const { client_secret, status } = payment_intent;
      const { invoice_settings: { default_payment_method: payment_method } } = customer;
      if (status === 'requires_action' || (initial && payment_method.type === 'card')) {
        const result = await stripe.confirmCardPayment(client_secret, {
          payment_method: payment_method.id,
        });
        if (result.error) {
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          // toastService.showError(result.error.message);
          // console.log('>>ERR: ', result.error);
        }
        return validatePaymentData(garage, stripe, false);
      } if (status === 'requires_confirmation' || (initial && payment_method.type === 'sepa_debit')) {
        const result = await stripe.confirmSepaDebitPayment(client_secret, {
          payment_method: payment_method.id,
          save_payment_method: true,
          setup_future_usage: 'off_session',
        });
        if (result.error) {
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
        }
        return validatePaymentData(garage, stripe, false);
      } if (status === 'requires_payment_method' || status === 'requires_confirmation') {
        return { success: false, message: status };
      }
      return { success: true, message: '' };
    }
    return { success: true, message: '' };
  }
  return { success: true, message: '' };
};

export const isGarageLocked = (garage) => {
  if (!garage) return null;

  const { unpaid } = garage;

  let isLocked = unpaid;
  if (garage.lockDate) {
    isLocked = moment().isAfter(moment(garage.lockDate, 'DD.MM.YYYY'));
  }
  return isLocked;
};
