export function getPositionGroups({ positionGroup }) {
  return positionGroup.list;
}

export function getTotalAmount({ positionGroup }) {
  return positionGroup.totalAmount;
}

export function getPositionGroupById({ positionGroup }, id) {
  return positionGroup.list.find((p) => p._id === id);
}
