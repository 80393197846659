import { last } from 'lodash/array';
import { updateAppByAction } from 'resources/utils';
import {
  FETCH_CLIENTS,
  FETCH_CLIENT,
  RESET_CLIENTS_STATE,
  CREATE_CLIENT,
  REMOVE_CLIENT,
  UPDATE_CLIENT,
  CHANGE_CLIENTS_FILTER,
  RESET_CURRENT_CLIENT,
  RESTORE_CLIENT,
} from './clients.actions';

const initialState = { list: [], currentClient: {}, totalAmount: 0 };
const actionTypesToUpdateApp = [CREATE_CLIENT, REMOVE_CLIENT, UPDATE_CLIENT];

export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case FETCH_CLIENTS: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }

    case FETCH_CLIENT:
      return {
        ...state,
        currentClient: action.payload,
      };

    case RESET_CLIENTS_STATE:
      return {
        ...state,
        list: [],
        totalAmount: 0,
      };

    case RESET_CURRENT_CLIENT:
      return {
        ...state,
        currentClient: {},
      };

    case UPDATE_CLIENT:
      return {
        list: state.list.map((client) => (client._id === action.payload._id
          ? { ...client, ...action.payload } : client)),
        currentClient: action.payload,
      };

    case REMOVE_CLIENT:
      return {
        ...state,
        list: state.list.filter((client) => !action.clientIds.includes(client._id)),
        totalAmount: state.totalAmount - action.clientIds.length,
      };

    case CHANGE_CLIENTS_FILTER:
      return {
        ...state,
        list: [...action.payload.results],
        totalAmount: action.payload.count,
      };

    case CREATE_CLIENT:
      return {
        ...state,
        currentClient: action.payload,
      };

    case RESTORE_CLIENT: {
      return {
        ...state,
        list: state.list.filter((client) => action.payload.client._id !== client._id),
        totalAmount: state.totalAmount - 1,
      };
    }
    default:
      return state;
  }
};
