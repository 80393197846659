import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { debounce } from 'lodash/function';
import inputStyles from 'styles/input.pcss';
import pageStyles from 'styles/page.pcss';
import polyglot from 'services/localization';
import InteractableDiv from '../interactableDiv';

export default class SearchInput extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onDebounce: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    totalAmount: PropTypes.number,
    maxLength: PropTypes.number,
    dataTest: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    placeholder: '',
    totalAmount: undefined,
    maxLength: 50,
    dataTest: '',
  };

  state = {
    showCancel: false,
  };

  componentDidMount() {
    this.focusInput();
    setTimeout(() => {
      if (this.searchInput) {
        this.focusInput();
      }
    }, 150);
  }

  onChange = ({ target }) => {
    const { onChange } = this.props;
    let newVal = target.value;
    if (newVal !== '' && newVal.trim() === '') newVal = '';
    onChange(newVal);
    this.setShowCancelState(target.value !== '');
    this.searchDebounce();
  };

  onClearClicked = () => {
    this.onChange({ target: { value: '' } });
    setTimeout(() => {
      this.focusInput();
    }, 100);
  };

  onInputFocus = () => {
    const { value } = this.props;
    if (value !== '') {
      this.setShowCancelState(true);
    }
  };

  onInputBlur = () => {
    setTimeout(() => {
      this.setShowCancelState(false);
    }, 100);
  };

  isHovering = (hoverState) => {
    const { value } = this.props;
    if (hoverState && value !== '') {
      this.setShowCancelState(true);
    } else if (!hoverState && this.searchInput !== document.activeElement) {
      this.setShowCancelState(false);
    }
  };

  focusInput() {
    this.searchInput.focus();
    this.searchInput.select();
  }

  setShowCancelState(active) {
    this.setState({ showCancel: active });
  }

  searchDebounce = debounce(() => {
    const { onDebounce } = this.props;
    onDebounce();
  }, 500, { leading: false, trailing: true });

  render() {
    const {
      className,
      placeholder,
      value,
      maxLength,
      totalAmount,
      dataTest,
    } = this.props;

    const { showCancel } = this.state;

    return (
      <Fragment>
        <div className={className}>
          <input
            className={cx(inputStyles.input, inputStyles.inputSearch)}
            onChange={this.onChange}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onMouseEnter={() => this.isHovering(true)}
            onMouseLeave={() => this.isHovering(false)}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            ref={(input) => { this.searchInput = input; }}
            data-test={dataTest}
          />
          <InteractableDiv
            className={cx(inputStyles.clearInputX)}
            onClick={this.onClearClicked}
            role="button"
            onMouseEnter={() => this.isHovering(true)}
            onMouseLeave={() => this.isHovering(false)}
            style={{ display: showCancel ? 'inline-block' : 'none' }}
          >
            X
          </InteractableDiv>
        </div>
        {!Number.isNaN(+totalAmount) && (
          <div className={cx(pageStyles.pageActionsSearchResult)}>
            {`${totalAmount} ${totalAmount > 1 ? polyglot.t('actions.results') : polyglot.t('actions.result')}`}
          </div>
        )}
      </Fragment>
    );
  }
}
