import * as React from 'react';
import PropTypes from 'prop-types';

export function format(iban) {
  if (!iban) {
    return '';
  }

  const cleanIban = iban
    .replace(/\s\s+/g, ' ')
    .replace(/[^0-9a-zA-Z]/gi, '')
    .toUpperCase();

  const parts = [];

  if (cleanIban.length > 0) {
    parts.push(cleanIban.substring(0, 4));
  }

  if (cleanIban.length > 4) {
    parts.push(cleanIban.substring(4, 8));
  }

  if (cleanIban.length > 8) {
    parts.push(cleanIban.substring(8, 12));
  }

  if (cleanIban.length > 12) {
    parts.push(cleanIban.substring(12, 16));
  }

  if (cleanIban.length > 16) {
    parts.push(cleanIban.substring(16, 20));
  }

  if (cleanIban.length > 20) {
    parts.push(cleanIban.substring(20, 24));
  }

  if (cleanIban.length > 24) {
    parts.push(cleanIban.substring(24, 28));
  }

  if (cleanIban.length > 28) {
    parts.push(cleanIban.substring(28, 32));
  }

  if (cleanIban.length > 32) {
    parts.push(cleanIban.substring(32, 34));
  }

  return parts.join(' ');
}

export class IbanInput extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    initialValue: PropTypes.string,
    type: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = this.ibanState(props.initialValue || '');
  }

  onChange = (event) => {
    const ibanInput = event.target.value || '';
    const newState = this.ibanState(ibanInput);

    this.setState(newState, () => {
      this.propUpdate(this.state.iban);
    });
  };

  ibanState = (input) => {
    const iban = input.replace(/[^0-9a-zA-Z]/gi, '').toUpperCase();

    return {
      iban: iban.substring(0, 35),
      formattedIban: format(iban),
    };
  };

  propUpdate = (iban) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(iban || '');
    }
  };

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      onChange, value, initialValue, ...restProps
    } = this.props;
    return <input type="text" onChange={this.onChange} value={this.state.formattedIban} size={42} {...restProps} />;
  }
}

export default IbanInput;
