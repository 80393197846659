export function getClients({ clients }) {
  return clients.list;
}

export function getTotalAmount({ clients }) {
  return clients.totalAmount;
}

export function getClientById({ clients }, clientId) {
  return clients.list.find((client) => client._id === clientId) || {};
}

export function getCurrentClient({ clients }) {
  return clients.currentClient;
}
