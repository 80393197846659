import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './switch.styles.pcss';

export default function Switch({
  name, label, value, onChange, className, tabIndex, onFocus, onBlur, dataTest, ...props
}) {
  const [isClickFocus, setIsClickFocus] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  return (
    <label
      htmlFor={name}
      className={styles.switch}
      data-test={dataTest}
    >
      <span
        className={cx(
          styles.switchFocusContainer,
          isFocused && !isClickFocus && styles.switchFocused,
        )}
      />
      <input
        tabIndex={tabIndex}
        id={name}
        className={styles.switchInput}
        name={name}
        type="checkbox"
        checked={value}
        onClick={() => setIsClickFocus(true)}
        onChange={onChange}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onChange(event);
            event.preventDefault();
            event.stopPropagation();
          }
        }}
        onFocus={(event) => {
          setIsFocused(true);
          onFocus(event);
        }}
        onBlur={(event) => {
          setIsFocused(false);
          setIsClickFocus(false);
          onBlur(event);
        }}
        {...props}
      />
      <span
        className={cx(styles.switchToggle, className, {
          [styles.switchToggleChecked]: value,
        })}
      />
      {label}
    </label>
  );
}

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  tabIndex: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  dataTest: PropTypes.string,
};

Switch.defaultProps = {
  className: '',
  label: '',
  tabIndex: 0,
  onFocus: () => {},
  onBlur: () => {},
  dataTest: '',
};
