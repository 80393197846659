import * as apiClient from 'resources/api.client';

export function fetchSettings(garageId) {
  return apiClient.get('printing-settings', { garageId });
}

export function updateSettings(garageId, data) {
  return apiClient.put('printing-settings', { garageId }, data);
}

export function generateInvoicePreview(garageId, data) {
  return apiClient.post('printing-settings/generate-invoice-preview', { garageId }, data);
}

export function generateInternalPreview(garageId, data) {
  return apiClient.post('printing-settings/generate-internal-preview', { garageId }, data);
}

export function generateReceiptPreview(garageId, data) {
  return apiClient.post('printing-settings/generate-receipt-preview', { garageId }, data);
}

export function getReceiptPreviewPdf(garageId) {
  return apiClient.get('printing-settings/receipt-preview', { garageId });
}

export function getInternalPreviewPdf(garageId) {
  return apiClient.get('printing-settings/internal-preview', { garageId });
}

export function getInvoicePreviewPdf(garageId) {
  return apiClient.get('printing-settings/invoice-preview', { garageId });
}

export const getCanPrintnodeBeUsed = (garageId, status) => apiClient.get('printing-settings/can-printnode-be-used', { garageId, status });
