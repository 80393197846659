import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DatePickerComponent, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { it, de, enGB } from 'date-fns/locale';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { FaCalendar } from 'react-icons/fa';

import { DATE_FORMAT_MOMENT, DATE_FORMAT } from 'constants.js';
import FormValidator from 'helpers/formValidation/formValidator';
import { getCorrectDate } from 'helpers/date';
import ValidatableComponent from 'helpers/formValidation/validatableComponent';

import polyglot from 'services/localization';

import '!style-loader!css-loader!./datePickerModern.override.pcss';
import styles from './datePickerModern.styles.pcss';

registerLocale('it', it);
registerLocale('de', de);
registerLocale('en-gb', enGB);
setDefaultLocale('de');

const DATEPICKER_LOCALE = {
  en_US: 'en-gb', // eslint-disable-line camelcase
  de_DE: 'de', // eslint-disable-line camelcase
  it_IT: 'it', // eslint-disable-line camelcase
  fr_FR: 'fr', // eslint-disable-line camelcase
};

const beforeMaskedValueChange = (newState, oldState) => {
  let { value, selection } = newState;
  if (newState.value !== oldState.value) {
    if (value[0] !== '_') {
      if (value[0] > '3') {
        value = `0${value[0]}${oldState.value.substring(2)}`;
        selection = { start: 3, end: 3 };
      } else if (value[0] === '3') {
        if (value[1] !== '_' && value[1] > '1') {
          if (selection.start === 1 && selection.end === 1) {
            value = `30${oldState.value.substring(2)}`;
            selection = { start: 1, end: 1 };
          } else {
            value = `0${value[1]}${oldState.value.substring(2)}`;
            selection = { start: 3, end: 3 };
          }
        }
      }
    }
    if (value[3] !== '_' && value[3] > '1') {
      value = `${value.substring(0, 3)}0${value[3]}${oldState.value.substring(5)}`;
      selection = { start: 6, end: 6 };
    }
    if (value[3] === '1' && value[4] !== '_' && value[4] > '2') {
      if (selection.start === 6 && selection.end === 6) {
        value = `${value.substring(0, 3)}0${value[4]}${oldState.value.substring(5)}`;
      } else {
        value = `${value.substring(0, 3)}${value[3]}0${oldState.value.substring(5)}`;
      }
    }
  }

  return {
    value,
    selection,
  };
};

const DateInput = ({
  isValid,
  onClick,
  index,
  isReadOnly,
  value,
  onChange,
  onBlur,
  onFocus,
  onCustomFocus,
  onCustomBlur,
  onMouseEnter,
  onMouseLeave,
  dataTest,
  dateInputClassName,
}) => (
  <InputMask
    className={cx(styles.datePickerField, {
      [styles.datePickerFieldError]: !isValid,
    }, dateInputClassName)}
    value={value}
    mask="99.99.9999"
    beforeMaskedValueChange={beforeMaskedValueChange}
    placeholder={polyglot.t('datePicker.placeholder')}
    onBlur={(event) => {
      onCustomBlur(event);
      onChange(event);
    }}
    onFocus={(event) => {
      onCustomFocus(event);
    }}
    onChange={onChange}
    onClick={onClick}
    tabIndex={index}
    readOnly={isReadOnly}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    data-test={dataTest}
  />
);

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  isValid: PropTypes.bool,
  index: PropTypes.number,
  isReadOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onCustomFocus: PropTypes.func,
  onCustomBlur: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  dateInputClassName: PropTypes.string,
  dataTest: PropTypes.string,
};

DateInput.defaultProps = {
  value: '',
  onChange: () => {},
  onClick: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onCustomFocus: () => {},
  onCustomBlur: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  isValid: true,
  index: 0,
  isReadOnly: false,
  dateInputClassName: '',
  dataTest: '',
};

export default class DatePickerModern extends ValidatableComponent {
  static propTypes = {
    className: PropTypes.string,
    dateInputClassName: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    errorMessage: PropTypes.string,
    tabIndex: PropTypes.number,
    readOnly: PropTypes.bool,
    isRow: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    formValidator: FormValidator.getShape(),
    dataTest: PropTypes.string,
    isValid: PropTypes.bool,
    popperPositionFixed: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    label: '',
    value: '',
    isRequired: false,
    errorMessage: '',
    tabIndex: 0,
    readOnly: false,
    isRow: false,
    startDate: '',
    endDate: '',
    formValidator: null,
    dataTest: '',
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
    isValid: true,
    popperPositionFixed: false,
  };

  render() {
    const {
      className,
      name,
      label,
      isRequired,
      value,
      tabIndex,
      readOnly,
      isRow,
      startDate,
      endDate,
      formValidator,
      dataTest,
      dateInputClassName,
      popperPositionFixed,
      ...props
    } = this.props;

    const hasError = super.hasError();
    let currValue = formValidator ? formValidator.value : value;
    currValue = getCorrectDate(currValue);

    let parsedDateObject = moment(currValue, DATE_FORMAT_MOMENT).toDate();
    if (Number.isNaN(parsedDateObject.getTime())) parsedDateObject = null;

    const popperProps = {};
    if (popperPositionFixed) popperProps.strategy = 'fixed';

    return (
      <div className={cx(className, styles.datePicker)}>
        <label
          htmlFor={name}
          className={cx(styles.datePickerLabel, {
            [styles.datePickerLabelColumn]: !isRow,
          })}
        >
          {label && (
            <div className={styles.datePickerLabelText}>
              <div>{label}</div>
              {isRequired && <div className={styles.datePickerRequiredMark}>*</div>}
            </div>
          )}
        </label>
        <div className={styles.datePickerContainer}>
          <DatePickerComponent
            name={name}
            selected={currValue === '' ? null : parsedDateObject}
            locale={DATEPICKER_LOCALE[window.config.user.locale]}
            dateFormat={DATE_FORMAT}
            onChangeRaw={({ target: { value: selectedValue } }) => {
              if (!readOnly) {
                if (formValidator) {
                  formValidator.set(selectedValue);
                }
                this.onChange(selectedValue);
              }
            }}
            onSelect={(date) => {
              const newDate = date === null ? '' : moment(date).format(DATE_FORMAT_MOMENT);
              if (formValidator) {
                formValidator.set(newDate);
              }
              this.onChange(newDate);
            }}
            customInput={(
              <DateInput
                isValid={!hasError}
                index={tabIndex}
                isReadOnly={readOnly}
                onCustomFocus={this.onFocus}
                onCustomBlur={this.onBlur}
                onMouseEnter={(e) => this.setState({ isHovered: true })}
                onMouseLeave={(e) => this.setState({ isHovered: false })}
                dataTest={dataTest}
                dateInputClassName={dateInputClassName}
              />
            )}
            value={currValue}
            startDate={startDate}
            endDate={endDate}
            popperProps={popperProps}
            {...props}
          />
        </div>
        <FaCalendar
          className={styles.datePickerIcon}
        />

        {super.renderTooltip()}
      </div>
    );
  }
}
