import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import modalStyles from 'styles/modal.pcss';
import cx from 'classnames';
import ButtonModern from 'components/common/buttonModern';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import toastService from 'helpers/toastService';
import parseError from 'helpers/parseError';
import styles from './confirmModal.styles.pcss';
import ResponsiveModal from '../responsiveModal';

export default class ConfirmModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onProceed: PropTypes.func,
    isDelete: PropTypes.bool,
    isMoveToRecycleBin: PropTypes.bool,
    customConfirmButtonText: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    onProceed: null,
    isDelete: false,
    isMoveToRecycleBin: false,
    customConfirmButtonText: null,
    children: null,
    style: {},
  };

  state = {
    isLoading: false,
  };

  onProceed = async () => {
    const { onProceed, onClose } = this.props;
    if (onProceed) {
      this.setState({ isLoading: true });
      try {
        await onProceed();
        onClose('proceed');
      } catch (err) {
        const msg = parseError(err);
        toastService.showError(msg || polyglot.t('genericError'));
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  render() {
    const {
      isOpen,
      title,
      text,
      onClose,
      onProceed,
      isDelete,
      customConfirmButtonText,
      children,
      isMoveToRecycleBin,
      style,
    } = this.props;

    const { isLoading } = this.state;

    return (
      <ResponsiveModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        isDelete={isDelete}
        isMoveToRecycleBin={isMoveToRecycleBin}
        customConfirmButtonText={customConfirmButtonText}
      >
        <div style={style} className={modalStyles.containerModern}>
          {text}
          {children && (
            <div>
              {children}
            </div>
          )}
        </div>

        <div className={modalStyles.footerModern}>
          {isDelete || isMoveToRecycleBin ? (
            <ButtonModern styleType="delete" onClick={this.onProceed} dataTest="removeConfirmButton" isLoading={isLoading}>
              <DeleteIcon
                className={cx(styles.deleteIcon)}
              />
              {customConfirmButtonText ? (<Fragment>{customConfirmButtonText}</Fragment>) : (polyglot.t(isMoveToRecycleBin ? 'actions.yesMoveToRecycleBin' : 'actions.yesDelete'))}
            </ButtonModern>
          ) : (
            <ButtonModern styleType="add" dataTest="confirmButton" onClick={this.onProceed} isLoading={isLoading}>
              {customConfirmButtonText ? (<Fragment>{customConfirmButtonText}</Fragment>) : polyglot.t('actions.yes')}
            </ButtonModern>
          )}

          {onProceed && (
            <ButtonModern styleType="cancel" dataTest="cancelButton" onClick={onClose}>
              {polyglot.t('actions.cancel')}
            </ButtonModern>
          )}
        </div>
      </ResponsiveModal>
    );
  }
}
