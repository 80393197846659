import React from 'react';

const BlobV3 = () => {
  return (
    <svg width="135" height="132" viewBox="0 0 135 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M85.3326 130.599C71.8184 133.049 58.7627 126.282 46.5726 119.954C35.9808 114.456 27.8988 106.125 20.3605 96.8741C12.1928 86.8502 2.92557 77.0637 1.18453 64.2513C-0.715064 50.2721 2.33845 35.5546 10.4605 24.0195C18.7277 12.2782 31.6045 3.97275 45.6687 1.07376C59.0192 -1.67811 71.5772 5.49062 84.8275 8.69052C99.9067 12.3321 118.825 8.95567 128.504 21.0783C138.193 33.2133 132.457 51.0025 130.951 66.4577C129.573 80.5971 128.4 95.034 120.166 106.611C111.79 118.388 99.5526 128.021 85.3326 130.599Z" fill="#AAD9D0" />
    </svg>
  );
};

export default BlobV3;
