const { config } = window;

export const fetchPrinters = (printNodeId) => {
  return fetch(`${config.printNode.url}/printers`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Buffer.from(`${config.printNode.Username}:`).toString('base64')}`,
      'X-Child-Account-By-Id': printNodeId,
    },
  })
    .then((response) => response.json());
};
