/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Icon from 'components/common/icon/Icon';
import { FaBars } from 'react-icons/fa';
import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { faUserPlus, faCalendarPlus } from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import withDeviceInfo from 'components/higherOrderComponents/withDeviceInfo';
import polyglot from 'services/localization';

import styles from './fabNavigation.styles.pcss';

class FabNavigation extends Component {
  static fabActions = [
    {
      id: 'addClient',
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      pathname: '/clients/new',
    },
    {
      id: 'addVehicle',
      icon: <Icon type="carPlus" />,
      pathname: '/vehicles/new',
    },
    {
      id: 'ocr',
      icon: <i className="fak fa-ocr" />,
      pathname: '/clients/all/create-with-document',
    },
    {
      id: 'addInvoice',
      icon: <Icon type="filePlus" />,
      pathname: '/invoices/draft/new',
      experimental: true,
    },
    {
      id: 'addCalendarEvent',
      icon: <FontAwesomeIcon icon={faCalendarPlus} />,
      pathname: '/calendar/new',
    },
  ];

  static propTypes = {
    onBurgerClicked: PropTypes.func,
    mobileViewOption: PropTypes.string,
    isMobileSize: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onBurgerClicked: () => null,
    mobileViewOption: 'optionOne',
  };

  constructor(props) {
    super(props);
    this.state = {
      fabActionsShown: false,
    };
  }

  render() {
    const { onBurgerClicked, mobileViewOption, isMobileSize } = this.props;
    const { fabActionsShown } = this.state;

    if (!isMobileSize) return null;

    return (
      <div className={styles.fabNavigation}>
        <div
          className={cx(styles.fabNavigationFab, styles.fabNavigationMenu)}
          onClick={onBurgerClicked}
        >
          <FaBars />
        </div>
        <div
          onClick={() => this.setState({ fabActionsShown: false })}
          className={cx(styles.fabNavigationSelectionBackground, {
            [styles.fabNavigationSelectionBackgroundVisible]: fabActionsShown,
          })}
        />
        <div
          className={cx(styles.fabNavigationFab, styles.fabNavigationQuickActions)}
          onClick={() => this.setState({ fabActionsShown: !fabActionsShown })}
        >
          {fabActionsShown && (
            <div
              className={cx(styles.fabNavigationFabContent)}
            >
              {FabNavigation.fabActions.map((action, index) => {
                if (action.experimental && mobileViewOption !== 'optionTwo') return null;
                return (
                  <Link
                    key={index}
                    className={styles.fabNavigationFabContentEntry}
                    to={action.pathname}>
                    <div className={styles.fabNavigationFabContentFabContainer}>
                      <div className={styles.fabNavigationFabContentFab}>
                        {action.icon}
                      </div>
                    </div>
                    <div className={styles.fabNavigationFabContentLabel}>
                      {polyglot.t(`fabNavigation.actions.${action.id}`)}
                    </div>
                  </Link>
                );
              })}
            </div>
          )}
          <Icon
            type="plus"
            className={cx({
              [styles.fabNavigationQuickActionsActive]: fabActionsShown,
              [styles.fabNavigationQuickActionsInactive]: fabActionsShown === false,
            })}
          />
        </div>
      </div>
    );
  }
}

export default withDeviceInfo(FabNavigation);
