import { connect } from 'react-redux';
import { fetchManufacturers } from 'resources/manufacturers/manufacturers.actions';
import { getManufacturersDropdown } from 'resources/manufacturers/manufacturers.selectors';
import * as fromGarage from 'resources/garage/garage.selectors';

import VehicleForm from './vehicleForm';

export default connect((state) => ({
  manufacturers: getManufacturersDropdown(state),
  country: fromGarage.getCountry(state),
  garage: fromGarage.getGarage(state),
  defaultCarClass: fromGarage.getGarageType(state),
}), {
  fetchManufacturers,
})(VehicleForm);
