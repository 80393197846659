import { last } from 'lodash/array';
import {
  GET_POSITION_GROUPS,
  CREATE_POSITION_GROUP,
  RESET_POSITION_GROUPS_STATE,
  REMOVE_POSITION_GROUP,
  UPDATE_POSITION_GROUP,
  CHANGE_POSITION_GROUPS_FILTER,
} from './positionGroup.actions';

const initialProductsCount = {
  product: null,
  work: null,
  other: null,
  positionGroups: null,
  suppliers: null,
  all: null,
};

const initialState = {
  list: [],
  report: [],
  totalAmount: 0,
  productsCounts: { ...initialProductsCount },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POSITION_GROUP:
      return {
        ...state,
        list: [...state.list, action.payload],
        totalAmount: state.totalAmount + 1,
      };
    case GET_POSITION_GROUPS:
      // do not update if case of multiple identical responses
      // eslint-disable-next-line no-case-declarations
      const lastFromState = last(state.list);
      // eslint-disable-next-line no-case-declarations
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }
      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
        productsCounts: action.data.productsCounts || { ...initialProductsCount },
      };
    case RESET_POSITION_GROUPS_STATE:
      return {
        ...initialState,
      };
    case REMOVE_POSITION_GROUP:
      return {
        ...state,
        list: state.list.filter((p) => !action.groupId.includes(p._id)),
        totalAmount: state.totalAmount - 1,
      };
    case CHANGE_POSITION_GROUPS_FILTER:
      return {
        ...state,
        list: action.payload.results || [],
        totalAmount: action.payload.count,
        productsCounts: action.data.productsCounts || { ...initialProductsCount },
      };
    case UPDATE_POSITION_GROUP:
      return {
        ...state,
        list: state.list.map((p) => (
          p._id === action.groupId ? action.payload : p
        )),
      };
    default:
      return state;
  }
};
