import * as apiClient from 'resources/api.client';

export const fetchTires = (garageId, data) => apiClient.get('tires', { garageId, ...data });

export function createTire(garageId, data) {
  return apiClient.post('tires', { garageId }, data);
}

export function removeTires(garageId, tireIds) {
  return apiClient.post('tires/remove', { garageId }, { tireIds });
}

export function updateTire(garageId, data) {
  return apiClient.put(`tires/${data._id}`, { garageId }, data);
}

export function getTiresCSV(garageId, data) {
  return apiClient.get('tires/csv', { garageId, ...data });
}

export function validateTireStorage(garageId, { storageLocation, _id }) {
  return apiClient.get('tires/validate-storage', { garageId, storageLocation, _id });
}

export function printStorageConfirmation(garageId, tireId) {
  return apiClient.get(`tires/${tireId}/print-confirmation`, { garageId });
}

export function getConfirmationPdfUrl(garageId, tireId) {
  return apiClient.get(`tires/${tireId}/get-pdf-url`, { garageId });
}

export function getCurrentVehicleTire(garageId, vehicleId) {
  return apiClient.get('tires/current', { garageId, vehicleId }).catch((err) => {
    const errObj = JSON.parse(err.message);
    if (errObj.status === 403) {
      return new Promise(() => {});
    }

    throw err;
  });
}
