import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import inputStyles from 'styles/input.pcss';
import IbanInput from 'components/common/ibanInput';
import styles from './input.styles.pcss';

export default class Input extends PureComponent {
  componentDidUpdate() {
    if (this.props.type !== 'iban') {
      if (
        this.inputRef.current.type !== 'email'
        && document.activeElement === this.inputRef.current
      ) {
        this.inputRef.current.setSelectionRange(
          this.caretPosition,
          this.caretPosition,
        );
      }
    }
  }

  inputRef = React.createRef();

  caretPosition = 0;

  onChange = (event) => {
    const { onChange } = this.props;
    onChange(event);

    if (this.props.type !== 'iban') {
      const { selectionEnd } = event.target;
      this.caretPosition = selectionEnd;
    }
  };

  render() {
    const {
      name,
      label,
      errorMessage,
      isValid,
      className,
      inputClassName,
      labelClassName,
      value,
      icon,
      isUppercase,
      isRequired,
      type,
      tabIndex,
      isRow,
      inputClasses,
      dataTest,
      ...props
    } = this.props;

    return (
      <div className={cx(styles.input, className)}>
        <label
          htmlFor={name}
          className={cx(styles.inputLabel, {
            [styles.inputLabelColumn]: !isRow,
          })}
        >
          {label ? (
            <span className={cx(styles.inputLabelText, labelClassName)}>
              {label}
              {isRequired ? <div className={styles.requiredMark}>*</div> : null}
            </span>
          ) : null}
          <div className={cx(styles.inputContainer, inputClassName)}>
            {icon}
            {type === 'iban' ? (
              <IbanInput
                className={cx(
                  styles.inputField,
                  styles.inputFieldBorder,
                  {
                    [styles.inputFieldInvalid]: !isValid,
                    [inputStyles.inputIsUppercase]: isUppercase,
                    [styles.inputFieldDate]: type === 'date',
                    [styles.inputFieldDefault]: type !== 'date',
                  },
                  inputClasses,
                )}
                name={name}
                initialValue={value}
                type="text"
                {...props}
                tabIndex={tabIndex}
                ref={this.inputRef}
                onChange={this.onChange}
              />
            ) : (
              <input
                className={cx(
                  styles.inputField,
                  styles.inputFieldBorder,
                  {
                    [styles.inputFieldInvalid]: !isValid,
                    [inputStyles.inputIsUppercase]: isUppercase,
                    [styles.inputFieldDate]: type === 'date',
                    [styles.inputFieldDefault]: type !== 'date',
                  },
                  inputClasses,
                )}
                name={name}
                id={name}
                value={value}
                type={type}
                data-test={dataTest}
                {...props}
                tabIndex={tabIndex}
                ref={this.inputRef}
                onChange={this.onChange}
              />
            )}
          </div>
        </label>
        {!errorMessage || errorMessage === true ? null : (
          <div className={styles.inputError}>{isValid ? '' : errorMessage}</div>
        )}
      </div>
    );
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  isValid: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  icon: PropTypes.element,
  isUppercase: PropTypes.bool,
  isRequired: PropTypes.bool,
  isRow: PropTypes.bool,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  inputClasses: PropTypes.string,
  dataTest: PropTypes.string,
};

Input.defaultProps = {
  label: '',
  errorMessage: '',
  isValid: true,
  className: '',
  inputClassName: '',
  labelClassName: '',
  value: '',
  icon: null,
  onChange: () => {},
  isUppercase: false,
  isRequired: false,
  tabIndex: 0,
  type: '',
  isRow: false,
  inputClasses: '',
  dataTest: '',
};
