import * as fromUser from 'resources/user/user.selectors';
import * as api from './manufacturers.api';

export const FETCH_MANUFACTURERS = 'fetchManufacturers';

export const fetchManufacturers = () => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  return api.fetchManufacturers(garageId)
    .then((payload) => dispatch({ type: FETCH_MANUFACTURERS, payload }));
};
