import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';

import SearchInput from 'components/common/searchInput';
import NoEntities from 'components/common/noEntities';
import TableLoading from 'components/common/loading/components/tableLoading/tableLoading';
import Retry from 'components/common/retry';
import _ from 'lodash';
import modalStyles from 'styles/modal.pcss';
import ButtonModern from 'components/common/buttonModern';
import Button from '../button';
import styles from './clientsTableModal.styles';
import ClientForm from '../clientForm';
import EntitiesList from '../entitiesList';
import NoEntity from '../noEntity';
import ResponsiveModal from '../responsiveModal';
import ClientInfo from '../clientInfo';

const getClientData = (client) => {
  const _id = client._id || client.clientId || client.recipientId;

  return _id ? { ...client, _id } : undefined;
};

const getFormattedLabel = (strings, ...values) => values
  .reduce((result, value, index) => (value
    ? `${result}${value}${strings[index + 1]}`
    : result), '');

const getListItemLabel = ({
  lastName,
  firstName,
  address,
  zipCode,
  city,
}) => {
  return getFormattedLabel`${lastName} ${firstName}, ${address}, ${zipCode}-${city}`;
};

class ClientsTableModal extends PureComponent {
  static propTypes = {
    fetchClients: PropTypes.func.isRequired,
    changeClientsFilter: PropTypes.func.isRequired,
    resetClientsState: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClientSelected: PropTypes.func.isRequired,
    onRemoveClient: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    client: PropTypes.shape({}).isRequired,
    isInvoice: PropTypes.bool,
    isNoCustomerDisabled: PropTypes.bool,
    updateClient: PropTypes.func.isRequired,
    createClient: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isInvoice: false,
    isNoCustomerDisabled: false,
  };

  state = {
    searchText: '',
    clients: [],
    selectedClient: undefined,
    totalAmount: 0,
    isNoClients: false,
    isFetchLoading: false,
    isFetchError: false,
    isChangeFilterLoading: false,
    isChangeFilterError: false,
    isShowClientsForm: false,
  };

  componentDidMount = () => {
    this.fetch();
  };

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (isOpen !== prevProps.isOpen) {
      this.preselectCustomer();
      this.onSearchChange('');
      this.changeFilter({ searchText: '' });
    }
  }

  componentWillUnmount = () => {
    const { resetClientsState } = this.props;
    resetClientsState();
  };

  preselectCustomer() {
    const { client } = this.props;

    this.setState({
      selectedClient: getClientData(client),
    });
  }

  onSearchChange = (searchText) => {
    this.setState((prevState) => ({
      searchText,
      isChangeFilterLoading: searchText !== prevState.searchText,
    }));
  };

  onCancelClientEdit = (reset) => {
    const { selectedClient } = this.state;
    const client = selectedClient._id ? selectedClient : undefined;

    reset();
    this.setState({
      selectedClient: client,
    });
  };

  onSearchDebounce = () => {
    const { searchText } = this.state;
    this.changeFilter({ searchText });
  };

  onScroll = () => {
    const {
      searchText,
      clients,
      totalAmount,
    } = this.state;
    const { fetchClients } = this.props;

    if (clients.length >= totalAmount) {
      return Promise.resolve();
    }

    return fetchClients({
      skip: clients.length,
      searchText,
    }).then(({ payload: { results } }) => this.setState((prevState) => ({
      clients: [...prevState.clients, ...results],
    })));
  };

  onRetryChangeFilter = () => {
    this.setState({ isChangeFilterLoading: true });
    const { searchText } = this.state;
    this.changeFilter({ searchText });
  };

  fetch = () => {
    const { fetchClients } = this.props;
    const { searchText } = this.state;

    this.setState({ isFetchLoading: true });
    fetchClients({ searchText })
      .then(({ payload: { results, count, isExists } }) => this.setState({
        clients: results,
        totalAmount: count,
        isNoClients: !isExists,
        isFetchLoading: false,
        isFetchError: false,
      }))
      .catch(() => this.setState({ isFetchError: true, isFetchLoading: false }));
  };

  changeFilter = ({ searchText }) => {
    const { changeClientsFilter } = this.props;

    changeClientsFilter({ searchText })
      .then(({ payload: { results, count, isExists } }) => this.setState({
        clients: results,
        totalAmount: count,
        isNoClients: !isExists,
        isChangeFilterLoading: false,
        isChangeFilterError: false,
      }))
      .catch(() => this.setState({ isChangeFilterLoading: false, isChangeFilterError: true }));
  };

  renderClientsTable = () => {
    const { onClientSelected } = this.props;
    const {
      isFetchLoading,
      isChangeFilterLoading,
      isChangeFilterError,
      isNoClients,
      isFetchError,
      clients,
      selectedClient,
    } = this.state;

    if (isFetchLoading) {
      return <TableLoading withoutBorder />;
    }

    if (isFetchError) {
      return <Retry isSmall onRetry={this.fetch} withoutBorder />;
    }

    if (isNoClients) {
      return <NoEntities withoutBorder noEntitiesText={polyglot.t('clients.thereAreNoClients')} />;
    }

    if (!isChangeFilterLoading && isChangeFilterError) {
      return <Retry isSmall withoutBorder onRetry={this.onRetryChangeFilter} />;
    }

    if (!clients.length) {
      return <NoEntities withoutBorder noEntitiesText={polyglot.t('clients.notFound')} />;
    }

    return (
      <EntitiesList
        entities={clients}
        onScroll={this.onScroll}
        onSelect={this.onClientSelect}
        onDoubleClick={onClientSelected}
        isDataLoading={isChangeFilterLoading}
        selectedEntity={selectedClient}
        getLabel={getListItemLabel}
      />
    );
  };

  renderFormButtons = (clientForm) => {
    const { selectedClient: { _id } } = this.state;
    const { onClientSelected } = this.props;
    if (!_id || clientForm.isDirty) {
      return (
        <Fragment>
          <ButtonModern
            styleType="add"
            type="submit"
            disabled={clientForm.isSubmitting}
            dataTest="clientSaveButton"
          >
            {_id ? polyglot.t('actions.saveAndSelect') : polyglot.t('actions.createAndSelect')}
          </ButtonModern>

          <ButtonModern styleType="cancel" onClick={() => this.onCancelClientEdit(clientForm.reset)}>
            {polyglot.t('actions.cancel')}
          </ButtonModern>
        </Fragment>
      );
    }

    return (
      <>
        <ButtonModern
          styleType="add"
          onClick={() => {
            onClientSelected(clientForm.getValues());
            this.setState({ isShowClientsForm: false });
          }}
        >
          {polyglot.t('actions.select')}
        </ButtonModern>

        <ButtonModern
          styleType="cancel"
          onClick={() => {
            this.setState({ isShowClientsForm: false });
          }}
        >
          {polyglot.t('actions.cancel')}
        </ButtonModern>
      </>
    );
  };

  onClientSelect = (client) => {
    const { searchText } = this.state;

    this.setState({
      selectedClient: client,
      searchText: (client && client._id) ? searchText : '',
    }, () => {
      const { searchText: newSearchText } = this.state;

      if (searchText !== newSearchText) {
        this.onRetryChangeFilter();
      }
    });
  };

  onRemoveClient = (e) => {
    const { onRemoveClient, onCancel } = this.props;

    onRemoveClient();
    onCancel(e);
  };

  updateClient = (values) => {
    const { updateClient, onClientSelected } = this.props;
    const { selectedClient: { _id }, clients } = this.state;

    return updateClient({ _id, ...values }).then(({ payload }) => {
      this.setState({
        clients,
      });

      onClientSelected(payload);
    });
  };

  createClient = (values) => {
    const { createClient, onClientSelected } = this.props;

    return createClient(values).then(({ payload }) => {
      this.setState({
        selectedClient: payload,
      });

      onClientSelected(payload);
    });
  };

  onSubmitForm = (values, clientForm) => {
    const { selectedClient } = this.state;

    this.setState({ isShowClientsForm: false });
    if (selectedClient._id) {
      return this.updateClient(values).then(this.fetch);
    }

    return this.createClient(values).then(this.fetch);
  };

  searchOnBlur = ({
    lastName = '',
    firstName = '',
    address = '',
    phone = '',
    email = '',
  }) => {
    const searchText = [
      lastName,
      firstName,
      address,
      phone,
      email,
    ].join(' ').trim();

    this.onSearchChange(searchText);
    this.changeFilter({ searchText });
  };

  render() {
    const {
      isOpen,
      onCancel,
      isInvoice,
      isNoCustomerDisabled,
      onClientSelected,
    } = this.props;
    const {
      totalAmount, searchText, selectedClient, isShowClientsForm,
    } = this.state;
    return (
      <ResponsiveModal
        isOpen={isOpen}
        title={polyglot.t('editVehicle.clientsModal.title')}
        onClose={onCancel}
        width={1050}
        height={800}
      >
        <div className={styles.clients} data-test="addClientToVehicleModal">
          <div className={styles.clientsListWrapper}>
            <div className={modalStyles.actions}>
              <Button
                styleType="remove"
                className={styles.clientsActionButton}
                onClick={() => this.onClientSelect()}
              >
                {polyglot.t('actions.noCustomer')}
              </Button>
              <Button
                styleType="add"
                className={styles.clientsActionButton}
                onClick={() => this.onClientSelect({})}
                dataTest="createNewClientVehicleModal"
              >
                {polyglot.t('actions.createCustomer')}
              </Button>
            </div>
            <div className={modalStyles.searchBlock}>
              <SearchInput
                onChange={this.onSearchChange}
                onDebounce={this.onSearchDebounce}
                placeholder={polyglot.t('actions.searchPlaceholder')}
                value={searchText}
                totalAmount={totalAmount}
                className={modalStyles.searchBlockInput}
              />
            </div>
            <div className={styles.hint}>
              {polyglot.t('clients.doubleClickHint')}
            </div>
            {this.renderClientsTable()}
          </div>
          <div className={styles.clientsForm}>
            {selectedClient ? (
              <>
                {isShowClientsForm || _.isEmpty(selectedClient) ? (
                  <ClientForm
                    client={selectedClient}
                    onSubmit={this.onSubmitForm}
                    renderButtons={this.renderFormButtons}
                    searchOnBlur={this.searchOnBlur}
                    isTwoColumnLayout
                  />
                ) : (
                  <div className={styles.clientsInfoWrapper}>
                    <ClientInfo client={selectedClient} isDetailView={false} />
                    <div className={styles.clientsInfoButtons}>
                      <Button styleType="remove" onClick={() => this.setState({ isShowClientsForm: true })}>
                        {polyglot.t('actions.edit')}
                      </Button>
                      <Button styleType="add" onClick={() => onClientSelected(selectedClient)}>
                        {polyglot.t('actions.select')}
                      </Button>
                    </div>
                  </div>
                )}

              </>
            ) : (
              <NoEntity
                description={isInvoice
                  ? polyglot.t('clients.noCustomerDescriptionInvoice')
                  : polyglot.t('clients.noCustomerDescription')}
                createButtonLabel={polyglot.t('actions.createCustomer')}
                selectButtonLabel={polyglot.t('actions.selectNoCustomer')}
                onCreate={this.onClientSelect}
                onRemove={this.onRemoveClient}
                isSelectDisabled={isNoCustomerDisabled}
              />
            )}
          </div>
        </div>
      </ResponsiveModal>
    );
  }
}

export default ClientsTableModal;
