import React from 'react';

const Blob = () => {
  return (
    <svg width="799" height="628" viewBox="0 0 799 628" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M792.227 216.252C818.66 310.609 761.951 404.411 704.199 485.374C656.373 552.423 586.14 600.658 503.327 621.267C427.918 640.034 354.837 613.341 280.599 590.796C188.978 562.972 76.4738 557.151 30.1027 477.556C-19.5267 392.368 -3.77429 279.714 51.6134 197.748C102.478 122.476 208.254 116.138 296.334 83.5729C388.498 49.4984 474.708 -19.3646 569.939 6.11506C674.831 34.1796 764.36 116.778 792.227 216.252Z" fill="#E3EFED" />
    </svg>
  );
};

export default Blob;
