import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InfiniteBlock from 'components/common/infiniteBlock';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import {
  faChevronDown as dropdownIcon,
  faXmark as closeIcon,
} from 'fontawesome/pro-solid-svg-icons';
import Downshift from 'downshift';
import InputModern from 'components/common/inputModern';
import ValidatableComponent from 'helpers/formValidation/validatableComponent';
import FormValidator from 'helpers/formValidation/formValidator';

import styles from './inputDropdownModern.styles.pcss';

export default class inputDropdownModern extends ValidatableComponent {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })).isRequired,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    isRequired: PropTypes.bool,
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
    tabIndex: PropTypes.number,
    preselectFirstItem: PropTypes.bool,
    allowCustom: PropTypes.bool,
    onBlur: PropTypes.func,
    inputClassName: PropTypes.string,
    inputClasses: PropTypes.string,
    formValidator: FormValidator.getShape(),
    dataTest: PropTypes.string,
    labelClassName: PropTypes.string,
    allowOverflow: PropTypes.bool,
    itemToAppend: PropTypes.node,
  };

  static defaultProps = {
    className: '',
    label: '',
    value: '',
    isRequired: false,
    isValid: true,
    errorMessage: '',
    tabIndex: 0,
    onChange: () => {},
    onBlur: () => {},
    onSelect: () => {},
    onScroll: () => Promise.resolve(),
    isListOnTop: false,
    dropDownClassName: '',
    preselectFirstItem: false,
    allowCustom: true,
    inputClassName: '',
    inputClasses: '',
    formValidator: null,
    dataTest: '',
    labelClassName: '',
    allowOverflow: false,
    itemToAppend: null,
  };

  state = {
    dropdownId: '',
  };

  anchorRef = React.createRef();

  arrowButtonRef = React.createRef();

  getLabel = (key) => {
    const { items } = this.props;
    const item = items.find((i) => i.key === key);

    return item ? item.label : key;
  };

  onSelectHandler = (value) => {
    const { onSelect } = this.props;
    this.onChange(value || '');
    onSelect(value);
  };

  componentDidMount = () => {
    this.setState({ dropdownId: Math.floor(Math.random() * 10000000) });
  };

  render() {
    const {
      label,
      items,
      className,
      errorMessage,
      isValid,
      value,
      tabIndex,
      preselectFirstItem,
      allowCustom,
      onBlur,
      inputClassName,
      inputClasses,
      formValidator,
      dataTest,
      labelClassName,
      buttonClass,
      placeholder,
      onScroll,
      isListOnTop,
      dropDownClassName,
      allowOverflow,
      itemToAppend,
    } = this.props;

    const currValue = formValidator ? formValidator.value : value;
    const hasError = super.hasError();
    const isRequired = super.isRequired();

    const {
      onSelectHandler,
      onChange,
      onFocus: thisFocus,
      onBlur: thisBlur,
    } = this;

    return (
      <div
        className={cx(styles.inputDropdown, className)}
        onMouseLeave={this.onMouseLeave}
        onMouseEnter={this.onMouseEnter}
      >
        <Downshift
          onChange={(selection) => this.onSelectHandler((selection || '').key)}
          itemToString={(item) => (item ? item.label : '')}
          selectedItem={{ label: this.getLabel(currValue), key: currValue }}
        >
          {({
            getInputProps,
            getItemProps,
            getToggleButtonProps,
            isOpen,
            inputValue,
            selectedItem,
            highlightedIndex,
            clearSelection,
            openMenu,
            setHighlightedIndex,
            selectItemAtIndex,
          }) => {
            if (typeof inputValue !== 'string') {
              // eslint-disable-next-line no-param-reassign
              inputValue = '';
            }
            const filteredItems = items.filter((item) => {
              return item.label.toLowerCase().startsWith((inputValue || '').toLowerCase());
            });

            const itemsToDisplay = filteredItems
              .map((item, index) => (
                <div
                  key={item.key}
                  {...getItemProps({
                    key: item.key,
                    index,
                    item,
                    disabled: !!item.disabled,
                    'data-test': `${dataTest}DropdownItem${index}`,
                  })}
                  className={cx(styles.inputDropdownInputListItem, dropDownClassName, {
                    [styles.inputDropdownInputListItemActive]: highlightedIndex === index,
                    [styles.inputDropdownInputListItemSelected]: selectedItem === item,
                    [styles.inputDropdownInputListItemAllowOverflow]: allowOverflow,
                  })}
                >
                  {item.label}
                </div>
              ));

            if (itemToAppend) {
              itemsToDisplay.push((
                <div
                  {...getItemProps({
                    key: 'itemToAppend',
                    index: filteredItems.length,
                    item: { key: 'itemToAppend' },
                  })}
                  className={cx(styles.inputDropdownInputListItem, dropDownClassName, {
                    [styles.inputDropdownInputListItemActive]:
                      highlightedIndex === filteredItems.length,
                    [styles.inputDropdownInputListItemSelected]: selectedItem === itemToAppend,
                    [styles.inputDropdownInputListItemAllowOverflow]: allowOverflow,
                  })}
                >
                  {itemToAppend}
                </div>
              ));
            }

            return (
              <div className={styles.inputDropdownWrapper}
                   id={this.state.dropdownId}
                   onKeyDown={({ key }) => { if (key === 'Enter' && !filteredItems.length && !allowCustom) clearSelection(); }}
                   tabIndex={-1}
                   role="button"
              >
                <InputModern
                  tabIndex={tabIndex}
                  ref={this.anchorRef}
                  inputClasses={cx(styles.inputDropdownInputButton, inputClasses)}
                  inputClassName={inputClassName}
                  errorMessage={formValidator ? '' : errorMessage}
                  isValid={formValidator ? !hasError : isValid}
                  dataTest={dataTest}
                  placeholder={placeholder}
                  {...getInputProps({
                    onFocus(e) {
                      if (inputValue) {
                        if (e && e.target && e.target.select) {
                          e.target.select();
                        }
                      }
                      openMenu();
                      if (preselectFirstItem) {
                        setHighlightedIndex(0);
                      }
                      thisFocus(e);
                    },
                    onBlur() {
                      if (filteredItems.length) {
                        if (highlightedIndex !== null) {
                          selectItemAtIndex(highlightedIndex);
                          onBlur();
                          return;
                        }

                        if (inputValue.length !== 0) {
                          selectItemAtIndex(0); // Auto select first item
                          onBlur();
                          return;
                        }

                        clearSelection();
                      } else if (allowCustom) {
                        onSelectHandler(inputValue);
                        onBlur();
                      } else {
                        clearSelection();
                      }
                      thisBlur();
                    },
                    onChange,
                  })}
                />
                {inputValue ? (
                  <button
                    type="button"
                    tabIndex={-1}
                    className={cx(
                      styles.inputDropdownInputButtonIcon,
                      styles.inputDropdownInputButtonIconX,
                      buttonClass,
                    )}
                    onClick={() => {
                      this.anchorRef.current.inputRef.current.focus();
                      setHighlightedIndex(null);
                      clearSelection();
                      this.onSelectHandler('');
                      openMenu();
                    }}
                    data-test={`${dataTest}dropdownClear`}
                  >
                    <FontAwesomeIcon icon={closeIcon} />
                  </button>
                ) : (
                  <button
                    type="button"
                    className={cx(styles.inputDropdownInputButtonIcon, buttonClass)}
                    {...getToggleButtonProps({
                      onClick: () => this.arrowButtonRef.current.focus(),
                    })}
                    tabIndex={-1}
                    ref={this.arrowButtonRef}
                    data-test={`${dataTest}dropdownShowOptions`}
                  >
                    <FontAwesomeIcon icon={dropdownIcon}
                      className={cx(styles.inputDropdownInputButtonIconArrow, {
                        [styles.inputDropdownInputButtonIconArrowOpen]: isOpen,
                      })}
                    />
                  </button>
                )}
                {isOpen
                  ? <InfiniteBlock
                    onScroll={onScroll}
                    className={cx(styles.inputDropdownInputList, {
                      [styles.inputDropdownInputListOnTop]: isListOnTop,
                      [styles.inputDropdownInputListAllowOverflow]: allowOverflow,
                    })}
                    setHighlightedIndex={setHighlightedIndex}
                    highlightedIndex={highlightedIndex}
                    dataTest={`${dataTest}dropdownModernList`}
                  >
                    {itemsToDisplay}
                  </InfiniteBlock>
                  : null}
              </div>
            );
          }}
        </Downshift>
        {label && (
          <label className={cx(styles.inputDropdownLabel, labelClassName)}>
            {label}
            {isRequired ? <div className={styles.requiredMark}>
              *
            </div> : null}
          </label>
        )}
        {super.renderTooltip()}
      </div>
    );
  }
}
