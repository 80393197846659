import { hasPermission } from 'helpers/permissions';

export function getGarages({ user }) {
  return user.currentUser.garages;
}

export function getTotalAmount({ user }) {
  return user.totalAmount;
}

export function isCustomerMode({ user }) {
  return user.isCustomerMode;
}

export function getCurrentUserId({ user }) {
  return user.currentUser._id;
}

export function getCurrentUser({ user }) {
  return user.currentUser;
}

export function getCurrentGarageId({ user }) {
  return user.currentUser.currentGarageId;
}

export function getUsers({ user }) {
  return user.list;
}

export function can({ user, garage }, permission) {
  return hasPermission(user.currentUser, garage, permission);
}

export function getUserRoles({ user }) {
  if (!user.currentUser.currentGarageId) return null;
  return user.currentUser.garages
    .find((garage) => garage.garageId === user.currentUser.currentGarageId)
    .roles;
}

export function getMobileViewOption({ user }) {
  return user.currentUser.mobileViewOption;
}
