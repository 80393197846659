import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withWindowSize from 'components/higherOrderComponents/withWindowSize';

import styles from './overscroll.styles.pcss';

class Overscroll extends Component {
  static propTypes = {
    children: PropTypes.node,
    overscrollContent: PropTypes.node,
    overscrollContentDesktop: PropTypes.node, // Content to display on Desktop if mobileOnly is true
    centerHeader: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    start: PropTypes.number,
    unit: PropTypes.string,
    mobileOnly: PropTypes.bool,
    onScrollChange: PropTypes.func,
    scrollDisabled: PropTypes.bool,
    isMobileSize: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
  };

  static defaultProps = {
    children: null,
    overscrollContent: null,
    overscrollContentDesktop: null,
    centerHeader: true,
    min: 10,
    max: 80,
    start: 30,
    unit: '%',
    mobileOnly: true,
    onScrollChange: () => null,
    scrollDisabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.overscrollRef = React.createRef();
    this.overscrollHeaderRef = React.createRef();
    this.overscrollHeaderContainerRef = React.createRef();
  }

  componentDidMount() {
    // Set timeout for iOS, since all element clientHeights are wrong during initial few millisecs
    setTimeout(() => {
      this.scrollToInitialPosition();
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const { scrollDisabled, isMobileSize, height } = this.props;

    if (prevProps.scrollDisabled !== scrollDisabled && this.overscrollRef.current) {
      this.overscrollRef.current.style.overflow = scrollDisabled ? 'hidden' : 'auto';
    }

    if (isMobileSize) {
      if (!prevProps.isMobileSize) {
        this.scrollToInitialPosition();
      } else if (height !== prevProps.height && this.overscrollRef.current) {
        this.updateHeaderHeight(this.overscrollRef.current.scrollTop);
      }
    }
  }

  isScrolling = false;

  scrollTimeout = null;

  scrollToInitialPosition = () => {
    const { max, start, unit } = this.props;

    let toScroll = 0;
    if (unit === '%') {
      if (!this.overscrollHeaderContainerRef.current) return;
      const hundretPerc = (this.overscrollHeaderContainerRef.current.clientHeight / max) * 100;
      toScroll
        = this.overscrollHeaderContainerRef.current.clientHeight - (hundretPerc * start) / 100;
    } else {
      toScroll = max - start;
    }
    if (this.overscrollRef.current) {
      this.overscrollRef.current.scrollTop = toScroll;
      this.updateHeaderHeight(toScroll);
    }
  };

  updateHeaderHeight = (scrollTop) => {
    const { min, max, unit } = this.props;
    let contentHeaderHeight = 0;
    if (unit === '%') {
      if (!this.overscrollHeaderContainerRef.current) return;
      const containerHeight = this.overscrollHeaderContainerRef.current.clientHeight;
      contentHeaderHeight
        = Math.max((containerHeight * min) / 100, containerHeight - scrollTop);
    } else {
      contentHeaderHeight = Math.max(min, max - scrollTop);
    }
    if (this.overscrollHeaderRef.current) {
      this.overscrollHeaderRef.current.style.height = `${contentHeaderHeight}px`;
    }
  };

  onScroll = (event) => {
    this.updateHeaderHeight(event.target.scrollTop);
    if (!this.isScrolling) {
      this.setScroll(true);
    }
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(() => {
      this.scrollTimeout = null;
      this.setScroll(false);
    }, 500);
  };

  setScroll = (toSet) => {
    const { onScrollChange } = this.props;

    if (this.isScrolling !== toSet) {
      this.isScrolling = toSet;
      onScrollChange(this.isScrolling);
    }
  };

  render() {
    const {
      children,
      overscrollContent,
      min,
      unit,
      max,
      centerHeader,
      mobileOnly,
      overscrollContentDesktop,
      isMobileSize,
    } = this.props;

    if (mobileOnly && !isMobileSize) {
      if (overscrollContentDesktop) {
        return (
          <div>
            {overscrollContentDesktop}
            {children}
          </div>
        );
      }
      return children;
    }

    let contentMinHeight = '0px';

    if (this.overscrollRef && this.overscrollRef.current) {
      if (unit === '%') contentMinHeight = 100 - min;
      else contentMinHeight = this.overscrollRef.current.clientHeight - min;
      contentMinHeight += unit;
    }

    const overscrollHeaderContainerStyle = {
      height: `${max}${unit}`,
    };

    return (
      <div
        className={styles.overscroll}
        ref={this.overscrollRef}
        onScroll={this.onScroll}
      >
        <div
          className={styles.overscrollHeaderContainer}
          style={overscrollHeaderContainerStyle}
          ref={this.overscrollHeaderContainerRef}
        >
          <div
            className={cx(styles.overscrollHeader, {
              [styles.overscrollHeaderCentered]: centerHeader,
            })}
            ref={this.overscrollHeaderRef}
          >
            {overscrollContent}
          </div>
        </div>
        <div style={{ minHeight: contentMinHeight }}>
          {children}
        </div>
      </div>
    );
  }
}

export default withWindowSize(Overscroll);
