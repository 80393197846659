import React from 'react';
import PropTypes from 'prop-types';
import SwissEmblem from './components/swissEmblem';

import styles from './license.styles.pcss';

const renderEuEmblem = () => {
  return (
    <svg viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="8" height="8">
      <g id="starPiece" transform="translate(150,30)" fill="#FDCC03">
        <g id="c">
          <path xmlns="http://www.w3.org/2000/svg" transform="scale(10)" d="M 0 0 l 0.5 -1.5 l 0.5 1.5 l 1.5 0 l -1.25 0.9 l 0.5 1.46 l -1.25 -0.9 l -1.25 0.9 l 0.5 -1.46 l -1.25 -0.9 z" />
        </g>
      </g>
      {Array.from(Array(11)).map((curr, index) => (
        <use xlinkHref="#starPiece" key={index} transform={`rotate(${30 * (index + 1)} 150,150) rotate(${330 - (index * 30)} 150,30)`} />
      ))}
    </svg>
  );
};

const getBackgroundColor = (country) => {
  switch (country) {
    case 'NL':
    case 'LU': return '#FCBC02';
    case 'LI': return '#000000';
    default: return '#fff';
  }
};

const getFontColor = (country) => {
  switch (country) {
    case 'LI': return '#fff';
    default: return '#26282D';
  }
};

const getCountryShort = (country) => {
  switch (country) {
    case 'PL':
    case 'HR':
    case 'NL': return country;
    case 'LI': return 'FL';
    default: return country.charAt(0);
  }
};

const getCountryEmblem = (country) => {
  if (!country) return <div className={styles.country} />;
  if (country === 'CH') {
    return (
      <div className={styles.emblemContainer}>
        <SwissEmblem className={styles.emblem} />
      </div>
    );
  }
  if (country === 'LI') {
    return null;
  }
  return (
    <div className={styles.country}>
      {renderEuEmblem()}
      {getCountryShort(country)}
    </div>
  );
};

const License = ({
  licenseNumber, country, ...props
}) => {
  const licensePlateStyles = {
    backgroundColor: getBackgroundColor(country),
    color: getFontColor(country),
  };

  return (
    <div className={styles.container} style={licensePlateStyles}>
      {getCountryEmblem(country)}
      <div className={styles.license}>
        {licenseNumber || '-- --- --'}
      </div>
    </div>

  );
};

License.propTypes = {
  licenseNumber: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

License.defaultProps = {
};

export default License;
