import React from 'react';
import PropTypes from 'prop-types';

import InfiniteBlock from 'components/common/infiniteBlock';
import ListItem from './components/listItem';

import styles from './entitiesList.styles';

const EntitiesList = ({
  entities,
  selectedEntity,
  onScroll,
  onSelect,
  getLabel,
  isDataLoading,
  onDoubleClick,
}) => (
  <InfiniteBlock
    className={styles.entitiesListWrapper}
    isDataLoading={isDataLoading}
    onScroll={onScroll}
  >
    <ul className={styles.entitiesList}>
      {entities.map((entity) => (
        <ListItem
          key={entity._id}
          entity={entity}
          selected={selectedEntity._id === entity._id}
          onSelect={onSelect}
          onDoubleClick={onDoubleClick}
          label={getLabel(entity)}
          dataTest={`addEventVehicleListItem${entity._id}`}
        />
      ))}
    </ul>
  </InfiniteBlock>
);

EntitiesList.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
  })).isRequired,
  selectedEntity: PropTypes.shape({
    _id: PropTypes.string,
  }),
  getLabel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
  isDataLoading: PropTypes.bool,
  onDoubleClick: PropTypes.func,
};

EntitiesList.defaultProps = {
  onScroll: () => Promise.resolve(),
  onDoubleClick: () => {},
  selectedEntity: {},
  isDataLoading: false,
};

export default EntitiesList;
