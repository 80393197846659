const _ = require('lodash');

module.exports.BRANDS = [
  'APlus',
  'Accelera',
  'Achilles',
  'American Classic',
  'Antares',
  'Aoteli',
  'Apollo',
  'Artum',
  'Assa',
  'Atlas',
  'Atturo',
  'Aurora',
  'Autogrip',
  'Avon',
  'Avon Vintage',
  'BF Goodrich',
  'Barum',
  'Boto',
  'Bridgestone',
  'Camac',
  'Coker',
  'Compass',
  'Constancy',
  'Continental',
  'Cooper',
  'Cordiant',
  'Dayton',
  'Debica',
  'Delinte',
  'Double Star',
  'Dunlop',
  'Duraturn',
  'Duro',
  'Durun',
  'Effiplus',
  'Event',
  'Event Tyres',
  'Evergreen',
  'Excelon',
  'Excelsior',
  'Falken',
  'Federal',
  'Firestone',
  'Formula',
  'Fortuna',
  'Fulda',
  'Fullrun',
  'GT Radial',
  'General',
  'Gerutti',
  'Gislaved',
  'Goform',
  'Goodride',
  'Goodyear',
  'Gremax',
  'Gripmax',
  'HI FLY',
  'Habilead',
  'Haida',
  'Hankook',
  'Headway',
  'Heidenau',
  'Horizon',
  'Imperial',
  'Infinity',
  'Insa Turbo',
  'Interstate',
  'Jinyu',
  'Joyroad',
  'Kama',
  'Kelly',
  'Kenda',
  'Keter',
  'Kinforest',
  'King Meiler',
  'Kingstar',
  'Kleber',
  'Kormoran',
  'Kumho',
  'Landsail',
  'Lanvigator',
  'Lassa',
  'Laufenn',
  'Leao',
  'Lexani',
  'Linglong',
  'Malatesta',
  'Maloya',
  'Marix',
  'Marshal',
  'Master',
  'Mastersteel',
  'Matador',
  'Maxtrek',
  'Maxxis',
  'Mentor',
  'Meteor',
  'Metzler',
  'Michelin',
  'Michelin Collection',
  'Mickey Thompson',
  'Milestone',
  'Minerva',
  'Mohawk',
  'Nankang',
  'Nexen',
  'Nokian',
  'Nordexx',
  'Nortenha',
  'Novex',
  'Ovation',
  'Pace',
  'Petlas',
  'Phoenix',
  'Pirelli',
  'Pneumant',
  'PowerTrac',
  'Premada',
  'Premiorri',
  'Profil',
  'Radar',
  'Radburg',
  'Recip',
  'Retro',
  'Riken',
  'Roadstone',
  'Rockstone',
  'Rosava',
  'Rotalla',
  'Rotex',
  'Rovelo',
  'Runway',
  'Saetta',
  'Sailun',
  'Sava',
  'Security',
  'Semperit',
  'Silverstone',
  'Sonar',
  'Sportiva',
  'Star Performer',
  'Starfire',
  'Starmaxx',
  'Sunitrac',
  'Sunny',
  'Sunwide',
  'Superia',
  'Syron',
  'Taurus',
  'Tigar',
  'Toledo',
  'Torque',
  'Toyo',
  'Tracmax',
  'Trelleborg',
  'Triangle',
  'Tristar',
  'Tyfoon',
  'Uniroyal',
  'Viking',
  'Vredestein',
  'Wanli',
  'Waymaster',
  'Westlake',
  'Windforce',
  'Winrun',
  'Winter Tact',
  'Yokohama',
  'Zeetex',
  'Zeta',
];

module.exports.WIDTHS = [
  '10',
  '11',
  '13',
  '90',
  '100',
  '110',
  '120',
  '125',
  '130',
  '135',
  '140',
  '145',
  '140',
  '150',
  '155',
  '160',
  '165',
  '170',
  '175',
  '180',
  '185',
  '190',
  '195',
  '205',
  '215',
  '225',
  '235',
  '245',
  '255',
  '265',
  '275',
  '285',
  '295',
  '305',
  '315',
  '325',
  '335',
  '355',
  '385',
  '445',
  '8.5',
  '5.00',
  '7.50',
  '31x10.50',
  '35x12.50',
];

module.exports.MAX_LOAD_INDEXES = {
  20: 80,
  21: 82.5,
  22: 85,
  23: 87.5,
  24: 90,
  25: 92.5,
  26: 95,
  27: 97.5,
  28: 100,
  29: 103,
  30: 106,
  31: 109,
  32: 112,
  33: 115,
  34: 118,
  35: 121,
  36: 125,
  37: 128,
  38: 132,
  39: 136,
  40: 140,
  41: 145,
  42: 150,
  43: 155,
  44: 160,
  45: 165,
  46: 170,
  47: 175,
  48: 180,
  49: 185,
  50: 190,
  51: 195,
  52: 200,
  53: 206,
  54: 212,
  55: 218,
  56: 224,
  57: 230,
  58: 236,
  59: 243,
  60: 250,
  61: 257,
  62: 265,
  63: 272,
  64: 280,
  65: 290,
  66: 300,
  67: 307,
  68: 315,
  69: 325,
  70: 335,
  71: 345,
  72: 355,
  73: 365,
  74: 375,
  75: 387,
  76: 400,
  77: 412,
  78: 425,
  79: 437,
  80: 450,
  81: 462,
  82: 475,
  83: 487,
  84: 500,
  85: 515,
  86: 530,
  87: 545,
  88: 560,
  89: 580,
  90: 600,
  91: 615,
  92: 630,
  93: 650,
  94: 670,
  95: 690,
  96: 710,
  97: 730,
  98: 750,
  99: 775,
  100: 800,
  101: 825,
  102: 850,
  103: 875,
  104: 900,
  105: 925,
  106: 950,
  107: 975,
  108: 1000,
  109: 1030,
  110: 1060,
  111: 1090,
  112: 1120,
  113: 1150,
  114: 1180,
  115: 1215,
  116: 1250,
  117: 1285,
  118: 1320,
  119: 1360,
  120: 1400,
  121: 1450,
  122: 1500,
  123: 1550,
  124: 1600,
  125: 1650,
  126: 1700,
  127: 1750,
  128: 1800,
  129: 1850,
  130: 1900,
  131: 1950,
  132: 2000,
  133: 2060,
  134: 2120,
  135: 2180,
  136: 2240,
  137: 2300,
  138: 2360,
  139: 2430,
  140: 2500,
  141: 2575,
  142: 2650,
  143: 2725,
  144: 2800,
  145: 2900,
  146: 3000,
  147: 3075,
  148: 3150,
  149: 3250,
  150: 3350,
  151: 3450,
  152: 3550,
  153: 3650,
  154: 3750,
  155: 3875,
  156: 4000,
  157: 4125,
  158: 4250,
  159: 4375,
  160: 4500,
  161: 4625,
  162: 4750,
  163: 4875,
  164: 5000,
  165: 5150,
  166: 5300,
  167: 5450,
  168: 5600,
  169: 5850,
  170: 6000,
  171: 6150,
  172: 6300,
  173: 6500,
  174: 6700,
  175: 6900,
  176: 7100,
  177: 7300,
  178: 7500,
  179: 7750,
  180: 8000,
  181: 8250,
  182: 8500,
  183: 8750,
  184: 9000,
  185: 9250,
  186: 9500,
  187: 9750,
  188: 10000,
  189: 10300,
  190: 10600,
  191: 10900,
  192: 11200,
  193: 11500,
  194: 11800,
  195: 12150,
  196: 12500,
  197: 12850,
  198: 13200,
  199: 13600,
  200: 14000,
  201: 14500,
  202: 15000,
  203: 15550,
  204: 16000,
};

module.exports.SPEED_INDEXES = {
  G: 90,
  J: 100,
  K: 110,
  L: 120,
  M: 130,
  N: 140,
  P: 150,
  Q: 160,
  R: 170,
  S: 180,
  T: 190,
  H: 210,
  V: 240,
  W: 270,
  Y: 300,
  ZR: undefined,
};

module.exports.MODELS = ['winter', 'summer', 'all', 'spiked'];

module.exports.RIMS = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];

module.exports.CROSS_SECTIONS = _.range(25, 85, 5);

module.exports.RIM_TYPES = ['steel', 'alloy', 'none'];
