import React, { Component } from 'react';
import cx from 'classnames';

import moment from 'moment';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faArrowDown, faCircleXmark, faInfoCircle } from 'fontawesome/pro-solid-svg-icons';
import InteractableDiv from 'components/common/interactableDiv';

import loggerService from 'services/logger.service';
import localStorage from 'services/localStorage';

import styles from './logger.styles.pcss';

class Logger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logsShown: localStorage.getItem('loggerSettings')?.logsShown || false,
      minimized: localStorage.getItem('loggerSettings')?.minimized || false,
    };

    this.logs = [];

    loggerService.addLogsUpdateListener(({ logs }) => {
      const { logsShown } = this.state;
      this.logs = logs;
      if (logsShown) {
        this.forceUpdate();
      }
    });
  }

  toggleLogsShown = () => {
    const { logsShown, minimized } = this.state;
    this.setState({ logsShown: !logsShown });
    localStorage.setItem('loggerSettings', { logsShown: !logsShown, minimized });
  };

  render() {
    const { logsShown, minimized } = this.state;
    return (
      <>
        <InteractableDiv
          className={styles.toggleLogsButton}
          onClick={this.toggleLogsShown}
        >
          <FontAwesomeIcon icon={logsShown ? faCircleXmark : faInfoCircle} />
        </InteractableDiv>
        <div className={cx(styles.logsContainer, {
          [styles.logsContainerShown]: logsShown,
          [styles.logsContainerMinimized]: minimized,
        })}>
          <div
            className={cx(styles.logs)}
          >
            {this.logs.map((log, index) => (
              <div className={cx(styles.log)} key={index}>
                <div className={cx({
                  [styles.logInfo]: log.level === loggerService.LOG_LEVELS.INFO,
                  [styles.logWarn]: log.level === loggerService.LOG_LEVELS.WARNING,
                  [styles.logErr]: log.level === loggerService.LOG_LEVELS.ERROR,
                })}>
                  {moment(log.timestamp).format('HH:mm:SSS')}
                </div>
                <div>
                  {log.message}
                </div>
              </div>
            ))}
          </div>
          <InteractableDiv
            className={cx(styles.minimizeButton, minimized && styles.maximizeButton)}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ minimized: !minimized });
              localStorage.setItem('loggerSettings', { logsShown, minimized: !minimized });
            }}
          >
            <FontAwesomeIcon
              icon={faArrowDown}
            />
          </InteractableDiv>
        </div>
      </>
    );
  }
}

export default Logger;
