import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  createClient,
  updateClient,
} from 'resources/clients/clients.actions';

import ClientEditModal from './clientEditModal';

export default connect(null, {
  createClient,
  updateClient,
})(withRouter(ClientEditModal));
