import DymoPrintService from './dymoPrintService';
import toastService from './toastService';
import polyglot from '../services/localization';

export async function printTireLabels(tire) {
  let tireCount = 4;
  if (tire.vehicle && tire.vehicle.carClass) {
    switch (tire.vehicle.carClass.substr(0, 3)) {
      case 'L1e':
      case 'L3e':
        tireCount = 2;
        break;
      case 'L2e':
      case 'L4e':
      case 'L5e':
        tireCount = 3;
        break;
      default:
        break;
    }
  }

  // const activePrinter = await DymoPrintService.getActivePrinter();
  let activePrinter;
  try {
    const printers = await DymoPrintService.getPrinters();
    if (printers.length === 0) {
      toastService.showError(polyglot.t('dymoPrinter.noPrintersReturned'));
      return;
    }
    activePrinter = printers.find((printer) => printer.isConnected.toLowerCase() === 'true');
    if (!activePrinter) activePrinter = printers.find((printer) => printer.isLocal?.toLowerCase() === 'false');
  } catch (err) {
    toastService.showError(polyglot.t('dymoPrinter.noConnectionToService'));
    throw err;
  }

  if (!activePrinter) {
    toastService.showError(polyglot.t('dymoPrinter.noActivePrintersFound'));
    return;
  }
  const { license } = tire.vehicle;
  const { firstName, lastName } = tire.client;
  const { storedOn, storageLocation } = tire;
  for (let i = 0; i < tireCount; i += 1) {
    let label;
    let dimension;
    switch (i) {
      case 0:
        label = polyglot.t('tires.position.frontLeft');
        dimension = tire.frontTires;
        if (tireCount < 4) label = polyglot.t('tires.position.front');
        break;
      case 1:
        label = polyglot.t('tires.position.frontRight');
        dimension = tire.frontTires;
        if (tireCount < 4) dimension = tire.backTires;
        if (tireCount === 2) label = polyglot.t('tires.position.rear');
        else if (tireCount === 3) label = polyglot.t('tires.position.rearLeft');
        break;
      case 2:
        label = polyglot.t('tires.position.rearLeft');
        dimension = tire.backTires;
        if (tireCount === 3) label = polyglot.t('tires.position.rearRight');
        break;
      case 3:
        label = polyglot.t('tires.position.rearRight');
        dimension = tire.backTires;
        break;
      default:
        label = i + 1;
        dimension = '-';
        break;
    }
    const dimensionString = `${dimension.width || '-'}/${dimension.crossSection || '-'}${'-'}${dimension.rim || '-'} ${dimension.maxLoad || '-'}${dimension.speedIndex || '-'}`;
    try {
      await DymoPrintService.printTireLabel(
        label || '',
        license || '',
        `${firstName || ''} ${lastName || ''}`,
        dimensionString,
        storageLocation || '',
        storedOn || '',
        activePrinter.name,
      );
    } catch (e) {
      toastService.showError(polyglot.t('dymoPrinter.noConnectionToServiceWhilePrinting'));
      throw e;
    }
  }
}
