import React from 'react';
import PropTypes from 'prop-types';

const OfferIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <path d="M19.627 12.8615C19.2173 12.49 18.5784 12.5143 18.1479 12.8615L14.9396 15.4274C14.5458 15.7434 14.0556 15.9149 13.5507 15.9135H9.44424C9.2969 15.9135 9.1556 15.855 9.05141 15.7508C8.94723 15.6466 8.8887 15.5053 8.8887 15.358C8.8887 15.2106 8.94723 15.0693 9.05141 14.9652C9.1556 14.861 9.2969 14.8024 9.44424 14.8024H12.1626C12.7147 14.8024 13.2289 14.424 13.3171 13.8788C13.3279 13.8162 13.3333 13.7528 13.333 13.6893C13.3325 13.3949 13.2152 13.1129 13.0069 12.905C12.7986 12.697 12.5163 12.5803 12.222 12.5803H6.66652C5.7296 12.5805 4.82124 12.9028 4.09366 13.4931L2.47911 14.8024H0.555544C0.408204 14.8024 0.2669 14.861 0.162715 14.9652C0.0585303 15.0693 0 15.2106 0 15.358L0 18.6912C0 18.8386 0.0585303 18.9799 0.162715 19.0841C0.2669 19.1883 0.408204 19.2468 0.555544 19.2468H12.9431C13.4478 19.2472 13.9377 19.0757 14.332 18.7607L19.5829 14.5594C19.7092 14.4584 19.812 14.331 19.8839 14.1861C19.9559 14.0413 19.9954 13.8825 19.9996 13.7208C20.0039 13.5591 19.9727 13.3984 19.9084 13.25C19.8441 13.1016 19.7479 12.969 19.627 12.8615Z" fill={props.color}/>
    <path d="M11.4512 0.60611L13.3633 2.52017C13.4512 2.60806 13.5 2.7272 13.5 2.85025V2.96939H11V0.469391H11.1191C11.2441 0.469391 11.3633 0.518219 11.4512 0.60611Z" fill={props.color}/>
    <path d="M10.375 0.469391V3.12564C10.375 3.38345 10.5859 3.59439 10.8438 3.59439H13.5V10.0006C13.5 10.2604 13.291 10.4694 13.0312 10.4694H6.46875C6.20898 10.4694 6 10.2604 6 10.0006V0.938141C6 0.678375 6.20898 0.469391 6.46875 0.469391H10.375Z" fill={props.color}/>
  </svg>
);

OfferIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

OfferIcon.defaultProps = {
  width: '20',
  height: '21',
  color: '#4F6374',
};

export default OfferIcon;
